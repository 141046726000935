import axios from 'axios';
import moment from 'moment';
import {API_URL_BASE} from '../constants/constants';


import {
  WAITING_REQUEST,
  GET_COMPANY_JOBS_FULFILLED,
  GET_COMPANY_JOBS_FAILED,
  GET_COMPANY_JOBS_FILTERED_DATA
} from '../constants/actionTypes';
import {common} from '../utils/common';

export const getJobsData = (token) => {
  const commonObj=new common();
  return (dispatch) => {
    dispatch({type:WAITING_REQUEST, value:true});

    axios.get(`${API_URL_BASE}/event/booked/company`,{headers:{"Authorization" : `Token ${token}`}})
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch,GET_COMPANY_JOBS_FULFILLED);
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,GET_COMPANY_JOBS_FAILED);
      })
  }
}

export const getJobsDataByDate = (token,sort,filter) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_REQUEST, value:true});

    axios.get(`${API_URL_BASE}/event/list/${sort}/${filter}`,{headers:{"Authorization" : `Token ${token}`}})
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch,GET_COMPANY_JOBS_FULFILLED);
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,GET_COMPANY_JOBS_FAILED);
      })
  }
}