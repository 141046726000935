import React,{Component} from 'react';
import {
  Segment,
  Button,
  Item,
  Icon,
  Grid,
  Header,
  Label,
  List} from 'semantic-ui-react';
import * as moment from "moment";
import DateBox from '../../Common/DateBox';
import {isMobileOnly} from 'react-device-detect';
import './bookings-talent.css';

import {getJobEarliestDate} from "../../Jobs/helpers";

class BookingList extends Component{
  constructor(props){
    super(props);
  }

  sortList = [
    { text: "Date", value: "Date"},
    { text: "City", value: "City"},
    { text: "Pay", value: "Pay"}
  ];

  multiDayEvent(days) {
    if (days.length > 1){
      return (
        <Label as='a' color='olive' ribbon='left'>
          {days.length}-day event
        </Label>
      )
    }
    return
  }

  countDown(start_date) {
    const now = moment();
    const end = moment(start_date);
    const days = end.diff(now, 'days');
    const hours = end.diff(now, 'hours');
    const humanize = end.fromNow();
    // const days = humanize.asDays();

    if (hours > 0 && days < 7){
      return (
        <Label as='a' color='red' ribbon='left'>
          {humanize}
        </Label>
      )
    } else if (days > 7 && days < 21){
      return (
        <Label as='a' color='olive' ribbon='left'>
          {humanize}
        </Label>
      )
    }
    return
  }

  render(){
    let list=[];

    let sortedJobs = this.props.bookings.sort((job1, job2) => getJobEarliestDate(job1) > getJobEarliestDate(job2));

    if(this.props.bookings.length>0){
      list=sortedJobs.map((item,index)=>{
        if(item===undefined){
          return
        }
        return (
          <div key={index} style={{marginBottom:'14px', cursor:'pointer'}} onMouseEnter={(e)=>{this.props.onHover(item.id)}} onClick={(e)=>this.props.goToPage(item.id)}>
            <Item>
              <Item.Content>
                <Item.Description className={'item-client-name'}>
                  <Grid textAlign={'left'}>
                    <Grid.Row>
                      <Grid.Column width={3} >
                        <DateBox date={item.start_date} />
                        { this.multiDayEvent(item.event_days) }
                        { this.countDown(item.start_date) }
                      </Grid.Column>
                      <Grid.Column width={13} >
                        <Header as={'h3'}>
                          <a href={'/job/'+item.id} className={'job-title'}>{item.name}</a>
                        </Header>

                        <Header as={'h5'} textAlign={'left'}>
                          <Icon name={'building'} color={'blue'}/>
                          {item.company.name}
                        </Header>

                        <Header as={'h5'} textAlign={'left'}>
                          <Icon name={'location arrow'} color={'blue'}/>
                          {item.event_days[0].location}
                        </Header>

                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Item.Description>
              </Item.Content>
            </Item>
            {index<this.props.bookings.length-1 && <hr/>}
          </div>
        )
      });
    }

    let display = this.props.bookings.length==0 ? 'none' : 'block';

    return (
      <Segment.Group style={{display:display}}>
        <Segment secondary textAlign={'left'}>
          <Grid verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={10} computer={10} textAlign={'left'}>
                <Header size='small' color='grey' style={{display: 'inline'}}>
                  {this.props.bookings.length} {this.props.status}
                </Header>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={6} computer={6}
               {...(isMobileOnly===true && {textAlign:'left'})}
               {...(isMobileOnly===false && {textAlign:'right'})}
              >
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment>
          {list}
        </Segment>
      </Segment.Group>
    )
  }
}

export default BookingList
