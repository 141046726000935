import React,{Component} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import {Container, Grid, Button, Loader} from 'semantic-ui-react';
import TimePicker from 'rc-time-picker';
import UserCard from './UserCard';
import {
    updateTimeSheetLineItems,
    getEventTimeSheetLineItemList,
    withdrawTimeSheet,
} from "../../../actions/timesheet";
import moment from 'moment';
import ServerErrors from "../../Common/ServerErrors";
import ServerSuccess from "../../Common/ServerSuccess"

const stateToProps=(state)=>{

  const timeSheets = state.timesheet.eventTimeSheets;
  _.each(timeSheets, timeSheet => {
      _.each(timeSheet['time_sheet_line_items'], item => {
          const shift = item['event_shift'];
          if (!item['talent_start_time']){
              item['talent_start_time'] = item['application_start_time'] ? item['application_start_time'] : `${shift['event_day']['date']} ${shift['start_time']}`;
          }
          if (timeSheet.status === 'submitted_to_talent'){
              item['talent_start_time'] = item['client_start_time']
          }

          if (!item['talent_end_time']){
              item['talent_end_time'] = item['application_end_time'] ? item['application_end_time'] : `${shift['event_day']['date']} ${shift['end_time']}`;
          }
          if (timeSheet.status === 'submitted_to_talent'){
              item['talent_end_time'] = item['client_end_time']
          }
      })
  });

  return{
    waitingRequest: state.common.waitingRequest,
    timeSheets,
    error: state.timesheet.error,
    success: state.timesheet.success,
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    getEventTimeSheetLineItemList:(id)=>dispatch(getEventTimeSheetLineItemList(id)),
    updateTimeSheetLineItems:(id, data)=>dispatch(updateTimeSheetLineItems(id, data)),
    withdrawTimeSheet:(eventId, timeSheetId)=>dispatch(withdrawTimeSheet(eventId, timeSheetId))
  }
};

class TimeSheet extends Component{
  constructor(props){
    super(props);
    this.state = {
        timeSheets: [],
        finalized: false,
    }
  }

  componentDidMount(){
    const eventId=this.props.match.params['event_id'];
    this.jobId = eventId;
    this.props.getEventTimeSheetLineItemList(eventId);
  }

  componentWillReceiveProps(props){
      this.setState({timeSheets: props.timeSheets});
  }

  onChange = (id, name, value) => {
      // update time_sheet_line_item.start_time/end_time
      const {timeSheets} = this.state;
      _.each(timeSheets, timeSheet => {
          _.each(timeSheet['time_sheet_line_items'], item => {
             if (item.id === id){
                 item[name] = moment(value, 'hh:mm A').utc().format();
             }
          })
      });

      this.setState({timeSheets});
  };

  finalizeTimeSheets = () => {
    let timeSheetItems = this.state.timeSheets.reduce(
        (arr, timeSheet) => arr.concat(timeSheet['time_sheet_line_items']), []
      );

    timeSheetItems = timeSheetItems.map(item => {
       return {
           id: item['id'],
           talent_start_time: item['talent_start_time'],
           talent_end_time: item['talent_end_time'],
           time_sheet: item['time_sheet']['id']
       };
    });

    const eventId = this.props.match.params['event_id'];
    this.props.updateTimeSheetLineItems(eventId, timeSheetItems);
    this.setState({finalized: true});
  };

  renderTalent = (talent, role) => {
      return <UserCard key={talent.id} user={talent.user} role={role}/>
  };

  renderTimeSheetStatus = (timeSheetItem) => {
      if (timeSheetItem['time_sheet']['status'] === 'submitted_to_client'){
          return `Submitted to client at ${moment.utc(timeSheetItem['time_sheet']['submitted_to_client_at']).local().format('LLL')}`;
      }

      if (timeSheetItem['time_sheet']['status'] === 'in_review'){
          return 'In Review for Shift Dash Staff';
      }

      if (timeSheetItem['time_sheet']['status'] === 'reviewed'){
          return 'Reviewed by Shift Dash Staff';
      }

      if (timeSheetItem['time_sheet']['status'] === 'approved'){
          return 'Approved';
      }
  };

  renderTimeSheetItem = (timeSheetItem) => {
     const shift = timeSheetItem['event_shift'];
     const isEditable = ['new', 'submitted_to_talent'].includes(timeSheetItem['time_sheet']['status']);
     const shiftDate = `${moment(shift['event_day']['date']).format('MMM')} ${moment(shift['event_day']['date']).format('DD')}`;
     const talentStartTime = timeSheetItem['talent_start_time'] ? moment.utc(timeSheetItem['talent_start_time']).local() : null;
     const talentEndTime = timeSheetItem['talent_end_time'] ? moment.utc(timeSheetItem['talent_end_time']).local() : null;

     return (
         <Grid.Row className={'time-sheet-item'} style={{'marginBottom': '20px'}}>
            <Grid.Row>
                 <Grid.Column width={16}>
                     <p style={{'textAlign': 'center'}}>
                        <strong>{shiftDate}</strong>
                          &emsp;
                        {moment.utc(`${shift['event_day']['date']} ${shift['start_time']}`).local().format('hh:mm A')}
                          &nbsp; - &nbsp;
                        {moment.utc(`${shift['event_day']['date']} ${shift['end_time']}`).local().format('hh:mm A')}
                    </p>
                 </Grid.Column>
             </Grid.Row>
            <Grid.Row style={{'textAlign': 'center'}}>
                    <TimePicker
                      placeholder={'start time'}
                      allowEmpty={false}
                      minuteStep={15}
                      showSecond={false}
                      value={talentStartTime}
                      format={'hh:mm A'}
                      use12Hours={true}
                      disabled={!isEditable}
                      onChange={value => {
                          isEditable &&
                          this.onChange(timeSheetItem['id'], 'talent_start_time', value)
                      }}
                    />
                    &nbsp; - &nbsp;
                    <TimePicker
                      placeholder={'end time'}
                      allowEmpty={false}
                      minuteStep={15}
                      showSecond={false}
                      value={talentEndTime}
                      format={'hh:mm A'}
                      use12Hours={true}
                      disabled={!isEditable}
                      onChange={value => {
                          isEditable &&
                          this.onChange(timeSheetItem['id'], 'talent_end_time', value)
                      }}
                    />
                </Grid.Row>
            <Grid.Row style={{'marginTop': '10px'}}>
                 <Grid.Column width={16}>
                     <p style={{'textAlign': 'center'}}>
                     {this.renderTimeSheetStatus(timeSheetItem)}
                     </p>
                 </Grid.Column>
            </Grid.Row>
         </Grid.Row>
     )
  };

  renderTimeSheet = (timeSheet) => {
        const talentRole = timeSheet['time_sheet_line_items'][0]['event_shift']['talent_role']['name']
        return (
            <Grid.Row style={{'marginBottom': '250px'}}>
                <Grid.Column width={8} style={{'width': '50%', 'float': 'left'}}>
                    {this.renderTalent(timeSheet.talent, talentRole)}
                </Grid.Column>

                <Grid.Column width={8} style={{'width': '50%', 'float': 'left'}}>
                    {timeSheet['time_sheet_line_items'].map(item => {
                        return this.renderTimeSheetItem(item);
                    })}
                </Grid.Column>

                <Grid.Column width={8} style={{'width': '50%', 'float': 'left', 'textAlign': 'center'}}>
                    {
                        timeSheet.status === 'submitted_to_client' &&
                        <Button size='large' color={'blue'} onClick={() => this.props.withdrawTimeSheet(this.jobId, timeSheet.id)}>Withdraw</Button>
                    }
                </Grid.Column>
            </Grid.Row>
        )
  };

  render(){
    const {waitingRequest, success, error} = this.props;
    const {timeSheets, finalized} = this.state;

    if (timeSheets.length === 0 && !error && !waitingRequest){
        return <ServerErrors errorMessage={'No time sheet found for this job'} />
    }

    return(
      <Container className={'main-container'}>
        <Grid stackable  padded style={{width:'100%'}}>
          <Grid.Row className={'content-row'}>
            <Grid style={{width: '100%'}}>
                <Grid.Row>
                    {!waitingRequest && error && <ServerErrors errorMessage={error} />}
                </Grid.Row>
              <Grid.Row>
                <Grid.Column width={11}>
                    {timeSheets.map(timeSheet => {
                       return this.renderTimeSheet(timeSheet)
                    })}
                </Grid.Column>
                <Grid.Column width={5}>
                  <div style={{textAlign: 'center', 'marginBottom': '10px'}}>
                    <Button size='large' onClick={()=>this.props.history.push(`/job/${this.jobId}`)}>Cancel</Button>
                    <Button size='large' color={'blue'} onClick={() => this.finalizeTimeSheets()}>Finalize</Button>
                  </div>
                  {waitingRequest && <Loader active inline='centered' />}
                  {!waitingRequest && finalized && success && <ServerSuccess successMessage={'Time sheets updated'} />}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}

export default connect(stateToProps,dispatchToProps)(TimeSheet)
