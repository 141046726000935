/* eslint no-use-before-define: ["error", { "variables": false }] */

import PropTypes from 'prop-types';
import React from 'react';
import {connect} from "react-redux";
import { Platform, StyleSheet, TextInput } from 'react-native';
import {Button, Icon} from 'semantic-ui-react';
import ReactS3Uploader from 'react-s3-uploader';

import { MIN_COMPOSER_HEIGHT, DEFAULT_PLACEHOLDER } from './Constant';
import Color from './Color';
import 'emoji-mart/css/emoji-mart.css';
import EmojiMartPicker from "emoji-mart-picker";
import { findDOMNode } from 'react-dom'

import {
  CHAT_TEXT_INPUT_FOCUSED
} from "../../../constants/actionTypes";
import {API_URL_BASE} from "../../../constants/constants";

const stateToProps=(state)=>{
  return{
    chatTextInputFocused: state.messaging.chatTextInputFocused,
    loggedInUser: state.user.profile
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    setChatTextInputFocused:()=>dispatch({type:CHAT_TEXT_INPUT_FOCUSED,value:true}),
    removeChatTextInputFocused:()=>dispatch({type:CHAT_TEXT_INPUT_FOCUSED,value:false})
  }
};

class Composer extends React.Component {

  constructor(props){
    super(props);

    this.state={
      selectionStartPoint:0,
    }
  }

  onContentSizeChange(e) {
    const { contentSize } = e.nativeEvent;

    // Support earlier versions of React Native on Android.
    if (!contentSize) return;

    if (
      !this.contentSize ||
      this.contentSize.width !== contentSize.width ||
      this.contentSize.height !== contentSize.height
    ) {
      this.contentSize = contentSize;
      this.props.onInputSizeChanged(this.contentSize);
    }
  }

  onChangeText(text, isEmoji) {
    if (typeof text==='string'){
      this.props.onTextChanged(text);
    } else {
      let prevText=this.props.text;
      let formattedText=prevText.substring(0, this.state.selectionStartPoint+1) + text.native + prevText.substring(this.state.selectionStartPoint)
      this.props.onTextChanged(formattedText);
    }

    if (isEmoji){
      document.getElementById('messagingTextInput').focus();
    }
  }

  handleKeyDown=(e)=> {
    if(e.nativeEvent.key === "Enter"){
      this.props.onSend({ text: this.props.text.trim() }, true)
    }

    let selectionStartPoint = e.target.selectionStart;
    this.setState({selectionStartPoint});
  }

  render() {
    const {props} = this;
    return (
      <React.Fragment>
        <label className={'ui big icon button'}>
          <Icon name='attach' style={{height:'23px'}}/>
          <ReactS3Uploader
            disabled={!props.user._id || props.isFileUploadStarted}
            style={{display:'none'}}
            signingUrl={`${API_URL_BASE}/messaging/s3/sign-url`}
            signingUrlMethod={"GET"}
            s3path={"messages"}
            preprocess={(file, next) => props.s3PreProcessFileUpload(file, next)}
            onProgress={(percent, message) => props.s3FileUploadProgress(percent, message)}
            onError={ message => props.s3FileUploadError(message)}
            onFinish={ result => props.s3FileUploadFinish(result)}
            signingUrlHeaders={{ authorization: `Token ${props.loggedInUser.token}` }}
            signingUrlQueryParams={{ eventName: props.activeChannelName }}
            signingUrlWithCredentials={ true }      // in case when need to pass authentication credentials via CORS
            uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}  // this is the default
            contentDisposition="auto"
            scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/ig, '')}
            autoUpload={true}
          />
        </label>
        <TextInput
          id="messagingTextInput"
          testID={this.props.placeholder}
          accessible
          accessibilityLabel={this.props.placeholder}
          placeholder={this.props.placeholder}
          placeholderTextColor={this.props.placeholderTextColor}
          multiline={this.props.multiline}
          onChange={(e) => this.onContentSizeChange(e)}
          onContentSizeChange={(e) => this.onContentSizeChange(e)}
          onChangeText={(text) => this.onChangeText(text,false)}
          style={[styles.textInput, this.props.textInputStyle, { height: this.props.composerHeight }]}
          autoFocus={this.props.textInputAutoFocus}
          value={this.props.text}
          enablesReturnKeyAutomatically
          underlineColorAndroid="transparent"
          keyboardAppearance={this.props.keyboardAppearance}
          onKeyPress={(e)=>this.handleKeyDown(e)}
          onFocus={this.props.setChatTextInputFocused}
          onBlur={this.props.removeChatTextInputFocused}
          {...this.props.textInputProps}
        />

        <EmojiMartPicker onChange={(e) => this.onChangeText(e,true)}>
          <Button circular icon='smile outline' size={'big'}/>
        </EmojiMartPicker>
      </React.Fragment>
    );
  }

}

const styles = StyleSheet.create({
  textInput: {
    flex: 1,
    marginLeft: 10,
    fontSize: 16,
    lineHeight: 16,
    marginTop: Platform.select({
      ios: 6,
      android: 0,
    }),
    marginBottom: Platform.select({
      ios: 5,
      android: 3,
    }),
  },
});

Composer.defaultProps = {
  composerHeight: MIN_COMPOSER_HEIGHT,
  text: '',
  placeholderTextColor: Color.defaultProps,
  placeholder: DEFAULT_PLACEHOLDER,
  textInputProps: null,
  multiline: true,
  textInputStyle: {},
  textInputAutoFocus: false,
  keyboardAppearance: 'default',
  onTextChanged: () => {},
  onInputSizeChanged: () => {},
  onImageChange:()=>{}
};

Composer.propTypes = {
  composerHeight: PropTypes.number,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderTextColor: PropTypes.string,
  textInputProps: PropTypes.object,
  onTextChanged: PropTypes.func,
  onInputSizeChanged: PropTypes.func,
  multiline: PropTypes.bool,
  textInputStyle: TextInput.propTypes.style,
  textInputAutoFocus: PropTypes.bool,
  keyboardAppearance: PropTypes.string,
  onImageChange:PropTypes.func
};

export default connect(stateToProps,dispatchToProps)(Composer);
