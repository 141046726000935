import React,{Component} from 'react';
import {
  Container,
  Segment,
  Item,
  Grid,
  Rating,
  Header,
  Label} from 'semantic-ui-react';

class BookingHistory extends Component{
  constructor(props){
    super(props);

    this.state={
      bookingRowsShowingMore:false,
    }
  }

  showBookingRowsMore(e,show){
    e.preventDefault();
    this.setState({bookingRowsShowingMore:show})
  }

  render(){
    let list=[];

    if(this.props.review.data){
      list=this.props.review.data.map((item,index)=>{
        return (
          <Item key={index} className={'history-item'}>
            <Item.Content>
              <Item.Header className={'history-header'}>
                <Grid className={'history-header-grid'}>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      {item.reviewer_name}
                    </Grid.Column>
                    <Grid.Column width={6} textAlign={'right'}>
                      <Item.Extra className={'history-date'}>
                        {item.date}
                      </Item.Extra>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Item.Header>
              <Item.Description>
                <div>
                  "{item.review}"
                </div>
                <Rating icon='star' rating={item.rating} maxRating={item.rating} className={'booking-rating'} disabled/>
              </Item.Description>
            </Item.Content>
          </Item>
        )
      });
    }

    if (this.state.bookingRowsShowingMore===false){
      list=list.slice(0,3);
    }

    return (
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <Header size='small' color='grey' style={{display: 'inline'}}>RATINGS & REVIEWS</Header>
          <Label circular>{this.props.bookingInfo.length}</Label>
        </Segment>
        <Segment textAlign={'left'}>
          <Item.Group divided>
            {list.length===0?<span style={{color: '#777'}}>No reviews</span>: list}
            <hr className={'booking-divider'} style={{marginTop:'10px',marginBottom:'18px'}}/>
            {this.state.bookingRowsShowingMore?
              <Container textAlign={'center'}>
                <a href={''} onClick={(e)=>this.showBookingRowsMore(e,false)}>
                  LESS
                </a>
              </Container>
              :
              <Container textAlign={'center'}>
                <a href={''} onClick={(e)=>this.showBookingRowsMore(e,true)}>
                  VIEW ALL ({this.props.bookingInfo.length})
                </a>
              </Container>
            }
          </Item.Group>
        </Segment>
      </Segment.Group>
    )
  }
}

export default BookingHistory
