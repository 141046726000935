/* eslint no-use-before-define: ["error", { "variables": false }] */

import PropTypes from 'prop-types';
import React from 'react';
import { StyleSheet, View, Keyboard, ViewPropTypes,Text } from 'react-native';
import {Button, Icon} from 'semantic-ui-react';
import Composer from './Composer';
import Send from './Send';
import Actions from './Actions';
import Color from './Color';
import './../styles.css';

export default class InputToolbar extends React.Component {

  constructor(props) {
    super(props);
    this.keyboardWillShow = this.keyboardWillShow.bind(this);
    this.keyboardWillHide = this.keyboardWillHide.bind(this);
    this.state = {
      position: 'absolute',
      fileName: null,
      fileUploadPercentage: 100,
      isFileUploadStarted: false,
      fileUploadError: null,
      fileUploadSuccess: null,
      uploadedFileUrl: null,
      isFileAttached: false,
    };
  }

  componentWillMount() {
    this.keyboardWillShowListener = Keyboard.addListener('keyboardWillShow', this.keyboardWillShow);
    this.keyboardWillHideListener = Keyboard.addListener('keyboardWillHide', this.keyboardWillHide);
  }

  componentWillUnmount() {
    this.keyboardWillShowListener.remove();
    this.keyboardWillHideListener.remove();
  }

  keyboardWillShow() {
    if (this.state.position !== 'relative') {
      this.setState({
        position: 'relative',
      });
    }
  }

  keyboardWillHide() {
    if (this.state.position !== 'absolute') {
      this.setState({
        position: 'absolute',
      });
    }
  }

  renderActions() {
    if (this.props.renderActions) {
      return this.props.renderActions(this.props);
    } else if (this.props.onPressActionButton) {
      return <Actions {...this.props} />;
    }
    return null;
  }

  renderSend() {
    if (this.props.renderSend) {
      return this.props.renderSend(this.props);
    }
    return <Send {...this.props} {...this.state}  onSend={this.onSend} />;
  }

  renderComposer() {
    if (this.props.renderComposer) {
      return this.props.renderComposer(this.props);
    }

    return <Composer
        {...this.props}
        {...this.state}
        onSend={this.onSend}
        s3PreProcessFileUpload={this.s3PreProcessFileUpload}
        s3FileUploadProgress={this.s3FileUploadProgress}
        s3FileUploadError={this.s3FileUploadError}
        s3FileUploadFinish={this.s3FileUploadFinish}
    />;
  }

  renderAttachedFile = () => {
    const {isFileAttached, uploadedFileUrl, fileName} = this.state;
    if (isFileAttached && uploadedFileUrl){
      return (
          <div className={'attachment'}>
            <Icon name='file' floated={'left'} />
            <strong>{fileName}</strong>
            <Button onClick={() => this.removeAttachment()} icon size={'mini'} floated={'right'}>
              <Icon name='trash' />
            </Button>
          </div>
      )
    }
  };

  onSend = (message, shouldResetInputToolbar = false) => {
    const {isFileAttached, uploadedFileUrl, fileName} = this.state;
    if (isFileAttached){
       message.type = 'file';
       message.fileUrl = uploadedFileUrl;
       message.name = fileName;
       this.props.pushDownMessagesContainer();
    }

    if (!message.text && !isFileAttached){
       return false;
    }
    this.props.onSend(message, shouldResetInputToolbar);
    this.setState({ isFileAttached: false, uploadedFileUrl: null, fileName: null })
  };

  renderAccessory() {
    if (this.props.renderAccessory) {
      return (
        <View style={[styles.accessory, this.props.accessoryStyle]}>{this.props.renderAccessory(this.props)}</View>
      );
    }
    return null;
  }

  s3PreProcessFileUpload = (file, next) => {
      this.setState({
          fileName: file.name,
          isFileUploadStarted: true,
          fileUploadError: null,
          fileUploadSuccess: null,
          fileUploadPercentage: 0
      });
      next(file);
      this.props.pushUpMessagesContainer();
  };

  s3FileUploadProgress = (percent, message) => {
    this.setState({
          fileUploadPercentage: percent
      });
  };

  renderFileUploadProgress = () => {
    const {fileName, fileUploadPercentage, isFileUploadStarted, fileUploadSuccess} = this.state;
    if (isFileUploadStarted && !fileUploadSuccess) {
        return (
            <View className={'progress-bar-container'}>
                <p>File uploading: {fileName}</p>
                <div className={'progress-bar'} style={{width: `${fileUploadPercentage}%`}}>
                    {`${fileUploadPercentage}%`}
                </div>
            </View>
        )
    }
  };

  s3FileUploadError = (error) => {
    this.setState({
          fileUploadError: error,
          fileUploadSuccess: false,
      });
  };

  s3FileUploadFinish = (result) => {
    this.setState(
        {
          isFileUploadStarted: false,
          fileUploadError: null,
          fileUploadSuccess: true,
          fileUploadPercentage: 0,
          uploadedFileUrl: result.signedUrl.split('?')[0],
          isFileAttached: true
        }
        );
  };

  removeAttachment = () => {
    this.setState(
        {
          isFileUploadStarted: false,
          fileUploadError: null,
          fileUploadSuccess: false,
          fileUploadPercentage: 0,
          uploadedFileUrl: null,
          isFileAttached: false
        },
        () => this.props.pushDownMessagesContainer()
        );
  };

  render() {
    let typingMemberNames=this.props.typingMembers.map((member)=>{
      return member.nickname;
    });

    return (

        <View  style={[styles.container, this.props.containerStyle, { position: this.state.position }]}>

          {this.renderFileUploadProgress()}

          <View>
            <View style={[styles.primary, this.props.primaryStyle]}>
            {this.renderActions()}
            {this.renderComposer()}
            {this.renderSend()}
          </View>
        {this.renderAccessory()}
        {this.renderAttachedFile()}
        {typingMemberNames.length>0 && typingMemberNames.length<3 &&
          <View style={{flexDirection: 'column',alignItems:'flex-start', marginLeft:'68px'}}>
            <Text>
              {typingMemberNames.join(',')}
              {typingMemberNames.length===1 && <React.Fragment> is </React.Fragment>}
              {typingMemberNames.length>1 && <React.Fragment> are </React.Fragment>}
              typing...
            </Text>
          </View>
        }

        {typingMemberNames.length>=3 &&
          <View style={{flexDirection: 'column',alignItems:'flex-start', marginLeft:'68px'}}>
            <Text>
              Several people are typing...
            </Text>
          </View>
        }
      </View>
        </View>
    );
  }

}

const styles = StyleSheet.create({
  container: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: Color.defaultColor,
    backgroundColor: Color.white,
    bottom: 0,
    left: 0,
    right: 0,
  },
  primary: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  accessory: {
    height: 44,
  },
});

InputToolbar.defaultProps = {
  renderAccessory: null,
  renderActions: null,
  renderSend: null,
  renderComposer: null,
  containerStyle: {},
  primaryStyle: {},
  accessoryStyle: {},
  onPressActionButton: () => {},
};

InputToolbar.propTypes = {
  renderAccessory: PropTypes.func,
  renderActions: PropTypes.func,
  renderSend: PropTypes.func,
  renderComposer: PropTypes.func,
  onPressActionButton: PropTypes.func,
  containerStyle: ViewPropTypes.style,
  primaryStyle: ViewPropTypes.style,
  accessoryStyle: ViewPropTypes.style,
};
