import React,{Component} from 'react';
import { withRouter } from "react-router-dom";
import {
  Card,
  Icon,
  Image,
  Button,
  Form,
  Segment,
  Header,
  Modal,
  Checkbox,
  Input,
  Menu} from 'semantic-ui-react';

import {common} from "../../../utils/common";
import FieldErrors from '../../Common/FieldErrors';
import { store } from "../../../store";
import ImageCrop from 'react-image-crop-component';
import 'react-image-crop-component/style.css';


class EditCompanyInfo extends Component{
  commonObj=new common();

  constructor(props){
    super(props);
    this.gotoBack=this.gotoBack.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.onCropped = this._onCropped.bind(this)

    this.state={
      imageFile:null,
      imagePreviewUrl:null,
      form_error:false,
      server_error:false,
      company_id:'',
      user_id:'',
      name:'',
      phone:'',
      site_url:'',
      street:'',
      city: '',
      state: '',
      country: '',
      lat:'',
      lng:'',
      errors:{},
      photo:null,
      edit_show:false,
      savebutton:false,
      cropedImage:null,
      open:false,
    }
  }

  componentDidMount(){
    this.getData();
  }

  componentWillReceiveProps(nextProps){
    this.getData()
  }

  getData(){
    const state = store.getState();


    this.setState({
      company_id: state.company.profile.id,
      user_id: state.user.profile.id,
      name: state.company.profile.name,
      phone: state.user.profile.phone,
      site_url: state.company.profile.site_url,
      facebook_url: state.company.profile.facebook_url,
      twitter_url: state.company.profile.twitter_url,
      linkedin_url: state.company.profile.linkedin_url,
      street: state.company.profile.street,
      city: state.company.profile.city,
      state: state.company.profile.state,
      country: state.company.profile.country,
      lat: state.company.profile.lat,
      lng: state.company.profile.lng,
      photo: state.user.profile.photo===''?this.props.data.companyLogo: state.company.profile.photo,
      errors:{}
    });
  }

  gotoBack(event){
    event.preventDefault();
    this.props.history.push('/profile-company/'+ this.state.company_id);
  }

  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        imageFile: file,
        imagePreviewUrl: reader.result,
        open: true,
      });
    }
    
    reader.readAsDataURL(file)
  }


  savePhoto(event){
    let data = new FormData();

    data.append('id', this.state.company_id);
    data.append('photo', this.state.cropedImage);
    
    this.props.updateCompanyProfile(data);
    this.setState({imageFile: null, imagePreviewUrl: null,open: false, cropedImage:null, savebutton:true})
  }

  cancelPhoto(event) {
    this.setState({imageFile: null, imagePreviewUrl: null,open: false, cropedImage:null, savebutton:true})
  }

  handleChange = (e,{name,value,fieldtype}) => {
    this.commonObj.handleChange(e,this,{name,value,fieldtype});
  }

  savePersonal(event){
    event.preventDefault();

    if (this.state.form_error===true || Object.keys(this.state.errors).length > 0){
      return;
    }

    let dataCompany = new FormData();
    dataCompany.append('id', this.state.company_id);
    dataCompany.append('name', this.state.name);
    dataCompany.append('lat', this.state.lat);
    dataCompany.append('lng', this.state.lng);
    dataCompany.append('site_url', this.state.site_url);
    dataCompany.append('facebook_url', this.state.facebook_url);
    dataCompany.append('twitter_url', this.state.twitter_url);
    dataCompany.append('linkedin_url', this.state.linkedin_url);
    dataCompany.append('street', this.state.street);
    dataCompany.append('city', this.state.city);
    dataCompany.append('state', this.state.state);
    dataCompany.append('country', this.state.country);
   
    this.props.updateCompanyProfile(dataCompany);

    let dataUser = new FormData();
    dataUser.append('id', this.state.user_id);
    dataUser.append('phone', this.state.phone);
    this.props.updateCompanyProfileUser(dataUser);

    // const { first_name, last_name, gender,date_of_birth } = this.state;
    this.props.history.push('/profile-company/'+ this.state.company_id);
  }

  show(){
    this.setState({open: true})
  }

  goBack(event){
    event.preventDefault();
    this.props.history.push('/profile-company/'+ this.state.company_id);
  }

   _onCropped(e) {
    this.setState({cropedImage:e.image, savebutton:false})
  }

  render(){
    let {name,phone,site_url,facebook_url,twitter_url,linkedin_url,street,city,state,country,photo}=this.state

    return(
      <div>
        <Card>
          <label>
            <div onMouseEnter={(e)=>{this.setState({edit_show:true})}} onMouseOut={(e)=>{this.setState({edit_show:false})}}>
              {
                this.state.imagePreviewUrl===null?
                  <Image src={photo} as='a' />
                  :
                  <Image src={this.state.imagePreviewUrl} />
              }
                  <Icon name='image' onMouseEnter={(e)=>{this.setState({edit_show:true})}}  color='blue' size='big' style={{position: 'absolute', bottom: '15%', cursor:'pointer', right: '5%', display:this.state.edit_show?'block':'none'}}/>
                  <input type="file" style={{display:'none'}} onClick={(event)=> {event.target.value = null}} onChange={(e)=>this.handleImageChange(e)}/>
            </div>
          </label>
          <Card.Content extra>
            <Modal open={this.state.open}>
              <Modal.Content>
              </Modal.Content>
              <div>
                {this.state.imagePreviewUrl && (
                  <div style={{width: "100%", height: "100%"}}>
                      <ImageCrop src={this.state.imagePreviewUrl}
                                      setWidth={1000} 
                                      setHeight={1000} 
                                      square={false} 
                                      resize={true}
                                      border={"dashed #ffffff 2px"}
                                      onCrop={this.onCropped}/>
                  </div>
                )}
              </div>
              <Modal.Actions>
                <Button color='red' onClick={(e)=>this.cancelPhoto(e)}>
                  <Icon name='remove' /> Cancel
                </Button>
                <Button color='green' disabled={this.state.savebutton} onClick={(e)=>this.savePhoto(e)}>
                  <Icon name='checkmark' /> Crop & Save Image
                </Button>
              </Modal.Actions>
            </Modal>
          </Card.Content>
        </Card>
        <Form size='small' onSubmit={(e)=>this.savePersonal(e)} error={Object.keys(this.state.errors).length>0 || this.state.server_error}>
          <Segment raised>
            <Form.Input
              fluid
              icon='user'
              iconPosition='left'
              placeholder='Company Name'
              name='name'
              value={name}
              fieldtype='text'
              error = {this.state.errors && this.state.errors['name']}
              onChange={this.handleChange}
            />
            <FieldErrors formSubmitted={true} errors={this.state.errors} fieldName={'name'}/>
            <Form.Input
              fluid
              icon='add user'
              iconPosition='left'
              placeholder='Phone'
              name='phone'
              value={phone}
              fieldtype='text'
              error = {this.state.errors && this.state.errors['phone']}
              onChange={this.handleChange}
            />
            <FieldErrors formSubmitted={true} errors={this.state.errors} fieldName={'phone'}/>
            <Form.Input
              fluid
              label="http://"
              placeholder='facebook_url'
              name='facebook_url'
              value={facebook_url}
              fieldtype='text'
              onChange={this.handleChange}
              error = {this.state.errors && this.state.errors['facebook_url']}
              className={'url-field'}
            />
            <FieldErrors formSubmitted={true} errors={this.state.errors} fieldName={'facebook_url'}/>
            <Form.Input
              fluid
              label="http://"
              placeholder='twitter_url'
              name='twitter_url'
              value={twitter_url}
              fieldtype='text'
              onChange={this.handleChange}
              error = {this.state.errors && this.state.errors['twitter_url']}
              className={'url-field'}
            />
            <FieldErrors formSubmitted={true} errors={this.state.errors} fieldName={'twitter_url'}/>
            <Form.Input
              fluid
              label="http://"
              placeholder='linkedin_url'
              name='linkedin_url'
              value={linkedin_url}
              fieldtype='url'
              error = {this.state.errors && this.state.errors['linkedin_url']}
              onChange={this.handleChange}
              className={'url-field'}
            />
            <FieldErrors formSubmitted={true} errors={this.state.errors} fieldName={'linkedin_url'}/>
            <Form.Input
              fluid
              label="http://"
              placeholder='site_url'
              name='site_url'
              value={site_url}
              fieldtype='text'
              error = {this.state.errors && this.state.errors['site_url']}
              onChange={this.handleChange}
              className={'url-field'}
            />
            <Form.Input
              fluid
              icon='add user'
              iconPosition='left'
              placeholder='Street'
              name='street'
              value={street}
              fieldtype='text'
              error = {this.state.errors && this.state.errors['street']}
              onChange={this.handleChange}
            />
            <FieldErrors formSubmitted={true} errors={this.state.errors} fieldName={'street'}/>
            <Form.Input
              fluid
              icon='add user'
              iconPosition='left'
              placeholder='City'
              name='city'
              value={city}
              fieldtype='text'
              error = {this.state.errors && this.state.errors['City']}
              onChange={this.handleChange}
            />
            <FieldErrors formSubmitted={true} errors={this.state.errors} fieldName={'city'}/>
            <Form.Input
              fluid
              icon='add user'
              iconPosition='left'
              placeholder='State'
              name='state'
              value={state}
              fieldtype='text'
              error = {this.state.errors && this.state.errors['state']}
              onChange={this.handleChange}
            />
            <FieldErrors formSubmitted={true} errors={this.state.errors} fieldName={'state'}/>
           
            <Form.Input
              fluid
              icon='add user'
              iconPosition='left'
              placeholder='Country'
              name='country'
              value={country}
              fieldtype='text'
              error = {this.state.errors && this.state.errors['country']}
              onChange={this.handleChange}
            />
            <FieldErrors formSubmitted={true} errors={this.state.errors} fieldName={'country'}/>
            
            <Button color='blue' size='small'>SAVE</Button>
            <Menu secondary>
              <Menu.Item as='a' style={{marginLeft:'auto',marginRight:'auto'}} onClick={(e)=>this.goBack(e)}>
                <Icon name='arrow left' link/>
                Back
              </Menu.Item>
            </Menu>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default withRouter(EditCompanyInfo)