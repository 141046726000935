export const UPDATE_USER = 'update_user';
export const ADD_STRIPE_ACCOUNT_TO_USER = 'add_stripe_account_to_user';

export const WAITING_REQUEST='WAITING_REQUEST';
export const SIGNUP_SUBMIT_FULFILLED='SIGNUP_SUBMIT_FULFILLED';
export const SIGNUP_SUBMIT_ERROR='SIGNUP_SUBMIT_ERROR';
export const SIGNUP_VEFIFY_FULFILLED='SIGNUP_VEFIFY_FULFILLED';
export const SIGNUP_VEFIFY_FAILED='SIGNUP_VEFIFY_FAILED';

export const RESEND_EMAIL_SUCCESS='resend_email_success';
export const RESEND_EMAIL_ERROR='resend_email_error';

export const PHONE_VERIFY_START_FULFILLED='PHONE_VERIFY_START_FULFILLED';
export const PHONE_VERIFY_START_FAILED='PHONE_VERIFY_START_FAILED';
export const PHONE_VERIFY_CODE_FULFILLED='PHONE_VERIFY_CODE_FULFILLED';
export const PHONE_VERIFY_CODE_FAILED='PHONE_VERIFY_CODE_FAILED';

export const TALENT_UPDATE_SUCESS = 'talent_update_success';
export const TALENT_UPDATE_ERROR = 'talent_update_error';
export const TOGGLE_STRIPE_CONNECT = 'TOGGLE_STRIPE_CONNECT';
export const REQUEST_STRIPE_CONNECT = 'REQUEST_STRIPE_CONNECT'

export const COMPANY_UPDATE_SUCCESS = 'company_update_success';
export const COMPANY_UPDATE_ERROR = 'company_update_error';
export const GET_COMPANY_PROFILE_SUCCESS = 'get_company_profile_success';
export const GET_COMPANY_PROFILE_ERROR = 'get_company_profile_error';

export const ADD_WORK_CITY_SUCCESS = 'add_work_city_success';
export const ADD_WORK_CITY_ERROR = 'add_work_city_error';
export const REMOVE_WORK_CITY_SUCCESS = 'remove_work_city_success';
export const REMOVE_WORK_CITY_ERROR = 'remove_work_city_error';
export const PRIMARY_WORK_CITY_SUCCESS = 'primary_work_city_success';
export const PRIMARY_WORK_CITY_ERROR = 'primary_work_city_error';

export const GET_TALENT_PROFILE_SUCESS='GET_TALENT_PROFILE_SUCESS';
export const GET_TALENT_PROFILE_FAILED='GET_TALENT_PROFILE_FAILED';

export const GET_PROFILE_TALENT_FULFILLED='GET_PROFILE_TALENT_FULFILLED';
export const GET_PROFILE_TALENT_FAILED='GET_PROFILE_TALENT_FAILED';

export const GET_PROFILE_COMPANY_FULFILLED='GET_PROFILE_COMPANY_FULFILLED';
export const GET_PROFILE_COMPANY_FAILED='GET_PROFILE_COMPANY_FAILED';

export const GET_PROFILE_COMPANY_USER_FULFILLED='GET_PROFILE_COMPANY_USER_FULFILLED';
export const GET_PROFILE_COMPANY_USER_FAILED='GET_PROFILE_COMPANY_USER_FAILED';

export const GET_PROFILE_COMPANY_USER_FULFILLED_DATA='GET_PROFILE_COMPANY_USER_FULFILLED_DATA';
export const GET_PROFILE_COMPANY_USER_FAILED_DATA='GET_PROFILE_COMPANY_USER_FAILED_DATA';

export const GET_TALENT_JOBS_WAITING='GET_TALENT_JOBS_WAITING';
export const GET_TALENT_JOBS_FULFILLED='GET_TALENT_JOBS_FULFILLED';
export const GET_TALENT_JOBS_FAILED='GET_TALENT_JOBS_FAILED';
export const GET_TALENT_JOBS_FILTERED_DATA='GET_TALENT_JOBS_FILTERED_DATA';

export const GET_TALENT_JOB_DETAILS_WAITING='GET_TALENT_JOB_DETAILS_WAITING';
export const GET_TALENT_JOB_DETAILS_FULFILLED='GET_TALENT_JOB_DETAILS_FULFILLED';
export const GET_TALENT_JOB_DETAILS_FAILED='GET_TALENT_JOB_DETAILS_FAILED';

export const GET_SHIFT_FULFILLED='GET_SHIFT_FULFILLED';
export const GET_SHIFT_FAILED='GET_SHIFT_FAILED';

export const WAITING_ADD_APPLICANT='WAITING_ADD_APPLICANT';
export const POST_SHIFT_FULFILLED='POST_SHIFT_FULFILLED';
export const POST_SHIFT_FAILED='POST_SHIFT_FAILED';
export const WAITING_UPDATE_APPLICANT='WAITING_UPDATE_APPLICANT';
export const UPDATE_APPLICANT_FULFILLED='UPDATE_APPLICANT_FULFILLED';
export const UPDATE_APPLICANT_FAILED='UPDATE_APPLICANT_FAILED';

export const GET_BOOKINGS_TALENT_WAITING='GET_BOOKINGS_TALENT_WAITING';
export const GET_BOOKINGS_TALENT_FULFILLED='GET_BOOKINGS_TALENT_FULFILLED';
export const GET_BOOKINGS_TALENT_FAILED='GET_BOOKINGS_TALENT_FAILED';
export const GET_BOOKINGS_TALENT_FILTERED_DATA='GET_BOOKINGS_TALENT_FILTERED_DATA';

export const GET_COMPANY_JOBS_FULFILLED='GET_COMPANY_JOBS_FULFILLED';
export const GET_COMPANY_JOBS_FAILED='GET_COMPANY_JOBS_FAILED';
export const GET_COMPANY_JOBS_FILTERED_DATA='GET_COMPANY_JOBS_FILTERED_DATA';

export const GET_SETTINGS_FULFILLED='GET_SETTINGS_FULFILLED';
export const GET_SETTINGS_FAILED='GET_SETTINGS_FAILED';

export const ADD_USER_FULFILLED='ADD_USER_FULFILLED';
export const ADD_USER_FAILED='ADD_USER_FAILED';

export const REMOVE_USER_FULFILLED='REMOVE_USER_FULFILLED';
export const REMOVE_USER_FAILED='REMOVE_USER_FAILED';

export const GET_SETTINGS_FULFILLED_JSON='GET_SETTINGS_FULFILLED_JSON';
export const GET_SETTINGS_FAILED_JSON='GET_SETTINGS_FAILED_JSON';

export const GET_TALENT_PAYMENTS_FULFILLED='GET_TALENT_PAYMENTS_FULFILLED';
export const GET_TALENT_PAYMENTS_FAILED='GET_TALENT_PAYMENTS_FAILED';
export const GET_TALENT_PAYMENTS_FILTERED_DATA='GET_TALENT_PAYMENTS_FILTERED_DATA';

// Notifications
export const EMAIL_SIGNUP_OPENED_CLOSED='EMAIL_SIGNUP_OPENED_CLOSED';
export const EMAIL_SIGNUP_MESSAGE_OPEN_CLOSED='EMAIL_SIGNUP_MESSAGE_OPEN_CLOSED';
export const SIGNUP_NEXT='SIGNUP_NEXT';
export const TALENT_JOB_APPLY_NEXT='TALENT_JOB_APPLY_NEXT';
export const IS_MODAL_OPENED='IS_MODAL_OPENED';
export const LOGIN_SUBMIT_FULFILLED='LOGIN_SUBMIT_FULFILLED';
export const LOGIN_SUBMIT_ERROR='LOGIN_SUBMIT_ERROR';
export const BACK_FROM_VARIFICATION='BACK_FROM_VARIFICATION';

export const CREATE_NEW_EVENT='CREATE_NEW_EVENT';
export const EVENT_CREATION_STEP='EVENT_CREATION_STEP';
export const EVENT_LIST_SUCCESS='event_list_success';
export const EVENT_LIST_ERROR='event_list_error';
export const SET_EMPTY_EVENT_CREATION_PROCESS='SET_EMPTY_EVENT_CREATION_PROCESS';

export const WAITING_GET_EVENT_REQUEST='WAITING_GET_EVENT_REQUEST';
export const GET_EVENT_SUCCESS='GET_EVENT_SUCCESS';
export const GET_EVENT_ERROR='GET_EVENT_ERROR';

export const ADD_EVENT_SUCCESS='add_event_success';
export const ADD_EVENT_ERROR='add_event_error';

export const VISIBLE_JOB_SUCCESS='VISIBLE_JOB_SUCCESS';
export const VISIBLE_JOB_ERROR='VISIBLE_JOB_ERROR';

export const UPDATE_EVENT_SUCCESS='UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_ERROR='UPDATE_EVENT_ERROR';

export const GET_TALENT_ROLES_SUCCESS='get_talent_roles_success';
export const GET_TALENT_ROLES_ERROR='get_talent_roles_error';
export const ADD_TALENT_ROLE_SUCCESS='add_talent_role_success';
export const ADD_TALENT_ROLE_ERROR='add_talent_role_error';
export const UPDATE_TALENT_ROLE_SUCCESS='update_talent_role_success';
export const UPDATE_TALENT_ROLE_ERROR='update_talent_role_error';
export const SET_TALENT_ROLE_UPDATED_FALSE='SET_TALENT_ROLE_UPDATED_FALSE';

export const ADD_SKILL_WAITING='ADD_SKILL_WAITING';
export const ADD_SKILL_SUCCESS='add_skill_success';
export const ADD_SKILL_ERROR='add_skill_error';
export const GET_SKILLS_SUCCESS='get_skills_success';
export const GET_SKILLS_ERROR='get_skills_error';

export const ADD_CERTIFICATION_WAITING='ADD_CERTIFICATION_WAITING';
export const ADD_CERTIFICATION_SUCCESS='add_certification_success';
export const ADD_CERTIFICATION_ERROR='add_certification_error';
export const GET_CERTIFICATIONS_SUCCESS='get_certifications_success';
export const GET_CERTIFICATIONS_ERROR='get_certifications_error';

export const SAVE_SHIFT_SUCCESS='SAVE_SHIFT_SUCCESS';
export const SAVE_SHIFT_ERROR='SAVE_SHIFT_ERROR';

export const UPDATE_SHIFT_SUCCESS='UPDATE_SHIFT_SUCCESS';
export const UPDATE_SHIFT_ERROR='UPDATE_SHIFT_ERROR';

export const CONNECT_STRIPE_ACCOUNT_ERROR = 'connect_stripe_account_error';
export const GET_STRIPE_ACCOUNT_ERROR = 'get_stripe_account_error';

export const GET_REVIEW_FULFILLED='GET_REVIEW_FULFILLED';
export const GET_REVIEW_FAILED='GET_REVIEW_FAILED';

export const GET_JOB_EXPERIENCE_SUCCESS='GET_JOB_EXPERIENCE_SUCCESS';
export const GET_JOB_EXPERIENCE_ERROR='GET_JOB_EXPERIENCE_ERROR';

export const DELETE_JOB_EXPERIENCE_SUCCESS='DELETE_JOB_EXPERIENCE_SUCCESS';
export const DELETE_JOB_EXPERIENCE_ERROR='DELETE_JOB_EXPERIENCE_ERROR';

export const ADD_JOBEXPERIENCE_SUCCESS='ADD_JOBEXPERIENCE_SUCCESS';
export const ADD_JOBEXPERIENCE_ERROR='ADD_JOBEXPERIENCE_ERROR';

export const ADD_JOBEXPERIENCE_WAITING='ADD_JOBEXPERIENCE_WAITING';

export const GET_PORTFOLIO_SUCCESS='GET_PORTFOLIO_SUCCESS';
export const GET_PORTFOLIO_ERROR='GET_PORTFOLIO_ERROR';
export const UPDATE_PORTFOLIO_WAITING='UPDATE_PORTFOLIO_WAITING';
export const UPDATE_PORTFOLIO_SUCCESS='UPDATE_PORTFOLIO_SUCCESS';
export const UPDATE_PORTFOLIO_ERROR='UPDATE_PORTFOLIO_ERROR';
export const CREATE_PORTFOLIO_WAITING='CREATE_PORTFOLIO_WAITING';
export const CREATE_PORTFOLIO_SUCCESS='CREATE_PORTFOLIO_SUCCESS';
export const CREATE_PORTFOLIO_ERROR='CREATE_PORTFOLIO_ERROR';
export const DELETE_PORTFOLIO_SUCCESS='DELETE_PORTFOLIO_SUCCESS';
export const DELETE_PORTFOLIO_ERROR='DELETE_PORTFOLIO_ERROR';

export const ADD_GALLERYPHOTO_SUCCESS='ADD_GALLERYPHOTO_SUCCESS';
export const ADD_GALLERYPHOTO_ERROR='ADD_GALLERYPHOTO_ERROR';
export const GET_GALLERYPHOTO_SUCCESS='GET_GALLERYPHOTO_SUCCESS';
export const GET_GALLERYPHOTO_ERROR='GET_GALLERYPHOTO_ERROR';
export const DELETE_PHOTOGALLERY_IMAGE_SUCCESS='DELETE_PHOTOGALLERY_IMAGE_SUCCESS';
export const DELETE_POTOGALLERY_IMAGE_ERROR='DELETE_POTOGALLERY_IMAGE_ERROR';
export const RESET_TALENT = 'reset-talent';

export const UPDATE_JOBEXPERIENCE_SUCCESS='UPDATE_JOBEXPERIENCE_SUCCESS';
export const UPDATE_JOBEXPERIENCE_ERROR='UPDATE_JOBEXPERIENCE_ERROR';

export const UPDATE_JOBEXPERIENCE_WAITING='UPDATE_JOBEXPERIENCE_WAITING';

export const GET_APPLICANT_LIST_SUCCESS='GET_APPLICANT_LIST_SUCCESS';
export const GET_APPLICANT_LIST_ERROR='GET_APPLICANT_LIST_ERROR';

export const UPDATE_APPLICANT_SUCCESS='UPDATE_APPLICANT_SUCCESS';
export const UPDATE_APPLICANT_ERROR='UPDATE_APPLICANT_ERROR';

export const DND_ASSIGN_SHIFTS='DND_ASSIGN_SHIFTS';

export const GET_EVENT_INVOICE_SUCCESS = 'GET_EVENT_INVOICE_SUCCESS';
export const GET_EVENT_INVOICE_ERROR = 'GET_EVENT_INVOICE_ERROR';

export const PUBLISH_EVENT_SUCCESS = 'PUBLISH_EVENT_SUCCESS';
export const PUBLISH_EVENT_ERROR = 'PUBLISH_EVENT_ERROR';

export const UPDATE_EVENT_TIME_SHEET_ITEMS_SUCCESS = 'UPDATE_EVENT_TIME_SHEET_ITEMS_SUCCESS';
export const UPDATE_EVENT_TIME_SHEET_ITEMS_ERROR = 'UPDATE_EVENT_TIME_SHEET_ITEMS_ERROR';

export const PAY_INVOICE_VIA_BANK_SUCCESS = 'PAY_INVOICE_VIA_BANK_SUCCESS';
export const PAY_INVOICE_VIA_BANK_ERROR = 'PAY_INVOICE_VIA_BANK_ERROR';
export const PAY_INVOICE_VIA_CARD_SUCCESS = 'PAY_INVOICE_VIA_CARD_SUCCESS';
export const PAY_INVOICE_VIA_CARD_ERROR = 'PAY_INVOICE_VIA_CARD_ERROR';

export const GET_EVENT_AMOUNT_DETAILS_FULFILLED = 'GET_EVENT_AMOUNT_DETAILS_FULFILLED';
export const GET_EVENT_AMOUNT_DETAILS_FAILED = 'GET_EVENT_AMOUNT_DETAILS_FAILED';

export const GET_EVENT_DAY_DETAILS_FULFILLED = 'GET_EVENT_DAY_DETAILS_FULFILLED';
export const GET_EVENT_DAY_DETAILS_FAILED = 'GET_EVENT_DAY_DETAILS_FAILED';

export const SEND_PASSWORD_RESET_LINK_SUCCESS = 'SEND_PASSWORD_RESET_LINK_SUCCESS';
export const SEND_PASSWORD_RESET_LINK_ERROR = 'SEND_PASSWORD_RESET_LINK_ERROR';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const MESSAGING_GROUPS_LOAD_SUCCESS = 'MESSAGING_GROUPS_LOAD_SUCCESS';
export const MESSAGING_GROUPS_LOAD_ERROR = 'MESSAGING_GROUPS_LOAD_ERROR';
export const UPDATE_CHANNEL_URL_SUCCESS = 'UPDATE_CHANNEL_URL_SUCCESS';
export const UPDATE_CHANNEL_URL_ERROR = 'UPDATE_CHANNEL_URL_ERROR';
export const CREATE_ONE_TO_ONE_CHAT_SUCCESS = 'CREATE_ONE_TO_ONE_CHAT_SUCCESS';
export const CREATE_ONE_TO_ONE_CHAT_ERROR = 'CREATE_ONE_TO_ONE_CHAT_ERROR';
export const ONE_TO_ONE_CHATS_LOAD_SUCCESS = 'ONE_TO_ONE_CHATS_LOAD_SUCCESS';
export const ONE_TO_ONE_CHATS_LOAD_ERROR = 'ONE_TO_ONE_CHATS_LOAD_ERROR';

export const ONE_TO_ONE_CHAT_PARTICIPANT_GET_SUCCESS = 'ONE_TO_ONE_CHAT_PARTICIPANT_GET_SUCCESS';
export const ONE_TO_ONE_CHAT_PARTICIPANT_GET_ERROR = 'ONE_TO_ONE_CHAT_PARTICIPANT_GET_ERROR';

export const SYNC_GOOGLE_CALENDAR_SUCCESS = 'sync-google-calendar-success';
export const SYNC_GOOGLE_CALENDAR_ERROR = 'sync-google-calendar-error';
export const DISCONNECT_GOOGLE_CALENDAR_SUCCESS = 'disconnect-google-calendar-success';
export const DISCONNECT_GOOGLE_CALENDAR_ERROR = 'disconnect-google-calendar-error';

export const WAITING_GET_APPLYED_APPLICANT = 'WAITING_GET_APPLYED_APPLICANT';
export const GET_APPLYED_APPLICANT_FULFILLED = 'GET_APPLYED_APPLICANT_FULFILLED';
export const GET_APPLYED_APPLICANT_FAILED = 'GET_APPLYED_APPLICANT_FAILED';

export const DELETE_EVENT_EVENTAPPLICANT_FULFILLED='DELETE_EVENT_EVENTAPPLICANT_FULFILLED';
export const DELETE_EVENT_EVENTAPPLICANT_FAILED='DELET_EVENT_EVENTAPPLICANT_FAILED';

export const DELETE_SHIFT_WAITING = 'DELETE_SHIFT_WAITING';
export const DELETE_SHIFT_SUCCESS = 'DELETE_SHIFT_SUCCESS';
export const DELETE_SHIFT_ERROR = 'DELETE_SHIFT_ERROR';
export const DELETED_SHIFT_ID='DELETED_SHIFT_ID';

export const GET_TIMESHEETLINEITEM_LIST_SUCCESS='GET_TIMESHEETLINEITEM_LIST_SUCCESS';
export const GET_TIMESHEETLINEITEM_LIST_ERROR='GET_TIMESHEETLINEITEM_LIST_ERROR';

export const WITHDRAW_APPLICATION_FULFILLED='WITHDRAW_APPLICATION_FULFILLED';
export const WITHDRAW_APPLICATION_FAILED='WITHDRAW_APPLICATION_FAILED';

export const ADD_SOCIAL_PROFILE_SUCCESS='ADD_SOCIAL_PROFILE_SUCCESS';
export const ADD_SOCIAL_PROFILE_ERROR='ADD_SOCIAL_PROFILE_ERROR';
export const GET_SOCIAL_PROFILE_FULFILLED='GET_SOCIAL_PROFILE_FULFILLED';
export const GET_SOCIAL_PROFILE_FAILED='GET_SOCIAL_PROFILE_FAILED';
export const DELETE_SOCIAL_PROFILE_SUCCESS='DELETE_SOCIAL_PROFILE_SUCCESS';
export const DELETE_SOCIAL_PROFILE_ERROR='DELETE_SOCIAL_PROFILE_ERROR';

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const CHAT_TEXT_INPUT_FOCUSED='CHAT_TEXT_INPUT_FOCUSED';
export const NEW_MESSAGE_ARRIVED='NEW_MESSAGE_ARRIVED';
export const UPDATE_CHANNEL_LIST_ON_REDUX='UPDATE_CHANNEL_LIST_ON_REDUX';
export const UPDATE_ONE_TO_ONE_CHANNEL_LIST_ON_REDUX='UPDATE_ONE_TO_ONE_CHANNEL_LIST_ON_REDUX';
export const CHANNEL_LIST_SORTED='CHANNEL_LIST_SORTED';

export const SET_ACTIVE_JOB = 'set-active-job';

export const RATE_JOB_SUCCESS = 'rate-job-success';
export const RATE_JOB_ERROR = 'rate-job-error';
export const RATE_JOB_LOADING = 'rate-job-loading';

export const EVENT_REVIEW_LIST_SUCCESS = 'event-review-list-success';
export const EVENT_REVIEW_LIST_ERROR = 'event-review-list-error';
export const EVENT_REVIEW_LIST_LOADING = 'event-review-list-loading';

export const GET_RATING_SUCCESS = 'get-rating-success';
export const GET_RATING_ERROR = 'get-rating-error';
export const GET_RATING_LOADING = 'get-rating-loading';

export const EVENT_DOCUMENTS_UPLOAD_SUCCESS = 'event-documents-upload-success';
export const EVENT_DOCUMENTS_UPLOAD_ERROR = 'event-documents-upload-error';
export const EVENT_DOCUMENTS_UPLOADING = 'event-documents-uploading';
export const EVENT_DOCUMENTS_UPLOAD_PROGRESS = 'event-documents-upload-progress';
