import React,{Component} from 'react';
import {connect} from 'react-redux';
import {
    Icon, Menu,
    Modal,
    Step
} from 'semantic-ui-react';
import _ from 'lodash';

import ShiftDay from './ApplySteps/ShiftDay';
import ShiftTime from './ApplySteps/ShiftTime';
import ApplySummary from './ApplySteps/ApplySummary';
import ProfileSelection from "./ApplySteps/ProfileSelection";

const stateToProps=(state)=>{
  return{
    jobApplyNext:state.common.jobApplyNext,
  }
}

class ApplyModal extends Component{

  constructor(props){
      super(props);
      this.state = {
          step: 'day_selection',
          selectedDays: this.props.selectedDays,
          selectedShifts: this.props.selectedShifts,
          selectedPortfolio: this.props.selectedPortfolio
      }
  }

  resetSelections = () => {
      let selectedShifts = []
      let selectedDays = []
      selectedDays = this.props.job['event_days'].filter((item)=>{
        if(this.isEqualShif(item,this.props.applicants).length!==0){
          let shifts=this.isEqualShif(item,this.props.applicants)
          shifts.map((itm)=>{
            let newSelectedShifts = selectedShifts;
            let shift = itm
            const dayIndex = newSelectedShifts.map(day => day.id).indexOf(item.id);
            if (dayIndex === -1){
                const shiftDay = Object.assign({}, item);
                shiftDay.shifts = [shift];
                newSelectedShifts.push(shiftDay);
            }
            else{
                const shiftDay = newSelectedShifts.find(shiftDay => shiftDay.id === item.id);
                const shiftIndex = shiftDay.shifts.map(selectedShift => selectedShift.id).indexOf(shift.id);

                if(shiftIndex === -1){
                    shiftDay.shifts.push(shift);
                }else{
                    shiftDay.shifts.splice(shiftIndex, 1);
                }
            }

            selectedShifts = newSelectedShifts.filter( day => item.shifts.length !== 0 );
          });
        }
        return this.isEqualDay(item,this.props.applicants)
      });
      this.setState({
          step: 'day_selection',
          selectedDays: selectedDays,
          selectedShifts: selectedShifts,
      })
  };

  isEqualDay = (day,days) => {
    for (var i=0; i<days.length; i++){
      if(day.id===days[i].event_shift.event_day.id)
        return true
    }
  }

  isEqualShif = (day,days) => {
    let shifts = []
    for (var i=0; i<days.length; i++){
      if(day.id===days[i].event_shift.event_day.id){
        for(var j=0;j<day.shifts.length;j++){
          if(day.shifts[j].id===days[i].event_shift.id){
            shifts.push(day.shifts[j])
          }
        }
      }
    }
    return shifts
  }

  selectStep = (step) => {
      this.setState({step});
  };

  selectDay = (day) => {
    if(this.props.job['talent_apply_status']!==null){
      const {selectedShifts} = this.state;
      const indexSelectedShiftDay = selectedShifts.map(selectedDay => selectedDay.id).indexOf(day.id);

      if (indexSelectedShiftDay>=0){
        selectedShifts.splice(indexSelectedShiftDay, 1);
        this.setState({selectedShifts});
      }
    }
    const {selectedDays} = this.state;
    const index = selectedDays.map(selectedDay => selectedDay.id).indexOf(day.id);
    // remove if already added otherwise add
    if(index === -1){
        selectedDays.push(day);
    }else{
        selectedDays.splice(index, 1);
    }
    this.setState({selectedDays});
  };

  selectShift = (day, shift) => {
    /*
     day1: [shift, shift1],
     day2: [shift]
     */
    let {selectedShifts} = this.state;
    const dayIndex = selectedShifts.map(day => day.id).indexOf(day.id);
    if (dayIndex === -1){
        const shiftDay = Object.assign({}, day);
        shiftDay.shifts = [shift];
        selectedShifts.push(shiftDay);
    }
    else{
        const shiftDay = selectedShifts.find(shiftDay => shiftDay.id === day.id);
        const shiftIndex = shiftDay.shifts.map(selectedShift => selectedShift.id).indexOf(shift.id);
        // remove if already added otherwise add

        if(shiftIndex === -1){
            shiftDay.shifts.push(shift);
        }else{
            shiftDay.shifts.splice(shiftIndex, 1);
        }
    }

    selectedShifts = selectedShifts.filter( day => day.shifts.length !== 0 );
    this.setState({selectedShifts});
  };

  selectFixedShifts = (shifts,day) => {
    let {selectedShifts} = this.state;
    const dayIndex = selectedShifts.map(day => day.id).indexOf(day.id);
    if (dayIndex === -1){
      const shiftDay = Object.assign({}, day);
      shiftDay.shifts = [...shifts];
      selectedShifts.push(shiftDay);
    }
    else{
      const shiftDay = selectedShifts.find(shiftDay => shiftDay.id === day.id);

      for(let i=0;i<shifts.length;i++){
        let shift=shifts[i];
        const shiftIndex = shiftDay.shifts.map(selectedShift => selectedShift.id).indexOf(shift.id);
        // remove if already added otherwise add

        if(shiftIndex === -1){
          shiftDay.shifts.push(shift);
        }else{
          shiftDay.shifts.splice(shiftIndex, 1);
        }
      }
    }

    selectedShifts = selectedShifts.filter( day => day.shifts.length !== 0 );
    this.setState({selectedShifts});
  };

  selectPortfolio = (e, data) => {
    let selected_one = this.props.portfolios.find(p => p.id === data.value);
    this.setState({
      selectedPortfolio: selected_one
    })
  }

  render(){
    if (_.isEmpty(this.props.job)){
      return;
    }

    if (this.props.job['talent_apply_status']!==null && _.isEmpty(this.props.applicants)){
      return null;
    }

    let stepElement=null;
    const {job, portfolios} = this.props;
    const {step, selectedDays, selectedShifts, selectedPortfolio} = this.state;

    if (step === 'day_selection'){
      stepElement=(
          <ShiftDay
              availableDays={job['event_days']}
              selectedDays={selectedDays}
              selectDay={this.selectDay}
              selectStep={this.selectStep}
              companyName={job.company.name}
          />)
    }
    else if (step === 'shift_selection' ){
      stepElement=(
          <ShiftTime
              selectedDays={selectedDays}
              selectedShifts={selectedShifts}
              selectShift={this.selectShift}
              selectFixedShifts={this.selectFixedShifts}
              selectStep={this.selectStep}
          />)
    }
    else if (step === 'profile_selection' ){
      stepElement=(
          <ProfileSelection
            portfolios={portfolios}
            selectedPortfolio={selectedPortfolio}
            selectPortfolio={this.selectPortfolio}
            selectStep={this.selectStep}
          />)
    }
    else if (step === 'summary'){
      stepElement=(
          <ApplySummary
              applicants={this.props.applicants}
              status={job['talent_apply_status']}
              selectedDays={selectedDays}
              applyedShifts={this.props.selectedShifts}
              selectedShifts={selectedShifts}
              selectedPortfolio={selectedPortfolio}
              selectStep={this.selectStep}
              toggleApplyModal={this.props.toggleApplyModal}
          />)
    }

    return(
      <Modal size={'tiny'} closeOnDimmerClick={false} open={this.props.modalOpen}>
        <Modal.Header>Apply to Work</Modal.Header>
        <Modal.Content>
          <Step.Group widths={4} attached='top'>
            <Step active={step === 'day_selection'}>
              <Step.Content>
                <Step.Title>Day Selection</Step.Title>
                <Step.Description></Step.Description>
              </Step.Content>
            </Step>
            <Step active={step === 'shift_selection'}>
              <Step.Content>
                <Step.Title>Time Selection</Step.Title>
                <Step.Description></Step.Description>
              </Step.Content>
            </Step>
            <Step active={step === 'profile'}>
              <Step.Content>
                <Step.Title>Profile Selection</Step.Title>
                <Step.Description></Step.Description>
              </Step.Content>
            </Step>
            <Step active={step === 'summary'}>
              <Step.Content>
                <Step.Title>Review & Apply</Step.Title>
                <Step.Description></Step.Description>
              </Step.Content>
            </Step>
          </Step.Group>
          {stepElement}
        </Modal.Content>

        <Menu secondary>
            <Menu.Item as='a'
                       style={{marginLeft:'auto',marginRight:'auto'}}
                       onClick={() => {
                           this.resetSelections();
                           this.props.toggleApplyModal();
                       }}>
              <Icon name='cancel' link/>
              Cancel
            </Menu.Item>
        </Menu>
      </Modal>
    )
  }
}

export default connect(stateToProps,null)(ApplyModal)
