import React, { Component } from 'react';

class FieldErrors extends Component {
  render() {
    let elem=null;
    if (this.props.formSubmitted && this.props.errors.hasOwnProperty(this.props.fieldName)){
      if (this.props.fieldType==='radio'){
        elem=(<span className={'radio-field-error'}>{this.props.errors[this.props.fieldName]}</span>);
      }
      else{
        elem=(<span className={'field-error'}>{this.props.errors[this.props.fieldName]}</span>);
      }
    }

    return(
      elem
    )
  }
}

export default FieldErrors