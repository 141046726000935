import * as actionType from "../constants/actionTypes";

const initialState={
  profile: {},
  success: false,
  error: false,
  changePassword: {
      success: false,
      error: false,
      message: '',
  },
};

export default (state=initialState,action) =>{
  switch (action.type){
      case actionType.UPDATE_USER: {
          return {
              ...state,
              profile: {...state.profile, ...action.responseData},
              error: null,
              success: true
          };
      }

      case actionType.COMPANY_UPDATE_ERROR: {
          return {
              ...state,
              success: false,
              error: action.errorMessage
          };
      }

      case actionType.ADD_STRIPE_ACCOUNT_TO_USER: {
          return {
              ...state,
              profile: {...state.profile, stripe_account: action.responseData},
              error: null,
              success: true
          };
      }

      case actionType.TOGGLE_STRIPE_CONNECT:{
          return {
              ...state,
              profile: {...state.profile, ...action.responseData},
              error: null,
              success: true
          };
      }

      case actionType.CHANGE_PASSWORD_SUCCESS:{
          const {changePassword} = state;
          changePassword.success = true;
          changePassword.error = false;
          changePassword.message = action.responseData;
          const st={
              ...state,
              changePassword
          }
          return st;
      }

      case actionType.CHANGE_PASSWORD_ERROR:{
          const {changePassword} = state;
          changePassword.success = false;
          changePassword.error = true;
          changePassword.message = action.errorMessage;
          const st={
              ...state,
              changePassword
          }
          return st;
      }
      default:
        return state;
  }
}