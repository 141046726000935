import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';

import common from './reducers/common';
import signup from './reducers/signup';
import login from './reducers/login';
import talent from './reducers/talent';
import user from './reducers/user';
import company from './reducers/company';
import event from './reducers/event';
import { deleteAuthHeader } from "./utils/common";
import profileTalent from './reducers/profileTalent';
import profileCompany from './reducers/profileCompany';
import profileCompanyUser from './reducers/profileCompanyUser';
import jobsTalent from './reducers/jobsTalent';
import bookingsTalent from './reducers/bookingsTalent';
import jobsCompany from './reducers/jobsCompany';
import settings from './reducers/settings';
import paymentsTalent from './reducers/paymentsTalent';
import review from './reducers/review';
import messaging from './reducers/messaging';
import timesheet from './reducers/timesheet';


const appReducer = combineReducers({
  common,
  signup,
  login,
  user,
  talent,
  company,
  event,
  profileTalent,
  profileCompany,
  profileCompanyUser,
  jobsTalent,
  bookingsTalent,
  jobsCompany,
  settings,
  paymentsTalent,
  messaging,
  router:routerReducer,
  review,
  timesheet,
})

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
        deleteAuthHeader();
    }

    return appReducer(state, action)
}

export default rootReducer