import React,{Component} from 'react';
import { Image } from 'semantic-ui-react';
import VideoThumbnail from 'react-video-thumbnail';

var _url = require('url');
var _requestPromise = require('request-promise');

var _requestPromise2 = _interopRequireDefault(_requestPromise);

var RE_VIMEO = /^(?:\/video|\/channels\/[\w-]+|\/groups\/[\w-]+\/videos)?\/(\d+)$/;
var RE_YOUTUBE = /^(?:\/embed)?\/([\w-]{10,12})$/;
var RE_FACEBOOK = /^\/[\w-]+\/videos\/(\d+)(\/)?$/;
var RE_DAILYMOTION = /^\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?$/;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }


class Thumbnail extends Component{



  constructor(props) {
    super(props);
    this.fetch_thumbnail = this.fetch_thumbnail.bind(this)
  }

  fetch_thumbnail(){
    var urlobj = (0, _url.parse)(this.props.url, true);

    if(this.props.url===''){
      return null
    }

    //youtube
    if (['www.youtube.com', 'youtube.com', 'youtu.be'].indexOf(urlobj.host) !== -1) {
        var video_id = null;
        if ('v' in urlobj.query) {
            if (urlobj.query.v && urlobj.query.v.match(/^[\w-]{10,12}$/)) {
                video_id = urlobj.query.v;
            }
        } else {
            var match = RE_YOUTUBE.exec(urlobj.pathname);
            if (match) {
                video_id = match[1];
            }
        }

        if (video_id) {
            return 'http://img.youtube.com/vi/' + video_id + '/hqdefault.jpg';
        }
    }

    //vimeo
    if (['www.vimeo.com', 'vimeo.com', 'player.vimeo.com'].indexOf(urlobj.host) !== -1) {
        var _match = RE_VIMEO.exec(urlobj.pathname);
        if (_match) {
            var _video_id = _match[1];
            return fetch('https://vimeo.com/api/v2/video/' + _video_id + '.json').then(function(response) {
                return response.json();
            })
            .then(function(parsedData) {
                return(parsedData[0]['thumbnail_medium'])
            })
          }
    }

    //facebook
    if (['facebook.com', 'www.facebook.com'].indexOf(urlobj.host) !== -1) {
        var _match2 = RE_FACEBOOK.exec(urlobj.pathname);

        if (_match2) {
            var _video_id2 = _match2[1];
            return 'https://graph.facebook.com/' + _video_id2+'/picture'
        }
    }

    //dailymotion
    if (['www.dailymotion.com', 'dailymotion.com'].indexOf(urlobj.host) !== -1) {
        var _match3 = RE_DAILYMOTION.exec(urlobj.pathname);
        if (_match3 !== null) {
            if(_match3[4] !== undefined) {
                return "https://www.dailymotion.com/thumbnail/video/"+_match3[4]
                return _match3[4];
            }
            return "https://www.dailymotion.com/thumbnail/video/"+_match3[2]
        }
        return null;
    }

  }


  render() {

    var img_src = this.fetch_thumbnail();
    return (
        <div class="react-thumbnail-generator"><img src = {img_src} /></div>
    )
  }
}

export default Thumbnail