import React from 'react';
import {parse} from "qs";
import {connect} from "react-redux";
import {MOBILE_APP_EXPO_URL} from './../../constants/constants';

const stateToProps = state => {
  return {
    router: state.router,
  }
};

class StripeMobile extends React.Component{
    /*
     This component is used for stripe connect redirect.
     Stripe doesn't accept protocol schemes other than HTTP/HTTPS while our react native app has
     exp:// scheme
     We are using this component to make stripe redirection happy and then we are redirecting
     to our react native app from here using its expo url with url query string attached

     stripe connect redirect url: http://SITE_URL/stripe-mobile
    */
    componentWillMount(){
        const queryString = parse(this.props.router.location.search.substr(1));
        window.location.href = `${MOBILE_APP_EXPO_URL}/?code=${queryString['code']}`;
    }

    render(){
        return <p style={{textAlign: 'center'}}>Redirecting to app...</p>
    }
}

export default connect(stateToProps, null)(StripeMobile)
