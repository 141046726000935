import axios from "axios";

import { API_URL_BASE } from "../constants/constants";
import * as actionType from "../constants/actionTypes";
import { common } from "../utils/common";

export const getEventTimeSheetLineItemList = id => {
  const commonObj = new common();

  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });

    axios
      .get(`${API_URL_BASE}/payment/time-sheet/list/${id}`)
      .then(response => {
        dispatch({
          type: actionType.GET_TIMESHEETLINEITEM_LIST_SUCCESS,
          responseData: response.data
        });
        dispatch({ type: actionType.WAITING_REQUEST, value: false });
      })
      .catch(err => {
        commonObj.handleServerError(
          err,
          dispatch,
          actionType.GET_TIMESHEETLINEITEM_LIST_ERROR
        );
        dispatch({ type: actionType.WAITING_REQUEST, value: false });
      });
  };
};

export const updateTimeSheetLineItems = (eventId, data) => {
  const commonObj = new common();
  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });
    axios
      .put(
        `${API_URL_BASE}/payment/update-time-sheet-line-items/${eventId}`,
        data
      )
      .then(response => {
        console.log("inside timesheet actions response", response);
        commonObj.handleServerSucess(
          response,
          dispatch,
          actionType.UPDATE_EVENT_TIME_SHEET_ITEMS_SUCCESS
        );
        dispatch(getEventTimeSheetLineItemList(eventId));
      })
      .catch(err => {
        commonObj.handleServerError(
          err,
          dispatch,
          actionType.UPDATE_EVENT_TIME_SHEET_ITEMS_ERROR
        );
      });
  };
};

export const withdrawTimeSheet = (eventId, timeSheetId) => {
  const commonObj = new common();
  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });
    axios
      .put(`${API_URL_BASE}/payment/withdraw-time-sheet/${timeSheetId}`)
      .then(response => {
        commonObj.handleServerSucess(
          response,
          dispatch,
          actionType.UPDATE_EVENT_TIME_SHEET_ITEMS_SUCCESS
        );
        dispatch(getEventTimeSheetLineItemList(eventId));
      })
      .catch(err => {
        commonObj.handleServerError(
          err,
          dispatch,
          actionType.UPDATE_EVENT_TIME_SHEET_ITEMS_ERROR
        );
      });
  };
};
