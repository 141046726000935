import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import InfiniteCalendar from "react-infinite-calendar";
import "react-infinite-calendar/styles.css";
import withDragAndDrop from "../../../libs/react-big-calendar/src/addons/dragAndDrop";
import Calendar from "../../../libs/react-big-calendar/src";
import "../../../libs/react-big-calendar/src/addons/dragAndDrop/styles.css";
import "../../../libs/react-big-calendar/src/css/react-big-calendar.css";

import {
  Segment,
  Grid,
  Button,
  Modal,
  Checkbox,
  Label,
  Radio
} from "semantic-ui-react";
import {
  EVENT_CREATION_STEP,
  SET_EMPTY_EVENT_CREATION_PROCESS
} from "../../../constants/actionTypes";
import {
  addShift,
  updateShift,
  getEvent,
  deleteEventShift
} from "../../../actions/event";

import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import ServerErrors from "../../Common/ServerErrors";

Calendar.setLocalizer(Calendar.momentLocalizer(moment));

const DnDCalendar = withDragAndDrop(Calendar);

const stateToProps = state => {
  return {
    waitingRequest: state.event.waitingRequest,
    newEvent: state.event.newEvent,
    nextEvent: state.event.nextEvent,
    deleteShiftLoading: state.event.deleteShiftLoading,
    deletedShiftId: state.event.deletedShiftId,
    deleteShiftError: state.event.deleteShiftError
  };
};

const dispatchToProps = dispatch => {
  return {
    getEvent: id => dispatch(getEvent(id)),
    addShift: (data, eventDate) => dispatch(addShift(data, eventDate)),
    updateShift: (id, data) => dispatch(updateShift(id, data)),
    eventBackStep: () => dispatch({ type: EVENT_CREATION_STEP, value: 1 }),
    finishEvent: () => dispatch({ type: SET_EMPTY_EVENT_CREATION_PROCESS }),
    deleteEventShift: shiftId => dispatch(deleteEventShift(shiftId))
  };
};

const colors = [
  "#31DBDA",
  "#3187DB",
  "#AF31DB",
  "#DB3132",
  "#DB8531",
  "#31DB85",
  "#3132DB",
  "#AF31DB",
  "#DB3187"
];

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {
        ...this.props.nextEvent
      },
      talentRoles: this.props.nextEvent.talentRoles,
      eventSelectedDate: this.props.match.params.id
        ? this.props.newEvent.start_date
        : this.props.nextEvent.start_date,
      modalOpen: false,
      newShift: {
        start: "",
        end: ""
      },
      selectedRole: { id: 0 },
      selectedRoleIndex: 0,
      selectedRoles: [],
      selectedRoleShifts: [],
      selectedShift: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getEvent(this.props.match.params.id);
    }
    let selectedShift = this.props.newEvent.shifts.map(item => {
      let colorIndex = this.props.newEvent.talentRoles.findIndex(role => {
        if (item.talent_role !== null) return role.id === item.talent_role.id;
        else return false;
      });

      if (colorIndex === -1 && this.props.newEvent.name !== undefined) {
        colorIndex = 0;
      }

      return {
        ...item,
        event_day: item.event_day.id,
        start: item.start_time,
        end: item.end_time,
        bgColor: colors[colorIndex]
      };
    });

    this.setState({ selectedRoleShifts: selectedShift });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.newEvent.id > 0 && this.props.match.params.id) {
      this.setState({
        talentRoles: nextProps.newEvent.talentRoles
      });
    }
    if (nextProps.newEvent.shifts.length > this.props.newEvent.shifts.length) {
      let savedShift =
        nextProps.newEvent.shifts[nextProps.newEvent.shifts.length - 1];
      let shifts = this.state.event.shifts.slice();

      let newShift = {
        id: savedShift.id,
        start: savedShift.start_time,
        start_time: savedShift.start_time,
        end: savedShift.end_time,
        end_time: savedShift.end_time,
        event_day: savedShift.event_day,
        talent_role: this.state.talentRoles.find(
          item => item.id === savedShift.talent_role
        ),
        bgColor: colors[this.state.selectedRoleIndex]
      };

      let { selectedRoles, selectedRoleShifts } = this.state;
      let talentRoles = this.state.talentRoles.map(item => {
        if (item.id === this.state.selectedRole.id) {
          return { ...item, selected: true };
        }
        return item;
      });

      selectedRoles.push(this.state.selectedRole.id);
      selectedRoleShifts.push(newShift);
      shifts.push(newShift);
      this.setState({
        event: { ...this.state.event, shifts: shifts },
        talentRoles: talentRoles,
        selectedRoles: selectedRoles,
        selectedRoleShifts: selectedRoleShifts
      });
    }

    if (nextProps.deletedShiftId !== this.props.deletedShiftId) {
      let newShifts = _.reject(this.state.selectedRoleShifts, shift => {
        return shift.id === nextProps.deletedShiftId;
      });

      this.setState({ selectedRoleShifts: newShifts });
    }
  }

  onEventResize = (type, { event, start, end }) => {
    let updatedSelectedShifts = this.state.selectedRoleShifts.map(item => {
      if (item.id === event.id) {
        return { ...item, start, end };
      }
      return item;
    });

    this.setState({ selectedRoleShifts: updatedSelectedShifts });
    this.props.updateShift(event.id, {
      start_time: moment(start).format("hh:mm:ss"),
      end_time: moment(end).format("hh:mm:ss"),
      event_day: event.event_day,
      talent_role: event.talent_role.id
    });
  };

  onEventDrop = ({ event, start, end }) => {
    let updatedSelectedShifts = this.state.selectedRoleShifts.map(item => {
      if (item.id === event.id) {
        return { ...item, start, end };
      }

      return item;
    });

    this.props.updateShift(event.id, {
      start_time: moment(start).format("hh:mm:ss"),
      end_time: moment(end).format("hh:mm:ss"),
      event_day: event.event_day,
      talent_role: event.talent_role.id
    });

    this.setState({
      selectedRoleShifts: updatedSelectedShifts
    });
  };

  eventDateSelect = date => {
    this.setState({ eventSelectedDate: date });
  };

  onNavigate = () => {};

  onSelectSlot = data => {
    this.setState({
      modalOpen: true,
      newShift: { start: data.start, end: data.end }
    });
  };

  createShift = () => {
    if (this.state.selectedRole.id === 0) {
      alert("Select a Role");
      return;
    }

    if (moment(this.state.newShift.end) <= moment(this.state.newShift.start)) {
      alert("Start time must be greater than end time");
      return;
    }

    let start = moment(this.state.newShift.start).format("HH:mm");
    let end = moment(this.state.newShift.end).format("HH:mm");
    let selectedDate = this.props.newEvent.eventDays.find(date => {
      return (
        moment(date.date, "YYYY-MM-DD").format("YYYY-MM-DD") ===
        moment(this.state.eventSelectedDate).format("YYYY-MM-DD")
      );
    });

    this.props.addShift(
      [
        {
          start_time: start,
          end_time: end,
          event_day: selectedDate.id,
          talent_role: this.state.selectedRole.id
        }
      ],
      moment(this.state.eventSelectedDate).format("YYYY-MM-DD")
    );
    this.setState({ modalOpen: false });
  };

  handleClose = () => this.setState({ modalOpen: false });

  filterShifts = (event, data, item) => {
    let newSelectedRoles = [];

    if (data.checked === true) {
      let { selectedRoles } = this.state;
      selectedRoles.push(item.id);
      newSelectedRoles = selectedRoles;
      let talentRoles = this.state.talentRoles.map(itm => {
        if (item.id === itm.id) {
          return { ...itm, selected: true };
        }
        return itm;
      });

      this.setState({ selectedRoles: selectedRoles, talentRoles: talentRoles });
    } else {
      let { selectedRoles } = this.state;
      newSelectedRoles = selectedRoles.filter(itm => {
        return itm !== item.id;
      });

      let talentRoles = this.state.talentRoles.map(itm => {
        if (item.id === itm.id) {
          return { ...itm, selected: false };
        }
        return itm;
      });

      this.setState({
        selectedRoles: newSelectedRoles,
        talentRoles: talentRoles
      });
    }

    let selectedRoleShifts = this.state.event.shifts
      .filter(itm => {
        return newSelectedRoles.indexOf(itm.talent_role.id) > -1;
      })
      .map(itm => {
        let colorIndex = this.state.event.talentRoles.findIndex(role => {
          return role.id === itm.talent_role.id;
        });

        if (colorIndex === -1) {
          colorIndex = 0;
        }

        return {
          ...itm,
          start: itm.start_time,
          end: itm.end_time,
          bgColor: colors[colorIndex]
        };
      });

    this.setState({ selectedRoleShifts: selectedRoleShifts });
  };

  goBack = event => {
    this.props.eventBackStep();
  };

  save() {
    if (this.props.newEvent.shifts.length === 0) {
      alert("Please create at-least one shift.");
      return;
    }

    this.props.finishEvent();
    this.props.history.push("/events");
  }

  selectRole(e, item, index) {
    this.setState({ selectedRole: item, selectedRoleIndex: index });
  }

  onChangeTimePicker(type, value) {
    if (type === "start") {
      this.setState({
        newShift: { ...this.state.newShift, start: value.toDate() }
      });
    }
    if (type === "end") {
      this.setState({
        newShift: { ...this.state.newShift, end: value.toDate() }
      });
    }
  }

  onSelectEvent = shift => {
    this.setState({ selectedShift: shift });
  };

  deleteShift = () => {
    let shiftId = this.state.selectedShift.id;

    this.props.deleteEventShift(shiftId);
  };

  render() {
    let roles = this.state.talentRoles.map((item, index) => {
      return (
        <Button
          key={item.id}
          style={{ backgroundColor: colors[index] }}
          className={"role-checkbox"}
        >
          <Label style={{ color: "#fff", backgroundColor: colors[index] }}>
            <Checkbox
              onChange={(e, data) => this.filterShifts(e, data, item)}
              checked={item.selected}
            />{" "}
            &nbsp;
            {item.name}
          </Label>
        </Button>
      );
    });

    let roleButtons = this.state.talentRoles.map((item, index) => {
      return (
        <Radio
          key={index}
          label={item.name}
          name="roleGroup"
          value={item.id}
          checked={this.state.selectedRole.id === item.id}
          onChange={e => this.selectRole(e, item, index)}
          className={"role-radio"}
          style={{ backgroundColor: colors[index], color: "#fff" }}
        />
      );
    });

    return (
      <Segment attached raised loading={this.props.waitingRequest}>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={6}
              computer={6}
              style={{ paddingLeft: "20px" }}
            >
              <InfiniteCalendar
                minDate={this.props.newEvent.start_date}
                maxDate={this.props.newEvent.end_date}
                onSelect={this.eventDateSelect}
                theme={{
                  selectionColor: "rgb(138, 84, 156)"
                }}
                selected={this.state.eventSelectedDate}
                width="100%"
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={10} computer={10}>
              <Button.Group>{roles}</Button.Group>
              <div style={{ display: "inline", float: "right" }}>
                <Button size="large" onClick={this.goBack}>
                  Back
                </Button>
                <Button size="large" color={"blue"} onClick={e => this.save(e)}>
                  Finish
                </Button>
              </div>

              {this.props.deleteShiftError && (
                <ServerErrors errorMessage="Shift deletion failed! Try again." />
              )}

              <DnDCalendar
                step={15}
                timeslots={8}
                date={this.state.eventSelectedDate}
                onNavigate={this.onNavigate}
                defaultDate={this.state.eventSelectedDate}
                views={["day"]}
                defaultView="day"
                events={this.state.selectedRoleShifts}
                onEventDrop={this.onEventDrop}
                onEventResize={this.onEventResize}
                onSelectSlot={this.onSelectSlot}
                selectable={true}
                resizable
                scrollToTime={new Date(new Date().setHours(19))}
                onSelectEvent={shift => this.onSelectEvent(shift)}
                onDeleteClick={() => this.deleteShift()}
                deleteEventLoading={this.props.deleteShiftLoading}
                style={{ height: "2000px", marginTop: "20px" }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          size={"mini"}
        >
          <Modal.Header>Create Event</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Button.Group style={{ marginBottom: "10px" }}>
                {roleButtons}
              </Button.Group>
              <p>
                Start Time:
                <TimePicker
                  style={{ width: 100 }}
                  allowEmpty={false}
                  showSecond={false}
                  use12Hours={true}
                  minuteStep={15}
                  defaultValue={moment(this.state.newShift.start)}
                  onChange={value => this.onChangeTimePicker("start", value)}
                />
              </p>
              <p>
                End Time:
                <TimePicker
                  style={{ width: 100 }}
                  allowEmpty={false}
                  showSecond={false}
                  use12Hours={true}
                  inputReadOnly={true}
                  minuteStep={15}
                  defaultValue={moment(this.state.newShift.end)}
                  onChange={value => this.onChangeTimePicker("end", value)}
                />
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color="blue" onClick={this.createShift}>
              Create Event
            </Button>
            <Button basic onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    );
  }
}

export default withRouter(
  connect(
    stateToProps,
    dispatchToProps
  )(Step2)
);
