import React,{Component} from 'react';
import {connect} from "react-redux";
import StripeAccount from './../StripeAccount';

class PaymentSettings extends Component{

  render(){

    return(
      <StripeAccount {...this.props}/>
    )
  }
}

export default connect(null, null)(PaymentSettings)