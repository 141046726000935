import React, { Component } from "react";
import { Accordion, Icon } from "semantic-ui-react";

export default class DepositHelp extends Component {
  state = { activeIndex: 0 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <Accordion fluid styled>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={this.handleClick}
        >
          <Icon name="dropdown" />
          What is the purpose of the event deposit?
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <p>
            The deposit goes towards the total for the event. We want to make
            sure that only serious events are listed.
          </p>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={this.handleClick}
        >
          <Icon name="dropdown" />
          Is this a fee?
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <p>
            Absolutely not! The deposit goes towards the final total for the
            event.
          </p>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 2}
          index={2}
          onClick={this.handleClick}
        >
          <Icon name="dropdown" />
          What happens if my event does not occur?
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 2}>
          <p>You will be refunded for the deposit.</p>
        </Accordion.Content>
      </Accordion>
    );
  }
}
