import React,{Component} from 'react';
import {
  Container,
  Segment,
  Icon,
  Header,
  Modal,
  Checkbox,
  Input,
  Button,
  Label,
  Grid,
  Image} from 'semantic-ui-react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "react-image-gallery/styles/css/image-gallery-no-icon.css"
import './talent-profile.css';
import 'react-html5video/dist/styles.css';
import VideoThumbnail from 'react-video-thumbnail';
import ReactFileReader from 'react-file-reader';
import { ClipLoader } from 'react-spinners';
import ReactPlayer from 'react-player'
import Thumbnail from './thumbnail-generator'
import ReactImage from 'react-image-wrapper';

class PhotoGallyer extends Component{

  constructor(props) {
    super(props);
    this.state = {
      showIndex: true,
      showBullets: true,
      infinite: true,
      showThumbnails: true,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,
      showNav: true,
      slideDuration: 450,
      slideInterval: 2000,
      thumbnailPosition: 'bottom',
      showVideo: {},
      imageFile:[],
      imagePreviewUrl:[],
      open:false,
      type:null,
      loading: false,
      savebutton:true,
      message:'',
      uploadbutton:true,
      media_url:'',
      img_src:null,
    };

    this.handleFiles = this.handleFiles.bind(this);
  }

  savePhoto(event){
    if(this.state.uploadbutton){
        let data = new FormData();
        data.append('portfolio_id', this.props.portfolio_id);
        data.append('media_url', this.state.media_url);
        data.append('type', 'external');
        this.props.addphoto(data);
    }else{
      for (var i = 0; i< this.state.imageFile.length; i++){
        let data = new FormData();
        data.append('portfolio_id', this.props.portfolio_id);
        data.append('media', this.state.imageFile[i]);
        data.append('type', this.state.imageFile[i].type.split('/')[0]);
        this.props.addphoto(data);
      }
    }
    this.setState({imageFile: [], uploadbutton:true, imagePreviewUrl: [], open: false,loading: false, savebutton:true})
  }

  hsndleInputUrlChange(e,{name,value,fieldtype}){
    this.setState({media_url:value});
  }

  cancelPhoto(event) {
    this.setState({imageFile: [], uploadbutton:true, imagePreviewUrl: [], open: false,loading: false, savebutton:true})
  }

  selectExternalSource(){
    this.state.uploadbutton ? this.setState({uploadbutton:true, savebutton:true}) : this.setState({uploadbutton:true, savebutton:false});
  }

  handleFiles(files){

    var i = 0;
    for(i=0 ; i<files.fileList.length; i++){
      //
      if(files.fileList[i].type.split('/')[0]!=="image" && files.fileList[i].type.split('/')[0]!=="video"){
        this.setState({imageFile: [], imagePreviewUrl: [], savebutton:true, message:"Please Upload image and video only"})
        return
      }
      if(files.fileList[i].type.split('/')[0]==="video"){
        if(files.fileList[i].name.split('.')[files.fileList[i].name.split('.').length-1]!=="mp4" && files.fileList[i].name.split('.')[files.fileList[i].name.split('.').length-1]!=="ogg"){
          this.setState({imageFile: [], imagePreviewUrl: [], savebutton:true, message:"Only .mp4 and .ogg vido type supported"})
          return
        }
      }
    }

    this.setState({
      imagePreviewUrl:files.base64,
      imageFile: files.fileList,
      savebutton:false,
      loading: true,
      message:"",
    });
    for (var i = 0; i< this.state.imageFile.length; i++){
      let data = new FormData();
      data.append('portfolio_id', this.props.portfolio_id);
      data.append('media', this.state.imageFile[i]);
      data.append('type', this.state.imageFile[i].type.split('/')[0]);
      this.props.addphoto(data);
    }
    this.setState({loading: false});
  }

  show(){
    this.setState({open: true})
  }

  deleteImage(e, id){
    this.props.deletephoto(id)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.slideInterval !== prevState.slideInterval ||
        this.state.slideDuration !== prevState.slideDuration) {
      // refresh setInterval
      this._imageGallery.pause();
      this._imageGallery.play();
    }
  }

  _onImageClick(event) {
    console.debug('clicked on image', event.target, 'at index', this._imageGallery.getCurrentIndex());
  }

  _onImageLoad(event) {
    console.debug('loaded image', event.target.src);
  }

  _onSlide(index) {
    this._resetVideo();
    console.debug('slid to index', index);
  }

  _onPause(index) {
    console.debug('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    console.debug('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    console.debug('playing from index', index);
  }

  _handleInputChange(state, event) {
    this.setState({[state]: event.target.value});
  }

  _handleCheckboxChange(state, event) {
    this.setState({[state]: event.target.checked});
  }

  _handleThumbnailPositionChange(event) {
    this.setState({thumbnailPosition: event.target.value});
  }

  _resetVideo() {
    this.setState({showVideo: {}});

    if (this.state.showPlayButton) {
      this.setState({showGalleryPlayButton: true});
    }

    if (this.state.showFullscreenButton) {
      this.setState({showGalleryFullscreenButton: true});
    }
  }

  _toggleShowVideo(url) {
    const {showVideo} = this.state;
    showVideo[url] = !Boolean(showVideo[url]);
    this.setState({
      showVideo: showVideo
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({showGalleryPlayButton: false});
      }

      if (this.state.showFullscreenButton) {
        this.setState({showGalleryFullscreenButton: false});
      }
    }
  }

  _renderThumbnail(item){
    // getThumb("https://www.dailymotion.com/video/xyt1t1").then(thumb_url => { // thumb_url is  url or null
    //     //
    //     if(thumb_url===null){
    //       //
    //     }else{
    //       //
    //     } // http://img.youtube.com/vi/dQw4w9WgXcQ/hqdefault.jpg
    // })
    // .catch((err) =>{
    //   //
    // });

    if(item.thumbnail.split('.')[item.thumbnail.split('.').length-1]!=="mp4" && item.thumbnail.split('.')[item.thumbnail.split('.').length-1]!=="ogg"){
      return(
        <Thumbnail url={item.thumbnail} />
      )
    }else{
      return(
        <VideoThumbnail
          videoUrl={item.thumbnail}
        />
      )
    }
  }

  _renderVideo(item) {
    //
    //
    if(item.thumbnail.split('.')[item.thumbnail.split('.').length-1]!=="mp4" && item.thumbnail.split('.')[item.thumbnail.split('.').length-1]!=="ogg"){
      return (
        <div className='image-gallery-image'>
          {
            this.state.showVideo[item.embedUrl] ?
              <ReactPlayer url={item.original}
                playing={'true'}
                controls={'true'}
                width="800px"
                height="600px"
              />
            :
              <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)} className="anchertag">
                <div className='play-button'><Icon name='play' size='huge' color='white' /></div>
                <Thumbnail url={item.thumbnail} />
                {
                  item.description &&
                    <span
                      className='image-gallery-description'
                      style={{right: '0', left: 'initial'}}
                    >
                      {item.description}
                    </span>
                }
              </a>
          }
        </div>
      );
    }else{
      return (
        <div className='image-gallery-image'>
          {
            this.state.showVideo[item.embedUrl] ?
              <ReactPlayer url={item.original}
                playing={'true'}
                controls={'true'}
                width="800px"
                height="600px"
              />
            :
              <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)} className="anchertag">
                <div className='play-button'><Icon name='play' size='huge' color='white' /></div>
                <VideoThumbnail
                  videoUrl={item.thumbnail}
                />
                {
                  item.description &&
                    <span
                      className='image-gallery-description'
                      style={{right: '0', left: 'initial'}}
                    >
                      {item.description}
                    </span>
                }
              </a>
          }
        </div>
      );
    }
  }

  _renderImage(item) {
    return(
      <div style={{position:'relative'}}>
        {this.props.isLoggedUser ? <Button onClick={(e)=>this.deleteImage(e,item.id)} style={{position:'absolute',top:'50px' ,right:'0px'}}><Icon name='trash' /></Button> : null}
        <Image src={item.original}  size='big' centered/>
      </div>
    )

  }

  _renderImageThumbnail(item){
    return(
      <ReactImage
        src={item.original}
        keepAspectRatio={true}
        width={50}
        height={50}
      />
    )
  }

  render() {

    let images = [];
    let list=[];
    if(this.props.talent.galleryphoto){
      list=this.props.talent.galleryphoto.map((item,index)=>{
        if(item.type==="image"){
          return (
            {
              original: item.media_url,
              thumbnail: item.media_url,
              id:item.id,
              renderItem: this._renderImage.bind(this),
              description: this.props.isLoggedUser ? <Button onClick={(e)=>this.deleteImage(e,item.id)}><Icon name='trash' /></Button> : null,
              renderThumbInner: this._renderImageThumbnail.bind(this)//
            }
          )
        }else{
          return(
            {
              thumbnail: item.media_url,
              original: item.media_url,
              embedUrl: item.media_url,
              description: this.props.isLoggedUser ? <Button onClick={(e)=>this.deleteImage(e,item.id)}><Icon name='trash' /></Button> : null,
              renderItem: this._renderVideo.bind(this),
              renderThumbInner: this._renderThumbnail.bind(this)
            }
          )
        }
      });
    }

    if(this.state.imagePreviewUrl.length>0){
      images=this.state.imagePreviewUrl.map((item,index)=>{
        if((index+1) === this.state.imagePreviewUrl.length){
          if(this.state.imageFile[index].type.split('/')[0]==="image"){
            return(
              <Image style={{'padding':'5px 5px'}} src={item} width="100px" height="100px" />
            )
          }else if(this.state.imageFile[index].type.split('/')[0]==="video"){
            return(
              <VideoThumbnail
                videoUrl={item}
                height={100}
                width={100}
              />
            )
          }
        }else{
          if(this.state.imageFile[index].type.split('/')[0]==="image"){
            return(
              <Image style={{'float':'left','padding':'5px 5px'}} src={item} width="100px" height="100px" />
            )
          }else{
            return(
              <VideoThumbnail
                videoUrl={item}
                height={100}
                width={100}
                style={{'float':'left'}}
              />
            )
          }
        }
      });
    }

    return (
        <Segment.Group>
          <Segment secondary textAlign={'left'}>
            <Grid columns={2}>
              <Grid.Column width={8} style={{alignSelf: 'center'}}>
                <Icon name={'photo'} size={'large'} style={{marginRight: '10px'}}/>
                <Header size='small' color='grey' style={{display: 'inline'}}>MEDIA GALLERY</Header>
                <Label circular>{list.length}</Label>
              </Grid.Column>
              <Grid.Column width={8}>
                <Container style={{display: 'inline-block'}}>
                  <ReactFileReader fileTypes="file_extension|video/*|image/*|media_type" base64={true} multipleFiles={true} handleFiles={this.handleFiles}>
                    <Button floated={'right'} color='blue' size='small'>Upload Media</Button>
                  </ReactFileReader>
                  <Modal open={this.state.open} trigger={this.props.isLoggedUser ? <Button color='blue' size='small' floated={'right'} onClick={(e)=>this.show(e)}>Add External Link</Button> : null}>
                    <Header icon='image' content='Upload Media To Gallery' />
                    <Modal.Content>
                      <div><Input name="video_url" onChange={this.hsndleInputUrlChange.bind(this)} placeholder='Video url' /></div>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button color='red' onClick={(e)=>this.cancelPhoto(e)}>
                        <Icon name='remove' /> Cancel
                      </Button>
                      <Button color='green' onClick={(e)=>this.savePhoto(e)}>
                        <Icon name='checkmark' /> Save Media Link
                        </Button>
                    </Modal.Actions>
                  </Modal>
                </Container>
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment >
            { list.length!==0?
              <section className='app'>
                <div className="loading-icon">
                  <ClipLoader
                    color={'#123abc'}
                    loading={this.state.loading}
                  />
                </div>
                <ImageGallery
                  ref={i => this._imageGallery = i}
                  items={list}
                  lazyLoad={false}
                  onClick={this._onImageClick.bind(this)}
                  onImageLoad={this._onImageLoad}
                  onSlide={this._onSlide.bind(this)}
                  onPause={this._onPause.bind(this)}
                  onScreenChange={this._onScreenChange.bind(this)}
                  onPlay={this._onPlay.bind(this)}
                  infinite={this.state.infinite}
                  showBullets={this.state.showBullets}
                  showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
                  showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
                  showThumbnails={this.state.showThumbnails}
                  showIndex={this.state.showIndex}
                  showNav={this.state.showNav}
                  thumbnailPosition={this.state.thumbnailPosition}
                />
              </section>
              :
              <Segment placeholder style={{width: '-webkit-fill-available'}}>
                <Header icon>
                  <Icon name='picture' />
                  Add photos or videos to your profile
                </Header>
              </Segment>
            }
          </Segment>
        </Segment.Group>
    )
  }
}

export default PhotoGallyer
