import React,{Component} from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import {classnames} from "./classnames";

export default class GooglePlaceComponent extends Component{
  render(){
    return (
      <PlacesAutocomplete
        value={this.props.locationText}
        onChange={(address)=>this.props.handleLocationTextChange(address,this.props.index)}
        onSelect={(address)=> this.props.handleLocationSelect(address,this.props.index)}>
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          return (
            <div>
              <div className="google-loc-search-input-container">
                <input {...getInputProps({
                    placeholder: 'Search location...',
                    className: 'google-loc-search-input',
                  })}/>
                {this.props.locationText.length > 0 && (
                  <button className="google-loc-clear-button" onClick={()=>this.props.handleLocationClear(this.props.index)}>
                    x
                  </button>
                )}
              </div>
              {suggestions.length > 0 && (
                <div className="google-loc-autocomplete-container">
                  {suggestions.map(suggestion => {
                    const className = classnames('google-loc-suggestion-item', {
                      'google-loc-suggestion-item--active': suggestion.active,
                    });

                    return (
                      /* eslint-disable react/jsx-key */
                      <div {...getSuggestionItemProps(suggestion, { className })}>
                        <strong>
                          {suggestion.formattedSuggestion.mainText}
                        </strong>{' '}
                        <small>
                          {suggestion.formattedSuggestion.secondaryText}
                        </small>
                      </div>
                    );
                    /* eslint-enable react/jsx-key */
                  })}
                </div>
              )}
            </div>
          );
        }}
      </PlacesAutocomplete>
    )
  }
}