import {
  GET_TALENT_PAYMENTS_FULFILLED,
  GET_TALENT_PAYMENTS_FAILED,
  GET_TALENT_PAYMENTS_FILTERED_DATA
} from "../constants/actionTypes";

const initialState={
  data:[],
  errorMessage:null,
  filteredData:[],
}

export default (state=initialState,action)=>{
  switch (action.type){
    case GET_TALENT_PAYMENTS_FULFILLED:{
      const st={
        ...state,
        data:action.responseData,
        filteredData:action.responseData
      }

      return st;
    }
    case GET_TALENT_PAYMENTS_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }
    case GET_TALENT_PAYMENTS_FILTERED_DATA:{
      const st={
        ...state,
        filteredData:action.filteredData
      }

      return st;
    }
    default:
      return state;
  }
}