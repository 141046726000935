import React,{Component} from 'react';
import { withRouter } from "react-router-dom";
import {
  Card,
  Rating,
  Icon,
  Modal,
  Button,
  Container,
  Image} from 'semantic-ui-react';
import { store } from "../../../../store";
import ProfileStatistics from '../ProfileStatistics';
import '../company-profile.css'
import ImageCrop from 'react-image-crop-component';
import 'react-image-crop-component/style.css';


class CompanyUserInfo extends Component{
  constructor(props){
    super(props);

    this.state={
      edit_show:false,
      imageFile:null,
      imagePreviewUrl:null,
      cropedImage:null,
      open:false
    }

    this.gotoEdit=this.gotoEdit.bind(this);
    this.savePhoto=this.savePhoto.bind(this);
    this.onCropped = this._onCropped.bind(this)
  }

  gotoEdit(event){
    event.preventDefault();
    this.props.history.push('/profile-company-user/'+this.props.profileCompanyUserData.user.id+"/edit");
  }


  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        imageFile: file,
        imagePreviewUrl: reader.result,
        open: true,
      });
    }

    reader.readAsDataURL(file)
  }

  cancelPhoto(event) {
    this.setState({imageFile: null, imagePreviewUrl: null,open: false, cropedImage:null, savebutton:true})
  }

  savePhoto(event){
    let data = new FormData();
    data.append('id', this.props.company.user.id);
    data.append('email', this.props.company.user.email);
    data.append('photo', this.state.cropedImage);
    this.props.updateTalentProfileUser(data);
    this.setState({imageFile: null, imagePreviewUrl: null,open: false, cropedImage:null, savebutton:true})
    // window.location.reload(true);
    // setTimeout(() => window.location.reload(true), 1000);
  }

   _onCropped(e) {
    this.setState({cropedImage:e.image, savebutton:false})
  }


  // props.profileCompanyUserData.user {
  //   const state = store.getState();
  //   const user = state.user.profile;
  //   return user;
  // }

  render(){
    let photo = "https://api.adorable.io/avatars/120/" + this.props.profileCompanyUserData.user.email;
    return(
        <Card style={{width:'100%'}}>
          <Image src={this.props.profileCompanyUserData.photo} className={'company-photo'} />
          <Card.Content textAlign={'left'}>
            {this.props.isLoggedUser ?
                <label style={{cursor: this.state.edit_show ? 'pointer' : ''}} onMouseOver={(e)=>{this.setState({edit_show:true})}} onMouseOut={(e)=>{this.setState({edit_show:false})}}>
                  <Container align="center" style={{backgroundColor:'black'}}>
                    <Image src={!this.props.profileCompanyUserData.user.photo ? photo: this.props.profileCompanyUserData.user.photo} style={{width:'100%', height:'100%',borderRadius:'5px'}} className={'user-profile-image'} />
                  </Container>
                  <Icon name='camera' size='big' style={{color: 'rgba(138, 84, 156, 0.3)', position: 'absolute', top: '5%', right: '5%', display:this.state.edit_show ? 'block' : 'none'}}/>
                  <input type="file" style={{display:'none'}} onClick={(event)=> {event.target.value = null}} onChange={(e)=>this.handleImageChange(e)}/>
                </label>
              :
                <Image className={'user-profile-image'} src={!this.props.profileCompanyUserData.user.photo? photo: this.props.profileCompanyUserData.user.photo} />
            }
            <Modal open={this.state.open}>
              <Modal.Content>
              </Modal.Content>
              <div>
                {this.state.imagePreviewUrl && (
                  <div style={{width: "100%", height: "100%"}}>
                      <ImageCrop src={this.state.imagePreviewUrl}
                        setWidth={1000}
                        setHeight={1000}
                        square={false}
                        resize={true}
                        border={"dashed #ffffff 2px"}
                        onCrop={this.onCropped}/>
                  </div>
                )}
              </div>
              <Modal.Actions>
                <Button color='red' onClick={(e)=>this.cancelPhoto(e)}>
                  <Icon name='remove' /> Cancel
                </Button>
                <Button color='green' disabled={this.state.savebutton} onClick={(e)=>this.savePhoto(e)}>
                  <Icon name='checkmark' /> Crop & Save Image
                </Button>
              </Modal.Actions>
            </Modal>
            <Card.Header style={{marginTop: '20px'}}>
              <span>
              {this.props.profileCompanyUserData.user.first_name} {this.props.profileCompanyUserData.user.last_name}
              </span>
            </Card.Header>
            <Card.Meta>
              <span>
                {this.props.profileCompanyUserData.name}
              </span>
            </Card.Meta>
            {/*<Rating icon='star' rating={this.props.data.companyRating} maxRating={5} className={'user-rating'} disabled/>*/}
            <hr/>

            <ProfileStatistics company={this.props.profileCompanyUserData} isDisable={this.props.isEdit}/>

          </Card.Content>

        </Card>

    )
  }
}

export default withRouter(CompanyUserInfo)
