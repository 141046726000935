import React,{Component} from 'react';
import {connect} from "react-redux";
import {Container, Grid, Segment, Menu, Header, Icon, Message} from 'semantic-ui-react';
import { parse } from 'qs'
import './settings-talent.css';
import {userSettings} from '../../../actions/settingsAction';
import SocialSettings from './SocialSettings';
import WorkCitySettings from './WorkCitySettings';
import NotificationSettings from './NotificationSettings';
import DocumentSettings from './DocumentSettings';
import PaymentSettings from './PaymentSettings';
import CalendarSettings from '../CalendarSettings';
import { addWorkCity, removeWorkCity, primaryWorkCity } from "../../../actions/talent";
import UserInformation from "../UserInformation";
import ChangePassword from "../ChangePassword";
import MeasurementSystem from "./MeasurementSystem";


const stateToProps=(state)=>{
  return{
    data:state.settings.data,
    errorMessage:state.settings.errorMessage,
    router: state.router,
    talent:state.talent.profile,
    waitingRequest: state.common.waitingRequest,
    user:state.user.profile,
    settings:state.settings.data
  }
}

const dispatchToProps=(dispatch)=>{
  return{
    userSettings:(data)=> dispatch(userSettings(data)),
    addWorkCity: (data) => dispatch(addWorkCity(data)),
    removeWorkCity: (cityId) => dispatch(removeWorkCity(cityId)),
    primaryWorkCity: (cityId) => dispatch(primaryWorkCity(cityId))
  }
}

class SettingsTalent extends Component{
  constructor(props){
    super(props);

    this.state = {
      activeTab: '',
      queryString: '',
      lightBlueMessageVisible:false,
      blueMessageVisible:false,
      yellowMessageVisible:false
    }
  }

  componentWillMount(){
      this.queryString = parse(this.props.router.location.search.substr(1));
      let {activeTab} = this.props.match.params;
      activeTab = (activeTab !== undefined) ? activeTab : '';
      this.setState({activeTab});
      this.props.history.push(`/settings/${activeTab}`);
  }

  handleItemClick = (tab) => {
      this.setState({activeTab: tab});
      this.props.history.push(`/settings/${tab}`);
  };

  signout(){

  }

  handleDismiss = (event,name) => {
    if (name==='light-blue'){
      this.setState({ lightBlueMessageVisible: false });
    }
    if (name==='blue'){
      this.setState({ blueMessageVisible: false });
    }
    if (name==='yellow'){
      this.setState({ yellowMessageVisible: false });
    }
  }

  render() {

    const { activeTab } = this.state;

    return (
      <Container className={'main-container'}>
        <Grid stackable  padded>
        {
          (this.state.lightBlueMessageVisible===true ||
            this.state.blueMessageVisible===true ||
            this.state.yellowMessageVisible===true) &&
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              {this.state.lightBlueMessageVisible===true &&
              <Message info
                       onDismiss={(e)=>this.handleDismiss(e,'light-blue')}
                       header='This is a system notification with a short title line!'
                       content='This is a longer explanation of what this notification is about along with some descriptive text about how to take action.'
              />
              }
              {this.state.blueMessageVisible === true &&
              <Message color={'blue'}
                       onDismiss={(e) => this.handleDismiss(e, 'blue')}
                       header='This is a system notification with a short title line!'
                       content='This is a longer explanation of what this notification is about along with some descriptive text about how to take action.'
              />
              }
              {this.state.yellowMessageVisible === true &&
              <Message warning
                       onDismiss={(e) => this.handleDismiss(e, 'yellow')}
                       header='This is a system notification with a short title line!'
                       content='This is a longer explanation of what this notification is about along with some descriptive text about how to take action.'
              />
              }
            </Grid.Column>
          </Grid.Row>
        }

          <Grid.Row>
            <Grid.Column mobile={16} tablet={4} computer={4}>
              <Segment>
                <Header as='h4'>Settings</Header>
                <hr/>
                <Menu secondary vertical>
                  <Menu.Item name='notifications' active={!activeTab} onClick={(e, {name}) => this.handleItemClick('') } />
                  <Menu.Item name='social' active={activeTab === 'social'} onClick={(e, {name}) => this.handleItemClick(name) } />
                  <Menu.Item name='calendar' active={activeTab === 'calendar'} onClick={(e, {name}) => this.handleItemClick(name) } />
                  <Menu.Item name='payments' active={activeTab === 'payments'} onClick={(e, {name}) => this.handleItemClick(name) } />
                  <Menu.Item name='work-cities' active={activeTab === 'work-cities'} onClick={(e, {name}) => this.handleItemClick(name) } />
                  <Menu.Item name='user-information' active={activeTab === 'user-information'} onClick={(e, {name}) => this.handleItemClick(name) } />
                  <Menu.Item name='change-password' active={activeTab === 'change-password'} onClick={(e, {name}) => this.handleItemClick(name) } />
                  <Menu.Item name='measurement-system' active={activeTab === 'measurement-system'} onClick={(e, {name}) => this.handleItemClick(name) } />

                </Menu>
                <hr/>
                <a href='' onClick={(e)=>this.signout(e)}>
                  <Icon name='log out'/>
                  SIGN OUT
                </a>
              </Segment>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={12} computer={12}>
              {!this.state.activeTab && <NotificationSettings queryString={this.queryString}/>}
              {this.state.activeTab==='work-cities' && <WorkCitySettings queryString={this.queryString}/>}
              {this.state.activeTab==='important-docs' && <DocumentSettings queryString={this.queryString}/>}
              {this.state.activeTab==='social' && <SocialSettings queryString={this.queryString}/>}
              {this.state.activeTab==='payments' && <PaymentSettings queryString={this.queryString} history={this.props.history}/>}
              {this.state.activeTab==='calendar' && <CalendarSettings queryString={this.queryString}/>}
              {this.state.activeTab==='user-information' && <UserInformation queryString={this.queryString}/>}
              {this.state.activeTab==='change-password' && <ChangePassword {...this.props} />}
              {this.state.activeTab==='measurement-system' && <MeasurementSystem {...this.props} />}

            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}

export default connect(stateToProps,dispatchToProps)(SettingsTalent)
