import React,{Component} from 'react';
import {
  Segment,
  List,
  Dropdown,
  Image,
  Form,
  Button
} from 'semantic-ui-react';
import {common} from '../../../utils/common';
import {BLANK_TEXT_ERROR_MESSAGE} from "../../../utils/Constants";
import FieldErrors from '../../Common/FieldErrors';
import ServerErrors from '../../Common/ServerErrors';
import ServerSuccess from '../../Common/ServerSuccess';
import {addUser, getCompanyUsersData, removeUser} from "../../../actions/settingsAction";
import connect from "react-redux/es/connect/connect";



const stateToProps=(state)=>{
  return{
    companyUsers:state.settings.companyUsers,
    errorMessage:state.settings.errorMessage,
    success:state.settings.data.success,
    user:state.user.profile,
    data:state.settings.data
  }
}

const dispatchToProps=(dispatch)=>{
  return{
    getCompanyUsersData:()=> dispatch(getCompanyUsersData()),
    addUser:(data)=>dispatch(addUser(data)),
    removeUser:(data)=>dispatch(removeUser(data))
  }
}

class AdminUsers extends Component{
  commonObj=new common();
  constructor(props){
    super(props);
    this.state = {
      email:'',
      form_submitted:false,
      form_error:false,
      server_error:false,
      remove_user:false,
      success: false,
      errors:{country_code:BLANK_TEXT_ERROR_MESSAGE}
    }
  }

  componentWillReceiveProps(nextProps){

    if (nextProps.errorMessage === null){
      if (nextProps.success) {
        this.setState({success: true});
      }
      this.setState({server_error: false});
    }
    else{
      this.setState({form_error: false, server_error:true, success: false});
    }

    if (nextProps.data) {
      if(!nextProps.is_active && this.state.remove_user){
        this.props.getSettingsDataOnRemoveUser();

        this.setState({remove_user: false});
      }
    }
  }

  removeUser = (userId) => {
    this.setState({remove_user:true});
    this.props.removeUser({'id':userId, 'is_active': false});
  }

  viewUserProfile = (url) => {
    this.props.viewUserProfile(url);
  }

  addNewUser= (event) => {
    var email_list = [];
    event.preventDefault();
    this.setState({form_submitted:true, server_error:false, success: false});

    email_list.push(this.state.email);
    this.props.addUser({'email_list':email_list});
  }

  handleChange = (e,{name,value,fieldtype}) => {
    this.commonObj.handleChange(e,this,{name,value,fieldtype});
  }

  componentDidMount(){
    this.props.getCompanyUsersData();
  }

  getSettingsDataOnRemoveUser = () => {
    this.props.getCompanyUsersData();
  }

  viewUserProfile = (url) => {
    this.props.history.push(url);
  }

  renderForm(){
    const {email}=this.state;

    return(
      <Form size='large' onSubmit={this.addNewUser} error={this.state.form_error || this.state.server_error}>

          {this.state.server_error?
            <ServerErrors errorMessage={this.props.errorMessage} />
            :null
          }

          {this.state.success?
            <ServerSuccess successMessage="Success" />
            :null
          }

          <Form.Input
            fluid
            icon='mail'
            iconPosition='left'
            placeholder='Email'
            name='email'
            value={email}
            fieldtype='email'
            onChange={this.handleChange}
          />
          <FieldErrors formSubmitted={this.state.form_submitted} errors={this.state.errors} fieldName={'email'}/>

          <Button onClick={this.addNewUser} color='blue' fluid size='large'>Send</Button>

      </Form>
    )
  }

  render(){
    let listConnected=[];
    const {contacts} = this.props.companyUsers;

    if (contacts){
      listConnected=contacts.map((item,index)=>{
        if (item.is_active && item.type==='company'){
        return(
          <List.Item key={index}>
            <Image src={item.photo} style={{display:'inline',width:'10%'}}/>
            <List.Content style={{width:'90%'}}>
              <List.Description>
                {item.first_name}
                <Dropdown icon='setting' style={{float:'right'}} direction='left'>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={this.viewUserProfile.bind(this, '/profile-company-user/'+item.id)}>View User Details</Dropdown.Item>
                    <Dropdown.Item onClick={this.removeUser.bind(this, item.id)}>Disconnect from Company</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </List.Description>
              <List.Description>
                {item.email}
              </List.Description>
            </List.Content>
          </List.Item>
        )
      }else{return ""}
      });
    }

    return(
      <div>
        <Segment.Group>
          <Segment secondary textAlign={'left'}>
            <span className={'panel-header'}>
              Add Company User
            </span>
          </Segment>
          <Segment textAlign={'left'}>
            { this.renderForm() }
          </Segment>
        </Segment.Group>
        <Segment.Group>
          <Segment secondary textAlign={'left'}>
          <span className={'panel-header'}>
            Connected Company Users
          </span>
          </Segment>
          <Segment textAlign={'left'}>
            <div style={{borderBottom:'solid 1px rgba(34,36,38,.15)',paddingBottom:'10px'}}>
              <Image src={this.props.user.photo} style={{display:'inline',width:'10%',float:'left'}}/>
              <div style={{position:'relative',width:'89%',marginLeft:'11%'}}>
                {this.props.user.first_name}
                <br/>
                {this.props.user.email}
                  <Dropdown icon='setting' style={{right:'0px',position:'absolute',top:'10%'}} direction='left'>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.viewUserProfile.bind(this, '/profile-company-user/'+this.props.user.id)}>View User Details</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
              </div>
            </div>
            <List divided relaxed='very'>
              {listConnected}
            </List>
          </Segment>
        </Segment.Group>
      </div>
    )
  }
}

export default connect(stateToProps,dispatchToProps)(AdminUsers)
