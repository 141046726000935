import {Header, Segment} from "semantic-ui-react";
import React from "react";

const SocialShare = (props) => {
    return (
        <Segment textAlign={'center'}>
            <Header as={'h5'}>Share this job listing</Header>
            <hr/>
            <div className="sharethis-inline-share-buttons"></div>
       </Segment>
    )
};

export default SocialShare;
