import React,{Component} from 'react';
import {
  Grid,
  Image,
  Icon
} from 'semantic-ui-react';

class UserList extends Component{

  constructor(props){
    super(props);
  }


  render(){
    let rows=this.props.contact.map((item,index)=>{
      return(
        <Grid.Row key={index} className={'user-list-row'} style={{marginLeft:'10px'}}>
          <Grid.Column width={10}>
            <Image id={item.user.id} onClick={this.props.goToUser(item.user.id)} src={item.user.photo===null?"https://api.adorable.io/avatars/285/"+item.user.email:item.user.photo} className={'company-user-img'}/>
            <span id={item.user.id} onClick={this.props.goToUser(item.user.id)} className={'company-user-name'}>{item.user.first_name}</span>
          </Grid.Column>
          <Grid.Column textAlign={'right'} width={6}>
            <Icon name={'talk'} size={'large'} style={{marginRight: '10px'}}/>
          </Grid.Column>
        </Grid.Row>
      )
    });

    return(
      <Grid className={'user-list-grid'}>
        {rows}
      </Grid>
    )
  }
}

export default UserList
