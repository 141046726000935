import React,{Component} from 'react';
import {Segment, Button, Icon, Loader} from 'semantic-ui-react';
import ServerErrors from '../Common/ServerErrors';
import {connect} from "react-redux";
import { syncGoogleCalendar, disconnectGoogleCalendar } from "../../actions/settingsAction";

const stateToProps=(state)=>{
    return{
        googleCalendarSyncing: state.common.waitingRequest,
        syncGoogleCalendarError:state.settings.syncGoogleCalendarError,
        user: state.user.profile
    }
}

const dispatchToProps=(dispatch)=>{
    return{
        syncGoogleCalendar:(data)=> dispatch(syncGoogleCalendar(data)),
        disconnectGoogleCalendar:()=> dispatch(disconnectGoogleCalendar())
    }
}

class SyncGoogleCalendar extends Component{

  componentWillMount(){
      const {queryString} = this.props;
      if (!this.props.user['is_google_calendar_synced'] && queryString['code']){
          const {code, state} = queryString;
          this.props.syncGoogleCalendar({code, state});
      }
  }

  renderServerErrors(){
      const {syncGoogleCalendarError} = this.props;
      if (syncGoogleCalendarError){
          return <ServerErrors errorMessage={syncGoogleCalendarError} />
      }
  }

  clickButton = () => {
    // this.props.history.push(this.props.user['google_authorization_url']);
    // alert(this.props.user['google_authorization_url']);
    window.location = this.props.user['google_authorization_url'];

  }

  renderSyncButton = () => {
      if (this.props.googleCalendarSyncing){
          return <Loader active inline='centered' />
      }

      if (this.props.user['is_google_calendar_synced']){
          return <Button color='blue' onClick={() => this.props.disconnectGoogleCalendar()}>
              <Icon name='google plus' />Disconnect Google Calendar
          </Button>
      }

      return (
          <Button color='blue' onClick={() => this.clickButton()}>
            <Icon name='google plus'/>
              Connect Google Calendar
          </Button>
      )
  }

  render(){
    return(
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <span className={'panel-header'}>
            Calendar Sync
          </span>
        </Segment>
          <Segment>
              <div className={'stripe-connect'}>
                  {this.renderServerErrors()}
                  {this.renderSyncButton()}
              </div>
          </Segment>
      </Segment.Group>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(SyncGoogleCalendar)
