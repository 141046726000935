import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Container, Grid, Button, Loader } from "semantic-ui-react";
import TimePicker from "rc-time-picker";
import UserCard from "./UserCard";
import {
  updateTimeSheetLineItems,
  getEventTimeSheetLineItemList
} from "../../../actions/timesheet";
import moment from "moment";
import ServerErrors from "../../Common/ServerErrors";
import ServerSuccess from "../../Common/ServerSuccess";
import { stat } from "fs";

const stateToProps = state => {
  const timeSheets = state.timesheet.eventTimeSheets;
  _.each(timeSheets, timeSheet => {
    _.each(timeSheet["time_sheet_line_items"], item => {
      if (!item["client_start_time"]) {
        item["client_start_time"] = item["talent_start_time"];
      }
      if (!item["client_end_time"]) {
        item["client_end_time"] = item["talent_end_time"];
      }
    });
  });

  return {
    waitingRequest: state.common.waitingRequest,
    timeSheets,
    error: state.timesheet.error,
    success: state.timesheet.success
  };
};

const dispatchToProps = dispatch => {
  return {
    getEventTimeSheetLineItemList: id =>
      dispatch(getEventTimeSheetLineItemList(id)),
    updateTimeSheetLineItems: (id, data) =>
      dispatch(updateTimeSheetLineItems(id, data))
  };
};

class TimeSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSheets: [],
      finalized: false
    };
  }

  componentDidMount() {
    const eventId = this.props.match.params["event_id"];
    this.props.getEventTimeSheetLineItemList(eventId);
  }

  componentWillReceiveProps(props) {
    this.setState({ timeSheets: props.timeSheets });
  }

  onChange = (id, name, value) => {
    // update time_sheet_line_item.start_time/end_time
    const { timeSheets } = this.state;
    _.each(timeSheets, timeSheet => {
      _.each(timeSheet["time_sheet_line_items"], item => {
        if (item.id === id) {
          item[name] = moment(value, "hh:mm A")
            .utc()
            .format();
        }
      });
    });

    this.setState({ timeSheets });
  };

  finalizeTimeSheets = () => {
    let timeSheetItems = this.state.timeSheets.reduce(
      (arr, timeSheet) => arr.concat(timeSheet["time_sheet_line_items"]),
      []
    );

    timeSheetItems = timeSheetItems.map(item => {
      return {
        id: item["id"],
        client_start_time: item["client_start_time"],
        client_end_time: item["client_end_time"],
        time_sheet: item["time_sheet"]["id"]
      };
    });

    const eventId = this.props.match.params["event_id"];
    this.props.updateTimeSheetLineItems(eventId, timeSheetItems);
    this.setState({ finalized: true });
  };

  renderTalent = (talent, role) => {
    return <UserCard key={talent.id} user={talent.user} role={role} />;
  };

  renderTimeSheetStatus = timeSheetItem => {
    if (timeSheetItem["time_sheet"]["status"] === "submitted_to_talent") {
      return `Submitted to talent at ${moment
        .utc(timeSheetItem["time_sheet"]["submitted_to_talent_at"])
        .local()
        .format("LLL")}`;
    }

    if (timeSheetItem["time_sheet"]["status"] === "in_review") {
      return "In Review for Shift Dash Staff";
    }

    if (timeSheetItem["time_sheet"]["status"] === "reviewed") {
      return "Reviewed by Shift Dash Staff";
    }

    if (timeSheetItem["time_sheet"]["status"] === "approved") {
      return "Approved";
    }
  };

  renderTimeSheetItem = timeSheetItem => {
    const shift = timeSheetItem["event_shift"];
    const isEditable =
      timeSheetItem["time_sheet"]["status"] === "submitted_to_client";
    const shiftDate = `${moment(shift["event_day"]["date"]).format(
      "MMM"
    )} ${moment(shift["event_day"]["date"]).format("DD")}`;
    const clientStartTime = timeSheetItem["client_start_time"]
      ? moment.utc(timeSheetItem["client_start_time"]).local()
      : null;
    const clientEndTime = timeSheetItem["client_end_time"]
      ? moment.utc(timeSheetItem["client_end_time"]).local()
      : null;

    return (
      <Grid.Row className={"time-sheet-item"} style={{ marginBottom: "20px" }}>
        <Grid.Row>
          <Grid.Column width={16}>
            <p style={{ textAlign: "center" }}>
              <strong>{shiftDate}</strong>
              &emsp;
              {moment
                .utc(`${shift["event_day"]["date"]} ${shift["start_time"]}`)
                .local()
                .format("hh:mm A")}
              &nbsp; - &nbsp;
              {moment
                .utc(`${shift["event_day"]["date"]} ${shift["end_time"]}`)
                .local()
                .format("hh:mm A")}
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ textAlign: "center" }}>
          <TimePicker
            placeholder={"start time"}
            allowEmpty={false}
            minuteStep={15}
            showSecond={false}
            value={clientStartTime}
            disabled={!isEditable}
            format={"hh:mm A"}
            use12Hours={true}
            onChange={value => {
              isEditable &&
                this.onChange(timeSheetItem["id"], "client_start_time", value);
            }}
          />
          &nbsp; - &nbsp;
          <TimePicker
            placeholder={"end time"}
            allowEmpty={false}
            minuteStep={15}
            showSecond={false}
            value={clientEndTime}
            disabled={!isEditable}
            format={"hh:mm A"}
            use12Hours={true}
            onChange={value => {
              isEditable &&
                this.onChange(timeSheetItem["id"], "client_end_time", value);
            }}
          />
        </Grid.Row>
        <Grid.Row style={{ marginTop: "10px" }}>
          <Grid.Column width={16}>
            <p style={{ textAlign: "center" }}>
              {this.renderTimeSheetStatus(timeSheetItem)}
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid.Row>
    );
  };

  renderTimeSheet = timeSheet => {
    const talentRole =
      timeSheet["time_sheet_line_items"][0]["event_shift"]["talent_role"][
        "name"
      ];
    return (
      <Grid.Row style={{ marginBottom: "250px" }}>
        <Grid.Column width={8} style={{ width: "50%", float: "left" }}>
          {this.renderTalent(timeSheet.talent, talentRole)}
        </Grid.Column>

        <Grid.Column width={8} style={{ width: "50%", float: "left" }}>
          {timeSheet["time_sheet_line_items"].map(item => {
            return this.renderTimeSheetItem(item);
          })}
        </Grid.Column>
      </Grid.Row>
    );
  };

  render() {
    const { waitingRequest, success, error } = this.props;
    const { timeSheets, finalized } = this.state;
    if (timeSheets.length === 0 && !error && !waitingRequest) {
      return (
        <ServerErrors errorMessage={"No time sheets have been submitted"} />
      );
    }

    return (
      <Container className={"main-container"}>
        <Grid stackable padded style={{ width: "100%" }}>
          <Grid.Row className={"content-row"}>
            <Grid style={{ width: "100%" }}>
              <Grid.Row>
                {!waitingRequest && error && (
                  <ServerErrors errorMessage={error} />
                )}
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={11}>
                  {timeSheets.map(timeSheet => {
                    return this.renderTimeSheet(timeSheet);
                  })}
                </Grid.Column>
                <Grid.Column width={5}>
                  <div style={{ textAlign: "center", marginBottom: "10px" }}>
                    <Button
                      size="large"
                      onClick={() => this.props.history.push("/events")}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="large"
                      color={"blue"}
                      onClick={() => this.finalizeTimeSheets()}
                    >
                      Finalize
                    </Button>
                  </div>
                  {waitingRequest && <Loader active inline="centered" />}
                  {!waitingRequest && finalized && success && (
                    <ServerSuccess successMessage={"Time sheets updated"} />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default connect(
  stateToProps,
  dispatchToProps
)(TimeSheet);
