import axios from 'axios';

import {API_URL_BASE} from '../constants/constants';
import * as actionType from '../constants/actionTypes';
import {common} from '../utils/common';


export const updateTalentProfile =(data)=>{
    const commonObj=new common();
    return (dispatch)=>{
        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.patch(`${API_URL_BASE}/talent/profile/update`,data)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.TALENT_UPDATE_SUCESS);
            })  
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.TALENT_UPDATE_ERROR);
            })
    }
}

export const addWorkCity =(data)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.post(`${API_URL_BASE}/talent/workcity/add`,data)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.ADD_WORK_CITY_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.ADD_WORK_CITY_ERROR);
            })
    }
}

export const removeWorkCity =(cityId)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.delete(`${API_URL_BASE}/talent/workcity/delete/${cityId}`)
            .then((response)=>{
                removeCitySuccess(response,dispatch, cityId);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.REMOVE_WORK_CITY_ERROR);
            })
    }
}

export const primaryWorkCity =(cityId)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});
        axios.put(`${API_URL_BASE}/talent/workcity/primary-workcity/${cityId}`)
            .then((response)=>{
                dispatch({type: actionType.PRIMARY_WORK_CITY_SUCCESS, responseData: {id: cityId}});
                dispatch({type:actionType.WAITING_REQUEST, value:false});
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.REMOVE_WORK_CITY_ERROR);
            })
    }
}

export const updateTalentProfileUser =(data)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});
        let id = null;
        // this action could accept data in FormData format or Object format {}
        if (data instanceof FormData){
            id = data.get('id');
            data.delete('id');
        }else{
            id = data['id'];
            delete data['id'];
        }
        axios.put(`${API_URL_BASE}/user/update/profile/${id}`,data)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.UPDATE_USER);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.TALENT_UPDATE_ERROR);
            })
    }
}

export const updateCompanyProfileUser =(data)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});
        let id = null;
        // this action could accept data in FormData format or Object format {}
        if (data instanceof FormData){
            id = data.get('id');
            data.delete('id');
        }else{
            id = data['id'];
            delete data['id'];
        }
        axios.put(`${API_URL_BASE}/user/update/profile/${id}`,data)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.UPDATE_USER);
                axios.get(`${API_URL_BASE}/company/profile-company-user/${id}`)
                  .then((response)=>{
                    commonObj.handleServerSucess(response,dispatch,actionType.GET_PROFILE_COMPANY_USER_FULFILLED_DATA);
                  })
                  .catch((err) =>{
                    commonObj.handleServerError(err,dispatch,actionType.GET_PROFILE_COMPANY_USER_FAILED_DATA);
                  })
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.TALENT_UPDATE_ERROR);
            })
    }
}

export const updateTalentProfileUserImage =(data)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});
        let id = null;
        // this action could accept data in FormData format or Object format {}
        if (data instanceof FormData){
            id = data.get('id');
            data.delete('id');
        }else{
            id = data['id'];
            delete data['id'];
        }
        axios.patch(`${API_URL_BASE}/user/update/profile-image/${id}`,data)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.UPDATE_USER);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.TALENT_UPDATE_ERROR);
            })
    }
}


const removeCitySuccess = (response, dispatch, cityId) => {
        dispatch({type:actionType.REMOVE_WORK_CITY_SUCCESS, responseData:cityId});
        dispatch({type:actionType.WAITING_REQUEST, value:false});
}

export const getTalentRoles = (eventId, companyId)=>{
  const commonObj=new common();
  return (dispatch)=>{
    dispatch({type: actionType.WAITING_REQUEST, value:true});

    let params = '';
    if (eventId){
        params = `?event_id=${eventId}`;
    }

    if (companyId){
        params = `?company_id=${companyId}`;
    }

    axios.get(`${API_URL_BASE}/talent/role/list${params}`)
      .then((response)=>{
          commonObj.handleServerSucess(response, dispatch, actionType.GET_TALENT_ROLES_SUCCESS);
      })
      .catch((err) =>{
        commonObj.handleServerError(err, dispatch, actionType.GET_TALENT_ROLES_ERROR);
      })
  }
};

export const createTalentRole =(data)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.post(`${API_URL_BASE}/talent/role/add`,data)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.ADD_TALENT_ROLE_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.ADD_TALENT_ROLE_ERROR);
            })
    }
}

export const updateTalentRole =(data)=>{
    const commonObj=new common();
    const {id} = data;
    delete data.id;

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.put(`${API_URL_BASE}/talent/role/edit/${id}`,data)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.UPDATE_TALENT_ROLE_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.UPDATE_TALENT_ROLE_ERROR);
            })
    }
}

export const addSkill =(data)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.ADD_SKILL_WAITING, value:true});

        axios.post(`${API_URL_BASE}/talent/skill/add`,data)
            .then((response)=>{
                commonObj.serverSucessHandler(response.data,dispatch, actionType.ADD_SKILL_SUCCESS,actionType.ADD_SKILL_WAITING);
            })
            .catch((err) =>{
                commonObj.serverErrorHandler(err,dispatch, actionType.ADD_SKILL_ERROR,actionType.ADD_SKILL_WAITING);
            })
    }
}

export const getSkills =()=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.get(`${API_URL_BASE}/talent/skill/list`)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.GET_SKILLS_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.GET_SKILLS_ERROR);
            })
    }
}

export const addCertification =(data)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.ADD_CERTIFICATION_WAITING, value:true});

        axios.post(`${API_URL_BASE}/talent/certification/add`,data)
            .then((response)=>{
                commonObj.serverSucessHandler(response.data,dispatch, actionType.ADD_CERTIFICATION_SUCCESS, actionType.ADD_CERTIFICATION_WAITING);
            })
            .catch((err) =>{
                commonObj.serverErrorHandler(err,dispatch, actionType.ADD_CERTIFICATION_ERROR, actionType.ADD_CERTIFICATION_WAITING);
            })
    }
}

export const getCertifications =()=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.get(`${API_URL_BASE}/talent/certification/list`)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.GET_CERTIFICATIONS_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.GET_CERTIFICATIONS_ERROR);
            })
    }
}

export const getJobExperience =(id)=>{
    const commonObj=new common();

    return (dispatch)=>{
        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.get(`${API_URL_BASE}/talent/jobexperience/list/${id}`)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.GET_JOB_EXPERIENCE_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.GET_JOB_EXPERIENCE_ERROR);
            })
    }
}

export const deleteJobExperience =(id)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.delete(`${API_URL_BASE}/talent/jobexperience/delete/${id}`)
            .then((response)=>{
                removeExperienceSuccess(response,dispatch, id);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.DELETE_JOB_EXPERIENCE_ERROR);
            })
    }
}


const removeExperienceSuccess = (response, dispatch, cityId) => {
        dispatch({type:actionType.DELETE_JOB_EXPERIENCE_SUCCESS, responseData:cityId});
        dispatch({type:actionType.WAITING_REQUEST, value:false});
}


export const saveJobExperience =(data)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.post(`${API_URL_BASE}/talent/jobexperience/create`,data)
            .then((response)=>{
                commonObj.serverSucessHandler(response.data,dispatch, actionType.ADD_JOBEXPERIENCE_SUCCESS, actionType.ADD_JOBEXPERIENCE_WAITING);
                // getJobExperience();
            })
            .catch((err) =>{
                commonObj.serverErrorHandler(err,dispatch, actionType.ADD_JOBEXPERIENCE_ERROR, actionType.ADD_JOBEXPERIENCE_WAITING);
            })
    }

}


export const updateJobExperience =(data)=>{
    const commonObj=new common();

    const id = data.get('id');

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.patch(`${API_URL_BASE}/talent/jobexperience/update/${id}`,data)
            .then((response)=>{
                commonObj.serverSucessHandler(response.data,dispatch, actionType.UPDATE_JOBEXPERIENCE_SUCCESS, actionType.UPDATE_JOBEXPERIENCE_WAITING);
                getJobExperience();
            })
            .catch((err) =>{
                commonObj.serverErrorHandler(err,dispatch, actionType.UPDATE_JOBEXPERIENCE_ERROR, actionType.UPDATE_JOBEXPERIENCE_WAITING);
            })
    }
}

export const getPortfolio =(id)=>{
    const commonObj=new common();

    return (dispatch)=>{
        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.get(`${API_URL_BASE}/talent/portfolio/list/${id}`)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.GET_PORTFOLIO_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.GET_PORTFOLIO_ERROR);
            })
    }
}

export const updatePortfolio =(data)=>{
    const commonObj=new common();
    const id = data.id;
    return (dispatch)=>{
        dispatch({type: actionType.WAITING_REQUEST, value:true});
        axios.patch(`${API_URL_BASE}/talent/portfolio/update/${id}`,data)
            .then((response)=>{
                commonObj.serverSucessHandler(response.data,dispatch, actionType.UPDATE_PORTFOLIO_SUCCESS, actionType.UPDATE_PORTFOLIO_WAITING);
                getJobExperience();
            })
            .catch((err) =>{
                commonObj.serverErrorHandler(err,dispatch, actionType.UPDATE_PORTFOLIO_ERROR, actionType.UPDATE_PORTFOLIO_WAITING);
            })
    }
}

export const createPortfolio =(data)=>{
    const commonObj=new common();
    return (dispatch)=>{
        dispatch({type: actionType.WAITING_REQUEST, value:true});
        axios.post(`${API_URL_BASE}/talent/portfolio/create`,data)
            .then((response)=>{
                commonObj.serverSucessHandler(response.data,dispatch, actionType.CREATE_PORTFOLIO_SUCCESS, actionType.CREATE_PORTFOLIO_WAITING);
                getJobExperience();
            })
            .catch((err) =>{
                commonObj.serverErrorHandler(err,dispatch, actionType.CREATE_PORTFOLIO_ERROR, actionType.CREATE_PORTFOLIO_WAITING);
            })
    }
}

export const deletePortfolio =(id)=>{
    const commonObj=new common();
    return (dispatch)=>{
        dispatch({type: actionType.WAITING_REQUEST, value:true});
        axios.delete(`${API_URL_BASE}/talent/portfolio/delete/${id}`)
            .then((response)=>{
                removePortfolioSuccess(response,dispatch, id);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.DELETE_PORTFOLIO_ERROR);
            })
    }
}

const removePortfolioSuccess = (response, dispatch, Id) => {
        dispatch({type:actionType.DELETE_PORTFOLIO_SUCCESS, responseData:Id});
        dispatch({type:actionType.WAITING_REQUEST, value:false});
}

export const saveGalleryPhoto =(data)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.post(`${API_URL_BASE}/talent/photogallery/add`,data)
            .then((response)=>{
                commonObj.serverSucessHandler(response.data,dispatch, actionType.ADD_GALLERYPHOTO_SUCCESS, actionType.ADD_JOBEXPERIENCE_WAITING);
            })
            .catch((err) =>{
                commonObj.serverErrorHandler(err,dispatch, actionType.ADD_GALLERYPHOTO_ERROR, actionType.ADD_JOBEXPERIENCE_WAITING);
            })
    }
}

export const getGalleryPhoto =(id)=>{
    const commonObj=new common();

    return (dispatch)=>{
        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.get(`${API_URL_BASE}/talent/photogallery/list/${id}`)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.GET_GALLERYPHOTO_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.GET_GALLERYPHOTO_ERROR);
            })
    }
}


export const deleteGalleryPhoto =(id)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.delete(`${API_URL_BASE}/talent/photogallery/delete/${id}`)
            .then((response)=>{
                removeGalleryPhotoSuccess(response,dispatch, id);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.DELETE_POTOGALLERY_IMAGE_ERROR);
            })
    }
}


const removeGalleryPhotoSuccess = (response, dispatch, cityId) => {
        dispatch({type:actionType.DELETE_PHOTOGALLERY_IMAGE_SUCCESS, responseData:cityId});
        dispatch({type:actionType.WAITING_REQUEST, value:false});
}
