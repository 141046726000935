import _ from "lodash";


// range from 3 feet (36 inch) to 7 feet (84 inch) with step size of 1 inch
const heightImperialChoices = _.map(_.range(36, 85, 1), (inch, index) => {
                    const cm = (inch*2.54).toFixed(2); // centimeters
                    return {
                        key: index,
                        value: parseFloat(cm),
                        text: `${Math.floor(inch/12)}′${inch%12}″` // feet`inch``
                    }
                });

// range from 90 centimeter to 216 centimeter with step size of 2 centimeter
const heightMetricChoices = _.map(_.range(90, 217, 2), (cm, index) => {
                    return {
                        key: index,
                        value: parseFloat(cm),
                        text: `${cm} centimeter`
                    }
                });

 // range from 8 inch to 60 inch with step size of 0.5 inch
const chestImperialChoices = _.map(_.range(8, 60, 0.5), (inch, index) => {
                    const cm = (inch*2.54).toFixed(2); // centimeters
                    return {
                        key: index,
                        value: parseFloat(cm),
                        text: `${inch}″`
                    }
                });

 // range from 20 centimeter to 160 centimeter with step size of 2 centimeter
const chestMetricChoices = _.map(_.range(20, 161, 2), (cm, index) => {
                    return {
                        key: index,
                        value: parseFloat(cm),
                        text: `${cm} centimeter`
                    }
                });

const shoeSizeChoices = [
    {key: 0, value: 204, text: '3 Women'},
    {key: 1, value: 208, text: '3.5 Women'},
    {key: 2, value: 212, text: '4 Women'},
    {key: 3, value: 217, text: '4.5 Women'},
    {key: 4, value: 221, text: '5 Women / 4 Men'},
    {key: 5, value: 225, text: '5.5 Women / 4.5 Men'},
    {key: 6, value: 229, text: '6 Women / 5 Men'},
    {key: 7, value: 233, text: '6.5 Women / 5.5 Men'},
    {key: 8, value: 238, text: '7 Women / 6 Men'},
    {key: 9, value: 242, text: '7.5 Women / 6.5 Men'},
    {key: 11, value: 246, text: '8 Women / 7 Men'},
    {key: 12, value: 250, text: '8.5 Women / 7.5 Men'},
    {key: 13, value: 255, text: '9 Women / 8 Men'},
    {key: 14, value: 259, text: '9.5 Women / 8.5 Men'},
    {key: 15, value: 263, text: '10 Women / 9 Men'},
    {key: 16, value: 267, text: '10.5 Women / 9.5 Men'},
    {key: 17, value: 272, text: '11 Women / 10 Men'},
    {key: 18, value: 276, text: '11.5 Women / 10.5 Men'},
    {key: 19, value: 280, text: '12 Women / 11 Men'},
    {key: 20, value: 284, text: '11.5 Men'},
    {key: 21, value: 288, text: '12 Men'},
    {key: 22, value: 293, text: '12.5 Men'},
    {key: 23, value: 297, text: '13 Men'},
    {key: 24, value: 301, text: '13.5 Men'},
    {key: 25, value: 305, text: '14 Men'},
    {key: 26, value: 310, text: '14.5 Men'},
    {key: 27, value: 314, text: 15},
    {key: 28, value: 323, text: 16},
    {key: 29, value: 332, text: 17},
];

const shirtSizeChoices = [
    {key: 0, value: 'xl', text: 'Extra Large'},
    {key: 1, value: 'l', text: 'Large'},
    {key: 2, value: 'm', text: 'Medium'},
    {key: 3, value: 's', text: 'Small'},
    {key: 4, value: 'xs', text: 'Extra Small'}
];

const eyeColorChoices = [
    {key: 0, value: 'blue', text: 'Blue'},
    {key: 1, value: 'brown', text: 'Brown'},
    {key: 2, value: 'green', text: 'Green'},
    {key: 3, value: 'grey', text: 'Grey'},
    {key: 4, value: 'hazel', text: 'Hazel'}
  ];

const hairColorChoices = [
    {key: 0, value:'auburn', text: 'Auburn'},
    {key: 1, value:'black', text: 'Black'},
    {key: 2, value:'blonde', text: 'Blonde'},
    {key: 3, value:'dark blonde', text: 'Dark Blonde'},
    {key: 4, value:'brown', text: 'Brown'},
    {key: 5, value:'light brown', text: 'Light Brown'},
    {key: 6, value:'dark brown', text: 'Dark Brown'},
    {key: 7, value:'red', text: 'Red'},
    {key: 8, value:'bald/clean shaven', text: 'Bald/Clean Shaven'}
  ];

const hairTypeChoices = [
    {key: 0, value: 'bald/shaved', text: 'Bald/Shaved'},
    {key: 1, value: 'long/curly', text: 'Long/Curly'},
    {key: 2, value: 'long/straight', text: 'Long/Straight'},
    {key: 3, value: 'short/curly', text: 'Short/Curly'},
    {key: 4, value: 'short/straight', text: 'Short/Straight'},
    {key: 5, value: 'medium/curly', text: 'Medium/Curly'},
    {key: 6, value: 'medium/straight', text: 'Medium/Straight'},
    {key: 7, value: 'Military cut (buzzed)', text: 'Military Cut (Buzzed)'},
    {key: 8, value: 'mohawk', text: 'Mohawk'}
  ];

const cupSizeChoices = [
        {key: 0, value: 'AA', text: 'AA'},
        {key: 1, value: 'A', text: 'A'},
        {key: 2, value: 'B', text: 'B'},
        {key: 3, value: 'C', text: 'C'},
        {key: 4, value: 'D', text: 'D'},
        {key: 5, value: 'DD', text: 'DD'},
        {key: 6, value: 'E', text: 'E'},
        {key: 7, value: 'F', text: 'F'},
        {key: 8, value: 'FF', text: 'FF'},
        {key: 9, value: 'G', text: 'G'},
        {key: 10, value: 'GG', text: 'GG'},
        {key: 11, value: 'H', text: 'H'},
  ];

const measurementSystems  = {
    metric: {
        weight: 'kg',
        height: heightMetricChoices,
        chest: chestMetricChoices,
        waist: chestMetricChoices,
        pant: chestMetricChoices,
        hip: chestMetricChoices,
        bust: chestMetricChoices,
        shirt: shirtSizeChoices,
        hat: shirtSizeChoices,
        glove: shirtSizeChoices,
        eyeColor: eyeColorChoices,
        hairColor: hairColorChoices,
        hairType: hairTypeChoices,
        cup: cupSizeChoices,
        shoe: shoeSizeChoices
    },

    imperial: {
        weight: 'lb',
        height: heightImperialChoices,
        chest: chestImperialChoices,
        waist: chestImperialChoices,
        pant: chestImperialChoices,
        hip: chestImperialChoices,
        bust: chestImperialChoices,
        shirt: shirtSizeChoices,
        hat: shirtSizeChoices,
        glove: shirtSizeChoices,
        eyeColor: eyeColorChoices,
        hairColor: hairColorChoices,
        hairType: hairTypeChoices,
        cup: cupSizeChoices,
        shoe: shoeSizeChoices
    }
};

export default measurementSystems;