import React, { Component } from 'react';
import {connect} from "react-redux";
import {Segment, Button, Form, Container, List} from 'semantic-ui-react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import './../../Account/Signup/signup.css';
import {updateCompanyProfile} from "../../../actions/company";
import ServerErrors from '../../Common/ServerErrors';
import ServerSuccess from "../../Common/ServerSuccess";
import {classnames} from "../../Events/CreateEvent/classnames";


const stateToProps=(state)=>{
    return {
        waitingRequest: state.common.waitingRequest,
        error: state.company.error,
        success: state.company.success,
        company: state.company.profile
      }
};

const dispatchToProps=(dispatch)=>{
  return{
      updateCompanyProfile:(data)=>dispatch(updateCompanyProfile(data)),
  }
};

class CompanyInformation extends Component {

  constructor(props){
    super(props);
    const {company} = this.props;
    const {street, city, state, country, lat, lng} = company;
    let inputAddress = `${street} ${city} ${state} ${country}`;

    this.state = {
        formSubmit: false,
        inputAddress: inputAddress.trim(),
        company: {
            about: company.about,
            facebook_url: company['facebook_url'],
            twitter_url: company['twitter_url'],
            linkedin_url: company['linkedin_url'],
            site_url: company['site_url'],
            street,
            city,
            state,
            country,
            lat,
            lng
      }
    }
  }

  handleChange = (name, value) => {
    const {company} = this.state;
    company[name] = value;
    this.setState({ company, formSubmit: false });
  };

  onChangeAddress = (inputAddress) => { this.setState({inputAddress}) };

  onSelectAddress = (inputAddress) => {
      this.setState({inputAddress});
      const {company} = this.state;
      geocodeByAddress(inputAddress)
          .then(results => {
              const addresses = results[0]['address_components'];
              company.lat = results[0].geometry.location.lat();
              company.lng = results[0].geometry.location.lng();
              let street = null;

              for(let address of addresses){
                  if (address.types.includes('country')){
                      company.country = address['long_name'];
                  }

                  if (address.types.includes('administrative_area_level_1')){
                      company.state = address['long_name'];
                  }

                  if (address.types.includes('locality')){
                      company.city = address['long_name'];
                  }

                  if (address.types.includes('route')){
                      street = address['long_name'];
                  }

                  if (address.types.includes('street_number')){
                      street = address['long_name'];
                  }

                  if (address.types.includes('sublocality_level_3')){
                      street = street ? `${street} ${address['long_name']}` : address['long_name'];
                  }

                  if (address.types.includes('sublocality_level_2')){
                      street = street ? `${street} ${address['long_name']}` : address['long_name'];
                  }

                  if (address.types.includes('sublocality_level_1')){
                      street = street ? `${street} ${address['long_name']}` : address['long_name'];
                  }
              }

              company.street = street;
              this.setState({company});

          })
  };

  updateCompanyProfile = () => {
      this.setState({formSubmit: true});
      let {company} = this.state;
      company.id = this.props.company.id;
      this.props.updateCompanyProfile(company);
  };

  renderServerErrors(){
      const { error, waitingRequest } = this.props;
      if (!waitingRequest && error && error['non_field_errors'] && this.state.formSubmit){
          return <ServerErrors errorMessage={error['non_field_errors']} />
      }
  }

  renderAddressError = () => {
      const {error} = this.props;
      const {formSubmit} = this.state;
      if (formSubmit && error && (error['street'] || error['city'] || error['state'] || error['country'])){
        return (
            <span className={'field-error'}  >
              {error['street'] && error['street'].toString().replace('This', 'street')}
              {error['city'] && error['city'].toString().replace('This', 'city')}
              {error['state'] && error['state'].toString().replace('This', 'state')}
              {error['country'] && error['country'].toString().replace('This', 'country')}
          </span>
        )
      }
  };

  renderFieldError = (fieldName) => {
      const {error} = this.props;
      const {formSubmit} = this.state;
      if (formSubmit && error && error[fieldName]){
          return <span className={'field-error'}  >{error[fieldName]}</span>
      }
  };

  renderServerSuccess(){
      const {success, error, waitingRequest} = this.props;
      if (!error && !waitingRequest && success && this.state.formSubmit){
          return <ServerSuccess successMessage={'Details updated'} />
      }
  }

  renderForm() {
      const options = {
          types: ['address'],
          componentRestrictions: {country: "us"}
      };
      const styles = {
          root: { 'zIndex': '999999999', 'marginBottom': '20px' }
      };
      const {company} = this.state;
      return (
          <Form size='large' onSubmit={() => this.updateCompanyProfile()}>
                  <Segment style={{'border': '0px', 'box-shadow': 'none'}}>
                      <Form.TextArea
                          fluid
                          placeholder='about'
                          name='about'
                          value={company['about']}
                          rows={4}
                          onChange={(e, {name, value}) => this.handleChange(name, value)}
                      />
                      {this.renderFieldError('about')}

                      <Form.Input
                          fluid
                          icon='facebook'
                          iconPosition='left'
                          placeholder='facebook url'
                          name='facebook_url'
                          value={company['facebook_url']}
                          type='url'
                          onChange={(e, {name, value}) => this.handleChange(name, value)}
                      />
                       {this.renderFieldError('facebook_url')}

                      <Form.Input
                          fluid
                          icon='twitter'
                          iconPosition='left'
                          placeholder='twitter url'
                          name='twitter_url'
                          value={company['twitter_url']}
                          type='url'
                          onChange={(e, {name, value}) => this.handleChange(name, value)}
                      />
                       {this.renderFieldError('twitter_url')}

                      <Form.Input
                          fluid
                          icon='linkedin'
                          iconPosition='left'
                          placeholder='linkedin url'
                          name='linkedin_url'
                          value={company['linkedin_url']}
                          type='url'
                          onChange={(e, {name, value}) => this.handleChange(name, value)}
                      />
                       {this.renderFieldError('linkedin_url')}

                      <Form.Input
                          fluid
                          icon='link'
                          iconPosition='left'
                          placeholder='site url'
                          name='site_url'
                          value={company['site_url']}
                          type='url'
                          onChange={(e, {name, value}) => this.handleChange(name, value)}
                      />
                      {this.renderFieldError('site_url')}

                      <PlacesAutocomplete
                        value={this.state.inputAddress}
                        onChange={(address) => this.onChangeAddress(address)}
                        onSelect={(address) => this.onSelectAddress(address)}
                        options={options}
                        styles={styles}>
                      {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                        return (
                          <div className="Demo__search-bar-container" style={{'width':'100%', 'paddingBottom': '20px'}}>
                              <Form.Input
                                  fluid
                                  icon='location arrow'
                                  iconPosition='left'
                                  name='address'
                                  value={this.state.inputAddress}
                                  type='text'
                                  onChange={(address) => this.onChangeAddress(address)}
                                  style={{marginBottom:'25px'}}
                                  required={true}
                                  style={{ 'height': '40px', 'width': '100%'}}
                                  {...getInputProps({
                                    placeholder: 'Add your address',
                                    className: 'Demo__search-input',
                                  })}
                              />

                                {suggestions.length > 0 && (
                                  <Container style={{ marginTop: '9px', marginLeft: '6px'}}>

                                    {suggestions.map(suggestion => {
                                      const className = classnames('Demo__suggestion-item', {
                                        'Demo__suggestion-item--active': suggestion.active,
                                      });

                                      return (
                                        /* eslint-disable react/jsx-key */
                                        <List divided relaxed>
                                          <List.Item {...getSuggestionItemProps(suggestion, { className })} as='a'>
                                            <List.Icon name='building outline' size='large' verticalAlign='middle' />
                                            <List.Content>
                                              <List.Header>{suggestion.formattedSuggestion.mainText}</List.Header>
                                              <List.Description>
                                                  {suggestion.formattedSuggestion.secondaryText}
                                              </List.Description>
                                            </List.Content>
                                          </List.Item>
                                        </List>

                                      );
                                      /* eslint-enable react/jsx-key */
                                    })}
                                  </Container>
                                )}
                          </div>
                        );
                      }}
                    </PlacesAutocomplete>
                    { this.renderAddressError() }


                      <Button color='blue' fluid size='tiny' loading={this.props.waitingRequest}
                              disabled={this.props.waitingRequest}>Update</Button>
                  </Segment>
              </Form>
      )
  }

  render() {
    return(
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <span className={'panel-header'}>
            Company Information
          </span>
        </Segment>
        <Segment style={{width: '500px'}}>
          {this.renderServerErrors()}
          {this.renderServerSuccess()}
          { this.renderForm() }
        </Segment>
      </Segment.Group>
    )
  }

}

export default connect(stateToProps,dispatchToProps)(CompanyInformation);
