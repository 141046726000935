import {
  GET_COMPANY_JOBS_FULFILLED,
  GET_COMPANY_JOBS_FAILED,
  GET_COMPANY_JOBS_FILTERED_DATA
} from "../constants/actionTypes";
import * as actionType from "../constants/actionTypes";

const initialState={
  data:[],
  errorMessage:null,
  filteredData:[],
}

export default (state=initialState,action)=>{
  switch (action.type){
    case GET_COMPANY_JOBS_FULFILLED:{
      const st={
        ...state,
        data:action.responseData,
        filteredData:action.responseData
      }

      return st;
    }
    case GET_COMPANY_JOBS_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }
    case GET_COMPANY_JOBS_FILTERED_DATA:{
      const st={
        ...state,
        filteredData:action.filteredData
      }

      return st;
    }

    case actionType.PUBLISH_EVENT_SUCCESS:{
      let filteredData = state.filteredData;
      const updatedEvent = action.responseData;
      filteredData = filteredData.map(event => {
          if (event.id ===  updatedEvent.id){
              event['status'] = updatedEvent['status'];
              return event;
          }
          return event;
      });
      return {
        ...state,
        success: true,
        error: null,
        filteredData
      };
    }

    case actionType.PUBLISH_EVENT_ERROR: {
      return {
          ...state,
          success: false,
          error: action.errorMessage
      };
    }

    default:
      return state;
  }
}