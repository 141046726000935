import React,{Component} from 'react';
import {Segment, Item, Grid, Header, Icon, List, Dropdown, Container} from 'semantic-ui-react';
import {isMobileOnly} from 'react-device-detect';
import {
    getJobMaxRate,
    getJobMinRate
} from "../helpers";

class JobList extends Component{
  sortByOptions = [
    { text: "Date", value: "date"},
    { text: "Distance", value: "distance"},
    { text: "Pay", value: "pay_rate"}
  ];

  renderApplyButton = (job) => {
      const status = job['talent_apply_status'];
      if (!status){
          return <a className={'job-apply-button'} href={'javascript:void(0)'}>Apply</a>
      }

      if (status && status.toLowerCase() === 'booked'){
          return <a className={'job-apply-button disabled'} href={'javascript:void(0)'}>Booked</a>
      }

      if (status && status.toLowerCase() === 'pending'){
          return <a className={'job-apply-button disabled'} href={'javascript:void(0)'}>Pending</a>
      }

      if (status && status.toLowerCase() === 'not booked'){
          return <a className={'job-apply-button disabled'} href={'javascript:void(0)'}>Not Booked</a>
      }
  };

  render(){
    const list=this.props.jobs.map((job, index)=>{
      return (
        <div key={index} style={{cursor:'pointer'}} onClick={(e)=>{this.props.goToPage(job.id)}} onMouseEnter={(e)=>{this.props.onHover(index)}}>
          <Item style={{'padding': '15px 0px'}}>
            <Item.Content>
              <Item.Header>
                <Grid textAlign={'left'}>
                  <Grid.Row>
                    <Grid.Column width={8}>
                        <Header as={'h3'} width={6}>
                          <a className={'job-title'}>{job.name}</a>
                        </Header>
                      </Grid.Column>

                      <Grid.Column width={8}>
                          <Header as={'h4'}>
                              {this.renderApplyButton(job)}
                          </Header>
                      </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Item.Header>
              <Item.Description className={'item-desc-client-name'}>
                <Header as={'h5'} >
                {job.company.user.type==='agency' && <Icon name={'building'} style={{'color': '#774b86'}} />}
                {job.company.user.type==='company' && <Icon name={'university'} style={{'color': '#774b86'}}/>}
                {job.company.name}
              </Header>
              </Item.Description>
              <Item.Description>
                <List divided horizontal>
                  <List.Item>
                    <List.Icon name='calendar' style={{'color': '#774b86'}} />
                    <List.Content>
                        {job.dateRange}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='clock' style={{'color': '#774b86'}} />
                    <List.Content>
                        {job.startTime.format('hh:mm A')}
                        &nbsp;-&nbsp;
                        {job.endTime.format('hh:mm A')}
                     </List.Content>

                  </List.Item>
                  <List.Item>
                    <List.Icon name='marker' style={{'color': '#774b86'}} />
                    <List.Content>{job.location}</List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name='dollar' style={{'color': '#774b86'}} />
                    <List.Content>{Math.ceil(getJobMinRate(job))}-{Math.ceil(getJobMaxRate(job))}/hour</List.Content>
                  </List.Item>
                </List>
              </Item.Description>
            </Item.Content>
          </Item>
          {index<this.props.jobs.length-1 && <hr/>}
        </div>
      )
    });

    return (
      <React.Fragment>
        {this.props.jobs.length===0 &&
          <Container textAlign='center'>
            <Segment>
              <Header icon>
                <Icon color='blue' name='search' />
                No results
              </Header>
            </Segment>
          </Container>
        }
        {this.props.jobs.length>0 &&
          <Segment.Group>
            <Segment secondary textAlign={'left'}>

              <Grid verticalAlign='middle'>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={6} computer={6} textAlign={'left'}>
                    <Header size='small' color='grey' style={{display: 'inline'}}>
                      {this.props.jobs.length!==0 ? this.props.jobs.length + " LISTING RESULTS" : "No results found" }
                    </Header>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={10} computer={10}
                               {...(isMobileOnly===true && {textAlign:'left'})}
                               {...(isMobileOnly===false && {textAlign:'right'})}
                  >
                    <span>Sort by </span>
                    <Dropdown
                      placeholder='Sort'
                      selection
                      options={this.sortByOptions}
                      defaultValue={'date'}
                      onChange={(e, {value}) => this.props.sortBy(value)}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            <Segment>
              {list}
            </Segment>
          </Segment.Group>
        }
      </React.Fragment>
    )
  }
}

export default JobList
