import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import moment from "moment/moment";
import {Card, Segment, Button} from 'semantic-ui-react';
import _ from 'lodash';
import {DND_ASSIGN_SHIFTS} from '../../../constants/actionTypes';
import {getUserName} from "../../../utils/common";

const target = {
  drop(props) {
    const {role} = props;
    return ({ role, assignType: 'by_role' });
  },

  canDrop(props){
    const {bookedRoles, role} = props;
    const bookedRole = bookedRoles[role.id];
    if (bookedRole && bookedRole.length > 0){
        const staff = role.staff[0];
        return staff.count > bookedRole.length;
    }
    return true;
  }
};

const collect = (connect,  monitor) => ({
  connectDropTarget: connect.dropTarget(),
  titem:monitor.getItem(),
  isOver:monitor.isOver()
});

class RoleCard extends Component {
  constructor(props){
    super(props);
    this.state={
      isCardEntered:false,
      color:null,
    }
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.isOver !== this.props.isOver){
      this.setState({isCardEntered: nextProps.isOver});
        this.setState({color:'#774b86'});
    }
  }

  getRemainingSlots = () => {
      // will revisit this later for getting available slots, availble empty stots
      // and partially filled slots
      const { role, bookedShifts, applicants } = this.props;
      const filledSlots = [];
      applicants.forEach(applicant => {
          let roleShiftsBookedByApplicant = false;
         _.each(role['event_days'], eventDay => {
             roleShiftsBookedByApplicant = false;
             _.each(eventDay.shifts, shift => {
                 const found = bookedShifts.find(booking => booking.shift.id === shift.id && booking.applicant.talent.id === applicant.talent.id);
                 if (found){
                     roleShiftsBookedByApplicant = true
                 }else{
                     return false;
                 }
             });

             if (!roleShiftsBookedByApplicant){
                 return false;
             }

          });

         if (roleShiftsBookedByApplicant){
            filledSlots.push(applicant);
         }

      });

  };

  renderEventDayShifts = (eventDay) => {
    return eventDay.shifts.map(shift => {
      return (
        <div key={shift.id}>
            <Card.Meta>
              {moment.utc(shift['event_day'].date + ' ' + shift.start_time).local().format('hh:mm A')}
              &nbsp;-&nbsp;
              {moment.utc(shift['event_day'].date + ' ' + shift.end_time).local().format('hh:mm A')}
            </Card.Meta>
        </div>
      )
    });
  };

  renderRoleSlots = () => {
    const { role, bookedRoles } = this.props;
    let bookedRole = bookedRoles[role.id];
    const slots = [];

    for(let i=0; i<role.count; i++){
        if (bookedRole && bookedRole.length > 0){
            const bookedApplicant = bookedRole[0];
            bookedRole = bookedRole.slice(1, bookedRole.length);

            if (role.slots[i].show){
              slots.push(
                <Segment className='staffs' inverted color={'blue'} key={i}>
                  <span>{getUserName(bookedApplicant.talent.user)}</span>
                  <Button
                    size={'mini'}
                    floated={'right'}
                    icon={'trash'}
                    onClick={() => this.props.removeAssignedByRoleTalent(role.id, bookedApplicant.talent.id)}
                  />
                </Segment>
              )
            }
        }
        else {
          if (role.slots[i].show) {
            slots.push(
              <Segment className='staffs' key={i}/>
            )
          }
        }
    }

    return slots;
  };

  renderRoleBox = (eventDays, disabled=false) => {
      // don't draw slots if role has not event days
    if (eventDays.length === 0){
        return (
            <div>
                <Card raised className={'shift-card'} style={{backgroundColor: this.state.isCardEntered ? this.state.color:'transparent'}}>
                 <Card.Content style={{marginBottom: 20}}>
                     Seems 0 talent has applied yet
                 </Card.Content>
                </Card>
            </div>
        )
    }

      return (
           <div className={`shift-card-container ${disabled && 'shift-card-disabled'}`}>
           <Card raised className={'shift-card'} style={{backgroundColor: this.state.isCardEntered ? this.state.color:'transparent'}}>
             <Card.Content style={{marginBottom: 20}}>
                {eventDays}
                {this.renderRoleSlots()}
             </Card.Content>
           </Card>
       </div>
      )
  };

  render(){
    const {role, draggedApplicant, bookedRoles, connectDropTarget} = this.props;

    const eventDays = [];
   _.each(role['event_days'], eventDay => {
      eventDays.push(
                <div style={{marginBottom: 20}} key={eventDay.id}>
                    <h3>{moment(eventDay.date, 'YYYY-MM-DD').format("MMM D, YYYY")}</h3>
                    {this.renderEventDayShifts(eventDay)}
                </div>
      )
   });

   if (draggedApplicant){
       let isRoleBookedByDraggedTalent = false;
       Object.entries(bookedRoles).forEach(bookedRole => {
           const bookedRoleId = bookedRole[0];
           const roleBookings = bookedRole[1];
           roleBookings.forEach(booking => {
               if( parseInt(bookedRoleId) === role.id && booking.talent.id === draggedApplicant.talent.id ){
                   isRoleBookedByDraggedTalent = true;
               }
           });
       });

       let isRoleShiftsAppliedByDraggedTalent = true;
       role['event_days'].forEach(eventDay => {
           eventDay.shifts.forEach(shift => {
              let isDraggedApplicantInShiftApplicants = false;
              shift.applicants.forEach(applicant => {
                  if (applicant.status !== 'booked' && applicant.talent.id === draggedApplicant.talent.id){
                     isDraggedApplicantInShiftApplicants = true;
                     return;
                  }
              });
              isRoleShiftsAppliedByDraggedTalent = isDraggedApplicantInShiftApplicants;
           });
       });


        if (isRoleShiftsAppliedByDraggedTalent && !isRoleBookedByDraggedTalent){
            return connectDropTarget(this.renderRoleBox(eventDays))
        }else{
            return this.renderRoleBox(eventDays, true)
        }
    }

    return connectDropTarget(this.renderRoleBox(eventDays))

  }

}

export default DropTarget(DND_ASSIGN_SHIFTS, target, collect)(RoleCard);
