import React, { Component } from 'react';
import {
  Container,
  Segment,
  Button,
  Grid,
  Loader,
  Form} from 'semantic-ui-react';

import './../Signup/signup.css';
import {connect} from "react-redux";
import ServerErrors from '../../Common/ServerErrors';
import ServerSuccess from '../../Common/ServerSuccess';
import { resetPassword } from "../../../actions/signupAction";

const stateToProps=(state)=>{
    return{
        loading: state.common.waitingRequest,
        success: state.signup.resetPassword.success,
        error: state.signup.resetPassword.error,
        message: state.signup.resetPassword.message
    }
};

const dispatchToProps=(dispatch)=>{
    return{
        resetPassword:(data)=> dispatch(resetPassword(data)),
    }
}


class ResetPassword extends Component {

  constructor(props){
    super(props);
    this.state = {
      uid: null,
      token: null,
      new_password: '',
      confirm_password: ''
    }
  }

  componentDidMount(){
    const {uid, token } = this.props.match.params;
    this.setState({
        uid,
        token
    })
  }

  handleChange = (name, value) => {
    this.setState({[name]: value});
  };

  resetPassword = () => {
    const {new_password, confirm_password, uid, token} = this.state;
    this.props.resetPassword({new_password, confirm_password, uid, token});
  };

  renderMessage = () => {
    if (this.props.error && this.props.message){
      return <ServerErrors errorMessage={this.props.message} />
    }

    if (this.props.success && this.props.message){
       return <ServerSuccess successMessage={this.props.message} />;
     }

  };

  renderForm = () => {
      if (!this.state.uid || !this.state.token){
            return <ServerErrors errorMessage={'Invalid link. Did you click password reset link in inbox'} />
      }

      else{
          return (
              <Form size='large' onSubmit={() => this.resetPassword()}>
                <Segment raised>
                  <Form.Input
                    fluid
                    icon='lock'
                    iconPosition='left'
                    placeholder='New password'
                    type='password'
                    name='new_password'
                    value={this.state.new_password}
                    fieldtype='password'
                    onChange={(e, {name, value}) => this.handleChange(name, value)}
                  />

                  <Form.Input
                    fluid
                    icon='lock'
                    iconPosition='left'
                    placeholder='Confirm new password'
                    type='password'
                    name='confirm_password'
                    value={this.state.confirm_password}
                    fieldtype='password'
                    onChange={(e, {name, value}) => this.handleChange(name, value)}
                  />

                  <Button color='blue' fluid size='large'>Reset</Button>

                </Segment>
              </Form>
          );
      }
  }

  render() {
    return(
      <div>
        <Container textAlign='center' className={'main-container signup-container'}>
          <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
            <Grid.Column>

                {this.renderMessage()}

                {this.renderForm()}

                {
                  this.props.loading ?
                  <Loader active inline='centered' />
                  : null
                }
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    )
  }

}

export default connect(stateToProps, dispatchToProps)(ResetPassword);