import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Container, Grid, Loader, Segment } from "semantic-ui-react";
import _ from "lodash";
import "./job-details.css";
import { getJobDetailsData } from "../../../../actions/jobDetailsTalentAction";
import Details from "./Details";
import CompanyDetails from "./CompanyDetails";
import SocialShare from "./SocialShare";
import ReportJob from "./ReportJob";
import ServerErrors from "../../../Common/ServerErrors";
import Documents from "./Documents";
import { getEventTimeSheetLineItemList } from "../../../../actions/timesheet";
import { stat } from "fs";

const allComplete = timeSheets => {
  let complete = false;
  _.each(timeSheets, timeSheet => {
    if (timeSheet.status != "approved") {
      complete = false;
    } else {
      complete = true;
    }
  });
  return complete;
};

const stateToProps = state => {
  const timeSheets = state.timesheet.eventTimeSheets;
  let timeSheetSubmitted = !!timeSheets.length;
  let timeSheetComplete = allComplete(timeSheets);

  return {
    waitingRequest: state.common.waitingRequest,
    job: state.jobsTalent.singleData,
    errorMessage: state.jobsTalent.errorMessage,
    timeSheets: state.timesheet.eventTimeSheets,
    timeSheetSubmitted: timeSheetSubmitted,
    allTimeSheetsComplete: timeSheetComplete
  };
};

const dispatchToProps = dispatch => {
  return {
    getJobDetailsData: id => dispatch(getJobDetailsData(id)),
    getEventTimeSheetLineItemList: id =>
      dispatch(getEventTimeSheetLineItemList(id))
  };
};

class JobDetailsCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSheets: [],
      timeSheetSubmitted: false,
      allTimeSheetsComplete: false
    };
  }
  componentWillMount() {
    const script = document.createElement("script");
    script.src =
      "https://platform-api.sharethis.com/js/sharethis.js#property=5ace3b233368f4001495b78c&product=inline-share-buttons";
    script.async = true;
    document.body.appendChild(script);
  }

  componentDidMount() {
    const jobId = this.props.match.params.id;
    this.props.getEventTimeSheetLineItemList(jobId);
    this.props.getJobDetailsData(jobId);
  }

  componentWillReceiveProps(props) {
    this.setState({
      timeSheets: props.timeSheets,
      allTimeSheetsComplete: props.allTimeSheetsComplete
    });
  }

  renderJobDetails = () => {
    if (this.props.errorMessage) {
      return <ServerErrors errorMessage={this.props.errorMessage} />;
    }

    if (this.props.waitingRequest || _.isEmpty(this.props.job)) {
      return <Loader active inline="centered" />;
    }

    const { job } = this.props;

    return (
      <Grid stackable padded>
        <Grid.Row>
          <Details job={this.props.job} />
          <Grid.Column mobile={16} tablet={6} computer={6}>
            <CompanyDetails
              history={this.props.history}
              job={job}
              jobStatus={this.props.job.status}
              company={job.company}
              timeSheets={this.props.timeSheets}
              timeSheetSubmitted={this.props.timeSheetSubmitted}
              allTimeSheetsComplete={this.props.allTimeSheetsComplete}
            />
            <SocialShare job={job} />
            <Segment textAlign={"center"}>
              <h5>Give feedback</h5>
              {job["is_all_time_sheets_approved"] ? (
                <Button
                  color={`red`}
                  onClick={() =>
                    this.props.history.push(`/job/${job.id}/rating`)
                  }
                >
                  Rate talents
                </Button>
              ) : (
                "You can submit feedback once you approve timesheets"
              )}
            </Segment>
            <ReportJob job={job} />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={10} computer={10}>
            <Documents event={job} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  render() {
    return (
      <Container className={"main-container"}>
        {this.renderJobDetails()}
      </Container>
    );
  }
}

export default connect(
  stateToProps,
  dispatchToProps
)(JobDetailsCompany);
