import React,{Component} from 'react';
import {Segment, Button, Item, Grid, Header, Dropdown} from 'semantic-ui-react';
import {isMobileOnly} from 'react-device-detect';
import './payment.css';
import moment from "moment";

class PaymentList extends Component{

  render(){
    let pendingCount=this.props.payments.filter((item)=>{ return item.status === 'pending' }).length;
    let paidCount=this.props.payments.filter((item)=>{ return item.status === 'paid' }).length;

    const list=this.props.payments.map((item,index)=>{
      return (
        <div key={index} style={{marginBottom:'14px'}}>
          <Item>
            <Item.Content>
              <Item.Header>
                <Grid textAlign={'left'}>
                  <Grid.Row>
                    <Grid.Column width={10} >
                      <Header as={'h3'}>
                        <a href={'javascript:void(0)'} onClick={() => this.props.history.push(`/job/${item.eventId}`)}  className={'job-title'}>{item.eventName}</a>
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      {item.status==='paid' && <Button basic className={'status-button'} color={'green'} floated={'right'}>${item.amount}</Button>}
                      {item.status==='pending' && <Button basic className={'status-button'} color={'red'} floated={'right'}>${item.amount}</Button>}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Item.Header>
              <Item.Description className={'item-client-name'}>
                <Header as={'h5'} color={'grey'} textAlign={'left'}>{item.client}</Header>
              </Item.Description>
              <Item.Description>
                <Header as={'h5'} color={'blue'} style={{marginTop:'8px'}}>{item.hours} Total Hours</Header>
                  <span className={'paid-on'}>{item.status.toLowerCase() === 'paid' ? `Paid at ${moment(item.paidDate).format('LLLL')}` : `Scheduled at ${moment.utc(item.date).format('LLLL')}`}</span>
              </Item.Description>
            </Item.Content>
          </Item>
          {index<this.props.payments.length-1 && <hr/>}
        </div>
      )
    });

    return (
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <Grid verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={10} computer={10} textAlign={'left'}>
                <span>
                  {paidCount} Paid - {pendingCount} PENDING
                </span>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={6} computer={6}
               {...(isMobileOnly===true && {textAlign:'left'})}
               {...(isMobileOnly===false && {textAlign:'right'})}
              >
                <span>Sort by </span>
                <Dropdown
                  placeholder='sort'
                  selection
                  options={this.props.sortByList}
                  name={'sort'}
                  value={this.props.sortFilterValue}
                  fieldtype={'select'}
                  onChange={(e, {value}) => this.props.sortBy(value)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment>
          { list.length > 0 ? list : 'No payments yet' }
        </Segment>
      </Segment.Group>
    )
  }
}

export default PaymentList