import React,{Component} from 'react';
import {Button, Container, Header, Icon, Menu, Message, Segment, Dropdown} from "semantic-ui-react";

class ProfileSelection extends Component{
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage:false,
      portfolios: props.portfolios
    }
  }

  goNext = event => {
    event.preventDefault();
    if (this.props.selectedPortfolio === null){
      this.setState({showErrorMessage: true});
      return;
    }
    this.props.selectStep('summary');
  };

  render() {
    let portfolio_list = []
    this.state.portfolios.map((portfolio) => {
      return portfolio_list.push({ key:portfolio.id, value: portfolio.id, text: portfolio.title })
    })
    return(
      <Segment attached raised>
        <Container textAlign='center'>
          <Header as={'h5'}>What profile would you like to submit?</Header>
          {this.state.showErrorMessage &&
            <Message
              negative
              onDismiss={(e)=> this.handleDismiss(e)}
              header='Error!'
              content='Please select profile'
            />
          }
          <div className={'portfolio-list'}>
            <Dropdown
              placeholder='Select your portfolio'
              fluid
              selection
              defaultValue={this.props.selectedPortfolio? this.props.selectedPortfolio.id: null}
              onChange={this.props.selectPortfolio}
              options={portfolio_list} />
          </div>
          <Button fluid
            size={'large'}
            content='NEXT'
            color={'blue'}
            onClick={(e)=>this.goNext(e)}
          />
          <Menu secondary>
            <Menu.Item as='a'
                       style={{marginLeft:'auto',marginRight:'auto'}}
                       onClick={() => this.props.selectStep('shift_selection')}>
              <Icon name='arrow left' link/>
              Back
            </Menu.Item>
          </Menu>
        </Container>
      </Segment>
    )
  }
}

export default ProfileSelection