import React,{Component} from 'react';
import {
  Segment,
  Header,
  Radio,
  Button,
  Form,
  List,
  Dropdown,
  Container,
  Loader
} from 'semantic-ui-react';
import {classnames} from "../../Events/CreateEvent/classnames";
import {connect} from "react-redux";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import ServerErrors from '../../Common/ServerErrors';
import {userSettings} from '../../../actions/settingsAction';
import { addWorkCity, removeWorkCity, primaryWorkCity } from "../../../actions/talent";


const stateToProps=(state)=>{
  return{
    data:state.settings.data,
    errorMessage:state.settings.errorMessage,
    router: state.router,
    talent:state.talent.profile,
    waitingRequest: state.common.waitingRequest,
    user:state.user.profile,
    settings:state.settings.data
  }
}

const dispatchToProps=(dispatch)=>{
  return{
    userSettings:(data)=> dispatch(userSettings(data)),
    addWorkCity: (data) => dispatch(addWorkCity(data)),
    removeWorkCity: (cityId) => dispatch(removeWorkCity(cityId)),
    primaryWorkCity: (cityId) => dispatch(primaryWorkCity(cityId))
  }
}

class AvailabilitySettings extends Component{

  constructor(props){
    super(props);
    this.state = { city: '' };
  }

  onChangeCity = (city) => { this.setState({city}) };

  onSelectCity = (city) => {
      this.setState({city: ''});
      geocodeByAddress(city)
          .then(results => {
              const workCity = {};
              const addresses = results[0]['address_components'];
              workCity.name = addresses.length === 2 ? city.split(',')[0] : addresses[0]['long_name'];
              for(let address of addresses){
                  if (address.types.includes('country')){
                      workCity.country = address['long_name'];
                  }

                  if (address.types.includes('administrative_area_level_1')){
                      workCity.state = address['long_name'];
                  }
              }

              getLatLng(results[0]).then((results) => {
                  workCity.lat = results.lat;
                  workCity.lng = results.lng;
                  this.props.addWorkCity(workCity);
              })
          })
  };

  removeCity = (cityId) => {
      this.props.removeWorkCity(cityId);
  }

  primaryCity = (cityId) => {
      this.props.primaryWorkCity(cityId);
  }

  submitForm = (event) => {
    this.props.gotoNext();
  }

  renderServerErrors = () => {
        if (this.props.error){
            return <ServerErrors errorMessage={this.props.error} />
        }
    }

  render(){
    let listWorkcities=[];

    if (this.props.talent.work_cities){
      listWorkcities=this.props.talent.work_cities.map((item,index)=>{
        return(
          <List.Item key={index}>
            <List.Content style={{width:'100%'}}>
              <List.Description>
                {item.name}, {item.state}
                <Dropdown icon='setting' style={{float:'right'}} direction='left'>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={this.removeCity.bind(this, item.id)}>Remove</Dropdown.Item>
                    <Dropdown.Item onClick={this.primaryCity.bind(this, item.id)}>Primary</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </List.Description>
              <List.Description>
                {item.email}
              </List.Description>
            </List.Content>
          </List.Item>
        )
      });
    }

    // let travelList=[];
    // if (this.props.data.travelData){
    //   travelList=this.props.data.travelData.map((item,index)=>{
    //     return (
    //       <List.Item key={index}>
    //         <List.Icon name='plane' style={{paddingTop:'6px'}}/>
    //         <List.Content style={{width:'100%'}}>
    //           <List.Description as='a'>
    //             <div>
    //               <Header as={'h3'} style={{display:'inline'}}>
    //                 {item.target}
    //               </Header>
    //               <Button basic floated={'right'} size={'mini'}>...</Button>
    //             </div>
    //             <div style={{marginTop:'5px'}}>
    //             <span>
    //               {item.destination}
    //             </span>
    //             </div>
    //             <hr/>
    //             <EditTravel data={item}/>
    //           </List.Description>
    //         </List.Content>
    //       </List.Item>
    //     )
    //   });
    // }
  const searchOptions = {
      types: ['(cities)'],
      componentRestrictions: {country: "us"}
  };
  const styles = {
      root: { 'zIndex': '1', 'marginBottom': '20px', textAlign: 'left' }
  }

    return(
      <div>
        <Segment.Group>
          <Segment secondary textAlign={'left'}>
            <span className={'panel-header'}>
              Work Cities
            </span>
          </Segment>
          <Segment attached raised style={{paddingTop:'40px',paddingBottom:'40px'}}>
          <Container style={{marginTop: '32px'}}>
          <PlacesAutocomplete
              value={this.state.city}
              onChange={this.onChangeCity}
              onSelect={this.onSelectCity}
              searchOptions={searchOptions}
              styles={styles}>
            {({ getInputProps, suggestions, getSuggestionItemProps }) => {
              return (
                <div className="Demo__search-bar-container" style={{'width':'100%', 'paddingBottom': '20px', 'textAlign':'left'}}>

                    <Form.Input
                        fluid
                        icon='location arrow'
                        iconPosition='left'
                        name='address'
                        value={this.state.city}
                        fieldtype='text'
                        onChange={this.onChangeCity}
                        style={{marginBottom:'25px'}}
                        required={true}
                        style={{ 'height': '40px', 'width': '100%'}}
                        {...getInputProps({
                          placeholder: 'Add your address',
                          className: 'Demo__search-input',
                        })}
                    />



                  {suggestions.length > 0 && (
                    <Container style={{ marginTop: '9px', marginLeft: '6px'}}>

                      {suggestions.map(suggestion => {
                        const className = classnames('Demo__suggestion-item', {
                          'Demo__suggestion-item--active': suggestion.active,
                        });

                        return (
                          /* eslint-disable react/jsx-key */
                          <List divided relaxed>
                            <List.Item {...getSuggestionItemProps(suggestion, { className })} as='a'>
                              <List.Icon name='building outline' size='large' verticalAlign='middle' />
                              <List.Content>
                                <List.Header>{suggestion.formattedSuggestion.mainText}</List.Header>
                                <List.Description>
                                    {suggestion.formattedSuggestion.secondaryText}
                                </List.Description>
                              </List.Content>
                            </List.Item>
                          </List>

                        );
                        /* eslint-enable react/jsx-key */
                      })}
                    </Container>
                  )}
                </div>
              );
            }}
          </PlacesAutocomplete>


            {
                this.props.waitingRequest ?
                <Loader style={{marginTop: '10px'}} active inline='centered' /> :
                null
            }
            {this.renderServerErrors()}

            <List as='ol'>
                {this.props.talent.work_cities.map((city, index) => {
                    return (
                        <List.Item key={index} style={{marginTop: '10px'}} as='li'>
                            {city.name}, {city.state}
                            <Button type={'button'}  onClick={this.removeCity.bind(this, city.id)} negative size='mini' style={{float: 'right', padding: '10px'}}>Remove</Button>
                        </List.Item>
                    );
                })}
            </List>

          </Container>
        </Segment>
        </Segment.Group>
      </div>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(AvailabilitySettings)
