import {Button, Header, List, Icon, Segment, Form} from "semantic-ui-react";
import ApplyModal from "./ApplyModal";
import React from "react";
import {connect} from 'react-redux';
import moment from "moment";
import _ from "lodash";
import {SITE_URL, STRIPE_CLIENT_ID} from "../../../../constants/constants";
import {SET_ACTIVE_JOB} from "../../../../constants/actionTypes";
import {updateApplicant} from '../../../../actions/event';

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveJob: (job) => dispatch({type: SET_ACTIVE_JOB, payload: {job: job}})
    }
};

class Apply extends React.Component{
  constructor(props){
        super(props);
        let selectedShifts = [];
        let selectedDays = this.props.job['event_days'].filter((item)=>{
          if(this.isEqualShif(item,this.props.applyed).length!==0){
            let shifts=this.isEqualShif(item,this.props.applyed)
            shifts.map((itm)=>{
              let newSelectedShifts = selectedShifts;
              let shift = itm
              const dayIndex = newSelectedShifts.map(day => day.id).indexOf(item.id);
              if (dayIndex === -1){
                  const shiftDay = Object.assign({}, item);
                  shiftDay.shifts = [shift];
                  newSelectedShifts.push(shiftDay);
              }
              else{
                  const shiftDay = newSelectedShifts.find(shiftDay => shiftDay.id === item.id);
                  const shiftIndex = shiftDay.shifts.map(selectedShift => selectedShift.id).indexOf(shift.id);

                  if(shiftIndex === -1){
                      shiftDay.shifts.push(shift);
                  }else{
                      shiftDay.shifts.splice(shiftIndex, 1);
                  }
              }
              selectedShifts = newSelectedShifts.filter( day => item.shifts.length !== 0 );
            });
          }
          return this.isEqualDay(item,this.props.applyed)
        });
        
        selectedShifts = selectedShifts.sort((shift1, shift2) => moment(shift1['date']) - moment(shift2['date']));
        let selectedPortfolio = null;
        if (this.props.applyed.length > 0) {
          selectedPortfolio = this.props.applyed[0].portfolio
        }
        this.state = {
            openModal: false,
            selectedShifts:selectedShifts,
            selectedDays:selectedDays,
            selectedPortfolio: selectedPortfolio,
            type:'pending_reopened'
        }
    }

  goToTimeSheet = (jobId) => {
        this.props.history.push(`/job/${jobId}/timesheet`);
    };

  toggleApplyModal = () => {
        this.setState({openModal: !this.state.openModal});
    };

  isEqualDay = (day,days) => {
    for (var i=0; i<days.length; i++){
      if(day.id===days[i].event_shift.event_day.id)
        return true
    }
  }

  isEqualShif = (day,days) => {
    let shifts = []
    for (var i=0; i<days.length; i++){
      if(day.id===days[i].event_shift.event_day.id){
        for(var j=0;j<day.shifts.length;j++){
          if(day.shifts[j].id===days[i].event_shift.id){
            shifts.push(day.shifts[j])
          }
        }
      }
    }
    return shifts
  }

  handleChange = (e,{name,value,fieldtype}) => {
    this.setState({type:value});
    this.props.updateApplicantstatus(value)
    window.location.reload();
  }

  renderShift = (shift, index) => {
      return (
          <div key={index}>
            <Icon name={'time'} style={{'color': '#774b86'}}/> &nbsp;
            <span><strong>{moment.utc(shift['start_time'], 'HH:mm:ss').local().format("hh:mm A")} - {moment.utc(shift['end_time'], 'HH:mm:ss').local().format("hh:mm A")}</strong></span>
          </div>
      )
  };
//
  renderDays = (day, index) => {
    const selectedDay = this.state.selectedDays.find(selectedDay => selectedDay.id === day.id);
    return (
      <Segment.Group key={index}>
        <Segment textAlign={'left'}>
          <Icon name={'calendar'} style={{'color': '#774b86', 'marginRight': '10px'}}/>
          {moment.utc(day.date).format("MMM D, YYYY")}
        </Segment>
        <Segment secondary textAlign={'left'}>
          <List divided verticalAlign='middle'>
            <List.Item key={'1'}>
              <List.Content>
                  {day.shifts.map((shift, index) => this.renderShift(shift, index))}
                <div>
                  <Icon name={''}/> &nbsp;
                  <span>{day.shifts.length}/{selectedDay.shifts.length} Shifts Selected</span>
                </div>
                <hr/>
                <div>
                  <Icon name={'marker'} style={{'color': '#774b86'}}/> &nbsp;
                  {day.location}
                </div>
                <hr/>
                {this.state.selectedPortfolio && (
                  <div>
                    <Icon name={'user'} style={{'color': '#774b86'}}/> &nbsp;
                    <span><strong>{this.state.selectedPortfolio.title}</strong></span>
                  </div>
                )}
              </List.Content>
            </List.Item>
          </List>
        </Segment>
      </Segment.Group>
    )
  };

  applyToJob = () => {
      const {user} = this.props;
      const userStripeConnected = user.is_stripe_connected && !_.isEmpty(user['stripe_account']);
      if (userStripeConnected){
          this.setState({openModal: true});
      }else{
          this.props.setActiveJob(this.props.job);
          window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${SITE_URL}/settings/payments`;
      }
  };
  udpateJob() {
    this.setState({openModal: true});
  }
    render(){

        const {job} = this.props;
        const status = job['talent_apply_status'];
        let is_button_display = false;
        let {type} = this.state

        this.props.applyed.map((item)=>{
          if(item.status === "pending_reopened" || item.status === "unavailable" || item.status === "interested"){
            is_button_display = true;
            type=item.status;
          }
        });

        return (
          <div>
            <Segment>

                {/* apply*/}

                {/*pending*/}
                {status && status.toLowerCase() === 'pending' ?
                    <div>
                        <Header as = {'h3'} style={{'textAlign': 'center'}} > Your application is pending</Header>
                        <Button fluid color={'blue'} size={'large'} onClick={this.udpateJob.bind(this)}>UPDATE APPLY</Button>
                        <br/>
                        <Button fluid color={'red'} size={'large'} onClick={() => this.props.deleteJob()}>WITHDRAW APPLICATION</Button>
                    </div>
                    :  null
                }

                {/*booked*/}
                {status && status.toLowerCase() === 'booked' ?
                    <div>
                      <Header as = {'h3'} style={{'textAlign': 'center'}}>You are booked!</Header>
                      <Button fluid color={'red'} size={'large'} onClick={() => this.props.deleteJob()}>WITHDRAW APPLICATION</Button>
                      <br/>
                      <Button fluid color={'blue'} size={'large'} onClick={()=>this.goToTimeSheet(job.id)}>TIME SHEET</Button>
                    </div>
                    :  null
                }

                {/*not booked*/}
                {status && status.toLowerCase() === 'notbooked' ?
                    <Header as = {'h3'} style={{'textAlign': 'center'}}>You application is waitlisted.</Header>
                    :  null
                }

                {/*stripe not connected*/}
                {status === null ?
                    <div>
                        <Header as={'h3'}>You are qualified to work this listing!<br/>Are you interested?</Header>
                        <Button fluid color={'blue'} size={'large'} onClick={() => this.applyToJob()}>APPLY NOW</Button>
                    </div>
                    :  null
                }

                <div>
                    <Header as={'h3'} />
                    {
                      is_button_display ?
                        <Form>
                          <Form.Group inline widths="equal">
                            <Form.Radio name='type' label='Interested' value='interested' checked={type === 'interested'} onChange={this.handleChange} fieldtype='radio'/>
                            <Form.Radio name='type' label='Maybe' value='pending_reopened' checked={type === 'pending_reopened'} onChange={this.handleChange} fieldtype='radio' />
                            <Form.Radio name='type' label='Unavailable' value='unavailable' checked={type === 'unavailable'} onChange={this.handleChange} fieldtype='radio' />
                          </Form.Group>
                        </Form>
                      :
                        null
                    }
                </div>

              <ApplyModal
                  job={job}
                  selectedDays={this.state.selectedDays}
                  selectedShifts={this.state.selectedShifts}
                  selectedPortfolio={this.state.selectedPortfolio}
                  portfolios={this.props.portfolios}
                  modalOpen={this.state.openModal}
                  toggleApplyModal={this.toggleApplyModal}
                  applicants={this.props.applyed}
              />

              {/*<hr/>*/}
              {/*<span><strong>Stacy W. from Techweek Inc rated you!</strong></span>*/}
              {/*<br/>*/}
              {/*<br/>*/}
              {/*<span>Submit a rating of this job listing to view your rating.</span>*/}
              {/*<br/>*/}
              {/*<Rating icon='star' maxRating={5} style={{marginBottom:'17px'}}/>*/}
              {/*<Button fluid color={'teal'} size={'large'}>SUBMIT</Button>*/}

            </Segment>
            {
              status !== null ?
                <Segment loading={this.props.waitGetApplyedApplicant}>
                  {this.state.selectedShifts.map((day, index) => this.renderDays(day, index))}
                </Segment>
              :null
            }
          </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(Apply)
