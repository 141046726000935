import React,{Component} from 'react';
import {connect} from "react-redux";
import {
  Container,
  Grid} from 'semantic-ui-react';

import '../company-profile.css';

import {getProfileCompanyUser} from "../../../../actions/profileCompanyAction";
import CompanyUserInfo from './CompanyUserInfo';
import ProfileAbout from './ProfileAbout';
import BookingHistory from '../BookingHistory';
import EditCompanyUserInfo from './EditCompanyUserInfo';
import  {updateCompanyProfileUser} from "../../../../actions/talent";
import {getReviewProfileData} from '../../../../actions/getprofile';



const stateToProps=(state)=>{
  return{
    waitingRequest:state.signup.waitingRequest,
    data:state.profileCompanyUser.data,
    profileCompanyUserData:state.profileCompanyUser.profileCompanyUserData,
    errorMessage:state.profileCompanyUser.errorMessage,
    review:state.review,
    user:state.user.profile,
    company:state.company.profile,
    state:state,
  }
}

const dispatchToProps=(dispatch)=>{
  return{

    getProfileCompanyUser:(id)=> dispatch(getProfileCompanyUser(id)),
    updateCompanyProfileUser:(data) => dispatch(updateCompanyProfileUser(data)),
    getProfileReviewData:(id)=> dispatch(getReviewProfileData(id)),
  }
}

class CompanyUserProfile extends Component{
  constructor(props){
    super(props);

    this.state={
      edit:false,
      isLoggedUser:true
    }
  }

  componentDidMount(){
    let id=this.props.match.params.id;
    this.props.getProfileCompanyUser(id);
    this.props.getProfileReviewData(id);
  }


  componentWillReceiveProps(nextProps){
    if(this.props.profileCompanyUserData.id){
      if(this.props.user.company && this.props.user.company.id === this.props.profileCompanyUserData.id){
        this.setState({isLoggedUser:true});
      }else{
        this.setState({isLoggedUser:false});
      }

      if (nextProps.match && this.props.user.company && this.props.user.company.id === this.props.profileCompanyUserData.id){
        if (nextProps.match.params.mode==='edit'){
          this.setState({edit:true});
        }
        else{
          this.setState({edit:false});
        }
      }
    }
  }

  render(){
    if(this.props.profileCompanyUserData.message === undefined){
      return(
        <Container textAlign='center' className={'main-container'}>
          <Grid stackable padded>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={6} computer={5}>
                <CompanyUserInfo user={this.state.user} company={this.props.company} data={this.props.data} profileCompanyUserData={this.props.profileCompanyUserData} isEdit={this.state.edit} isLoggedUser={this.state.isLoggedUser} updateTalentProfileUser={this.props.updateCompanyProfileUser}/>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={10} computer={11}>
                <BookingHistory bookingInfo={this.props.review.data} review={this.props.review}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      )
    }else{
      return(
        <Container textAlign='center' className={'main-container'}>
          {this.props.profileCompanyUserData.message}
        </Container>
      )
    }
  }
}

export default connect(stateToProps,dispatchToProps)(CompanyUserProfile);
