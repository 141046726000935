import React, {useCallback} from 'react';
import Dropzone from 'react-dropzone';
import {connect} from 'react-redux';
import { Progress , Grid, Image, Segment, Header, Label} from 'semantic-ui-react'
import {uploadEventDocuments} from "../../../../actions/jobDetailsTalentAction";
import {formatBytes} from '../../../../utils/common';

const stateToProps=(state)=>{
  return{
    list: state.event.documents.list,
    uploadInPorgress: state.event.documents.uploadInPorgress,
    uploadProgressInPercentage: state.event.documents.uploadProgressInPercentage,
    error: state.event.documents.error,
    uploadedDocuments: state.event.documents.list
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    uploadEventDocuments:(data)=> dispatch(uploadEventDocuments(data)),
  }
};

class Documents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedFiles: []
        }
    }

    handleFiles = (files) => {
      this.setState({selectedFiles: files});
      const data = new FormData();
      data.append('event', this.props.event.id);
      files.forEach(file => {
        data.append('files', file);
      });
      this.props.uploadEventDocuments(data);
    }

    renderUploadProgress = () => {
      const {uploadProgressInPercentage, uploadInPorgress} = this.props;
      if (uploadInPorgress && uploadProgressInPercentage < 100){
        return (
          <div className="ui progress blue" data-percent="{uploadProgressInPercentage}">
            <div className="bar" style={{width :`${uploadProgressInPercentage}%`}}>
              <div className="progress">{uploadProgressInPercentage}%</div>
            </div>
          </div>
        )
      }
    }

    renderSelectedFiles = () => {
      const {selectedFiles} = this.state;
      const {uploadProgressInPercentage, uploadInPorgress} = this.props;
      if (uploadInPorgress && uploadProgressInPercentage < 100){
        return (
          <div>
            {selectedFiles.map(file => {
              return <p>{file.name} {formatBytes(file.size)}</p>
            })}
          </div>
        )
      }
    }

    renderFile = (file) => {
      const fileExtension = file.split('.').pop();
      if (['jpg', 'jpeg', 'png'].includes(fileExtension)){
        return (
          <a href={file} target="_new">
            <Image src={file} />
          </a>
        )
      }
      else{
        return (
              <a href={file} target="_new">
                <Image src={'../../../../../../assets/images/file-placeholder.png'} />
                {file.replace(/^.*[\\\/]/, '')}
              </a>
            )
      }
    }

  render(){
        const {uploadedDocuments} = this.props;
        return (
            <div className='ui segments' style={{'margin-top':'20px'}}>
              <Segment secondary textAlign={'left'}>
                  <Grid columns={1}>
                    <Grid.Column width={12}>
                      <Header size='small' color='grey' style={{display: 'inline'}}>Documents</Header>
                    </Grid.Column>
                  </Grid>
                </Segment>
              <Dropzone onDrop={acceptedFiles => this.handleFiles(acceptedFiles)}>
              {({getRootProps, getInputProps}) => (
                <section style={{'background': '#774b86', 'color': 'white'}}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p style={{'padding': '50px', 'border': '1px solid', 'margin-top': '15px'}}>Drag 'n' drop some files here, or click to select files</p>

                    {this.renderSelectedFiles()}

                  </div>
                </section>
              )}
            </Dropzone>

            {this.renderUploadProgress()}

            <Grid style={{'margin': '30px auto'}} relaxed columns={4}>
                {uploadedDocuments && uploadedDocuments.map(document => {
                  return (
                    <Grid.Column>
                      {this.renderFile(document.file)}
                    </Grid.Column>
                  )
                })}
            </Grid>
            </div>
          );
  }
}


export default connect(stateToProps, dispatchToProps)(Documents);
