import React,{Component} from 'react';
import {
  Container,
  Header,
  Segment,
  Item} from 'semantic-ui-react';
import Dotdotdot from 'react-clamp';
import { store } from "../../../store";


class ProfileAbout extends Component{
  constructor(props){
    super(props);

    this.state={
      aboutClampSize:3,
    }
  }

  showAboutMore(e,show){
    e.preventDefault();

    if (show){
      this.setState({aboutClampSize:100})
    }
    else{
      this.setState({aboutClampSize:3})
    }
  }

  render(){
    return (
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <Header size='small' color='grey' style={{display: 'inline'}}>
            ABOUT {this.props.company.name}
          </Header>
        </Segment>
        <Segment textAlign={'left'}>
          <Item>
            <Dotdotdot clamp={this.state.aboutClampSize} className="three-line-text-clamp">
              <p>
                {this.props.company.about===""?<span style={{color: '#777'}}>{this.props.isLoggedUser ? "Tell us about you" : "No data" }</span>: this.props.company.about}
              </p>
            </Dotdotdot>
          </Item>
          <hr className={'about-divider'} style={{marginTop:'18px',marginBottom:'13px',}}/>
          {this.state.aboutClampSize>3?
            <Container textAlign={'center'}>
              <a href={''} onClick={(e)=>this.showAboutMore(e,false)}>
                LESS
              </a>
            </Container>
            :
            <Container textAlign={'center'}>
              <a href={''} onClick={(e)=>this.showAboutMore(e,true)}>
                READ MORE
              </a>
            </Container>
          }
        </Segment>
      </Segment.Group>
    )
  }
}

export default ProfileAbout
