import React,{Component} from 'react';
import {connect} from "react-redux";
import {
  Container,
  Grid} from 'semantic-ui-react';

import './company-profile.css';
import CompanyInfo from './CompanyInfo';
import ProfileAbout from './ProfileAbout';
import BookingHistory from './BookingHistory';
import EditCompanyInfo from './EditCompanyInfo';
import EditCompanyAbout from './EditCompanyAbout';
import {getReviewProfileData} from '../../../actions/getprofile';
import {updateCompanyProfile, updateCompanyProfileUser} from "../../../actions/company";
import {getCompanyProfile} from "../../../actions/publicProfile";

const stateToProps=(state)=>{
  return{
    waitingRequest:state.signup.waitingRequest,
    data:state.profileCompany.data,
    errorMessage:state.profileCompany.errorMessage,
    user:state.user.profile,
    review:state.review,
    company:state.company.profile,
    state:state,
  }
}

const dispatchToProps=(dispatch)=>{
  return{
    getProfileData:(id)=> dispatch(getCompanyProfile(id)),
    getProfileReviewData:(id)=> dispatch(getReviewProfileData(id)),
    updateCompanyProfile: (data) => dispatch(updateCompanyProfile(data)),
    updateCompanyProfileUser: (data) => dispatch(updateCompanyProfileUser(data))
  }
}

class CompanyProfile extends Component{
  constructor(props){
    super(props);
    this.state={
      user:[],
      edit:false,
      isLoggedUser:true
    }
  }

  componentDidMount(){
    this.props.getProfileData(this.props.match.params.id);
    if(this.props.company.user && this.props.company.message === undefined){
      this.props.getProfileReviewData(this.props.company.user.id);
      this.setState({user:this.props.company.user});

      if(this.props.user.id === this.props.company.user.id){
        this.setState({isLoggedUser:true});
      }else{
        this.setState({isLoggedUser:false});
      }

      if(this.props.user.id === this.props.company.user.id && this.props.match.params.mode==='edit'){
        this.setState({edit:true});
      }else{
        this.setState({edit:false});
      }
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.company.user && nextProps.company.message === undefined){
      if(nextProps.user.id === nextProps.company.user.id){
        this.setState({isLoggedUser:true});
      }else{
        this.setState({isLoggedUser:false});
      }

      if (nextProps.match){
        if (nextProps.user.id === nextProps.company.user.id && nextProps.match.params.mode==='edit'){
          this.setState({edit:true});
        }
        else{
          this.setState({edit:false});
        }
      }
    }
  }

  render(){
    if(this.props.company.user && this.props.company.message === undefined){
      return(
        <Container textAlign='center' className={'main-container'}>
          <Grid stackable padded>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={6} computer={5}>
                {
                  this.state.edit ?
                    <EditCompanyInfo user={this.state.user} data={this.props.data} updateCompanyProfileUser={this.props.updateCompanyProfileUser} updateCompanyProfile={this.props.updateCompanyProfile} />
                    :
                    <CompanyInfo data={this.props.data} company={this.props.company} isEdit={this.state.edit} isLoggedUser={this.state.isLoggedUser} updateCompanyProfile={this.props.updateCompanyProfile} />
                }
              </Grid.Column>
              <Grid.Column mobile={16} tablet={10} computer={11}>
                {
                  this.state.edit?
                    <EditCompanyAbout user={this.state.user} data={this.props.data} updateCompanyProfile={this.props.updateCompanyProfile} />
                    :
                    <ProfileAbout data={this.props.data} company={this.props.company} isLoggedUser={this.state.isLoggedUser}/>
                }
                <BookingHistory bookingInfo={this.props.review.data} review={this.props.review}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      )
    }else{
      return(
        <Container textAlign='center' className={'main-container'}>
          {this.props.company.message}
        </Container>
      )
    }
  }
}

export default connect(stateToProps,dispatchToProps)(CompanyProfile);
