import React,{Component} from 'react';
import {
  Segment,
  Dropdown,
  Table,
  Button,
  Form,
  Header,
  Icon,
  Label} from 'semantic-ui-react';
import {common} from "../../../utils/common";
import moment from 'moment';
import ServerErrors from '../../Common/ServerErrors';
import ServerSuccess from '../../Common/ServerSuccess';
import MonthPicker from 'react-simple-month-picker';


class JobExperience extends Component{

  jobRole=[
        {text: "Select Role", value: "Select Role" },
        { text: "Brand Ambassador", value: "brand ambassador"},
        { text: "Booth Host / Hostess", value: "booth host / hostess"},
        { text: "Promotion Model", value: "promotion model"},
        { text: "Lead Generator", value: "lead generator"},
        { text: "Crowd Gatherer", value: "crowd gatherer"},
        { text: "Product Demonstrator", value: "product demonstrator"},
        { text: "Sales Representative", value: "sales representative"},
        { text: "Emcee/Presenter/Moderator", value: "emcee/presenter/moderator"},
        { text: "Team Leader", value: "team leader"},
        { text: "Event Manager", value: "event manager"},
        { text: "Interpreter/Translator", value: "interpreter/translator"},
        { text: "Actor/Actress", value: "actor/actress"},
        { text: "Caricaturist", value: "caricaturist"},
        { text: "Magician", value: "magician"},
        { text: "Make-Up Artist", value: "make-up artist"},
        { text: "Photographer", value: "photographer"},
        { text: "Engager", value: "engager"},
        { text: "Driver", value: "driver"},
        { text: "Talent Scout", value: "talent scout"},
        { text: "Hospitality Staff", value: "hospitality staff"}
        ];

  commonObj=new common();

  constructor(props){
    super(props);

    this.state={
        errors:{},
        jobrole : 'brand ambassador',
        eventname : '',
        clientbrand : '',
        eventdate : '',
        jobexperience:this.props.talent.jobexperience,
        updaterow:'Select Role',
        startDate: moment(),
        success:true,
        msg:"",
        show:0
    }

    this.convert=this.convert.bind(this);
    this.deleteJob=this.deleteJob.bind(this);
    this.updateRole=this.updateRole.bind(this);
  }

  addRole(e){
    e.preventDefault()
    let data = new FormData();
    data.append('jobrole', 'brand ambassador');
    data.append('eventname', '');
    data.append('clientbrand', '');
    this.props.addjob(data);
  }

  convert(str) {
      var date = new Date(str),
          mnth = ("0" + (date.getMonth()+1)).slice(-2),
          day  = ("0" + date.getDate()).slice(-2);
      return [ date.getFullYear(), mnth, day ].join("-");
  }

  handleUpdateDateChange(date,id, row) {
    let olddata = this.props.talent.jobexperience;
    date = this.convert(date)
    olddata[row]['eventdate'] = date
    this.setState({jobexperience:olddata,updaterow:row,show:0});
    let data = new FormData();
    data.append('eventdate', date);
    data.append('jobrole', this.state.jobexperience[row] !== undefined ? this.state.jobexperience[row].jobrole : 'brand ambassador');
    data.append('eventname', this.state.jobexperience[row] !== undefined ? this.state.jobexperience[row].eventname : '');
    data.append('clientbrand', this.state.jobexperience[row] !== undefined ? this.state.jobexperience[row].clientbrand : '');
    data.append('id', id);
    this.props.updatejob(data);
  }

  handleUpdateChange = (e,row,value,fieldtype) => {
    let olddata = this.props.talent.jobexperience;
    olddata[row][fieldtype] = value
    this.setState({jobexperience:olddata,updaterow:row});
  }

  updateRole(e){
    e.preventDefault();
    let row = this.state.updaterow
    let data = new FormData();
    this.setState({msg:''});
    try{
      data.append('jobrole', this.state.jobexperience[row].jobrole);
      data.append('eventname', this.state.jobexperience[row].eventname);
      data.append('clientbrand', this.state.jobexperience[row].clientbrand);
      data.append('id', this.state.jobexperience[row].id);
      this.props.updatejob(data);
      this.setState({msg:"Job Experience successfully saved.",success:true});
    }catch(err) {
      this.setState({msg:"Job Experience not saved.",success:false});
    }
  }

  showCalander(e,id){
    this.state.show===id ? this.setState({show:0}) : this.setState({show:id});
  }

  deleteJob(id,row){
    this.props.deleteJob(id)
    var array = this.state.jobexperience;
    array.pop(row.target.value)
    this.setState({jobexperience: array});
  }

  render(){
    let list=[];
    if(this.props.talent.jobexperience){
      list=this.props.talent.jobexperience.map((item,index)=>{
        if(this.props.isLoggedUser){
          return (
              <Table.Row key={item.id}>
                <Table.Cell className="selecttag">
                    <Dropdown placeholder='Sort' className="selecttag" selection options={this.jobRole} defaultValue={item.jobrole} name='jobrole' onChange={(e,{value,name})=>this.handleUpdateChange(e,index,value,name)} />
                </Table.Cell>
                <Table.Cell className="comlumninput">
                  <form  onSubmit={(e)=>this.updateRole(e)} >
                    <Form.Input
                        fluid
                        placeholder='Event Name'
                        name='eventname'
                        fieldtype='text'
                        value={item.eventname}
                        onChange={(e,{value,name})=>this.handleUpdateChange(e,index,value,name)}
                        style={{minWidth:'100px'}}
                        />
                    </form>
                </Table.Cell>
                <Table.Cell className="comlumninput">
                <form  onSubmit={(e)=>this.updateRole(e)} >
                    <Form.Input
                        fluid
                        placeholder='Client/Brand'
                        name='clientbrand'
                        value={item.clientbrand}
                        fieldtype='text'
                        onChange={(e,{value,name})=>this.handleUpdateChange(e,index,value,name)}
                        style={{minWidth:'100px'}}
                        />
                    </form>
                </Table.Cell>
                <Table.Cell className="comlumninputeventdate">
                <div className="datediv">
                  <div style={{float:'left'}}>
                    <form>
                      <Form.Input
                          fluid
                          icon='calendar times'
                          onClick={(e)=>this.showCalander(e,item.id)}
                          value={item.eventdate.substring(0,7)}
                          style={{minWidth:'110px'}}
                          />
                      </form>
                  </div>
                  <div>
                    { this.state.show===item.id ? <div className="monthpicker"><MonthPicker style={{position:'absolute'}} onChange={(date)=>this.handleUpdateDateChange(date,item.id,index)} /></div> : null }
                  </div>
                </div>
                </Table.Cell>
                <Table.Cell className="comlumndelete">
                    <Button basic icon onClick={(index)=>this.deleteJob(item.id,index)} value={index} style={{minWidth:'30px'}}>
                        X
                    </Button>
                </Table.Cell>
              </Table.Row>
            )
          }else{
            return (
              <Table.Row key={item.id}>
                <Table.Cell className="selecttag">
                    {item.jobrole}
                </Table.Cell>
                <Table.Cell className="comlumninput">
                  {item.eventname}
                </Table.Cell>
                <Table.Cell className="comlumninput">
                  {item.clientbrand}
                </Table.Cell>
                <Table.Cell className="comlumninputeventdate">
                  {item.eventdate.substring(0,7)}
                </Table.Cell>
              </Table.Row>
            )
          }
        }
      );
    }

    list = list.reverse();
    return (
      <Segment.Group>
      <Segment secondary textAlign={'left'}>
        <Icon name={'fly'} size={'large'} style={{marginRight: '10px'}}/>
        <Header size='small' color='grey' style={{display: 'inline'}}>EXPERIENCE</Header>
        <Label circular>{this.props.talent.jobexperience.length}</Label>
        {this.props.isLoggedUser ? <Button color='blue' size='small' floated={'right'} onClick={(e)=>this.addRole(e)}>Add Role</Button> : null}
      </Segment>
        {
          this.state.msg!==""?
            this.state.success?
            <ServerSuccess successMessage={this.state.msg}/>
            :
            <ServerErrors errorMessage={this.state.msg}/>
          :null
        }
        <Segment >
              <Table celled compact padded>
                <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell>Job Role</Table.HeaderCell>
                    <Table.HeaderCell>Event Name</Table.HeaderCell>
                    <Table.HeaderCell>Client/Brand</Table.HeaderCell>
                    <Table.HeaderCell>Event Date</Table.HeaderCell>
                    {this.props.isLoggedUser ? <Table.HeaderCell /> : null}
                </Table.Row>
                </Table.Header>
                <Table.Body>
                  {list}
                </Table.Body>
            </Table>
      </Segment>
      </Segment.Group>
    )
  }
}

export default JobExperience
