import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { store } from "../store";
import { isAuthenticated } from './authenticated.route';
import {isSignupCompleted} from "../components/Common/isSignupCompleted"


const isCompany = () => {
    // this function will check if user is authenticated and user is of type company
    // returns True or False
    const user = store.getState().user.profile;
    if (isAuthenticated() && (['company', 'agency'].includes(user.type))) {
        return true;
    }

    return false;
}

const CompanyRoute = ({ component: Component, ...rest }) => (
    // this function will make sure that current matched route is allowed
    // to be visit by a company or not
    // If matched route is allowed and user isn't logged in, it will redirect user to
    // login page and pass the protected route as a prop so user can get back to same route
    // after login
    <Route {...rest} render={(props) => (
        isCompany() === true
            ? isSignupCompleted()===true ? <Component {...props} /> : <Redirect to={{ pathname: '/signup/next',referrer: { pathname: props.location.pathname }}} />
            : <Redirect to={{
                pathname: '/login',
                search: '?retUrl='+props.location.pathname,
                message: 'You are not allowed to view that page.'
            }} />
    )} />
)

export default CompanyRoute

