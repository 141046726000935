import {
  GET_TALENT_JOBS_FULFILLED,
  GET_TALENT_JOBS_FAILED,
  GET_TALENT_JOBS_FILTERED_DATA, GET_TALENT_JOB_DETAILS_FAILED, GET_TALENT_JOB_DETAILS_FULFILLED,
  GET_SHIFT_FULFILLED, GET_SHIFT_FAILED, POST_SHIFT_FULFILLED, POST_SHIFT_FAILED,
  GET_EVENT_AMOUNT_DETAILS_FULFILLED, GET_EVENT_AMOUNT_DETAILS_FAILED, GET_EVENT_DAY_DETAILS_FULFILLED,
  GET_EVENT_DAY_DETAILS_FAILED,
  WAITING_GET_APPLYED_APPLICANT,
  GET_APPLYED_APPLICANT_FULFILLED,
  GET_APPLYED_APPLICANT_FAILED,
  GET_TALENT_JOBS_WAITING,
  GET_TALENT_JOB_DETAILS_WAITING,
  VISIBLE_JOB_SUCCESS,
  VISIBLE_JOB_ERROR,
} from "../constants/actionTypes";
import * as actionType from "../constants/actionTypes";

const initialState={
  waitingRequest:false,
  waitingAddApplicant:false,
  waitGetApplyedApplicant:false,
  applyed:[],
  data:[],
  filteredData:[],
  singleData:{},
  shiftData:{},
  shiftPostData:{},
  pay_amount:[],
  evnentday:[],
  waitingRequestGetJobs:false,
  waitingRequestJobDetails:false,
}

export default (state=initialState,action)=>{
  switch (action.type){
    case actionType.WAITING_REQUEST:{
      return {
        ...state,
        waitingRequest:action.waitingRequest
      };
    }
    case GET_TALENT_JOBS_WAITING:{
      return {
        ...state,
        waitingRequestGetJobs:action.value
      };
    }
    case GET_TALENT_JOBS_FULFILLED:{
      const st={
        ...state,
        data:action.responseData,
        filteredData:action.responseData
      }

      return st;
    }
    case GET_TALENT_JOBS_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }
    case GET_TALENT_JOBS_FILTERED_DATA:{
      const st={
        ...state,
        filteredData:action.filteredData
      }

      return st;
    }
    case GET_TALENT_JOB_DETAILS_WAITING:{
      return {
        ...state,
        waitingRequestJobDetails:action.value
      };
    }
    case GET_TALENT_JOB_DETAILS_FULFILLED:{
      const st={
        ...state,
        singleData:action.responseData
      }

      return st;
    }
    case GET_TALENT_JOB_DETAILS_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }
    case VISIBLE_JOB_SUCCESS:{
      const st={
        ...state,
        singleData:{
          ...state.singleData,
          hidden: action.responseData
        }
      }

      return st;
    }
    case VISIBLE_JOB_ERROR:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }
    case GET_TALENT_JOB_DETAILS_FULFILLED:{
      const st={
        ...state,
        singleData:action.responseData
      }

      return st;
    }


    case GET_SHIFT_FULFILLED:{
      const st={
        ...state,
        shiftData:action.responseData
      }

      return st;
    }
    case GET_SHIFT_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }
    case actionType.WAITING_ADD_APPLICANT:{
      return {
        ...state,
        waitingAddApplicant:action.value
      };
    }
    case POST_SHIFT_FULFILLED:{
      const st={
        ...state,
        shiftPostData:action.responseData,
        success: true
      }

      return st;
    }
    case POST_SHIFT_FAILED:{
      const st={
        ...state,
        success: false,
        errorMessage:action.errorMessage
      }

      return st;
    }

    case GET_EVENT_AMOUNT_DETAILS_FULFILLED:{
      const st={
        ...state,
        pay_amount:action.responseData
      }
      return st;
    }
    case GET_EVENT_AMOUNT_DETAILS_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }
    case GET_EVENT_DAY_DETAILS_FULFILLED:{
      const st={
        ...state,
        evnentday:action.responseData
      }
      return st;
    }
    case GET_EVENT_DAY_DETAILS_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }

    case WAITING_GET_APPLYED_APPLICANT:{
      return {
        ...state,
        waitGetApplyedApplicant:action.value
      };
    }
    case GET_APPLYED_APPLICANT_FULFILLED:{
      const st={
        ...state,
        applyed:action.responseData
      }
      return st;
    }
    case GET_APPLYED_APPLICANT_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }
    case actionType.DELETE_EVENT_EVENTAPPLICANT_FULFILLED:{
        return {
            ...state,
            success: true,
            error: null,
        };
    }

    case actionType.DELETE_EVENT_EVENTAPPLICANT_FAILED:{
      return {
          ...state,
          errorMessage:action.errorMessage
      };
    }
    case actionType.WITHDRAW_APPLICATION_FULFILLED:{
        return {
            ...state,
            success: true,
            error: null,
            applyed:[],
        };
    }

    case actionType.WITHDRAW_APPLICATION_FAILED:{
      return {
          ...state,
          errorMessage:action.errorMessage
      };
    }
    default:
      return state;
  }
}
