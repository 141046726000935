import axios from 'axios';

import {API_URL_BASE} from '../constants/constants';
import * as actionType from '../constants/actionTypes';
import {common} from '../utils/common';
import { store } from "../store";
import _ from 'lodash';

import {loginSuccess} from './loginAction'


export const registerUser = (data) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type: actionType.WAITING_REQUEST, value:true});

    axios.post(`${API_URL_BASE}/user/register`,data)
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch, actionType.SIGNUP_SUBMIT_FULFILLED);
        dispatch({type: actionType.EMAIL_SIGNUP_MESSAGE_OPEN_CLOSED, value:true});
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch, actionType.SIGNUP_SUBMIT_ERROR);
      })
  }
}

export const resendVerificationEmail = (data) => {
    const commonObj=new common();

    return (dispatch) => {
        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.post(`${API_URL_BASE}/user/re-send-email`, data)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.RESEND_EMAIL_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.RESEND_EMAIL_ERROR);
            })
    }
}

export const verifyUser =({uid,token, newEmail=null})=>{
  /*
  This action is used for both email verification when new user is registered
  and when user change existing email from settings page
  For newly registered user there will just uid and token passed
  For email changed, there will uid, token and newEmail params
  */
  const commonObj=new common();
  let verifyURL = `${API_URL_BASE}/user/verify-email/${uid}/${token}`;
  if (newEmail) {
      verifyURL = `${API_URL_BASE}/user/verify-email/${uid}/${token}/${newEmail}`;
  }

  return (dispatch)=>{
    dispatch({type: actionType.WAITING_REQUEST, value:true});

    axios.get(verifyURL)
      .then((response)=>{
        loginSuccess(response, dispatch)
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch, actionType.SIGNUP_VEFIFY_FAILED);
      })
  }
}

export const mobileVerificationStart =(data)=>{
  const commonObj=new common();

  return (dispatch)=>{
    dispatch({type: actionType.WAITING_REQUEST, value:true});

    axios.post(`${API_URL_BASE}/user/phone-verification/start`,data)
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch, actionType.PHONE_VERIFY_START_FULFILLED);
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch, actionType.PHONE_VERIFY_START_FAILED);
      })
  }
}

export const mobileVerifyCode =(data)=>{
    const commonObj=new common();

    return (dispatch)=>{
        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.post(`${API_URL_BASE}/user/phone-verification/verify`,data)
            .then((response)=>{
                dispatch({type: actionType.UPDATE_USER, responseData: {'phone': data['phone']}});
                dispatch({type: actionType.PHONE_VERIFY_CODE_FULFILLED, responseData:response.data})
                dispatch({type: actionType.WAITING_REQUEST, value:false});
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.PHONE_VERIFY_CODE_FAILED);
            })
    }
}

export function setNextScreen(dispatch){
    let nextScreen = null;
    const state = store.getState();
    const user =  state.user.profile;

    if (!user.phone){
        nextScreen = 'mobile_verify'
    }

    else if (user.type.toLowerCase() === 'talent'){

        const talent = state.talent.profile;

        if (_.isEmpty(talent) || !talent.gender || !talent.date_of_birth){
            nextScreen = 'gender_birthday'
        }
        else if (talent['work_cities'].length === 0){
            nextScreen  = 'area';
        }
        // else if (!user.photo){
        //     nextScreen = 'photo';
        // }
    }

    else if (user.type.toLowerCase() === 'company'){
        // check if each value field of company is filled out
        const company = state.company.profile;
        const isCompanyEmpty = (company.name==="" || company.state==="");
        if (isCompanyEmpty){
            nextScreen = 'company_signup';
        }
    }
    dispatch({type: actionType.SIGNUP_NEXT,value: nextScreen})
}

export const registerSocialUser = (data) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:actionType.WAITING_REQUEST, value:true});

    axios.post(`${API_URL_BASE}/user/login/social`,data)
      .then((response)=>{
        loginSuccess(response, dispatch);
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,actionType.SIGNUP_SUBMIT_ERROR);
      })
  }
}

export const sendPasswordResetLink = (email) => {
    const commonObj=new common();

    return (dispatch) => {
        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.post(`${API_URL_BASE}/user/forgot-password`, {email})
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.SEND_PASSWORD_RESET_LINK_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.SEND_PASSWORD_RESET_LINK_ERROR);
            })
    }
}

export const resetPassword = (data) => {
    const commonObj=new common();
    const {uid, token, new_password, confirm_password} = data;
    data = {
                new_password,
                confirm_password
            };

    return (dispatch) => {
        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.post(`${API_URL_BASE}/user/reset-password/${uid}/${token}`, data)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.RESET_PASSWORD_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.RESET_PASSWORD_ERROR);
            })
    }
}

export const changePassword = (data) => {
    const commonObj=new common();

    return (dispatch) => {
        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.post(`${API_URL_BASE}/user/change-password`, data)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.CHANGE_PASSWORD_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.CHANGE_PASSWORD_ERROR);
            })
    }
};
