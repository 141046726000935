import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "semantic-ui-react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import "../events.css";
import { EVENT_CREATION_STEP } from "../../../constants/actionTypes";
import * as settings from "../../../constants/constants";

const stateToProps = state => {
  return {
    eventCreationStep: state.event.eventCreationStep
  };
};

const dispatchToProps = dispatch => {
  return {
    eventFirstStep: () => dispatch({ type: EVENT_CREATION_STEP, value: 1 }),
    eventSecondStep: () => dispatch({ type: EVENT_CREATION_STEP, value: 2 })
  };
};

class CreateEvent extends Component {
  componentDidMount() {
    if (settings.ENV === "production") {
      const script = document.createElement("script");

      script.src = "http://js.hs-scripts.com/6206960.js";
      script.async = true;

      document.body.appendChild(script);
    }
  }

  eventFirstStep = e => {
    this.props.eventFirstStep();
  };

  eventSecondStep = e => {
    this.props.eventSecondStep();
  };

  render() {
    let stepElement = null;
    let { eventCreationStep } = this.props;

    if (eventCreationStep === 1) {
      stepElement = <Step1 {...this.props} />;
    } else if (eventCreationStep === 2) {
      stepElement = <Step2 />;
    }

    return (
      <Container textAlign="left" fluid>
        {stepElement}
      </Container>
    );
  }
}

export default connect(
  stateToProps,
  dispatchToProps
)(CreateEvent);
