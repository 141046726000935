import React,{Component} from 'react';
import {Card, Image, Icon, Button, Grid} from 'semantic-ui-react';


class UserCard extends Component{

  render(){

    let {user, role}=this.props;

    return(
      <Grid.Row>
        <Grid.Column width={3}>
          <Card raised className={'user-card'}>
            <Card.Content>
              <Image floated='left' size='tiny' rounded src={user.photo} />
              <Card.Header>{user.first_name}</Card.Header>
              <Card.Meta>{role}</Card.Meta>
              <Button.Group>
                <Button icon compact ><Icon name='thumbs up outline' size='large' /></Button>
                <Button icon compact ><Icon name='thumbs down outline' size='large' /></Button>
              </Button.Group>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    )

  }
}

export default UserCard;