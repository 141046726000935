import React, { Component } from 'react';
import {
  Container,
  Segment,
  Button,
  Grid,
  Loader,
  Form} from 'semantic-ui-react';

import './../Signup/signup.css';
import {connect} from "react-redux";
import ServerErrors from '../../Common/ServerErrors';
import ServerSuccess from '../../Common/ServerSuccess';
import { sendPasswordResetLink } from "../../../actions/signupAction";

const stateToProps=(state)=>{
    return{
        loading: state.common.waitingRequest,
        success: state.signup.forgotPassword.success,
        error: state.signup.forgotPassword.error,
        message: state.signup.forgotPassword.message
    }
};

const dispatchToProps=(dispatch)=>{
    return{
        sendPasswordResetLink:(data)=> dispatch(sendPasswordResetLink(data)),
    }
}


class ForgotPassword extends Component {

  constructor(props){
    super(props);
    this.state = {
      email: ''
    }
  }

  handleChange = (value) => {
    this.setState({email: value});
  };

  sendPasswordResetLink = () => {
    this.props.sendPasswordResetLink(this.state.email);
  };

  renderMessage = () => {
    if (this.props.error && this.props.message){
      return <ServerErrors errorMessage={this.props.message} />
    }

    if (this.props.success && this.props.message){
       return <ServerSuccess successMessage={this.props.message} />;
     }
    };

  render() {
    return(
      <div>
        <Container textAlign='center' className={'main-container signup-container'}>
          <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
            <Grid.Column>

                {this.renderMessage()}

              <Form size='large' onSubmit={() => this.sendPasswordResetLink()}>
                <Segment raised>
                  <Form.Input
                    fluid
                    icon='mail'
                    iconPosition='left'
                    placeholder='Email'
                    name='email'
                    value={this.state.email}
                    fieldtype='email'
                    onChange={(e, {value}) => this.handleChange(value)}
                  />

                  <Button color='blue' fluid size='large'>Send</Button>

                </Segment>
              </Form>

                {
                  this.props.loading ?
                  <Loader active inline='centered' />
                  : null
                }
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    )
  }

}

export default connect(stateToProps, dispatchToProps)(ForgotPassword);