import React,{Component} from 'react';
import {Card, Container, Icon, Modal, Button, Image, Loader} from 'semantic-ui-react';
import Time from 'react-time'
import ProfileStatistics from './ProfileStatistics';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import connect from "react-redux/es/connect/connect";
import {updateTalentProfileUser} from "../../../actions/talent";
import {RESET_TALENT} from "../../../constants/actionTypes";
import ServerErrors from "../../Common/ServerErrors";

class PersonalInfo extends Component{
  constructor(props){
    super(props);
    this.state={
      crop: {
        aspect: 1,
        width: 90,
        height: 90,
        x: 0,
        y: 0,
      },
      image: null,
      croppedImage: null,
      isModalOpen: false,
      formSubmitted: false,
      edit_show:false,
      imageFile:null,
    };
  }

  componentDidMount(){
    this.props.resetTalentReducer({
        error: null,
        success: null
    });
  }

  renderServerErrors = () => {
      const {error} = this.props.talent;
      const {formSubmitted} = this.state;
      if (error && formSubmitted){
        return <ServerErrors errorMessage={error} />
      }
  };

  renderServerSuccess = () => {
      const {success, error} = this.props.talent;
      const {waitingRequest} = this.props;
      const {formSubmitted} = this.state;

      if (!error && formSubmitted && !waitingRequest){
        this.setState({
            isModalOpen: false,
            image: null,
            croppedImage: null,
            formSubmitted: false
        });
        this.props.resetTalentReducer({
            error: null,
            success: null
        });
      }
  };

  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        imageFile: file,
        imagePreviewUrl: reader.result,
        open: true,
      });
    }

    reader.readAsDataURL(file)
  }

  cancelPhoto = () => {
    this.setState({
        isModalOpen: false,
        croppedImage: null
    });
    this.props.resetTalentReducer({
        error: null,
        success: null
    });
  };

  uploadImage = () => {
      const {croppedImage} = this.state;
      const {id, email} = this.props.user;
      let data = new FormData();
      data.append('id', id);
      data.append('email', email);
      data.append('photo', croppedImage);
      this.props.updateTalentProfileUser(data);
      this.setState({
          formSubmitted: true
      });
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({
            image: reader.result,
            isModalOpen: true
        }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;
    this.makeClientCrop(this.state.crop, pixelCrop);
  };

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  async makeClientCrop(crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, pixelCrop);
      this.setState({
          croppedImage: croppedImageUrl
      });
    }
  }

  getCroppedImg(image, pixelCrop) {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    return canvas.toDataURL();
  }

  render(){
    const { user, waitingRequest } = this.props;
    let photo="https://api.adorable.io/avatars/280/" + user.email;
    const { crop, image, isModalOpen } = this.state;

    return(
      <Card className={'full-width-card'}>

          <label style={{position:'relative', cursor: this.state.edit_show ? 'pointer' : ''}} onMouseOver={(e)=>{this.setState({edit_show:true})}} onMouseOut={(e)=>{this.setState({edit_show:false})}}>
            <Container align="center" style={{backgroundColor:'black'}}>
              <Image src={user.photo==='' || user.photo===null ? photo : user.photo} />
            </Container>
            <Icon name='camera' size='big' style={{color: 'rgba(138, 84, 156, 0.3)', position: 'absolute', top: '5%', right: '5%', display:this.state.edit_show ? 'block' : 'none'}}/>
            <input type="file" style={{display:'none'}} onChange={this.onSelectFile} onClick={(event) => { event.target.value = null; }}/>
          </label>

        <Card.Content>
          <Modal open={isModalOpen}>
            <Modal.Content>
            </Modal.Content>
            {this.renderServerErrors()}
            {this.renderServerSuccess()}
            <div>
              {image && (
                <div style={{width: "100%", height: "100%"}}>
                    <ReactCrop
                        src={image}
                        crop={crop}
                        keepSelection={true}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                      />
                </div>
              )}
            </div>
            <Modal.Actions>
              <Button color='red' onClick={() => this.cancelPhoto()}>
                <Icon name='remove' /> Cancel
              </Button>
              <Button color='green' onClick={() => this.uploadImage()}>
                {!waitingRequest && <span><Icon name='checkmark' /> Crop & Save Image</span>}
                {waitingRequest && <Loader size={'tiny'} active inline='centered' />}
              </Button>
            </Modal.Actions>
          </Modal>
          <Card.Header className={'profile_name'}>
            <span>
              {user.first_name} {user.last_name}
            </span>
          </Card.Header>
          {user['date_joined'] &&
            <Card.Meta>
              <span>
                Member since <Time value={user['date_joined']} format="MMMM YYYY" />
              </span>
            </Card.Meta>
          }
          <Card.Content>
            {/*<ProfileRank profileRank={this.props.profileRank} isLoggedUser={this.props.isLoggedUser}/>*/}
            {/*<Rating icon='star' rating={this.props.data.rating} maxRating={5} className={'rating'} disabled/>*/}
            <br />
            <ProfileStatistics talent={this.props.talent} isDisable={this.props.isDisable}/>
          </Card.Content>
        </Card.Content>
      </Card>
    )
  }
}


const dispatchToProps=(dispatch)=>{
  return{
    updateTalentProfileUser: (data) => dispatch(updateTalentProfileUser(data)),
    resetTalentReducer: (data) => dispatch({ type: RESET_TALENT, payload: data }),
  }
};

const stateToProps=(state)=>{
    return {
      talent: state.talent,
      user: state.user.profile,
      waitingRequest: state.common.waitingRequest,
    };
};

export default connect(stateToProps,dispatchToProps)(PersonalInfo)
