import React,{Component} from 'react';
import * as SendBird from 'sendbird';
import {Grid, Container, Header, Confirm, Message, Segment, Icon, Loader} from 'semantic-ui-react';
import _ from 'lodash';
import './message.css';
import './styles.css';
import {connect} from "react-redux";
import {
    updateChannelUrl, createOneToOneChat,
    getParticipant
} from "../../actions/messaging";
import Messages from './Messages';
import ChannelList from './ChannelList';
import {GiftedChat} from './GiftedChatNative/GiftedChat';
import {getUserName} from '../../utils/common';
import {NEW_MESSAGE_ARRIVED, UPDATE_CHANNEL_LIST_ON_REDUX, UPDATE_ONE_TO_ONE_CHANNEL_LIST_ON_REDUX} from "../../constants/actionTypes";
import {updateMessageNotifications} from "./common";

const stateToProps=(state)=>{
  return{
    waitingRequest: state.common.waitingRequest,
    errorMessage: state.messaging.errorMessage,
    loadSuccess: state.messaging.loadSuccess,
    updateSuccess: state.messaging.updateSuccess,
    messageGroups: state.messaging.messageGroups,
    oneToOneChats: state.messaging.oneToOneChats,
    loggedInUser: state.user.profile,
    participant: state.messaging.oneToOneChatParticipant,
    chatTextInputFocused:state.messaging.chatTextInputFocused,
    isMsgGroupsSorted:state.messaging.isMsgGroupsSorted
  }
};

const dispatchToProps=(dispatch)=>{
  return{
      updateChannelUrl: (id, data)=>dispatch(updateChannelUrl(id, data)),
      createOneToOneChat: (participant)=>dispatch(createOneToOneChat(participant)),
      getParticipant: (id)=>dispatch(getParticipant(id)),
      setMessageNotification:(count)=>dispatch({type:NEW_MESSAGE_ARRIVED,value:count}),
      updateChannelListOnRedux:(channel)=>dispatch({type:UPDATE_CHANNEL_LIST_ON_REDUX,value:channel}),
      updateOneToOneChannelListOnRedux:(channel)=>dispatch({type:UPDATE_ONE_TO_ONE_CHANNEL_LIST_ON_REDUX,value:channel})
  }
};

class MessageComponent extends Component{
  sb=null;
  channelHandler=null;

  constructor(props){
    super(props);
    this.state={
      messageInput:'',
      messageDeleteConfirmOpen:false,
      deletionMessage:null,
      messageDeleted: false,
      messageDeletedError: '',
      activeChannelMessages: [],
      activeChannelName: null,
      sendBirdActiveChannel: null,
      channelMessagesLoading: false,
      channelMessagesLoadingError: '',
      channelPreviousMessagesLoading: false,
      channelPreviousMessagesLoadingError: '',
      messageSent: false,
      messageSentError: '',
      userChannelId: '',
      loadedMessagesCount: 10,
      onlineUsers: [],
      isGroupChatActive: true,
      loadMoreMessages:true,
      typingMembers:[],
      previousMessageGroup:null
    }
  }

  componentDidMount() {
    const {participant} = this.props.match.params;
    if (participant) {
      this.props.getParticipant(participant);
    }

    if (this.props.messageGroups.length > 0 || this.props.oneToOneChats.length > 0) {
      this.initSendBird();
      setInterval(this.checkUsersOnlineStatus, 3000);
    }

    if (this.props.isMsgGroupsSorted && this.props.messageGroups.length>0){
      this.createGroupChannel(this.props.messageGroups[0]);
    }
  }

  initSendBird=()=>{
    this.sb = SendBird.getInstance();
    this.channelHandler= window.globalVars.sendGridChanelHandler; // new this.sb.ChannelHandler();
    this.channelHandler.onMessageReceived = (channel, message)=> this.onMessageReceived(channel, message);
    this.channelHandler.onMessageDeleted = (channel, messageId)=> this.messageDeleted(channel, messageId);
    this.channelHandler.onReadReceiptUpdated = (channel)=> this.onReadReceiptUpdated(channel);
    this.channelHandler.onTypingStatusUpdated = (channel)=> this.onTypingStatusUpdated(channel);
    this.sb.addChannelHandler('channel', this.channelHandler);
  };

  componentWillReceiveProps(props) {
    const participantId = props.match.params.participant;
    const {participant} = props;
    if (participantId && !_.isEmpty(participant) && this.state.isGroupChatActive) {
      this.createOneToOneChat(participant);
    }

    if ((this.props.messageGroups.length !== props.messageGroups.length || this.props.oneToOneChats.length !== props.oneToOneChats.length)) {
      if (this.sb === null) {
        this.initSendBird();
      }

      updateMessageNotifications(this.sb, this.props.loggedInUser, this.props.setMessageNotification);
    }

    if (this.props.chatTextInputFocused === false && props.chatTextInputFocused === true && this.state.sendBirdActiveChannel && this.state.sendBirdActiveChannel.url) {
      this.state.sendBirdActiveChannel.markAsRead();
    }

    if (!this.props.isMsgGroupsSorted && props.isMsgGroupsSorted && props.messageGroups.length>0){
      this.createGroupChannel(props.messageGroups[0]);
    }
  }

  registerSendBirdUser = (user) => {
    return new Promise((resolve, reject) => {
      const nickname = getUserName(user);
      const UserPhoto = user['photo'] ? user['photo'] : "https://api.adorable.io/avatars/32/" + user.email;

      this.sb.updateCurrentUserInfo(nickname, UserPhoto, (response, error) => {
        if (error) {
          reject(error)
        }

        resolve(response);
      });
    });
  };

  checkUsersOnlineStatus = () => {
    const {sendBirdActiveChannel} = this.state;
    const onlineUsers = [];
    if (sendBirdActiveChannel) {
      sendBirdActiveChannel.refresh((response, error) => {
        if (error) {
          console.error(error);
          return;
        }

        const users = sendBirdActiveChannel.members;
        _.each(users, user => {
          if (user.connectionStatus === 'online') {
            onlineUsers.push(user.userId);
          }
        });

        this.setState({onlineUsers});
      });
    }
  };

  trackScrolling = (e) => {
      if ( e.target.scrollTop === 0) {
          this.setState({channelPreviousMessagesLoading: true});
          this.loadChannelPreviousMessages();
      }
  };

  createOneToOneChat = (participant) => {
     const channelName = getUserName(participant);
     this.setState({
        channelMessagesLoading: true,
        channelPreviousMessagesLoadingError: '',
        isGroupChatActive: false,
        activeChannelName: channelName
    });
    const {loggedInUser} = this.props;
    const loggedInUserId = loggedInUser.email;
    this.setState({userChannelId: loggedInUserId});
    this.registerSendBirdUser(loggedInUser).then(response => {
        this.registerSendBirdUser(participant).then(response => {
            this.sb.connect(loggedInUserId, (user, error) => {
               this.sb.GroupChannel.createChannelWithUserIds([loggedInUserId, participant.email], true, channelName, participant.photo, '', '', (channel, error) => {
                if (error) {
                    console.error(error);
                    this.setState({channelMessagesLoading: false, channelMessagesLoadingError: error});
                    return;
                }
                this.setState({sendBirdActiveChannel: channel});
                this.loadChannelMessages(channel);
                this.props.createOneToOneChat({'channel_url': channel.url, participant: participant.id});
              });
            });
        });
    });
  };

  messageDeleted = (channel, messageId) => {
    const {activeChannelMessages} = this.state;
    const newMessages = activeChannelMessages.filter((message)=>{
      return message.messageId.toString() !== messageId.toString()
    });

    this.setState({activeChannelMessages: newMessages});
  };

  deleteMessage = (message) => {
    this.setState({messageDeleteConfirmOpen:true, deletionMessage:message});
  };

  onMessageReceived = (channel,message) => {
    const {sendBirdActiveChannel, activeChannelMessages} = this.state;

    if (sendBirdActiveChannel && sendBirdActiveChannel.url === channel.url) { // channel opened. Append message with previous messages
      let messages= GiftedChat.append(activeChannelMessages, [message]);

      if (this.props.chatTextInputFocused===true){
        this.sb.markAsReadWithChannelUrls([channel.url], ()=>{
          this.props.updateChannelListOnRedux(channel);
          this.props.updateOneToOneChannelListOnRedux(channel);
        });
      }
      else{
        updateMessageNotifications(this.sb,this.props.loggedInUser,this.props.setMessageNotification);
        this.props.updateChannelListOnRedux(channel);
        this.props.updateOneToOneChannelListOnRedux(channel);
      }

      this.setState({activeChannelMessages:messages});
    }
    else{ // channel not opened. So set message notification count to redux store
      updateMessageNotifications(this.sb,this.props.loggedInUser,this.props.setMessageNotification);
      this.props.updateChannelListOnRedux(channel);
      this.props.updateOneToOneChannelListOnRedux(channel);
    }
  };

  onReadReceiptUpdated=(channel)=>{
    let readMembers = channel.getReadMembers(channel.lastMessage);
    let activeChannelMessages=this.state.activeChannelMessages.map((message)=>{
      if (channel.lastMessage && message.messageId===channel.lastMessage.messageId){
        return {...message,readMembers:readMembers}
      }

      return message;
    });

    this.setState({activeChannelMessages});
  };

  onTypingStatusUpdated=(groupChannel)=> {
    let typingMembers = groupChannel.getTypingMembers();

    if (this.state.sendBirdActiveChannel && this.state.sendBirdActiveChannel.url===groupChannel.url){
      this.setState({typingMembers});
    }
    else{
      this.setState({typingMembers:[]});
    }
  };

  createGroupChannel = (group) => {
    if (this.state.previousMessageGroup !== null && this.state.previousMessageGroup.id === group.id){
      return;
    }

    console.log(group);

    this.setState({
      channelMessagesLoading: true,
      channelPreviousMessagesLoadingError: '',
      activeChannelName: group.event.name,
      previousMessageGroup:group
    });

    const {loggedInUser} = this.props;
    const channelName = group.event.name;
    const loggedInUserId = loggedInUser.email;
    this.setState({userChannelId: loggedInUserId});

    const participants = group.participants.map((item) => {
      return item.email;
    });

    let params = new this.sb.GroupChannelParams();
    params.isPublic = true;
    params.isEphemeral = false;
    params.isDistinct = false;
    params.addUserIds(participants);
    params.name = channelName;
    params.coverUrl = '';
    params.customType = '';

    this.sb.connect(loggedInUserId, (user, error) => {
      if (error) {
        console.error(error);
        return;
      }

      this.registerSendBirdUser(loggedInUser);

      if (group['channel_url']) {
        this.sb.GroupChannel.getChannel(group['channel_url'], (channel, error) => {
          // if channel not exist on sendbird, then create it otherwise join it
          if (!channel){
            this.sb.GroupChannel.createChannel(params, (channel, error) => {
              if (error) {
                console.error(error);
                this.setState({channelMessagesLoading: false, channelMessagesLoadingError: error});
                return;
              }

              this.setState({sendBirdActiveChannel: channel});
              this.loadChannelMessages(channel);
              this.props.updateChannelUrl(group.id, {'channel_url': channel.url});
            });
          }
          else{
            this.loadChannelMessages(channel);
            channel.join((response, error) => {
              if (error) {
                console.error(error);
                return;
              }

              this.setState({sendBirdActiveChannel: channel});
            });
          }
        });
      }
      else {
          this.sb.GroupChannel.createChannel(params, (channel, error) => {
            if (error) {
              console.error(error);
              this.setState({channelMessagesLoading: false, channelMessagesLoadingError: error});
              return;
            }

            this.setState({sendBirdActiveChannel: channel});
            this.loadChannelMessages(channel);
            this.props.updateChannelUrl(group.id, {'channel_url': channel.url});
          });
        }

    });
  };

  loadChannelMessages = (channel) => {
    const messageListQuery = channel.createPreviousMessageListQuery();
    messageListQuery.load(10, true, (messageList, error) => {
      if (error) {
        this.setState({channelMessagesLoading: false, channelMessagesLoadingError: error});
        return;
      }

      channel.markAsRead();
      this.sb.markAsReadWithChannelUrls([channel.url], ()=>{
        updateMessageNotifications(this.sb,this.props.loggedInUser,this.props.setMessageNotification);
        this.props.updateChannelListOnRedux(channel);
      });

      let activeChannelMessages=messageList.map((message)=>{
        let readMembers = channel.getReadMembers(message);

        return {...message,readMembers:readMembers};
      });

      this.setState({channelMessagesLoading: false, activeChannelMessages: activeChannelMessages});
    });
  };

  loadChannelPreviousMessages = () => {
    const {sendBirdActiveChannel} = this.state;
    let {loadedMessagesCount} = this.state;
    loadedMessagesCount += 10;

    this.setState({channelPreviousMessagesLoading: true});

    const messageListQuery = sendBirdActiveChannel.createPreviousMessageListQuery();
    messageListQuery.load(loadedMessagesCount, true, (messageList, error) => {
      if (error) {
        this.setState({channelPreviousMessagesLoading: false});
        return;
      }

      this.setState({
        channelPreviousMessagesLoading: false,
        activeChannelMessages: messageList,
        loadedMessagesCount
      });

      if(loadedMessagesCount > messageList.length){
        this.setState({channelPreviousMessagesLoading: false,loadMoreMessages:true});
      }
    });
  };

  onSend = (messages = []) => {
    const message = messages[0];
    if (message.type === 'file' && message.fileUrl){
      this.sendFileMessage(message);
    }
    else{
      this.sendTextMessage(message);
    }
  };

  sendTextMessage = (message)=> {
    const {sendBirdActiveChannel} = this.state;

    if (this.state.userChannelId === '') {
      alert('Select a channel before send message.')
      return;
    }

    this.state.sendBirdActiveChannel.endTyping();
    sendBirdActiveChannel.sendUserMessage(message.text, '', (message, error) => this.sendMessageHandler(message, error));
  };

  sendFileMessage = (message)=> {
    const {sendBirdActiveChannel} = this.state;
    if (this.state.userChannelId === '') {
      alert('Select a channel before send message.');
      return;
    }

    sendBirdActiveChannel.endTyping();
    sendBirdActiveChannel.sendFileMessage(message.fileUrl, message.name, null, null, message.text, (fileMessage, error) => {
      this.sendMessageHandler(fileMessage, error);
    });
  };

  onInputTextChanged=()=>{
    if (this.state.sendBirdActiveChannel){
      this.state.sendBirdActiveChannel.startTyping();
    }
  };

  onImageChange=(file)=>{
    const {sendBirdActiveChannel,userChannelId} = this.state;

    this.sb.connect(userChannelId, (response, error) => {
      if (error){
        return;
      }

      const data = '';
      const customType = '';
      const thumbSizeList = [{'maxWidth': 160, 'maxHeight': 160}];

      // Instead of uploading directly to sendbird, let's upload to S3 and attach the URL.
      let FILE_URL = 'https://facebook.github.io/react-native/docs/assets/favicon.png';
      let FILE_NAME = 'icon_settings.png';
      let CUSTOM_TYPE = 'image/png';
      let CUSTOM_DATA = {};

      // Sending a file message with a file URL
      sendBirdActiveChannel.sendFileMessage(FILE_URL, CUSTOM_TYPE, function(fileMessage, error){
          if (error) {
              return;
          }

          console.log(fileMessage);
      });

    });
  };

  sendMessageHandler = (message, error) => {
    if (error){
      return;
    }

    const {activeChannelMessages} = this.state;
    let messages= GiftedChat.append(activeChannelMessages, [message]);
    console.log(messages[messages.length - 1]);

    this.setState({activeChannelMessages:messages})
  };

  handleChange = (message) => {
    this.setState({messageInput: message});
  };

  handleDeleteMessageCancel = () => {
    this.setState({messageDeleteConfirmOpen:false});
  };

  handleDeleteMessageConfirm = () => {
    this.setState({messageDeleteConfirmOpen:false});
    const {sendBirdActiveChannel, deletionMessage, activeChannelMessages} = this.state;

    sendBirdActiveChannel.deleteMessage(deletionMessage, (response, error)=>{
      if (error){
        this.setState({messageDeleted: false, messageDeletedError: error});
        return;
      }

      const newMessages = activeChannelMessages.filter((message)=>{
        return message.messageId.toString() !== deletionMessage.messageId.toString()
      });

      this.setState({
          activeChannelMessages: newMessages,
          messageDeleted: true,
          messageDeletedError: '',
          deletionMessage: '',
      });
    });
  };

  renderMessagesLoading = () =>{
    return (
      <Loader active inline='centered' style={{marginTop:'20px'}}>Loading...</Loader>
    );
  };

  render(){
    const {sendBirdActiveChannel, channelMessagesLoading} = this.state;
    const { loadSuccess, errorMessage, messageGroups, oneToOneChats,
            waitingRequest } = this.props;

    if (errorMessage && !loadSuccess){
      return <Message negative content={errorMessage}/>
    }
    return(
      <Container fluid>
        <Grid stackable>
          <Grid.Row className={'message-row'}>

              {/*lef side chat groups*/}
              <ChannelList
                  {...this.props}
                  {...this.state}
                  createGroupChannel={this.createGroupChannel}
                  createOneToOneChat={this.createOneToOneChat}
              />

              {/* messages list*/}

              {(!waitingRequest && !channelMessagesLoading && messageGroups.length === 0 && oneToOneChats.length === 0) ?
                  <Grid.Column mobile={16} tablet={10} computer={12} className={'messages-column'}>
                    <Segment placeholder style={{height: 'calc(100vh - 57px)'}}>
                      <Header icon>
                        <Icon name='comment outline' />
                        No messages
                      </Header>
                    </Segment>
                  </Grid.Column>

                  :
                  <Grid.Column mobile={16} tablet={10} computer={12} className={'messages-column'}>
                      {(!sendBirdActiveChannel && !channelMessagesLoading )&&
                      <div className={'no-chat'}>
                          <Header>
                              Select a channel to start conversation
                          </Header>
                      </div>
                      }

                      <div className={'gc-message-container'}>
                          <Messages
                              {...this.state}
                              onSend={this.onSend}
                              sendFileMessage={this.sendFileMessage}
                              onInputTextChanged={this.onInputTextChanged}
                              onImageChange={this.onImageChange}
                              loadChannelPreviousMessages={this.loadChannelPreviousMessages}
                              typingMembers={this.state.typingMembers}
                              renderLoading={this.renderMessagesLoading}
                              channelMessagesLoading={this.state.channelMessagesLoading}
                          />
                      </div>
                  </Grid.Column>
              }
          </Grid.Row>
        </Grid>
        <Confirm
          header='Delete message'
          open={this.state.messageDeleteConfirmOpen}
          onCancel={()=> this.handleDeleteMessageCancel()}
          onConfirm={()=> this.handleDeleteMessageConfirm()}
        />
      </Container>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(MessageComponent);
