import React,{Component} from 'react';
import {Segment, Header, Button, Form, Loader, Image} from 'semantic-ui-react';
import FieldErrors from './../Common/FieldErrors';
import ServerErrors from './../Common/ServerErrors';
import {common} from "./../../utils/common";
import {BLANK_TEXT_ERROR_MESSAGE} from "./../../utils/Constants";
import {SITE_URL, STRIPE_CLIENT_ID} from "./../../constants/constants";
import {connect} from "react-redux";
import { connectStripeAccount, disconnectStripeAccount, getStripeAccount } from "./../../actions/paymentsAction";
import {SET_ACTIVE_JOB} from "../../constants/actionTypes";

const stateToProps=(state)=>{
    return{
        stripeAccountLoading: state.common.waitingRequest,
        stripeAccountConnecting: state.company.waitingRequest,
        stripeConnectError:state.settings.stripeConnectError,
        user: state.user.profile,
        stripeAccount: state.user.profile.stripe_account,
        activeJob: state.talent.activeJob
    }
}

const dispatchToProps=(dispatch)=>{
    return{
        connectStripeAccount:(data)=> dispatch(connectStripeAccount(data)),
        disconnectStripeAccount:()=> dispatch(disconnectStripeAccount()),
        getStripeAccount:()=> dispatch(getStripeAccount()),
        setActiveJob: (job) => dispatch({type: SET_ACTIVE_JOB, payload: {job: job}})
    }
}

class StripeAccount extends Component{
  commonObj=new common();

  constructor(props){
    super(props);
    this.renderServerErrors = this.renderServerErrors.bind(this);

    this.state={
      showBank:false,
      form_submitted:false,
      form_error:true,
      routing_number: '',
      account_number: '',
      account_number_confirm: '',
      server_error:false,
      errors:{
        routing_number:BLANK_TEXT_ERROR_MESSAGE,
        account_number:BLANK_TEXT_ERROR_MESSAGE,
        account_number_confirm:BLANK_TEXT_ERROR_MESSAGE
      }
    }
  }

  componentWillMount(){
      const {queryString} = this.props;
      if (this.props.user.is_stripe_connected){
          this.props.getStripeAccount();
      }
      else if (queryString['scope'] && queryString['code']){
          this.props.connectStripeAccount({'code': queryString['code']});
      }
  }

  handleChange = (e,{name,value,fieldtype,othervalue}) => {
    this.commonObj.handleChange(e,this,{name,value,fieldtype});

    if (othervalue && value!==othervalue){
      const state = this.state;
      state['form_error']=true;
      state['errors'][name]='Account number and confirm account number are not same';
      state[name] = value;
      this.setState(state)
    }
  }

  openBankEntry(event){
    event.preventDefault();
    this.setState({showBank:true});
  }

  saveBankInfo(event){
    event.preventDefault();
    this.setState({form_submitted:true});

    if (this.state.form_error===true || Object.keys(this.state.errors).length > 0){
      return;
    }

    this.setState({showBank:false});
  }

  cancelSave(event){
    event.preventDefault();
    this.setState({form_submitted:false,showBank:false});
  }

  renderServerErrors(){
      if (this.props.stripeConnectError){
          return <ServerErrors errorMessage={this.props.stripeConnectError} />
      }
  }

  componentWillReceiveProps(nextProps, prevProps){
      const {activeJob, user} = nextProps;
      if (user.is_stripe_connected && activeJob.id){
          const jobId = activeJob.id;
          this.props.setActiveJob({});
          this.props.history.push(`/job/${jobId}`);
      }
  }

  renderStripeButton = () => {
      if (this.props.stripeAccountConnecting || this.props.stripeAccountLoading){
          return <Loader active inline='centered' />
      }

      if (this.props.user.is_stripe_connected){
          return (
              <div>
                  <div style={{marginBottom: '20px'}}>
                      <Header style={{display:'inline', marginBottom: '10px'}}>Stripe Account</Header>
                      <p className={'account-info'}>Account ID: {this.props.stripeAccount.account_id}</p>
                  </div>
                  <Button color='blue' onClick={() => this.props.disconnectStripeAccount()}>Disconnect Stripe account</Button>
              </div>
          )
      }

      return (
          <a href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${SITE_URL}/settings/payments`}>
              <Image
                  size='small'
                  src='/assets/images/stripe-connect.png'
              />
          </a>
      )
  }

  render(){
    const {routing_number,account_number,account_number_confirm}=this.state;

    return(
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <span className={'panel-header'}>
            PAYMENTS
          </span>
        </Segment>

        <Segment>
            {this.renderServerErrors()}

            {
              this.props.paymentInfo && this.state.showBank===false &&
              <div>
                <Header style={{display:'inline'}}>{this.props.paymentInfo.bank}</Header>
                <Button basic floated={'right'} size={'mini'} onClick={(e)=>this.openBankEntry(e)}>...</Button>
                <p className={'account-info'}>{this.props.paymentInfo.account}</p>
              </div>
            }
            {
              this.state.showBank===true &&
              <Form size='small' onSubmit={(e)=>this.saveBankInfo(e)} error={(this.state.form_submitted && Object.keys(this.state.errors).length>0) || this.state.server_error}>
                {this.state.server_error &&
                  <ServerErrors errorMessage={this.props.errorMessage} />
                }
                <Form.Input
                  fluid
                  placeholder='Eenter Routing Number'
                  name='routing_number'
                  value={routing_number}
                  fieldtype='text'
                  onChange={this.handleChange}
                />
                <FieldErrors formSubmitted={this.state.form_submitted} errors={this.state.errors} fieldName={'routing_number'}/>
                <Form.Input
                  fluid
                  placeholder='Eenter Account Number'
                  name='account_number'
                  value={account_number}
                  othervalue={account_number_confirm}
                  fieldtype='text'
                  onChange={(e,{name,value,fieldtype,account_number, othervalue})=>this.handleChange(e,{name,value,fieldtype,othervalue})}
                />
                <FieldErrors formSubmitted={this.state.form_submitted} errors={this.state.errors} fieldName={'account_number'}/>
                <Form.Input
                  fluid
                  placeholder='Eenter Confirm Account Number'
                  name='account_number_confirm'
                  value={account_number_confirm}
                  othervalue={account_number}
                  fieldtype='text'
                  onChange={(e,{name,value,fieldtype,account_number,othervalue})=>this.handleChange(e,{name,value,fieldtype,othervalue})}
                />
                <FieldErrors formSubmitted={this.state.form_submitted} errors={this.state.errors} fieldName={'account_number_confirm'}/>
                <Button color='blue' size='small' loading={this.props.waitingRequest} disabled={this.props.waitingRequest}>CONNECT</Button>
                <Button basic size='small' onClick={(e)=>this.cancelSave(e)}>CANCEL</Button>
              </Form>
            }
            <div className={'stripe-connect'}>
                {this.renderStripeButton()}
            </div>
        </Segment>
      </Segment.Group>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(StripeAccount)
