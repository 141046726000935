import React,{Component} from 'react';
import {connect} from "react-redux";
import {
  Container,
  Placeholder,
  Card,
  Grid} from 'semantic-ui-react';

import './talent-profile.css';
import BookingHistory from './BookingHistory';
import PersonalInfo from './PersonalInfo';
import EditPersonalInfo from "./EditPersonalInfo";
import EditProfileAbout from "./EditProfileAbout";
import JobExperience from "./JobExperience";
import PhotoGallery from "./PhotoGallery";
import Portfolio from "./Portfolio";
import {getTalentProfile} from "../../../actions/publicProfile";
import {updateTalentProfile, updateTalentProfileUser, updateTalentProfileUserImage, getPortfolio, deleteGalleryPhoto,
  saveGalleryPhoto, getGalleryPhoto, getJobExperience,deleteJobExperience,saveJobExperience,updateJobExperience,
  updatePortfolio, createPortfolio, deletePortfolio} from "../../../actions/talent";


const stateToProps=(state)=>{
  return{
    waitingRequest:state.signup.waitingRequest,
    data:state.profileTalent.data,
    error:state.talent.error,
    user:state.user.profile,
    review:state.review,
    talent:state.talent,
  }
}

const dispatchToProps=(dispatch)=>{
  return{
    getJobExperience:(id)=>dispatch(getJobExperience(id)),
    getPortfolio:(id)=>dispatch(getPortfolio(id)),
    getGalleryPhoto:(id)=>dispatch(getGalleryPhoto(id)),
    updateTalentProfile: (data) => dispatch(updateTalentProfile(data)),
    updateTalentProfileUser: (data) => dispatch(updateTalentProfileUser(data)),
    saveGalleryPhoto:(data) => dispatch(saveGalleryPhoto(data)),
    deleteGalleryPhoto:(id)=>dispatch(deleteGalleryPhoto(id)),
    updatePortfolio:(data)=>dispatch(updatePortfolio(data)),
    createPortfolio:(data)=>dispatch(createPortfolio(data)),
    deletePortfolio:(ID)=>dispatch(deletePortfolio(ID)),
    updateTalentProfileUserImage:(data)=>dispatch(updateTalentProfileUserImage(data)),
    getTalentProfile:(id)=>dispatch(getTalentProfile(id)),
  }
}

class TalentProfile extends Component{
  constructor(props){
    super(props);

    this.state={
      user:this.props.user,
      edit:false,
      isLoggedUser:true
    }
  }

  componentDidMount(){
    this.props.getTalentProfile(this.props.match.params.id)
    this.props.getJobExperience(this.props.match.params.id);
    this.props.getPortfolio(this.props.match.params.id);

    try{
      if(this.props.user.id===this.props.talent.profile.user.id){
        this.setState({isLoggedUser:true});
      }else{
       this.setState({isLoggedUser:false});
      }

      if (this.props.user.id===this.props.talent.profile.user.id && this.props.match.params.mode==='edit'){
        this.setState({edit: this.props.match.params.mode==='edit'});
      }else{
        this.setState({edit: false});
      }
    }catch(err) {
        console.log(err.message);
    }

  }

  componentWillReceiveProps(nextProps){
    try{
      if(nextProps.user.id===nextProps.talent.profile.user.id){
        this.setState({isLoggedUser:true});
      }else{
       this.setState({isLoggedUser:false});
      }
      if (nextProps.user.id===nextProps.talent.profile.user.id && nextProps.match){
        this.setState({edit: nextProps.match.params.mode==='edit'});
      }
    }catch(err) {
        console.log(err.message);
    }
  }

  render(){
    let {user, talent} = this.props
    if(this.props.talent.profile.user && this.props.talent.profile.message === undefined){
      return(
        <Container className={'main-container'}>
          <Grid stackable  padded>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={6} computer={4}>
                {
                  this.state.edit ?
                  <EditPersonalInfo
                      user={this.state.user}
                      talent={this.props.talent.profile}
                      data={this.props.data}
                      updateTalentProfileUser={this.props.updateTalentProfileUser}
                      updateTalentProfile={this.props.updateTalentProfile}
                      updateTalentProfileUserImage={this.props.updateTalentProfileUserImage}
                  />
                  :
                  <PersonalInfo user={user} talent={talent.profile} profileRank={this.props.data.profileRank} profileStatistics={this.props.data.statistics} talent={this.props.talent.profile} data={this.props.data} isDisable={this.state.edit} isLoggedUser={this.state.isLoggedUser} updateTalentProfileUser={this.props.updateTalentProfileUser}/>
                }
              </Grid.Column>
              <Grid.Column mobile={16} tablet={10} computer={12}>
                <BookingHistory bookingInfo={this.props.review.data} review={this.props.review}/>
                {
                  this.props.talent.portfolios
                    ?
                      <Portfolio
                        talent={this.props.talent}
                        addphoto={this.props.saveGalleryPhoto}
                        deletephoto={this.props.deleteGalleryPhoto}
                        updatePortfolio={this.props.updatePortfolio}
                        createPortfolio={this.props.createPortfolio}
                        deletePortfolio={this.props.deletePortfolio}
                        isLoggedUser={this.state.isLoggedUser}>
                      </Portfolio>
                    :
                    <div>
                      <Placeholder style={{ height: 400, width: '100%' }} fluid>
                        <Placeholder.Image rectangular />
                      </Placeholder>
                      <Placeholder fluid>
                        <Placeholder.Header image>
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Paragraph>
                      </Placeholder>
                    </div>
                 }
                <EditProfileAbout
                    {...this.props}
                    user={this.state.user}
                    talent={this.props.talent}
                    data={this.props.data}
                    updateTalentProfile={this.props.updateTalentProfile}
                />

              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      )
    }else{
      return(
        <Container className={'main-container'}>
          {this.props.talent.profile.message}
        </Container>
      )
    }
  }
}

export default connect(stateToProps,dispatchToProps)(TalentProfile);
