import React, { Component } from 'react';
import {connect} from "react-redux";
import { loginSuccess } from "../../../actions/loginAction";


const stateToProps=(state)=>{
    return{
    }
  }
  
  const dispatchToProps=(dispatch)=>{
    return{
        loginSuccess: response => loginSuccess(response, dispatch)
    }
  }
class LoginAs extends Component {
    componentDidMount() {
        /**
         * Parsing url to fetch user info and dispatching action to update user info in store
         */
        const _href = window.location.href;
        const userInfo = _href.split('q_loginas=').length > 1 ? _href.split('q_loginas=')[1] : ''; 
        if (userInfo) {
            const decodedUserInfo = decodeURIComponent(userInfo);
            const parsedUser = JSON.parse(decodedUserInfo);
            parsedUser.loginas = true;
            this.props.loginSuccess({'data': parsedUser}); 
            setTimeout(() => {
                this.props.history.push('/');
                window.location.reload()
            }, 1500)

        } else {
            this.props.history.push('/');
        }
    }
    render() {
        return(
            <div><h3>Logging in as other user...</h3></div>
        )
    }
}

export default connect(stateToProps,dispatchToProps)(LoginAs);
