import axios from "axios";
import moment from "moment";
import { API_URL_BASE } from "../constants/constants";
import * as actionType from "../constants/actionTypes";
import { common } from "../utils/common";
import { history } from "../store";

export const eventList = () => {
  const commonObj = new common();

  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });

    axios
      .get(`${API_URL_BASE}/event/list/company`)
      .then(response => {
        commonObj.handleServerSucess(
          response,
          dispatch,
          actionType.EVENT_LIST_SUCCESS
        );
      })
      .catch(err => {
        commonObj.handleServerError(err, dispatch, actionType.EVENT_LIST_ERROR);
      });
  };
};

export const getEvent = id => {
  const commonObj = new common();
  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });

    axios
      .get(`${API_URL_BASE}/event/details/${id}`)
      .then(response => {
        commonObj.handleServerSucess(
          response,
          dispatch,
          actionType.GET_EVENT_SUCCESS
        );
      })
      .catch(err => {
        commonObj.handleServerError(err, dispatch, actionType.GET_EVENT_ERROR);
      });
  };
};

export const createEvent = event => {
  const commonObj = new common();
  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });
    axios
      .post(`${API_URL_BASE}/event/add`, event)
      .then(response => {
        commonObj.handleServerSucess(
          response,
          dispatch,
          actionType.ADD_EVENT_SUCCESS
        );
        console.log("event-save-response", response);
      })
      .catch(err => {
        commonObj.handleServerError(err, dispatch, actionType.ADD_EVENT_ERROR);
      });
  };
};

export const updateEvent = event => {
  const commonObj = new common();
  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });
    axios
      .put(`${API_URL_BASE}/event/update/${event["id"]}`, event)
      .then(response => {
        commonObj.handleServerError(
          response,
          dispatch,
          actionType.ADD_EVENT_SUCCESS
        );
      })
      .catch(err => {
        commonObj.handleServerError(err, dispatch, actionType.ADD_EVENT_ERROR);
      });
  };
};

export const updateVisibleEvent = (data, id)=>{
  const commonObj=new common();
  return (dispatch)=>{
    dispatch({type: actionType.WAITING_REQUEST, value:true});
    axios
      .put(`${API_URL_BASE}/event/visible-update/${id}`, data)
      .then(response => {
          commonObj.handleServerSucess(response, dispatch, actionType.VISIBLE_JOB_SUCCESS);
      })
      .catch(err => {
          commonObj.handleServerError(err, dispatch, actionType.VISIBLE_JOB_ERROR);
      });
  }
};

export const partialUpdateEvent=(id, data)=>{
  const commonObj=new common();

  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });

    axios
      .patch(`${API_URL_BASE}/event/partial-update/${id}`, data)
      .then(response => {
        dispatch({ type: actionType.UPDATE_EVENT_SUCCESS, response });
        dispatch({ type: actionType.WAITING_REQUEST, value: false });
        history.push("/events");
      })
      .catch(err => {
        commonObj.handleServerError(
          err,
          dispatch,
          actionType.UPDATE_EVENT_ERROR
        );
        dispatch({ type: actionType.WAITING_REQUEST, value: false });
      });
  };
};

export const addShift = (data, eventDate) => {
  const commonObj = new common();

  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });

    axios
      .post(`${API_URL_BASE}/event/shift/add`, data)
      .then(response => {
        let newShift = {
          ...response.data[0],
          start_time: moment(
            eventDate + " " + response.data[0].start_time,
            "YYYY-MM-DD hh:mm:ss"
          ).toDate(),
          end_time: moment(
            eventDate + " " + response.data[0].end_time,
            "YYYY-MM-DD hh:mm:ss"
          ).toDate()
        };

        dispatch({
          type: actionType.SAVE_SHIFT_SUCCESS,
          responseData: { newShift: newShift }
        });
        dispatch({ type: actionType.WAITING_REQUEST, value: false });
      })
      .catch(err => {
        commonObj.handleServerError(err, dispatch, actionType.SAVE_SHIFT_ERROR);
        dispatch({ type: actionType.WAITING_REQUEST, value: false });
      });
  };
};

export const updateShift = (id, data) => {
  const commonObj = new common();

  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });

    axios
      .put(`${API_URL_BASE}/event/shift/update/${id}`, data)
      .then(response => {
        let updatedShift = {
          ...response.data,
          start_time: moment(response.data.start_time).toDate(),
          end_time: moment(response.data.end_time).toDate()
        };

        dispatch({
          type: actionType.UPDATE_SHIFT_SUCCESS,
          responseData: updatedShift
        });
        dispatch({ type: actionType.WAITING_REQUEST, value: false });
      })
      .catch(err => {
        commonObj.handleServerError(
          err,
          dispatch,
          actionType.UPDATE_SHIFT_ERROR
        );
        dispatch({ type: actionType.WAITING_REQUEST, value: false });
      });
  };
};

export const getApplicantList = id => {
  const commonObj = new common();

  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });

    axios
      .get(`${API_URL_BASE}/event/applicant/list/${id}`)
      .then(response => {
        dispatch({
          type: actionType.GET_APPLICANT_LIST_SUCCESS,
          responseData: response.data
        });
        dispatch({ type: actionType.WAITING_REQUEST, value: false });
      })
      .catch(err => {
        commonObj.handleServerError(
          err,
          dispatch,
          actionType.GET_APPLICANT_LIST_ERROR
        );
        dispatch({ type: actionType.WAITING_REQUEST, value: false });
      });
  };
};

export const updateApplicant = (id, data) => {
  const commonObj = new common();
  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });

    axios
      .put(`${API_URL_BASE}/event/applicant/update/${id}`, data)
      .then(response => {
        dispatch({ type: actionType.UPDATE_APPLICANT_SUCCESS, response });
        dispatch({ type: actionType.WAITING_REQUEST, value: false });
      })
      .catch(err => {
        commonObj.handleServerError(
          err,
          dispatch,
          actionType.UPDATE_APPLICANT_ERROR
        );
        dispatch({ type: actionType.WAITING_REQUEST, value: false });
      });
  };
};

export const getEventInvoice = eventId => {
  const commonObj = new common();

  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });

    axios
      .get(`${API_URL_BASE}/payment/invoice/${eventId}`)
      .then(response => {
        commonObj.handleServerSucess(
          response,
          dispatch,
          actionType.GET_EVENT_INVOICE_SUCCESS
        );
      })
      .catch(err => {
        commonObj.handleServerError(
          err,
          dispatch,
          actionType.GET_EVENT_INVOICE_ERROR
        );
      });
  };
};

export const payInvoiceViaBank = data => {
  const commonObj = new common();

  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });
    const id = data.id;
    const payType = data.payType;
    delete data["id"];
    delete data["payType"];

    axios
      .put(
        `${API_URL_BASE}/payment/invoice/pay-via-bank/${id}/${payType}`,
        data
      )
      .then(response => {
        commonObj.handleServerSucess(
          response,
          dispatch,
          actionType.PAY_INVOICE_VIA_BANK_SUCCESS
        );
      })
      .catch(err => {
        commonObj.handleServerError(
          err,
          dispatch,
          actionType.PAY_INVOICE_VIA_BANK_ERROR
        );
      });
  };
};

export const payInvoiceViaCard = data => {
  const commonObj = new common();

  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });
    const id = data.id;
    const payType = data.payType;
    delete data["id"];
    delete data["payType"];

    axios
      .put(
        `${API_URL_BASE}/payment/invoice/pay-via-card/${id}/${payType}`,
        data
      )
      .then(response => {
        commonObj.handleServerSucess(
          response,
          dispatch,
          actionType.PAY_INVOICE_VIA_CARD_SUCCESS
        );
      })
      .catch(err => {
        commonObj.handleServerError(
          err,
          dispatch,
          actionType.PAY_INVOICE_VIA_CARD_ERROR
        );
      });
  };
};

export const publishEvent = eventId => {
  const commonObj = new common();

  return dispatch => {
    dispatch({ type: actionType.WAITING_REQUEST, value: true });

    axios
      .put(`${API_URL_BASE}/event/publish/${eventId}`)
      .then(response => {
        commonObj.handleServerSucess(
          response,
          dispatch,
          actionType.PUBLISH_EVENT_SUCCESS
        );
      })
      .catch(err => {
        commonObj.handleServerError(
          err,
          dispatch,
          actionType.PUBLISH_EVENT_ERROR
        );
      });
  };
};

export const deleteEventShift = shiftId => {
  const commonObj = new common();

  return dispatch => {
    dispatch({ type: actionType.DELETE_SHIFT_WAITING, value: true });

    axios
      .delete(`${API_URL_BASE}/event/shift/delete/${shiftId}`)
      .then(response => {
        commonObj.handleServerSucess(
          response,
          dispatch,
          actionType.DELETE_SHIFT_SUCCESS
        );
        dispatch({ type: actionType.DELETE_SHIFT_WAITING, value: false });
        dispatch({ type: actionType.DELETED_SHIFT_ID, value: shiftId });
      })
      .catch(err => {
        commonObj.handleServerError(
          err,
          dispatch,
          actionType.DELETE_SHIFT_ERROR
        );
        dispatch({ type: actionType.DELETE_SHIFT_WAITING, value: false });
      });
  };
};

export const rateJob = data => {
  const commonObj = new common();
  return dispatch => {
    dispatch({ type: actionType.RATE_JOB_LOADING, value: true });
    axios
      .post(`${API_URL_BASE}/event/review/add`, data)
      .then(response => {
        commonObj.serverSucessHandler(
          response.data,
          dispatch,
          actionType.RATE_JOB_SUCCESS,
          actionType.RATE_JOB_LOADING
        );
      })
      .catch(err => {
        commonObj.serverErrorHandler(
          err,
          dispatch,
          actionType.RATE_JOB_ERROR,
          actionType.RATE_JOB_LOADING
        );
      });
  };
};

export const getJobRating = (eventId, reviewerId) => {
  const commonObj = new common();
  return dispatch => {
    dispatch({ type: actionType.GET_RATING_LOADING, value: true });
    axios
      .get(`${API_URL_BASE}/event/review/${eventId}/${reviewerId}`)
      .then(response => {
        commonObj.serverSucessHandler(
          response.data,
          dispatch,
          actionType.GET_RATING_SUCCESS,
          actionType.GET_RATING_LOADING
        );
      })
      .catch(err => {
        commonObj.serverErrorHandler(
          err,
          dispatch,
          actionType.GET_RATING_ERROR,
          actionType.GET_RATING_LOADING
        );
      });
  };
};

export const getEventReviews = eventId => {
  const commonObj = new common();
  return dispatch => {
    dispatch({ type: actionType.EVENT_REVIEW_LIST_LOADING, value: true });
    axios
      .get(`${API_URL_BASE}/event/reviews/${eventId}`)
      .then(response => {
        commonObj.serverSucessHandler(
          response.data,
          dispatch,
          actionType.EVENT_REVIEW_LIST_SUCCESS,
          actionType.EVENT_REVIEW_LIST_LOADING
        );
      })
      .catch(err => {
        commonObj.serverErrorHandler(
          err,
          dispatch,
          actionType.EVENT_REVIEW_LIST_ERROR,
          actionType.EVENT_REVIEW_LIST_LOADING
        );
      });
  };
};
