const location = window && window.location;
const hostname = location.hostname;

const getEnv = () => {
   if (process.env.NODE_ENV==='development'){
      return 'local';
   }

   if (process.env.NODE_ENV==='production'){
       if (hostname.startsWith('uat')){
          return 'staging';
       }

       if (hostname.startsWith('shiftdash')){
          return 'production';
       }
   }
};

export const ENV = getEnv();

const getApiBaseUrl = () => {
  if (ENV === 'local'){
    return 'http://localhost:8000';
  }

  if (ENV === 'staging') {
      return 'https://api-uat.shiftdash.com';
  }

  if (ENV === 'production'){
    return 'https://api.shiftdash.com';
  }
};

const getSentryDsn = () => {
    if (ENV === 'staging'){
        return 'https://b78f074787e7484cbbb6a553af4210d9@sentry.io/1412145';
    }

    if (ENV === 'production'){
       return 'https://3346f5dd018f43eea796d93f60354c23@sentry.io/1412145';
    }
};

const getMobileAppExpoUrl = () => {
    /*
     update this to expo xde host url for mobile app.
     Dev: this could be localhost, LAN or Tunnel based on your expo xde settings
     Prod: this must be the expo url for our mobile app
    */
    if (ENV === 'local' || ENV === 'staging'){
        return 'exp://localhost:19000';
    }

    if (ENV === 'production'){
       return 'exp://expo.io/@shiftdash/shiftdash';
    }
};

export const API_URL_BASE = getApiBaseUrl();

let port = '';
if (location.port){
  port = `:${location.port}`;
}

export const SITE_URL = `${location.protocol}//${location.hostname}${port}`;
export const SEND_BIRD_APP_ID = ENV === 'production' ? '809D33E8-3E49-44CB-B83B-4C14296B721D' : 'F195F2EC-B6E2-4859-BC1C-940B178B4CBA';
export const SENTRY_DSN = getSentryDsn();

export const STRIPE_CLIENT_ID = ENV === 'production' ? 'ca_DwFxmi7EY6r0AZD8VG5LXA5gwMVWYBQh' : 'ca_DwFxpCsPra00bPp3eM7m5URJCdUzKxcB';
export const PLAID_ENV = ENV === 'production' ? 'development' : 'sandbox';
export const PLAID_PUBLIC_KEY = ENV === 'production' ? 'f51cdba674981b8cc2274df96fa145' : 'c8b8bf60c64bb2c3202f4e2275f55c';

export const GOOGLE_ANALYTICS_ID = ENV === 'production' ? 'UA-136418070-2' : 'UA-136418070-1';
export const INSPECTLET_WID = ENV === 'production' ? '1619217325' : '';
export const MOBILE_APP_EXPO_URL = getMobileAppExpoUrl();

export const CAPTACHA_SITE_KEY = ENV === 'production' ? '6LdzHrEUAAAAAME1JxcxA8aDQtjk-83BwudNCv4S' : '6LdzHrEUAAAAAME1JxcxA8aDQtjk-83BwudNCv4S';
