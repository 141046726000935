import axios from 'axios';

import {
    WAITING_REQUEST,
    GET_TALENT_JOB_DETAILS_FULFILLED,
    GET_TALENT_JOB_DETAILS_FAILED,
    GET_SHIFT_FULFILLED,
    GET_SHIFT_FAILED,
    WAITING_ADD_APPLICANT,
    POST_SHIFT_FAILED,
    POST_SHIFT_FULFILLED,
    WAITING_GET_APPLYED_APPLICANT,
    GET_APPLYED_APPLICANT_FULFILLED,
    GET_APPLYED_APPLICANT_FAILED,
    DELETE_EVENT_EVENTAPPLICANT_FULFILLED,
    DELETE_EVENT_EVENTAPPLICANT_FAILED,
    WITHDRAW_APPLICATION_FULFILLED,
    WITHDRAW_APPLICATION_FAILED,
    GET_TALENT_JOB_DETAILS_WAITING
} from '../constants/actionTypes';
import * as actionType from '../constants/actionTypes'
import {common} from '../utils/common';
import {API_URL_BASE} from '../constants/constants';


export const getJobDetailsData = (id) => {
  const commonObj = new common();

  return (dispatch) => {
    dispatch({type: GET_TALENT_JOB_DETAILS_WAITING, value: true});

    axios.get(`${API_URL_BASE}/event/details/${id}`)
      .then((response) => {
        commonObj.handleServerSucess(response, dispatch, GET_TALENT_JOB_DETAILS_FULFILLED);
        dispatch({type: GET_TALENT_JOB_DETAILS_WAITING, value: false});
      })
      .catch((err) => {
        commonObj.handleServerError(err, dispatch, GET_TALENT_JOB_DETAILS_FAILED);
        dispatch({type: GET_TALENT_JOB_DETAILS_WAITING, value: false});
      })
  }
}

export const getShiftData = (id) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_REQUEST, value:true});

    axios.get(`${API_URL_BASE}/event/shift/list/${id}`)
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch,GET_SHIFT_FULFILLED);
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,GET_SHIFT_FAILED);
      })
  }
}

export const addApplicant = (data) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_ADD_APPLICANT, value:true});
    axios
        .post(`${API_URL_BASE}/event/applicant/add`, data)
        .then(response => {
          dispatch({type:WAITING_ADD_APPLICANT, value:false});
          dispatch({type:POST_SHIFT_FULFILLED, responseData: response.data});
        }).catch((err) =>{
          commonObj.handleServerError(err, dispatch, POST_SHIFT_FAILED);
          dispatch({type:WAITING_ADD_APPLICANT, value:false});
        })
  }
};

export const updateApplicantion = (id, data) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_ADD_APPLICANT, value:true});
    axios.delete(`${API_URL_BASE}/event/evnt-applicant/delete/${id}`)
      .then(response => {
        axios.post(`${API_URL_BASE}/event/applicant/add`, data)
          .then((res)=>{
            window.location.reload(true);
          });
        }).catch((err) =>{
          commonObj.handleServerError(err, dispatch, POST_SHIFT_FAILED);
          dispatch({type:WAITING_ADD_APPLICANT, value:false});
        })
  }
};

export const getApplyedApplicatData = (id) => {
  const commonObj=new common();
  return (dispatch) => {
    dispatch({type:WAITING_GET_APPLYED_APPLICANT, value:true});

    axios.get(`${API_URL_BASE}/event/applicant/applyed-list/${id}`)
      .then((response)=>{

        commonObj.handleServerSucess(response,dispatch,GET_APPLYED_APPLICANT_FULFILLED);
        dispatch({type:WAITING_GET_APPLYED_APPLICANT, value:false});
      })
      .catch((err) =>{

        dispatch({type:WAITING_GET_APPLYED_APPLICANT, value:false});
        commonObj.handleServerError(err,dispatch,GET_APPLYED_APPLICANT_FAILED);
      })
  }
}

export const deleteEventEventApplicant = (id) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_ADD_APPLICANT, value:true});
    axios.delete(`${API_URL_BASE}/event/applicant/delete/${id}`)
        .then(response => {

          dispatch({type:DELETE_EVENT_EVENTAPPLICANT_FULFILLED, responseData: response.data});
        }).catch((err) =>{

          commonObj.handleServerError(err, dispatch, DELETE_EVENT_EVENTAPPLICANT_FAILED);
        })
  }
};

export const withdrawApplication = (id) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_ADD_APPLICANT, value:true});
    axios.delete(`${API_URL_BASE}/event/evnt-applicant/delete/${id}`)
        .then(response => {

          dispatch({type:WITHDRAW_APPLICATION_FULFILLED, responseData: response.data});
        }).catch((err) =>{

          commonObj.handleServerError(err, dispatch, WITHDRAW_APPLICATION_FAILED);
        })
  }
};

export const uploadEventDocuments = (data) => {
  const commonObj = new common();

  return (dispatch) => {
    dispatch({type: actionType.EVENT_DOCUMENTS_UPLOADING, value: true});
    const config = {
      onUploadProgress: function(progressEvent) {
        const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        dispatch({type: actionType.EVENT_DOCUMENTS_UPLOAD_PROGRESS, value: percentCompleted});
      }
    };

    axios.post(`${API_URL_BASE}/event/documents/upload`, data, config)
      .then((response) => {
        commonObj.handleServerSucess(response, dispatch, actionType.EVENT_DOCUMENTS_UPLOAD_SUCCESS);
        dispatch({type: actionType.EVENT_DOCUMENTS_UPLOADING, value: false});
      })
      .catch((err) => {
        commonObj.handleServerError(err, dispatch, actionType.EVENT_DOCUMENTS_UPLOAD_ERROR);
        dispatch({type: actionType.EVENT_DOCUMENTS_UPLOADING, value: false});
      })
  }
}
