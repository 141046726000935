import React,{Component} from 'react';
import {connect} from 'react-redux';
import {Container, Grid, Button, Segment} from 'semantic-ui-react';
import './job-details.css';
import {getJobDetailsData, getApplyedApplicatData,withdrawApplication} from "../../../../actions/jobDetailsTalentAction";
import Details from './Details';
import {updateApplicant} from "../../../../actions/event";
import Apply from './Apply';
import SocialShare from './SocialShare';
import ServerErrors from "../../../Common/ServerErrors";
import {removeEmptyDaysShifts} from "../../helpers";
import ContentPlaceHolder from "../../../Common/ContentPlaceholder";
import Documents from "../../Company/Details/Documents";

const stateToProps=(state)=>{
  return{
    waitingRequest:state.common.waitingRequest,
    job:state.jobsTalent.singleData,
    errorMessage:state.jobsTalent.errorMessage,
    applyed:state.jobsTalent.applyed,
    user: state.user.profile,
    portfolios: state.talent.portfolios,
    waitingRequestJobDetails:state.jobsTalent.waitingRequestJobDetails,
    waitGetApplyedApplicant:state.jobsTalent.waitGetApplyedApplicant
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    getJobDetailsData:(id)=> dispatch(getJobDetailsData(id)),
    getApplyedApplicatData:(id)=> dispatch(getApplyedApplicatData(id)),
    updateApplicant:(id,data)=>dispatch(updateApplicant(id,data)),
    withdrawApplication:(id)=> dispatch(withdrawApplication(id)),
  }
};

class JobDetailsTalent extends Component {

  constructor(props) {
    super(props)
    this.deleteJob = this.deleteJob.bind(this)
    this.updateApplicantstatus = this.updateApplicantstatus.bind(this)
  }

  componentWillMount() {
    const script = document.createElement("script");
    script.src = "https://platform-api.sharethis.com/js/sharethis.js#property=5ace3b233368f4001495b78c&product=inline-share-buttons";
    script.async = true;
    document.body.appendChild(script);
  }

  componentDidMount() {
    const jobId = this.props.match.params.id;
    this.props.getApplyedApplicatData(jobId)
    this.props.getJobDetailsData(jobId);
  }

  deleteJob() {
    const jobId = this.props.match.params.id;
    this.props.withdrawApplication(jobId);
    this.props.getJobDetailsData(jobId);
  }

  updateApplicantstatus(value) {
    this.props.applyed.map((applicant) => {
      if (applicant.status === "pending_reopened" || applicant.status === "unavailable" || applicant.status === "interested") {
        this.props.updateApplicant(applicant.id, {status: value})
      }
    });
  }

  renderJobDetails = () => {
    if (this.props.errorMessage) {
      return <ServerErrors errorMessage={this.props.errorMessage}/>
    }

    const {applyed, portfolios} = this.props;
    const job = removeEmptyDaysShifts(this.props.job);
    return (
      <Grid stackable padded>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={10} computer={10}>
            {this.props.waitingRequestJobDetails ?
              <ContentPlaceHolder totalParagraphs={2}/>
              :
              <Details job={this.props.job} applyed={this.props.applyed}/>
            }
          </Grid.Column>
          <Grid.Column mobile={16} tablet={6} computer={6}>
            {this.props.waitingRequestJobDetails || this.props.waitGetApplyedApplicant ?
              <ContentPlaceHolder totalParagraphs={2}/>
              :
              <React.Fragment>
                <Apply
                  job={job}
                  portfolios={portfolios}
                  applyed={applyed}
                  deleteJob={this.deleteJob}
                  user={this.props.user}
                  updateApplicantstatus={this.updateApplicantstatus}
                  {...this.props}
                />
                <SocialShare job={job} />

                <Segment textAlign={'center'}>
                    <h5>Give feedback</h5>
                    {job['is_all_time_sheets_approved']
                        ?
                        <Button color={`red`} onClick={() => this.props.history.push(`/job/${job.id}/rating`)}>Rate this Job</Button>
                        :
                        "You can submit feedback once your timesheets are approved"
                    }
                </Segment>
              </React.Fragment>
            }
          </Grid.Column>
          <Grid.Column mobile={16} tablet={10} computer={10}>
            <Documents event={job} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  };

  render() {
    return (
      <Container className={'main-container'}>
        {this.renderJobDetails()}
      </Container>
    )
  }
}

export default connect(stateToProps,dispatchToProps)(JobDetailsTalent)
