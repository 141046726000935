import React,{Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {Container, Grid, Menu, Dropdown, Button, Icon, Form, Placeholder, Pagination} from 'semantic-ui-react';
import {isMobileOnly} from 'react-device-detect'
import geolib from 'geolib';
import './jobs-talent.css';
import GoogleMapComponent from './JobsGoogleMap';
import {getJobsData} from "../../../actions/jobsTalentAction";
import { primaryWorkCity } from "../../../actions/talent";
import JobList from './JobList';
import {GET_TALENT_JOBS_FILTERED_DATA} from "../../../constants/actionTypes";
import {
    removeEmptyEventsDaysShifts,
    formatDateRange,
    getJobEarliestDate,
    getJobEarliestShiftTime,
    getJobLatestDate,
    getJobLatestShiftTime,
    getJobMaxRate
} from "../helpers";
import * as moment from "moment";
import ContentPlaceHolder from "../../Common/ContentPlaceholder";


const stateToProps=(state)=>{
  return{
    waitingRequest:state.common.waitingRequest,
    waitingRequestGetJobs:state.jobsTalent.waitingRequestGetJobs,
    data:state.jobsTalent.data,
    filteredData:state.jobsTalent.filteredData,
    errorMessage:state.jobsTalent.errorMessage,
    workCities:state.talent.profile.work_cities,
    user: state.user.profile
  }
}

const dispatchToProps=(dispatch)=>{
  return{
    getJobsData:()=> dispatch(getJobsData()),
    primaryWorkCity: (cityId) => dispatch(primaryWorkCity(cityId)),
    getJobsFiltereData:(filteredData)=> dispatch({type:GET_TALENT_JOBS_FILTERED_DATA,filteredData:filteredData})
  }
};

class JobsTalent extends Component{

  constructor(props){
    super(props);
    this.state = {
      jobs: [],
      jobsFiltered: [],
      filter: {
          text: '',
          city: -1, // indicate to add more cities
          radius: 25,
          payType: 'All'
      },
      sortBy: 'date',
      name:'',
      activePage:1,
      job_index:-1,
    };

    this.radiusList = [
      { text: "1 miles", value: 1},
      { text: "5 miles", value: 5},
      { text: "10 miles", value: 10},
      { text: "25 miles", value: 25},
      { text: "30 miles", value: 30},
      { text: "50 miles", value: 50000},
    ];

    this.payTypeList = [
      { text: "All Type", value: "All"},
      { text: "Hourly", value: "Hourly"},
      { text: "Fixed", value: "Fixed"}
    ];

    this.workCities = this.props.workCities.map(city => {
      return {text: city.name, value: city.name }
    });
    this.workCities.push({text:"Add More Cities", value: -1});
  }

  componentDidMount(){
    this.props.getJobsData();
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.data){
      let jobs = [];
      const events = removeEmptyEventsDaysShifts(nextProps.data);

      // flatten all events into shifts array
      _.each(events, (event) => {
          const eventFirstDay = event['event_days'][0];
          event.location = eventFirstDay.location;
          event.lat = eventFirstDay.lat;
          event.lng = eventFirstDay.lng;
          event.startDate = getJobEarliestDate(event);
          event.endDate = getJobLatestDate(event);
          event.dateRange = formatDateRange(event);
          const startTime = getJobEarliestShiftTime(event);
          const endTime = getJobLatestShiftTime(event);
          event.startTime = startTime.isBefore(endTime) ? startTime : endTime;
          event.endTime = endTime.isAfter(startTime) ? endTime : startTime;
          jobs.push(event);
      });


      this.setState({jobs, jobsFiltered: jobs});

    }

    const primaryWorkCity = this.getPrimaryWorkCity();
    this.setFilter('city', primaryWorkCity ? primaryWorkCity.name : this.workCities[0].value);
  }

  getPrimaryWorkCity = () => {
    return this.props.workCities.find(city => city['is_primary']);
  };

  setFilter = (type, value) => {
      if(type==="city" && value === -1){
        this.props.history.push('/settings/work-cities');
        return;
      }

      if(type==="city"){
        let filteredCity = null;
         _.each(this.props.workCities, workCity => {
              if (workCity.name.toLowerCase() === value.toLowerCase()){
                  filteredCity = workCity;
                  return false;
              }
          });

          if(filteredCity!==null && this.state.name!==value){
            this.props.primaryWorkCity(filteredCity.id);
          }
      }

      const {filter} = this.state;
      filter[type] = value;
      this.setState({filter:filter, name:this.state.filter.city}, () => this.filterJobs());

  };

  filterJobs = () => {

      let jobsFiltered = this.state.jobs;
      const { text, city, radius, payType } = this.state.filter;

      // filter by job type
     if (payType.toLowerCase() !== 'all'){
         const payTypeExistJobs = [];
        _.each(jobsFiltered, job => {

            let payTypeExist = false;
            _.each(job['event_days'], eventDay => {

                _.each(eventDay['shifts'], shift => {
                    if (shift['talent_role']['pay_type'].toLowerCase().includes(payType.toLowerCase())){
                        payTypeExist = true;

                        payTypeExistJobs.push(job);
                        return false;
                    }
                });

                if (payTypeExist){
                    return false;
                }

            });

        });

        jobsFiltered = payTypeExistJobs;
      }

      // filter by search text
      if (text !== ''){
          const searchedJobs = [];
          _.each(jobsFiltered, job => {
                 if (
                     (job.name && job.name.toLowerCase().includes(text)) ||
                     (job['event_type'] && job['event_type'].toLowerCase().includes(text)) ||
                     (job['event_type_name'] && job['event_type_name'].toLowerCase().includes(text)) ||
                     (job['company']['name'] && job['company']['name'].toLowerCase().includes(text))
                 ){
                     searchedJobs.push(job);
                     return false;
                 }
                 else{
                     _.each(job['event_days'], eventDay => {
                        if((eventDay.location && eventDay.location.toLowerCase().includes(text))) {
                            searchedJobs.push(job);
                            return false;
                        }
                     });
                 }

            });

          jobsFiltered = searchedJobs;
      }

      // filter by city plus radius
      if (city !== -1){
          let filteredCity = this.getFilteredCity();
          const searchedByCityJobs = [];



          _.each(jobsFiltered, job => {

              _.each(job['event_days'], eventDay => {

                  // distance in miles between selected city and event location
                  const eventLocationLatLng = {latitude: eventDay.lat, longitude: eventDay.lng};
                  const cityLatLng = {latitude: filteredCity.lat, longitude: filteredCity.lng};
                  const distance = this.getLatLngDistance(eventLocationLatLng, cityLatLng);

                  if (distance <= radius){
                      searchedByCityJobs.push(job);

                      return false;
                  }
              });
          });


          jobsFiltered = searchedByCityJobs;
      }

      this.setState({jobsFiltered}, () => this.sortJobs());

  };

  clearFilters = () => {
    const {filter} = this.state;
    filter.text = '';
    filter.city = this.workCities[0].value;
    filter.radius = 10;
    filter.payType = 'All';
    this.setState({filter}, () => this.filterJobs());
  };

  getLatLngDistance(LatLngObj1, LatLngObj2){
    let distance = geolib.getDistance(LatLngObj1, LatLngObj2, 10); // distance in meters
    distance = distance/1609.34; // convert meters to miles
    return distance;
  }

  getJobDistanceFromCity = (job) => {
      // distance in miles between selected city and event location
      let eventDay = job['event_days'][0];
      let filteredCity = this.getFilteredCity();
      let cityLatLng = {latitude: filteredCity.lat, longitude: filteredCity.lng};
      let eventLocationLatLng = {latitude: eventDay.lat, longitude: eventDay.lng};
      const distance = this.getLatLngDistance(eventLocationLatLng, cityLatLng);


      return distance;
  };

  getFilteredCity = () => {
      if (this.state.filter.city !== -1){
        let filteredCity = null;
         _.each(this.props.workCities, workCity => {
              if (workCity.name.toLowerCase() === this.state.filter.city.toLowerCase()){
                  filteredCity = workCity;
                  return false;
              }
          });

          return filteredCity;
      }
  };

  sortJobs = () => {
    const {sortBy, jobsFiltered} = this.state;

    let sortedJobs = jobsFiltered;

    if (sortBy === 'date'){
      sortedJobs = jobsFiltered.sort((job1, job2) => getJobEarliestDate(job1) > getJobEarliestDate(job2));
    }

    if (sortBy === 'pay_rate'){
      sortedJobs = jobsFiltered.sort((job1, job2) => getJobMaxRate(job1) < getJobMaxRate(job2));
    }

    if (sortBy === 'distance' && this.state.filter.city !== -1){
      sortedJobs = jobsFiltered.sort((job1, job2) => this.getJobDistanceFromCity(job1) > this.getJobDistanceFromCity(job2));
    }

    this.setState({jobsFiltered: sortedJobs});
  };

  sortBy = (sortBy) => {
    this.setState({ sortBy }, () => this.sortJobs() );
  };

  onHover = (id)=>{
    this.setState({job_index:id});
  }

  goToPage = (id)=>{
    this.props.history.push(`/job/${id}`);
  }

  handlePaginationChange = (e, { activePage }) =>{
    this.setState({ activePage })
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  render(){
    let index = this.state.job_index;
    let start = (this.state.activePage-1)*10;
    let end = this.state.activePage*10;
    let total = 0

    let jobs = this.state.jobsFiltered.filter((item)=>{
      return item.startDate.isAfter(moment().subtract(60, 'days'))
    }).slice(start, end);

    if(this.state.jobsFiltered.length%10!==0)
      total = parseInt(this.state.jobsFiltered.length/10)+1;
    else
      total = parseInt(this.state.jobsFiltered.length/10);

    return(
      <Container fluid textAlign='center'>
        <Menu
          className={'secondary-fixed-top-menu'}
          stackable
          { ...( isMobileOnly===false && { fixed: 'top' } ) }
        >
          <Menu.Item className={'filter-menu-item location'}>
            <Dropdown
              placeholder='Select Location'
              fluid
              selection
              options={this.workCities}
              name={'city'}
              fieldtype={'select'}
              value={this.state.filter.city}
              onChange={(e, {name, value}) => this.setFilter(name, value)}
            />
          </Menu.Item>
          <Menu.Item className={'filter-menu-item radius'}>
            <Dropdown
              placeholder='Select radius'
              fluid selection
              options={this.radiusList}
              name={'radius'}
              value={this.state.filter.radius}
              onChange={(e, {name, value}) => this.setFilter(name, value)}
            />
          </Menu.Item>
          <Menu.Item className={'filter-menu-item type'}>
            <Dropdown
              placeholder='Select type'
              fluid
              selection
              name={'payType'}
              options={this.payTypeList}
              value={this.state.filter.payType}
              onChange={(e, {name, value}) => this.setFilter(name, value)}
            />
          </Menu.Item>
          <Menu.Item className={'filter-menu-item clear-button'}>
            <Button basic icon labelPosition='right' onClick={(e)=>{this.clearFilters()}}>
              <Icon name='trash' />
              Clear Filters
            </Button>
          </Menu.Item>
          <Menu.Item className={'filter-menu-item search-input'} position={'right'}>
            <Form size='large' className={'search-form'}>
              <Form.Input
                fluid
                icon={<Icon name='search' link onClick={(e)=>this.search(e)} />}
                placeholder='Search...'
                name='text'
                fieldtype='text'
                value={this.state.filter.text}
                onChange={(e, {name, value}) => this.setFilter(name, value)}
              />
            </Form>
          </Menu.Item>
        </Menu>
          <Grid stackable>
              <Grid.Row>
                  <Grid.Column mobile={16} tablet={8} computer={8}>
                      {/* job list */}
                      <Container textAlign={'left'}>
                          {
                          this.props.waitingRequestGetJobs ?
                            <ContentPlaceHolder totalParagraphs={2}/>
                          :
                            <div>
                              <JobList
                                  jobs={jobs}
                                  sortBy={this.sortBy}
                                  user={this.props.user}
                                  onHover={this.onHover}
                                  goToPage={this.goToPage}
                              />

                              {jobs.length>0 &&
                                <Pagination onPageChange={this.handlePaginationChange} defaultActivePage={1} totalPages={total} />
                              }
                            </div>
                          }
                      </Container>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={8} style={{width: '100%', height: '500px', position: 'sticky', top:'110px'}}>
                      {/* Google Map*/}
                      <GoogleMapComponent index={index} history={this.props.history} work_cities={this.props.workCities} city={this.state.filter.city} jobs={this.state.jobsFiltered}/>
                  </Grid.Column>
              </Grid.Row>
              }
          </Grid>
      </Container>
    )
  }
}

export default connect(stateToProps,dispatchToProps)(JobsTalent)
