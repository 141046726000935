import React from 'react';
import {GiftedChat} from './GiftedChatNative/GiftedChat';
import './styles.css';

// class Messages extends Component {
//   constructor(props) {
//     super(props);
//
//     this.state = {
//       activeChannelMessages: this.props.activeChannelMessages
//     }
//     this.onSend = this.onSend.bind(this);
//   }
//
//   componentDidMount() {
//
//   }
//
//   renderLoading() {
//     return (<div>Loading...</div>)
//   }
//
//   onSend(messages) {
//     for(let message of messages){
//       this.setState({messages: [...this.state.messages,message]})
//     }
//   }
//
//   render() {
//     console.log(this.state.activeChannelMessages)
//     return (
//       <div className="App">
//         <GiftedChat user={{_id: 1}}
//           messages={this.state.activeChannelMessages}
//           onSend={this.onSend}/>
//       </div>
//     );
//   }
// }
//
// export default Messages;

const Messages = (props) => {
  const {
    channelMessagesLoading, channelMessagesLoadingError,
    activeChannelMessages, userChannelId, onlineUsers,
    channelPreviousMessagesLoading, channelPreviousMessagesLoadingError,
    sendBirdActiveChannel,loadMoreMessages } = props;

  let messages=activeChannelMessages.map((message)=>{
    let text = message.message;
    if (message.messageType === 'file'){
      text = message.data;
    }

    const fileUrl = (message.messageType === 'file' && message.url) ? message.url : null;

    return{
      _id: message.messageId,
      text,
      fileUrl,
      createdAt: message.createdAt,
      readMembers:message.readMembers,
      name: message.name,
      user: {
        _id: message._sender.userId,
        name: message._sender.nickname,
        avatar:message._sender.profileUrl
      }
    }
  });
  return (
    <div className="App">
      <GiftedChat
        user={{_id: userChannelId}}
        messages={messages}
        onSend={props.onSend}
        sendFileMessage={props.sendFileMessage}
        loadEarlier={loadMoreMessages}
        onLoadEarlier={props.loadChannelPreviousMessages}
        isLoadingEarlier={channelPreviousMessagesLoading}
        onImageChange={props.onImageChange}
        onInputTextChanged={props.onInputTextChanged}
        typingMembers={props.typingMembers}
        activeChannelName={props.activeChannelName}
        renderLoading={props.renderLoading}
        channelMessagesLoading={props.channelMessagesLoading}
      />
    </div>
  )
}

export default Messages;
