import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Segment, Header, Button, Form, Loader, List, Container} from 'semantic-ui-react';
import { registerCompany, updateCompany } from "../../../actions/company";
import ServerErrors from '../../Common/ServerErrors';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import {classnames} from "../../Events/CreateEvent/classnames";

const dispatchToProps=(dispatch)=>{
  return{
      registerCompany: (data) => dispatch(registerCompany(data)),
      updateCompany: (data) => dispatch(updateCompany(data)),
  }
};

const stateToProps=(state)=>{
    return {
        ...state.company,
        waitingRequest: state.common.waitingRequest
    };
};

class CompanySignup extends Component {
  constructor(props){
    super(props);
    const { street, city, state, country, lat, lng } = props.profile;
    let address= (street==="") ? "" : street +
              (city==="") ? "" : city +
              (state==="") ? "" : state +
              (country==="") ? "" : country ;

    this.state = {
        inputAddress: address,
        street: street,
        city: city,
        state: state,
        country: country,
        lat: lat,
        lng: lng,
        formSubmitted: false,
    };
  }

  onChangeAddress = (inputAddress) => { this.setState({inputAddress}) };

  onSelectAddress = (inputAddress) => {

      this.setState({inputAddress});
      geocodeByAddress(inputAddress)
          .then(results => {
              const addresses = results[0]['address_components'];
              this.setState({lat: results[0].geometry.location.lat()});
              this.setState({lng: results[0].geometry.location.lng()});

              for(let address of addresses){
                  if (address.types.includes('country')){
                      this.setState({country: address['long_name']});
                  }

                  if (address.types.includes('administrative_area_level_1')){
                      this.setState({state: address['long_name']});
                  }

                  if (address.types.includes('locality')){
                      this.setState({city: address['long_name']});
                  }

                  if (address.types.includes('street_number')){
                      this.setState({street: address['long_name']});
                  }

                  if (address.types.includes('route')){
                      this.setState({street: `${this.state.street} ${address['long_name']}`});
                  }
              }

          })
  };

  submitForm = () => {
      const {...companyData} = this.state;

      // check if each value field of company is filled out
      const isFilled = true;//Object.values(companyData).every(el => el !== '');
      if (isFilled && !this.props.profile.id) {
          this.setState({formSubmitted: true});
          this.props.registerCompany(companyData);
      }
      else if (isFilled && this.props.profile.id) {
          this.setState({formSubmitted: true});
          companyData['id'] = this.props.profile.id;
          this.props.updateCompany(companyData);
      }
  }

  renderServerErrors = () => {
        if (this.props.error && this.state.formSubmitted){
            return <ServerErrors errorMessage={this.props.error} />
        }
    }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.success && this.state.formSubmitted){
        this.props.history.push('/events');
    }
  };

  render() {
      const options = {
          types: ['address'],
          componentRestrictions: {country: "us"}
      };
      const styles = {
          root: { 'zIndex': '999999999', 'marginBottom': '20px' }
      };

    return(
      <Segment attached raised style={{paddingTop:'40px',paddingBottom:'40px'}}>
        <Header size={'large'}>Fill out Company Location</Header>
        <Container textAlign='left' className={'signup-container'} style={{marginTop: '32px'}}>

            {this.renderServerErrors()}

            <PlacesAutocomplete
                value={this.state.inputAddress}
                onChange={address => this.onChangeAddress(address)}
                onSelect={address => this.onSelectAddress(address)}
                options={options}
                styles={styles}>
              {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                return (
                  <div className="Demo__search-bar-container" style={{'width':'100%', 'paddingBottom': '20px'}}>

                      <Form.Input
                          fluid
                          icon='location arrow'
                          iconPosition='left'
                          name='address'
                          value={this.state.inputAddress}
                          fieldtype='text'
                          onChange={address => this.onChangeAddress(address)}
                          style={{marginBottom:'25px'}}
                          required={true}
                          style={{ 'height': '40px', 'width': '100%'}}
                          {...getInputProps({
                            placeholder: 'Add your address',
                            className: 'Demo__search-input',
                          })}
                      />



                    {suggestions.length > 0 && (
                      <Container style={{ marginTop: '9px', marginLeft: '6px'}}>

                        {suggestions.map(suggestion => {
                          const className = classnames('Demo__suggestion-item', {
                            'Demo__suggestion-item--active': suggestion.active,
                          });

                          return (
                            <List divided relaxed key={suggestion.id}>
                              <List.Item {...getSuggestionItemProps(suggestion, { className })} as='a'>
                                <List.Icon name='building outline' size='large' verticalAlign='middle' />
                                <List.Content>
                                  <List.Header>{suggestion.formattedSuggestion.mainText}</List.Header>
                                  <List.Description>
                                      {suggestion.formattedSuggestion.secondaryText}
                                  </List.Description>
                                </List.Content>
                              </List.Item>
                            </List>

                          );
                        })}
                      </Container>
                    )}
                  </div>
                );
              }}
            </PlacesAutocomplete>

            {
                this.props.waitingRequest ?
                <Loader style={{marginTop: '10px'}} active inline='centered' /> :
                null
            }

            <Button style={{marginTop: '30px'}} onClick={() => this.submitForm()} color='blue' fluid size='large'>Next</Button>

        </Container>
      </Segment>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(CompanySignup)
