import * as actionType from "../constants/actionTypes";

const initialState={
  waitingRequest:false,
  emailSignupSuccess: false,
  emailSignupError: null,
  saveMessage:'',
  email:null,
  userType: 'talent',
  errorMessage:null,
  verifyMessage:null,
  verifyFailedMessage:null,
  phoneVerifyMessage:{success: false, message: ''},
  phoneVerifyFailedMessage:null,
  codeVerifyMessage:{success: false, message: ''},
  codeVerifyFailedMessage:null,
  gender: null,
  date_of_birth:null,
  resendEmailSuccess: false,
  resendEmailError: null,
  forgotPassword: {
      success: false,
      error: false,
      message: '',
  },
  resetPassword: {
      success: false,
      error: false,
      message: '',
  },
}

export default (state=initialState,action) =>{
  switch (action.type){
    case actionType.WAITING_REQUEST:{
      const st={
        ...state,
        waitingRequest:action.waitingRequest
      }

      return st;
    }
    case actionType.SIGNUP_SUBMIT_FULFILLED:{
      const st={
        ...state,
        email:action.responseData.email,
        userType: action.responseData.type,
        emailSignupSuccess: true
      }

      return st;
    }
    case actionType.SIGNUP_SUBMIT_ERROR: {
      const st = {
        ...state,
        emailSignupError: action.errorMessage
      }
      return st;
    }
    case actionType.SIGNUP_VEFIFY_FULFILLED:{
      const st={
        ...state,
        verifyMessage:action.responseData
      }
      return st;
    }
    case actionType.SIGNUP_VEFIFY_FAILED:{
      const st={
        ...state,
        verifyFailedMessage:action.errorMessage
      }
      return st;
    }
    case actionType.PHONE_VERIFY_START_FULFILLED:{
      const st={
        ...state,
        phoneVerifyFailedMessage: null,
        phoneVerifyMessage:action.responseData
      }
      return st;
    }
    case actionType.PHONE_VERIFY_START_FAILED:{
      const { phoneVerifyMessage } = state;
      phoneVerifyMessage.message = '';
      const st={
        ...state,
        phoneVerifyMessage,
        phoneVerifyFailedMessage:action.errorMessage
      }
      return st;
    }
    case actionType.PHONE_VERIFY_CODE_FULFILLED:{
          const st={
              ...state,
              codeVerifyFailedMessage: null,
              codeVerifyMessage:action.responseData
          }
          return st;
      }
    case actionType.PHONE_VERIFY_CODE_FAILED:{
        const { codeVerifyMessage } = state;
        codeVerifyMessage.message = '';
          const st={
              ...state,
              codeVerifyMessage,
              codeVerifyFailedMessage:action.errorMessage
          }
          return st;
      }
      case actionType.RESEND_EMAIL_SUCCESS:{
          const st={
              ...state,
              resendEmailSuccess: true
          }
          return st;
      }
      case actionType.RESEND_EMAIL_ERROR:{
          const st={
              ...state,
              resendEmailError:action.errorMessage
          }
          return st;
      }

      case actionType.SEND_PASSWORD_RESET_LINK_SUCCESS:{
          const {forgotPassword} = state;
          forgotPassword.success = true;
          forgotPassword.error = false;
          forgotPassword.message = action.responseData;
          const st={
              ...state,
              forgotPassword
          }
          return st;
      }

      case actionType.SEND_PASSWORD_RESET_LINK_ERROR:{
          const {forgotPassword} = state;
          forgotPassword.success = false;
          forgotPassword.error = true;
          forgotPassword.message = action.errorMessage;
          const st={
              ...state,
              forgotPassword
          }
          return st;
      }

      case actionType.RESET_PASSWORD_SUCCESS:{
          const {resetPassword} = state;
          resetPassword.success = true;
          resetPassword.error = false;
          resetPassword.message = action.responseData;
          const st={
              ...state,
              resetPassword
          }
          return st;
      }

      case actionType.BACK_FROM_VARIFICATION:{
          const st={
              ...state,
              email:null,
              emailSignupSuccess:null,
          }
          return st;
      }

      case actionType.RESET_PASSWORD_ERROR:{
          const {resetPassword} = state;
          resetPassword.success = false;
          resetPassword.error = true;
          resetPassword.message = action.errorMessage;
          const st={
              ...state,
              resetPassword
          }
          return st;
      }

    default:
      return state;
  }
}