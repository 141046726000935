import {Search, Grid, Icon, Image, Label, Loader} from "semantic-ui-react";
import React from "react";
import { getUserName } from "../../utils/common";

const ChannelList = (props) => {
    const {
        messageGroups, oneToOneChats, loggedInUser, activeChannelName
    } = props;

    const chatGroups = messageGroups.map((group, index)=>{
      const channelSelected = (activeChannelName && activeChannelName === group.event.name) ? 'channel-selected' : '';
      return(
        <a href={'javascript:void(0)'} key={group.id}>
            <div key={index} className={`group-item ${channelSelected}`} onClick={() => props.createGroupChannel(group)}>
              <div className='ui grid'>
                <div className='left ten wide column'>
                  {/* <Image src={group.event.company.photo} className={'user-image'}/> */}
                  <span className={'group-name'}>{group.event.name}</span>
                </div>
                <div className='right floated right aligned six wide column'>
                  {(group.unreadMessageCount && group.unreadMessageCount>0) ?
                    <Label circular color={'red'}>
                      {group.unreadMessageCount}
                    </Label>
                    :
                    ''
                  }
                 {'  '}<Icon name={'group'} />
                </div>
              </div>
            </div>
        </a>
      )
    });

    const oneToOneGroups = oneToOneChats.map((group, index)=>{
      let participant = {};
      group.participants.map(user => {
          if (user.id !== loggedInUser.id){
              participant = user;
          }
      });

      const participantName = getUserName(participant);
      const channelSelected = (activeChannelName && activeChannelName===participantName) ? 'channel-selected' : '';
      return(
        <a href={'javascript:void(0)'} key={group.id} >
            <div key={index} className={`group-item ${channelSelected}`} onClick={() => props.createOneToOneChat(participant)}>
              <div className='ui grid'>
                <div className='left floated ten wide column'>
                  <Image src={participant.photo} className={'user-image'}/>
                  <span className={'group-name'}>{getUserName(participant)}</span>
                </div>
                <div className='right floated six wide column'>
                  {(group.unreadMessageCount && group.unreadMessageCount>0) ?
                    <Label circular color={'red'}>
                      {group.unreadMessageCount}
                    </Label>
                    :
                    ''
                  }
                </div>
              </div>
            </div>
        </a>
      )
    });

    if (props.isMsgGroupsSorted){
      return (
        <Grid.Column mobile={16} tablet={6} computer={4} className={'users-column'}>
          <div className="left-nav">
            <div className="top">
              <Search className={'search'} placeholder='Search...'/>
            </div>
            <div className='content'>
              {chatGroups}
              {oneToOneGroups}
            </div>
          </div>
        </Grid.Column>
      );
    } else{
      return (
        <Grid.Column mobile={16} tablet={6} computer={4} className={'users-column'}>
          <div className="left-nav">
            <div className="top">
              <Search className={'search'} placeholder='Search...'/>
            </div>
            <div className='content'>
              <Loader active inline='centered' style={{marginTop:'20px'}}>Loading...</Loader>
            </div>
          </div>
        </Grid.Column>
      );
    }
};

export default ChannelList;
