import React,{Component} from 'react';
import {
  Segment,
  Radio,
  List
} from 'semantic-ui-react';

class SocialSettings extends Component{
  render(){
    let list=[]
    if (this.props.accounts){
      list=this.props.accounts.map((item,index)=>{
        return(
          <List.Item key={index}>
            <List.Icon name={item.icon} size='large' verticalAlign='middle' />
            <List.Content>
              <List.Header as='a'><strong>{item.title}</strong></List.Header>
              <List.Description as='a'>
                {item.link}
              </List.Description>
              <List.Description>
                <Radio toggle/>
              </List.Description>
            </List.Content>
          </List.Item>
        )
      });
    }

    return(
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <span className={'panel-header'}>
            SOCIAL
          </span>
        </Segment>
        <Segment textAlign={'left'}>
          Coming soon..
          <List divided relaxed='very'>
            {list}
          </List>
        </Segment>
      </Segment.Group>
    )
  }
}

export default SocialSettings
