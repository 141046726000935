import axios from 'axios';
import {API_URL_BASE} from '../constants/constants';


import {
  WAITING_REQUEST,
  GET_SETTINGS_FULFILLED,
  GET_SETTINGS_FAILED,
  GET_SETTINGS_FULFILLED_JSON,
  GET_SETTINGS_FAILED_JSON,
  ADD_USER_FULFILLED,
  ADD_USER_FAILED,
  REMOVE_USER_FULFILLED,
  REMOVE_USER_FAILED,
  GET_SOCIAL_PROFILE_FULFILLED,
  GET_SOCIAL_PROFILE_FAILED,
  ADD_SOCIAL_PROFILE_SUCCESS,
  ADD_SOCIAL_PROFILE_ERROR,
  DELETE_SOCIAL_PROFILE_SUCCESS,
  DELETE_SOCIAL_PROFILE_ERROR,
} from '../constants/actionTypes';
import * as actionTypes from '../constants/actionTypes';
import {common} from '../utils/common';

export const userSettings = (data) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_REQUEST, value:true});
    axios.put(`${API_URL_BASE}/notification/settings/update`, data)
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch,GET_SETTINGS_FULFILLED_JSON);
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,GET_SETTINGS_FAILED_JSON);
      })
  }
}


export const getCompanyUsersData = () => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_REQUEST, value:true});

    axios.get(`${API_URL_BASE}/company/profile`)
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch,GET_SETTINGS_FULFILLED);
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,GET_SETTINGS_FAILED);
      })
  }
}


export const addUser =(data)=>{
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_REQUEST, value:true});
    axios.post(`${API_URL_BASE}/company/contacts/invite`,data)
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch,ADD_USER_FULFILLED);
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,ADD_USER_FAILED);
      })
  }
}


export const removeUser =(data)=>{
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_REQUEST, value:true});
    const {id} = data;
    delete data.id;
    
    axios.put(`${API_URL_BASE}/user/remove-user/${id}`,data)
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch,REMOVE_USER_FULFILLED);
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,REMOVE_USER_FAILED);
      })
  }
}

export const syncGoogleCalendar =(data)=>{
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_REQUEST, value:true});
    axios.post(`${API_URL_BASE}/event/sync-google-calendar-events`, data)
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch,actionTypes.SYNC_GOOGLE_CALENDAR_SUCCESS);
        dispatch({type: actionTypes.UPDATE_USER, responseData: {is_google_calendar_synced: true}})
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,actionTypes.SYNC_GOOGLE_CALENDAR_ERROR);
      })
  }
};

export const disconnectGoogleCalendar =()=>{
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_REQUEST, value:true});
    axios.post(`${API_URL_BASE}/event/disconnect-google-calendar-events`)
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch,actionTypes.DISCONNECT_GOOGLE_CALENDAR_SUCCESS);
        dispatch({type: actionTypes.UPDATE_USER, responseData: {is_google_calendar_synced: false}})
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,actionTypes.DISCONNECT_GOOGLE_CALENDAR_ERROR);
      })
  }
};

export const addSocialProfile =(data, user_id)=>{
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_REQUEST, value:true});
    axios.post(`${API_URL_BASE}/user/add-social-profile`,data)
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch,actionTypes.ADD_SOCIAL_PROFILE_SUCCESS);
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,actionTypes.ADD_SOCIAL_PROFILE_ERROR);
      })
  }
};

export const getSocialProfile = (id) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_REQUEST, value:true});

    axios.get(`${API_URL_BASE}/user/social-profile/${id}`)
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch,GET_SOCIAL_PROFILE_FULFILLED);
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,GET_SOCIAL_PROFILE_FAILED);
      })
  }
}

export const deleteSocialProfile = (id,user_id) => {
  const commonObj=new common();

  return (dispatch)=>{

    axios.delete(`${API_URL_BASE}/user/delete-social-profile/${id}`)
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch, DELETE_SOCIAL_PROFILE_SUCCESS);
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch, DELETE_SOCIAL_PROFILE_ERROR);
      });
  }
}
