import {
  GET_PROFILE_TALENT_FULFILLED,
  GET_PROFILE_TALENT_FAILED
} from "../constants/actionTypes";

const initialState={
  data:{
    name:'',
    first_name:'',
    last_name:'',
    gender:'',
    date_of_birth:Date.now(),
    overview:'',
    rating:0,
    statistics:[],
    profileRank:0,
    about:'',
    bookingInfo:[]}
}

export default (state=initialState,action)=>{
  switch (action.type){
    case GET_PROFILE_TALENT_FULFILLED:{
      const st={
        ...state,
        data:action.responseData
      }

      return st;
    }
    case GET_PROFILE_TALENT_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }
    default:
      return state;
  }
}