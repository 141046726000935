import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Segment, Card, Header, Icon, Container, Form, Loader, Button, Modal} from 'semantic-ui-react';
import {updateTalentProfileUser} from "../../../actions/talent";
import ServerErrors from '../../Common/ServerErrors';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import '../Signup/signup.css';
import {RESET_TALENT} from "../../../constants/actionTypes";


const dispatchToProps=(dispatch)=>{
  return{
    updateTalentProfileUser: (data) => dispatch(updateTalentProfileUser(data)),
    resetTalentReducer: (data) => dispatch({ type: RESET_TALENT, payload: data }),
  }
};

const stateToProps=(state)=>{
    return {
      talent: state.talent,
      user: state.user.profile,
      waitingRequest: state.common.waitingRequest,
    };
};

class SignupPhoto extends Component {

  constructor(props){
    super(props);
    this.state = {
      crop: {
        aspect: 1,
        width: 90,
        height: 90,
        x: 0,
        y: 0,
      },
      image: null,
      croppedImage: null,
      isModalOpen: false,
      formSubmitted: false
    };

  }

  componentDidMount(){
      this.props.resetTalentReducer({
          error: null,
          success: null
      });
  }

  cancelPhoto = () => {
    this.setState({
        isModalOpen: false,
        croppedImage: null
    });
  };

  uploadImage = () => {
      const {croppedImage} = this.state;
      const {id, email} = this.props.user;
      let data = new FormData();
      data.append('id', id);
      data.append('email', email);
      data.append('photo', croppedImage);
      this.props.updateTalentProfileUser(data);
      this.setState({
          image: null,
          isModalOpen: false,
          croppedImage: null,
          formSubmitted: true
      });
  };

  renderServerErrors = () => {
      const {error} = this.props.talent;
      const {formSubmitted} = this.state;
      if (error && formSubmitted){
        return <ServerErrors errorMessage={error} />
      }
  };

  renderServerSuccess = () => {
      const {photo} = this.props.user;
      const {formSubmitted} = this.state;
      if (photo && formSubmitted){
        this.props.skip();
      }
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({
            image: reader.result,
            isModalOpen: true
        }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;
    this.makeClientCrop(this.state.crop, pixelCrop);
  };

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  async makeClientCrop(crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, pixelCrop);
      this.setState({
          croppedImage: croppedImageUrl
      });
    }
  }

  getCroppedImg(image, pixelCrop) {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    return canvas.toDataURL();
  }

  render() {
    const { crop, image, isModalOpen } = this.state;
      return(
        <Segment attached raised style={{paddingTop:'40px',paddingBottom:'40px'}}>
          <Header size={'large'}>Pick your best head shot</Header>
          <Container textAlign='center' className={'signup-container'}>
              {this.renderServerErrors()}
              {this.renderServerSuccess()}
            <Form size='large'>
              <Card.Content extra>
                <Modal open={isModalOpen}>
                  <Modal.Content>
                  </Modal.Content>
                  <div>
                    {image && (
                      <ReactCrop
                        src={image}
                        crop={crop}
                        keepSelection={true}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                      />
                    )}
                  </div>
                  <Modal.Actions>
                    <Button color='red' onClick={() => this.cancelPhoto()}>
                      <Icon name='remove' /> Cancel
                    </Button>
                    <Button color='green' onClick={() => this.uploadImage()}>
                      <Icon name='checkmark' /> Crop & Save Image
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Card.Content>
              <br/>
              <label className={'ui icon button blue upload-button'}>
                <Icon name='upload' /> &nbsp;Upload
                <input type="file" style={{display:'none'}} onChange={this.onSelectFile} onClick={(event) => { event.target.value = null; }}/>
              </label>
              <br/><br/>
              <a href="" onClick={this.props.skip}>Skip</a>
              {this.props.waitingRequest &&  <Loader active inline='centered' />}
            </Form>

          </Container>
        </Segment>
      )
  }
}

export default connect(stateToProps,dispatchToProps)(SignupPhoto)
