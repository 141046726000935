import React, { Component } from 'react';
import {connect} from 'react-redux';
import {
  Segment,
  Header,
  Button,
  List,
  Loader,
  Form,
  Container} from 'semantic-ui-react';
import {classnames} from "../../Events/CreateEvent/classnames";

import {
  SIGNUP_NEXT
} from "../../../constants/actionTypes";
import { addWorkCity, removeWorkCity } from "../../../actions/talent";
import ServerErrors from '../../Common/ServerErrors';
import './style.css';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

const dispatchToProps=(dispatch)=>{
  return{
    gotoNext:()=>dispatch({type:SIGNUP_NEXT,value:'photo'}),
    addWorkCity: (data) => dispatch(addWorkCity(data)),
    removeWorkCity: (cityId) => dispatch(removeWorkCity(cityId))
  }
}

const stateToProps=(state)=>{
    return { ...state.talent, talent: state.talent, waitingRequest: state.common.waitingRequest };
}

class SignupArea extends Component {
  constructor(props){
    super(props);
    this.renderServerErrors = this.renderServerErrors.bind(this);
    this.submitForm=this.submitForm.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onSelectCity = this.onSelectCity.bind(this);
    this.state = { city: '' };
  }

  onChangeCity(city) { this.setState({city}) };

  onSelectCity(city) {
      this.setState({city: ''});
      geocodeByAddress(city)
          .then(results => {
              const workCity = {};
              const addresses = results[0]['address_components'];
              workCity.name = addresses.length === 2 ? city.split(',')[0] : addresses[0]['long_name'];
              workCity.is_primary = false;
              for(let address of addresses){
                  if (address.types.includes('country')){
                      workCity.country = address['long_name'];
                  }

                  if (address.types.includes('administrative_area_level_1')){
                      workCity.state = address['long_name'];
                  }
              }

              getLatLng(results[0]).then((results) => {
                  workCity.lat = results.lat;
                  workCity.lng = results.lng;
                  this.props.addWorkCity(workCity);
              })
          })
  };

  removeCity(cityId){
      this.props.removeWorkCity(cityId);
  }

  submitForm(event){
    this.props.gotoNext();
  }

  renderServerErrors(){
        if (this.props.error){
            return <ServerErrors errorMessage={this.props.error} />
        }
    }

  render() {

      const searchOptions = {
          types: ['(cities)'],
          componentRestrictions: {country: "us"}
      };
      const styles = {
          root: { 'zIndex': '1', 'marginBottom': '20px', textAlign: 'left' }
      }
    return(
      <Segment attached raised style={{paddingTop:'40px',paddingBottom:'40px'}}>
        <Header size={'large'}>Which cities can you work?</Header>
        <Container style={{marginTop: '32px'}}>
        <PlacesAutocomplete
            value={this.state.city}
            onChange={this.onChangeCity}
            onSelect={this.onSelectCity}
            searchOptions={searchOptions}
            styles={styles}>
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div className="Demo__search-bar-container" style={{'width':'100%', 'paddingBottom': '20px', 'textAlign':'left'}}>

                  <Form.Input
                      fluid
                      icon='location arrow'
                      iconPosition='left'
                      name='address'
                      value={this.state.city}
                      fieldtype='text'
                      onChange={this.onChangeCity}
                      style={{marginBottom:'25px'}}
                      required={true}
                      style={{ 'height': '40px', 'width': '100%'}}
                      {...getInputProps({
                        placeholder: 'Add your address',
                        className: 'Demo__search-input',
                      })}
                  />



                {suggestions.length > 0 && (
                  <Container style={{ marginTop: '9px', marginLeft: '6px'}}>

                    {suggestions.map(suggestion => {
                      const className = classnames('Demo__suggestion-item', {
                        'Demo__suggestion-item--active': suggestion.active,
                      });

                      return (
                        /* eslint-disable react/jsx-key */
                        <List divided relaxed>
                          <List.Item {...getSuggestionItemProps(suggestion, { className })} as='a'>
                            <List.Icon name='building outline' size='large' verticalAlign='middle' />
                            <List.Content>
                              <List.Header>{suggestion.formattedSuggestion.mainText}</List.Header>
                              <List.Description>
                                  {suggestion.formattedSuggestion.secondaryText}
                              </List.Description>
                            </List.Content>
                          </List.Item>
                        </List>

                      );
                      /* eslint-enable react/jsx-key */
                    })}
                  </Container>
                )}
              </div>
            );
          }}
        </PlacesAutocomplete>


          {
              this.props.waitingRequest ?
              <Loader style={{marginTop: '10px'}} active inline='centered' /> :
              null
          }
          {this.renderServerErrors()}

          <List as='ol'>
              {this.props.talent.profile.work_cities.map((city, index) => {
                  return (
                      <List.Item key={index} style={{marginTop: '10px'}} as='li'>
                          {city.name}, {city.state}
                          <Button type={'button'}  onClick={this.removeCity.bind(this, city.id)} negative size='mini' style={{float: 'right', padding: '10px'}}>Remove</Button>
                      </List.Item>
                  );
              })}
          </List>


          <Button type={'submit'} style={{marginTop: '50px'}} onClick={this.props.gotoNext} color='blue' fluid size='large'>Next</Button>

        </Container>
      </Segment>
    )
  }
}

export default connect(stateToProps,dispatchToProps)(SignupArea)
