import React, { Component } from "react";
import { Container, Segment } from "semantic-ui-react";

export default class Terms extends Component {
  render() {
    return (
      <div>
        <Container
          textAlign="left"
          className={"main-container signup-container"}
        >
          <Segment>
            <h2>Terms & Conditions</h2>{" "}
            <p>
              This web page represents our Terms of Use ("Agreement") regarding
              our website, located at http://www.shiftdash.com, and the tools we
              provide you as well as other services we make available on
              third-party sites, applications, or platforms (the "Website" or
              the "Service"). The terms, "we" and "our" as used in this
              Agreement refer to Shift Dash.{" "}
            </p>{" "}
            <p>
              We may amend this Agreement at any time by posting the amended
              terms on our Website. We may or may not post notices on the
              homepage of our Website when such changes occur. You explicitly
              and implicitly agree to be bound by our Legal Terms each time you
              access our Website. If you do not wish to be so bound, please do
              not use or access our Website.{" "}
            </p>{" "}
            <p>
              By clicking on “I Agree” or by opening, accessing, browsing, or
              otherwise using the Services, you agree to be bound by these Terms
              of Use and all of the related policies or guidelines incorporated
              by reference below, including any subsequent changes or
              modifications to any of them.{" "}
            </p>{" "}
            <p>
              If you are using the Services on behalf of a company or other
              legal entity, you agree to be individually bound by these Terms of
              Use regardless of any agreement your company may have with us and
              you represent and warrant that you have the authority to bind that
              entity or agency to the Agreement and agree that you are binding
              both you and that entity or agency to the Agreement. If you do not
              agree or object, do not open, access, browse, or otherwise
              continue to use the Services. In that event, “you” and “your” will
              refer and apply to you and that entity or agency.{" "}
            </p>{" "}
            <p>
              PLEASE READ THE TERMS OF USE, OUR{" "}
              <a href="/privacy">PRIVACY POLICY</a>, AND OUR OTHER TERMS AND
              CONDITIONS VERY CAREFULLY BEFORE USING THE SERVICE AS THEY CONTAIN
              IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES,
              LIABILITIES, AND OBLIGATIONS. ANY DISPUTE BETWEEN YOU AND US MUST
              BE RESOLVED BY INDIVIDUAL BINDING ARBITRATION. PLEASE READ THE
              ARBITRATION PROVISION IN THESE TERMS OF USE AS IT AFFECTS YOUR
              RIGHTS UNDER THIS CONTRACT. NOTHING IN THESE TERMS OF USE IS
              INTENDED TO AFFECT YOUR RIGHTS UNDER THE LAW IN YOUR USUAL PLACE
              OF RESIDENCE. IF THERE IS A CONFLICT BETWEEN THOSE RIGHTS AND
              THESE TERMS OF USE, YOUR RIGHTS UNDER APPLICABLE LOCAL LAW WILL
              PREVAIL.
            </p>
            <p>
              Therefore, in consideration of the mutual covenants of the Parties
              and other valuable consideration, the sufficiency and receipt of
              which is hereby acknowledged, the Parties agree as follows:
            </p>
            <ol>
              <li font-weight="400">
                <strong>Definitions</strong>. Terms in the preamble have their
                assigned meanings and each of the following terms has the
                meaning assigned to it.
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>"Agreement"</strong> or means this User Agreement,
                  which may be amended at any time and without notice by Shift
                  Dash.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>&ldquo;Booked and Paid Event&rdquo;</strong> means an
                  Event that a User has paid for and remitted payment through
                  the Site.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  "<strong>Brand</strong>" means any talent agency or commercial
                  brand organization that uses the Services to search for and
                  hire Talent.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>"Effective Date"</strong> means the date User
                  registers for a Brand or Talent Account with Shift Dash.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  "<strong>Event</strong>" means an engagement where a Brand
                  hires one or more Talents or where an Agency coordinates the
                  hire of Talents for an engagement.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  &ldquo;<strong>Payment Method</strong>&rdquo; has the meaning
                  give to it in Section 13.1 of this Agreement.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  "<strong>Payment Processor</strong>" for the purposes of this
                  Agreement refers to Stripe Connect.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  "<strong>Payment Service</strong>" refers to all credit or
                  debit card processing services, including charging, refunding,
                  reversing, and adjusting transactions as well as all payouts
                  and disbursements provided by Shift Dash and its Payment
                  Processors.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  "<strong>Platform</strong>" refers collectively to Shift Dash
                  Application, Shift Dash Website, Shift Dash Website, any links
                  contained within or otherwise available through external
                  hyperlinks within Shift Dash Application, Shift Dash Website,
                  Shift Dash Website.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>&ldquo;Services Fee&rdquo;</strong> means any fee
                  changed by Shift Dash for the use of the Services (e.g. fees
                  on Booked and Paid Events).
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  "<strong>Shift Dash </strong>" means Shift Dash Inc., its
                  subsidiaries, affiliates, associates, and branches.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>"Shift Dash Application"</strong> means mobile
                  application providing access to Shift Dash Services.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  &ldquo;<strong>Shift Dash IP</strong>&rdquo; has the meaning
                  give to it in Section 18.2(d) of this Agreement.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  "<strong>Shift Dash Services</strong>"{" "}
                  <strong>or &ldquo;Services&rdquo;</strong> means comprehensive
                  marketplace owned by Shift Dash Inc., available through Shift
                  Dash Website and Shift Dash Application.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  "
                  <strong>
                    Shift Dash Website" or &ldquo;Website&rdquo; or
                    &ldquo;Site&rdquo;
                  </strong>{" "}
                  means internet website www.Shift Dash.com providing access to
                  Shift Dash Services.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  "<strong>Shift Dash Inc.</strong>" or{" "}
                  <strong>"Shift Dash"</strong> means the legal entity being
                  sole owner of Shift Dash Website, Platform and Shift Dash
                  Application, doing business as &ldquo;Shift Dash&rdquo;.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  "<strong>Services</strong>" mean services provided by Shift
                  Dash to Users through the Shift Dash Site.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>&ldquo;Talent&rdquo;</strong> means any individual who
                  will use the Services to search for and find employment at
                  Events.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  &ldquo;<strong>Taxes</strong>&rdquo; has the meaning give to
                  it in Section 7.1 of this Agreement.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>"User"</strong> means users of the Services,
                  specifically all Talent and Brands.
                </li>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Purpose and Use of Shift Dash Services </strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Description of Services</strong>. Shift Dash provides
                  a web and app-based platform for Brands and Agencies
                  (collectively &ldquo;Brands&rdquo;) to identify and hire
                  individuals currently seeking employment
                  (&ldquo;Talent&rdquo;) (collectively &ldquo;Users&rdquo;). The
                  Site and Services also provide hour-tracking and payment
                  functions for all Users.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Event Contracts</strong>. Shift Dash merely provides
                  the Services for Brands and Talent to engage by posting and
                  advertising events (&ldquo;Events&rdquo;), communicating
                  regarding Events, hiring Talent, and compensating Talent for
                  services. Shift Dash does not introduce Talent and Brands.
                  Shift Dash is not involved in the actual Event and does not
                  and is not authorized to act in any way as agent of either
                  Party. Through the Site and Services, Brands may be notified
                  that a Talent may be interested in the Event they offer, and
                  Talent may be notified of Brands may offer the Events of the
                  type they seek; at all times, however, Users are responsible
                  for evaluating and determining the suitability of any Event,
                  Talent, or Brand on their own. If a User decides to enter into
                  an agreement to participate in a transaction (an &ldquo;Event
                  Contract&rdquo;), the Event Contract is directly between the
                  Event Parties. Shift Dash is not a party to any Event
                  Contract.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Deactivation from Shift Dash Site.</strong> A Brand
                  can, at any time, deactivate the marketing of their Services
                  in the Shift Dash Site via the Brand&rsquo;s account settings.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Termination.</strong> Shift Dash may terminate the
                  publication or promotion of the Brand Services at any time as
                  established in Section 8 of this Agreement.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Liability.</strong> Shift Dash neither represents the
                  Brand or the Talent in any Event, nor does it control &mdash;
                  and is not liable to or responsible for &mdash; the quality,
                  safety, lawfulness, or availability of the Events offered on
                  the Site or the ability or inability of the Brands or Talent
                  to complete an Event Contract for any reason. Each Brand and
                  Talent acknowledges and agrees that it is fully assuming the
                  risks of conducting any Event Contract through the Shift Dash
                  Site, and that it is fully assuming the risks of liability or
                  harm of any kind in connection with subsequent activity of any
                  kind relating to Services that are the subject of transactions
                  booked through Shift Dash Site. Each Brand agrees that Shift
                  Dash shall not be liable or responsible for any damages,
                  claims, liabilities, costs, harms, inconveniences, business
                  disruptions or expenditures of any kind that may arise a
                  result of or in connection with any of the foregoing risks.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>No Employer Employee Relationship. </strong>All Talent
                  understand, acknowledge, and agree that Talent&rsquo;s
                  relationship with the Shift Dash will be that of an
                  &ldquo;independent contractor&rdquo; and not that of an
                  employee. Talent will be an &ldquo;independent
                  contractor&rdquo; and Talent will be entitled to work at such
                  times and places as Talent determines appropriate, will not be
                  under the direction or control of Shift Dash nor will the
                  manner in which Talent performs at Events.
                </li>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Account Registration </strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Registration</strong>. To access and use certain
                  portions of the Site and the Site Services, you must apply for
                  and be granted a User account (an &ldquo;Account&rdquo;).
                  Subject to this Agreement and applicable Terms, by using the
                  Site after the Effective Date if you had an Account on the
                  Effective Date, or by clicking to accept the Terms of Service
                  when prompted on the Site, you agree to abide by this
                  Agreement and the other Terms of Service. Shift Dash reserves
                  the right to decline a registration to join the Site or to add
                  an Account for any lawful reason, including supply and demand,
                  cost to maintain data, or other business considerations. If
                  you create an Account as an employee or agent on behalf of a
                  company, you represent and warrant that you are authorized to
                  enter into binding contracts on behalf of yourself and the
                  company.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>General Account Eligibility</strong>. Shift Dash
                  offers accounts to Brands to post and advertise events,
                  interview, hire, and compensate Talent (&ldquo;Brand
                  Account&rdquo;) and to Talent to find Events, submit time
                  worked, and collect compensation for services (&ldquo;Talent
                  Account&rdquo;). To register for an Account or use the Site
                  and Site Services, you must, and hereby represent that (a) all
                  required registration information you submit is truthful and
                  accurate; and (b) you are a legal entity or an individual 18
                  years or older who can form legally binding contracts.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Brand Account Eligibility</strong>. Shift Dash offers
                  the Site and Site Services to Brands for your business
                  purposes only and not for personal, household, or consumer
                  use. To register for a Brand Account, you must, and hereby
                  represent that you (a) have or are an employee or agent of and
                  authorized to act for an independent business (whether it be
                  as a self-employed individual/sole proprietor or as a
                  corporation, limited liability company, or other entity); (b)
                  will use the Site and Site Services for business purposes
                  only; and (c) will comply with any licensing, registration, or
                  other requirements with respect to your business, or the
                  business for which you are acting, and the provision of Brand
                  Services.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Account Registration</strong>. To register for an
                  Account to join the Site, you must complete a User profile
                  (&ldquo;Profile&rdquo;), which you consent to be shown to
                  other Users and, unless you change your privacy settings, the
                  public. You agree to provide true, accurate, and complete
                  information on your Profile and all registration and other
                  forms you access on the Site or provide to us and to update
                  your information to maintain its truthfulness, accuracy, and
                  completeness.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Username and Password</strong>. When you register for
                  an Account, you will be asked to choose a username and
                  password for the Account. You are entirely responsible for
                  safeguarding and maintaining the confidentiality of your
                  username and password and agree not to share your username or
                  password with any person who is not authorized to use your
                  Account. You authorize Shift Dash to assume that any person
                  using the Site with your username and password, either is you
                  or is authorized to act for you. You agree to notify us
                  immediately if you suspect or become aware of any unauthorized
                  use of your Account or any unauthorized access to the password
                  for any Account. You further agree not to use the Account or
                  log in with the username and password of another User of the
                  Site if (a) you are not authorized to use either or (b) the
                  use would violate the Terms of Service.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Required Communications. </strong>Shift Dash requires
                  Brands to provide timely communication to Talent hired using
                  the Site. Brands must respond to all Talent communications
                  within forty-eight (48) hours of receiving the communication.
                  Shift Dash maintains the right to void an order and refund a
                  Talent and/or may terminate Brand&rsquo;s Account if Brand
                  does not respond to a Talent within 48-hours.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Cancellation</strong>. Talent may cancel any
                  engagement where they are booked by providing notice no less
                  than 24-hours before the scheduled event. Failure to provide
                  notice within 24-hours will result in Talent being charged a
                  cancellation fee. We reserve the right to cancel bookings at
                  any time for any reason. No credits or promotional value
                  adjustments will be made in the event we cancel an engagement.
                  If we cancel or terminate a paid service for any reason, we
                  may, in our sole discretion, issue you a refund of any fees
                  prepaid for such service.
                </li>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Payment Services</strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  Shift Dash provides Payment Services through Stripe Connect
                  (&ldquo;Payment Processor&rdquo;) that allows Talent to accept
                  credit and debit card payments from Brands and receive bank
                  account transfer deposits for Events. A Shift Dash Account is
                  required to use Payment Services. Other identifying
                  information as outlined in this Agreement may be needed to use
                  Shift Dash Payment Services.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Payment Processers</strong>. Through the Payment
                  Processer, Shift Dash provides Brands with the following
                  services: collecting card-based payments from Brands, issuing
                  refunds, reversals, adjustments, and chargebacks, electronic
                  disbursements to your Reserve Account (if required by the
                  Payment Processor), withholding any applicable Shift Dash
                  fees, verification, and reporting.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Authorization</strong>. Shift Dash, as outlined in
                  this Agreement, is authorized to directly manage your Account
                  and Account Activity with our Payment Processor to provide
                  Payment Services in accordance with the Terms of the Payment
                  Processor. NEITHER SHIFT DASH NOR THE PAYMENT PROCESSORS ARE A
                  BANK OR A MONEY SERVICE BUSINESS (&ldquo;MSB&rdquo;), NOR DOES
                  EITHER PARTY OFFER BANKING OR MSB SERVICES. In addition, we do
                  not assume any liability for the services using our Payment
                  Service.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Availability</strong>. Shift Dash Payment Services are
                  available wherever Stripe Connect is available.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Terms</strong>. By using the Shift Dash Payment
                  Services, Brand agrees to the terms of this Agreement and the
                  Payment Provider&rsquo;s terms. Brand must provide the Payment
                  Provider with any information and documents required to setup
                  an account.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  At any time, Shift Dash or Payment Processor may determine
                  that you are incurring excessive Chargebacks (five or more
                  Chargebacks within 30 calendar days). Excessive Chargebacks
                  may result in additional fees, penalties, or fines. Excessive
                  Chargebacks may also result in additional controls and
                  restrictions to your use of the Payment Services, including
                  without limitation:
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    changes to the terms of your Reserve Account;
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    increases to your applicable Service Fees;
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">delays in your Payout Schedule; or</li>
                </ol>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    possible suspension or termination of your account and
                    services provided by Shift Dash. The Payment Networks may
                    also place additional controls or restrictions as part of
                    their own monitoring programs for merchants with excessive
                    Chargebacks.
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Authorization for Handling of Funds</strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Authorization</strong>. By accepting this Agreement
                  and using Shift Dash Payment Services, you authorize Shift
                  Dash to receive, deposit, hold, and disburse funds on your
                  behalf for all credit card transactions processed via Shift
                  Dash and the Payment Services, including transactions for
                  appointment bookings, products, vouchers, and packages. You
                  also authorize Shift Dash to hold funds in a Reserve Account
                  pending disbursement to you in accordance with the terms of
                  this Agreement. Furthermore, you authorize Shift Dash to
                  debit, withdraw, and reverse disbursements as defined below.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Payment Service Rules</strong>. You agree to submit
                  any and all refunds and adjustments for returns of your
                  Products and services through Shift Dash Payment Services to
                  the Consumer&rsquo;s card in accordance with the terms of this
                  Agreement and Payment Service Rules. Payment Service Rules
                  require that you will:
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    maintain a fair return, cancellation or adjustment policy;
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    disclose your return or cancellation policy to customers at
                    the time of purchase;
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    not give cash refunds to a consumer in connection with a
                    card sale, unless required by law; and
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    not accept cash or any other item of value for preparing a
                    card transaction refund.
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Exclusions</strong>. Payment Service Fees and Site
                  Fees are non-refundable on Booked and Paid Events. Refunds for
                  Booked and Paid Events made through the Site using the Payment
                  Services will not include any Payment Service Fees and any
                  Site Fees.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Exact Amount</strong>. Full refunds must be for the
                  exact amount of the original transaction including tax and
                  other charges. Please note that for Booked and Paid Events,
                  full refunds will not include Payment Services Fees or Site
                  Fees. The refund amount may not exceed the original sale
                  amount, except by an amount equal to any reimbursements to
                  User for postage costs incurred for product returns. Refunds
                  processed through Shift Dash Payment Services must be
                  submitted within sixty (60) days of the original transaction
                  and in all cases, within three (3) days of approving the
                  Consumer&rsquo;s refund.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Rights</strong>. Shift Dash reserves the right to
                  disapprove and cancel any refund request submitted for
                  processing through the Payment Services if your Reserve
                  Account balance is zero or negative, if you otherwise have any
                  outstanding balance owed to Shift Dash, for any reason
                  outlined in this paragraph, or at Shift Dash&rsquo;s
                  reasonable judgement on security breach or fraud risks or
                  other concerns.
                </li>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Payments and Payouts.</strong> Brands will prepay all
                Event fees as established in the Event proposal as posted to the
                Shift Dash Site. The event proposal shall include the hours and
                rate proposed for the Event.
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Timesheets Approval.</strong> Talent will complete a
                  timesheet for each Event. Brands will have forty-eight (48)
                  hours (the &ldquo;Time Approval Window&rdquo;) to approve or
                  dispute the timesheet before Event fees will be distributed to
                  the appropriate Talent&rsquo;s Payment Services Account.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Differential.</strong> If Talent reports working
                  additional hours, Brands must approve or dispute the adjusted
                  timesheets during the Time Approval Window and pay the fee
                  adjustment amount. If the Brand does not make this adjustment
                  in the Time Approval Window, Shift Dash will extract the fee
                  adjustment amount from the Brand&rsquo;s Payment Services
                  Account. If there is no such balance available, Shift Dash
                  will charge the Brand for the difference in payment amount.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Payouts</strong>. Brands will receive payouts to the
                  designated Payment Services Account and bank account in the
                  amount of the transaction charged less the Shift Dash Service
                  Fee, Payment Service Fee and any other Shift Dash fee, or
                  other withholding as described herein. The payouts will be
                  made to the bank account you provide in your Shift Dash
                  registration. The actual timing of the transfers to your bank
                  account of the settling funds will be subject to the payout
                  schedule as defined in section 16.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Payout Schedule</strong>. Under this agreement, Shift
                  Dash disburses funds that are available and payable to Talent
                  through Talent&rsquo;s Payment Processor Account upon
                  Talent&rsquo;s request. Talent can request disbursement of
                  available funds any time on a one-time basis.&nbsp;
                </li>
              </ol>
            </ol>
            <p>
              <br />
              <br />
            </p>
            <ol>
              <li font-weight="400">
                <strong>Event History and Reporting</strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Taxes and Reporting.</strong> It is your
                  responsibility to determine what, if any, taxes apply to your
                  Events or any payments processed through Shift Dash or other
                  methods ("Taxes"). While Shift Dash may provide reporting and
                  other tools in an effort to assist you calculate Taxes, Shift
                  Dash makes no guarantees as to the accuracy or effectiveness
                  of these tools or your use of them. It is solely your
                  responsibility to assess, collect, report, or remit the
                  correct tax to the proper tax authority. We are not obligated
                  to, nor will we determine whether Taxes apply, or calculate,
                  collect, report, or remit any Taxes to any tax authority
                  arising from any transaction. You acknowledge that Shift Dash
                  and our Payment Processor may make certain reports to tax
                  authorities regarding transactions processed through Shift
                  Dash Payment Services and our Payment Processor.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Issuing Form 1099-K.</strong> Where applicable, Shift
                  Dash will issue a form 1099-MISC to the IRS as required by law
                  for any Talent that receives both $600.00 or more in income
                  from Events in a calendar year. Talent are entitled to a copy
                  of their 1099-K to be available online at our website, or via
                  email at Shift Dash&rsquo;s discretion. Forms and notices such
                  as the 1099-MISC shall be considered to be received by you
                  within twenty-four (24) hours of the time it is posted to our
                  website, emailed, or mailed to you unless we are informed that
                  the notice was not received.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Transaction Records.</strong> Except as required by
                  law, you are solely responsible for compiling and retaining
                  permanent records of all transactions and other data
                  associated with your Account, your transaction history, and
                  your use of the Payment Services. Shift Dash may provide
                  dashboards, reports, and breakdowns of your transactions and
                  payouts through Shift Dash Site, and may maintain records for
                  the previous eighteen (18) months, subject to change without
                  notice.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Reconciliations and Discrepancies.</strong> You agree
                  to notify us of any discrepancies arising from any
                  reconciliations or audits. We will investigate any reported
                  discrepancies and attempt to rectify any errors that you or we
                  discover. In the event you are owed money as a result of the
                  discrepancy, we will remit funds through applicable Payment
                  Services within your regular Payout Schedule. Your failure to
                  notify us of an error or discrepancy within sixty (60) days of
                  when it first appears in your Shift Dash account (e.g., in any
                  dashboards or reporting provided to you) will be deemed a
                  waiver of any right to amounts owed to you in connection with
                  any such error or discrepancy in processing your card
                  payments.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Transaction Errors.</strong> If you submit or cause us
                  to process transactions erroneously, you agree to contact us
                  immediately. We will investigate any reported errors and
                  attempt to rectify any errors that you or we discover by
                  crediting or debiting your account as appropriate. Shift Dash
                  may only correct transactions that you process incorrectly if
                  and when you notify us of such an error, unless we are
                  otherwise made aware of it. Your failure to notify us of any
                  error within thirty (30) days of when it first appears on your
                  electronic transaction history will be deemed a waiver of any
                  right to amounts owed to you.
                </li>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Termination </strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  Unless both you and Shift Dash expressly agree otherwise in
                  writing, either of us may terminate this Agreement in our sole
                  discretion, at any time, without explanation, upon written
                  notice to the other, which will result in the termination of
                  the other Terms of Service as well, except as otherwise
                  provided herein. In the event you properly terminate this
                  Agreement, your right to use the Site is automatically
                  revoked, and your Account will be closed. Shift Dash is not a
                  party to any Event Contract between Users. Consequently, you
                  understand and acknowledge that termination of this Agreement
                  (or attempt to terminate this Agreement) does not terminate or
                  otherwise impact Event Contract entered into between Users. If
                  you attempt to terminate this Agreement while having one or
                  more open Events, you agree: (a) you hereby instruct Shift
                  Dash to close any open contracts; (b) you will continue to be
                  bound by this Agreement and the other Terms of Service until
                  all such Events have closed on the Site; (c) Shift Dash will
                  continue to perform those Site Services necessary to complete
                  any open Event between you and another User.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  Without limiting Shift Dash&rsquo;s other rights or remedies,
                  we may, but are not obligated to, temporarily or indefinitely
                  revoke access to the Site or Site Services, deny your
                  registration, or permanently revoke your access to the Site
                  and refuse to provide any or all Site Services to you if: (i)
                  you breach the letter or spirit of any terms and conditions of
                  this Agreement or any other provisions of the Terms of
                  Service; (ii) we suspect or become aware that you have
                  provided false or misleading information to us; or (iii) we
                  believe, in our sole discretion, that your actions may cause
                  legal liability for you, our Users, or Shift Dash or our
                  Affiliates; may be contrary to the interests of the Site or
                  the User community; or may involve illicit or illegal
                  activity. If your Account is temporarily or permanently
                  closed, you may not use the Site under the same Account or a
                  different Account or reregister under a new Account without
                  Shift Dash&rsquo;s prior written consent. If you attempt to
                  use the Site under a different Account, we reserve the right
                  to reclaim available funds in that Account and/or use an
                  available Payment Method to pay for any amounts owed by you to
                  the extent permitted by applicable law.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  You acknowledge and agree that the value, reputation, and
                  goodwill of the Site depend on transparency of User&rsquo;s
                  Account status to all Users, including both yourself and other
                  Users who have entered into Event Contracts with you. You
                  therefore agree as follows:&nbsp;IF SHIFT DASH DECIDES TO
                  TEMPORARILY OR PERMANENTLY CLOSE YOUR ACCOUNT, SHIFT DASH HAS
                  THE RIGHT WHERE ALLOWED BY LAW BUT NOT THE OBLIGATION TO: (A)
                  NOTIFY OTHER END USERS THAT HAVE ENTERED INTO ORDERS WITH YOU
                  TO INFORM THEM OF YOUR CLOSED ACCOUNT STATUS, (B) PROVIDE
                  THOSE END USERS WITH A SUMMARY OF THE REASONS FOR YOUR ACCOUNT
                  CLOSURE. YOU AGREE THAT SHIFT DASH WILL HAVE NO LIABILITY
                  ARISING FROM OR RELATING TO ANY NOTICE THAT IT MAY PROVIDE TO
                  ANY END USER REGARDING CLOSED ACCOUNT STATUS OR THE REASON(S)
                  FOR THE CLOSURE.
                </li>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Effect of Termination and Survival</strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  Should you discontinue use of the Site or otherwise suspend or
                  cancel your Shift Dash Site Account, we will immediately
                  discontinue your access to the Payment Services. You agree to
                  complete all pending transactions or outstanding appointments
                  (or notify Users of cancellation) and stop accepting new
                  transactions through Shift Dash, as applicable. Any funds due
                  to you will be paid out to you subject to the terms of your
                  payout schedule and any applicable withholding.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  Termination does not relieve you of your obligations as
                  defined in this Agreement and we may elect to continue to hold
                  any funds deemed necessary pending resolution of any other
                  terms or obligations defined in this Agreement, including but
                  not limited to Chargebacks, Fees, Refunds, reversals, or
                  Investigations.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  Your discontinuing to use Shift Dash Payment Services will not
                  necessarily terminate your Shift Dash UserAgreement, unless
                  Shift Dash informs you otherwise.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  Failure to pay any fees to Shift Dash including Service Fees,
                  or accruing any balance owed to Shift Dash for Payment
                  Services may result in the suspension of your account. Shift
                  Dash may disallow your access to any services provided at our
                  discretion until any balances owed are paid in full.
                </li>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Data Security and Privacy</strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  You are fully responsible for the security of data on your
                  website or otherwise in your possession. You agree to comply
                  with all applicable country and local laws and rules in
                  connection with your collection, security and dissemination of
                  any personal, financial, credit card, or transaction
                  information (defined as "Data") on your website. Shift Dash
                  expressly rejects all responsibility and liability for your
                  own Data.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  If we believe that a security breach or compromise of data has
                  occurred, Shift Dash may require you to have a third-party
                  auditor that is approved by Shift Dash conduct a security
                  audit of your systems and facilities and issue a report to be
                  provided to Shift Dash, our Payment Processor, financial
                  banks, and/or the Payment Networks.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  You represent to us that you are in compliance with all
                  applicable privacy laws, you have obtained all necessary
                  rights and consents under applicable law to disclose to us, or
                  allow Shift Dash or Payment Processor to collect, use, retain
                  and disclose any Consumer or cardholder data that you provide
                  to us or authorize us to collect, including email addresses,
                  phone numbers, or information that we may collect directly
                  from your end users via cookies or other means, and that we
                  will not be in breach of any such laws by collecting,
                  receiving, using and disclosing such information in connection
                  with the Payment Services or other services. As between the
                  parties to this Agreement, you are solely responsible for
                  disclosing to your customers that we assist in managing card
                  transactions for you and obtaining data from such customers.
                  If you receive information about others, including Consumers
                  and cardholders, through the use Shift Dash Payment Services,
                  you must keep such information confidential and only use it in
                  connection with Shift Dash platform.
                </li>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Warranties</strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Brand Representation and Warranties.</strong> You
                  represent and warrant to us that:
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    you are at least eighteen (18) years of age;
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    you are eligible to register and use Shift Dash, including
                    payouts, and have the right, power, and ability to enter
                    into and perform under this Agreement;
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    the name identified by you when you registered is your name
                    or business name under which you organize and operate
                    Events;
                  </li>
                </ol>
              </ol>
            </ol>
            <p>
              <br />
              <br />
            </p>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    any Event posting submitted by you will accurately describe
                    the Event and the duties and roles involved;
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    you will fulfill all of your obligations to each Talent for
                    which you submit a Event job posting for and will resolve
                    any dispute or complaint directly with theTalent;
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    you will comply with all federal, state, and local laws,
                    rules, and regulations applicable to your business,
                    including any applicable tax laws and regulations;
                  </li>
                </ol>
              </ol>
            </ol>
            <p>
              <br />
              <br />
            </p>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    you will not use the Services, directly or indirectly, for
                    any fraudulent undertaking or in any manner so as to
                    interfere with the use of any aspect of the Shift Dash
                    platform.
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Fees</strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Brand Fee.</strong> The Site Platform is provided to
                  Brands at no cost.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Talent Fee.</strong> All fees paid to Talent by Brands
                  will be subject to the Shift Dash Services Fee as established
                  in the applicable Fee Schedule.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Membership Fees</strong>. Brands and Talent may pay
                  Shift Dash a premium membership fee if they subscribe for such
                  a paid membership.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Notice Period.</strong> Subject to the terms of this
                  Agreement, Shift Dash reserves the right to change its fees,
                  including the amount of the fees or adding services with
                  additional separate charges, upon fourteen (14) days advance
                  written notice or by posting notice of the amendment on the
                  Agreement or Terms of Use. Notice of any change may be made to
                  the email address Brand provided during account registration.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Fee Verification.</strong> The pricing for the
                  Services are set forth within the Brand&rsquo;s online account
                  settings.
                </li>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Billing</strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Consent to Shift Dash Fees.</strong> By providing a
                  credit card or other payment method accepted by Shift Dash
                  ("Payment Method"), User is expressly agreeing that Shift Dash
                  is authorized to charge User with all fees attributable for
                  the use of Shift Dash Site without additional consent. If User
                  wants to use a different Payment Method than the one User
                  provided by Shift Dash, or if there is a change in
                  User&rsquo;s credit card validity or expiration date, User may
                  edit its Payment Method information in its online account
                  settings or by contacting&nbsp;Shift Dash directly.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Consent to Continue Billing.</strong> If User&rsquo;s
                  Payment Method expires and User does not edit its Payment
                  Method information, User authorizes Shift Dash to continue
                  billing, and User shall remain responsible for any uncollected
                  amounts.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Collection Mechanisms.</strong> Shift Dash Service
                  Fees will be collected in the form of a deduction from Payouts
                  made to User bank account.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  Shift Dash uses billing cycles that are in line with calendar
                  months. At the end of each billing cycle, Shift Dash will
                  issue an invoice to document monthly transactions, charges and
                  payments with the User.
                </li>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>License</strong>. Brand shall grant Shift Dash a
                non-exclusive, worldwide license to use, copy, distribute,
                transmit, and display any information Shift Dash collects
                through Shift Dash or from Brand's publicly available website
                and to Brand's name, logo and any trademarks ("Brand Marks") and
                any photographs, graphics, artwork, text and other content
                provided or specified by Brand ("Content") in connection with
                the marketing, promotion, in any and all media or formats in
                which such Brand Services are marketed, promoted, transmitted,
                sold, or distributed, including on the Website. Brands may
                request that Shift Dash remove or stop using any information
                obtained from Brand.
              </li>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Access to Brand Data</strong>.
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Information License.</strong> By submitting
                  information, data, materials and other content to Shift Dash,
                  you are licensing that content to Shift Dash solely for the
                  purpose of providing the Services. You hereby authorize and
                  permit Shift Dash to use and store information submitted by
                  you to accomplish the foregoing and to configure the Services
                  for you. Shift Dash may use and store the content, but only to
                  provide the Services to you. By submitting this content to
                  Shift Dash, you represent that you are entitled to submit it
                  to Shift Dash for use for this purpose, without any obligation
                  by Shift Dash to pay any fees or other limitations. By using
                  the Services, you also expressly authorize Shift Dash to
                  access your Account information maintained at Shift Dash. For
                  purposes of this Agreement and solely to provide the
                  appointment booking feature to you as part of the Services,
                  you grant Shift Dash a limited power of attorney, and appoint
                  Shift Dash as your attorney-in-fact and agent, to access third
                  party marketplaces, retrieve and use your information with the
                  full power and authority to do and perform each thing
                  necessary in connection with such activities, as you could do
                  in person. YOU ACKNOWLEDGE AND AGREE THAT WHEN SHIFT DASH IS
                  ACCESSING AND RETRIEVING ACCOUNT INFORMATION FROM THIRD PARTY
                  MARKETPLACES, SHIFT DASH IS ACTING AS YOUR AGENT, AND NOT AS
                  THE AGENT OF OR ON BEHALF OF THE THIRD PARTY. You understand
                  and agree that the Services are not sponsored or endorsed by
                  any third parties accessible through the Services.
                </li>
              </ol>
            </ol>
            <p>
              <br />
              <br />
            </p>
            <ol>
              <li font-weight="400">
                <strong>Aggregated User Data</strong>. Shift Dash may collect
                data from a Users account and may use, sell or report such data
                for any purpose or use including for promotional purposes,
                revenue generation and product development, so long as such data
                has been aggregated with the data of no fewer than 30 other
                User.
              </li>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Compliance with Laws</strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  At all times, Brand shall comply with all applicable local,
                  state or federal laws, statutes, rules, regulations, or
                  orders, including any Laws governing vouchers, Beauty
                  Products, coupons, and/or gift cards or certificates. These
                  laws include but are not limited to:
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    <strong>Unfair Competition Laws.</strong> All applicable
                    laws that prohibit false, deceptive or unfair advertising or
                    disparagement;
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    <strong>Intellectual Property Laws.</strong> All applicable
                    laws regarding the copyright, trademark, or other
                    intellectual property rights or rights of privacy or
                    publicity of any third party; and
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Further Representations and Warranties</strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  THE SHIFT DASH MARKETPLACE IS PROVIDED ON AN "AS IS" AND "AS
                  AVAILABLE" BASIS. USE OF THE SERVICES IS AT YOUR OWN RISK. TO
                  THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES
                  ARE PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS
                  OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES
                  OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-
                  INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                  WRITTEN, OBTAINED BY YOU FROM SHIFT DASH OR THROUGH THE
                  MARKETPLACE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED
                  HEREIN. WITHOUT LIMITING THE FOREGOING, SHIFT DASH, ITS
                  SUBSIDIARIES, ITS AFFILIATES, AND ITS LICENSORS DO NOT WARRANT
                  THAT THE CONTENT IS ACCURATE, RELIABLE OR CORRECT; THAT THE
                  WEBSITE WILL MEET YOUR REQUIREMENTS; THAT THE SHIFT DASH
                  MARKETPLACE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR
                  LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS
                  WILL BE CORRECTED; OR THAT SHIFT DASH MARKETPLACE IS FREE OF
                  VIRUSES OR OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR
                  OTHERWISE OBTAINED THROUGH THE USE OF SHIFT DASH MARKETPLACE
                  IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY
                  RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE
                  DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD OR YOUR
                  USE OF SHIFT DASH MARKETPLACE. SOME COUNTRIES DO NOT ALLOW THE
                  EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY
                  NOT APPLY TO YOU. THIS AGREEMENT GIVES YOU SPECIFIC LEGAL
                  RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM
                  COUNTRY TO COUNTRY. THE DISCLAIMERS AND EXCLUSIONS UNDER THIS
                  AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY
                  APPLICABLE LAW. SHIFT DASH DOES NOT WARRANT, ENDORSE,
                  GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
                  ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SHIFT DASH
                  MARKETPLACE OR ANY HYPERLINKED WEBSITE OR SERVICE, AND SHIFT
                  DASH WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY
                  TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS
                  OR SERVICES.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  The Brand represents and warrants to Shift Dash as follows:
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    <strong>Authority.</strong> The Brand has full corporate
                    power and authority to execute and deliver this Agreement
                    and to perform its obligations. The execution, delivery and
                    performance of this Agreement have been duly authorized, or
                    will have been duly authorized, by all necessary corporate
                    action on the part of the Brand, and this Agreement has been
                    duly executed and delivered by the Brand.
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    <strong>Taxes.</strong> Brand is registered for sales and
                    income tax collection purposes in all countries and states
                    in which Brand Services will be provided pursuant to the
                    terms of this Agreement.
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    <strong>Termination.</strong> In the event of Brand's breach
                    of any of the representations and warranties, Shift Dash
                    may, without prejudice to any other remedies or rights which
                    may be available to Shift Dash, immediately terminate or
                    suspend any Brand Account.
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <ol>
                <ol>
                  <li font-weight="400">
                    <strong>Intellectual Property</strong>. Shift Dash owns all
                    rights, titles, and interests in the Shift Dash Site, all
                    content, material, and trademarks presented on Shift Dash
                    Site or transferred thereby (excluding any licensed Brand
                    Marks), and all intellectual property rights related thereto
                    (collectively, the <strong>"Shift Dash IP"</strong>). Except
                    as explicitly provided herein, nothing in this Agreement
                    shall be deemed to create a license in or under any such
                    intellectual property rights and Brand may not rent, lease,
                    sublicense, distribute, transfer, copy, reproduce, download,
                    display, adapt, edit, modify or timeshare the Shift Dash IP
                    or any portion thereof, or use such Shift Dash IP as a
                    component of or a base for Products or services prepared for
                    commercial sale, sublicense, lease, access or distribution.
                    Brand may not prepare any derivative work based on the Shift
                    Dash IP. Brand may not translate, reverse engineer,
                    decompile or disassemble the Shift Dash IP.
                  </li>
                </ol>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Responsibility for Staff Member Actions</strong>. Staff
                Members of a Brand having access to Brand Account at Shift Dash,
                will also have access to Brand Account at Shift Dash Site. An
                agency relationship will exist between the staff members and the
                Brand and any actions taken by either staff member or Brand will
                bind the Brand.
              </li>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Indemnification</strong>. Brand agrees to defend,
                indemnify and hold Shift Dash, its affiliated and related
                entities, and any of their officers, directors, agents and staff
                members, harmless from and against any claims, actions,
                lawsuits, investigations, penalties, damages, losses or expenses
                (including but not limited to attorney's fees, court fees and
                collection costs) arising out of or relating to any of the
                following:
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  Any breach or alleged breach by Brand of this Agreement or the
                  representations and warranties stated in this Agreement;
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  Any claim for Taxes arising from use of the Services;
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  Any claim arising out of a violation of law and/or regulation
                  governing the use, sale, and/or distribution of alcohol;
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  Any claim arising out of or relating to the sale, performance,
                  or delivery of Services, including, but not limited to, any
                  claims for changes to or determination of the value of
                  Services, false advertising, product defects, personal injury,
                  death, or property damages;
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  Brand's failure to comply with any applicable Laws including,
                  but not limited to the regulations of: telephone consumer
                  protection, consumer personal data protection; or
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  Without limiting the foregoing, Brand shall pay any monies
                  owed to any party, as well as all attorney's fees, court fees
                  and collection costs related to any action against, or
                  determinations against, Shift Dash related to any action to
                  pursue Shift Dash for Taxes or Abandoned Property Claims.
                </li>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Limitation of Liability</strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                  SHALL SHIFT DASH, ITS AFFILIATES, AGENTS, DIRECTORS, STAFF
                  MEMBERS, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY DIRECT,
                  INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
                  EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR
                  LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
                  LOSSES, ARISING OUT OF OR RELATING TO THE USE OF, OR INABILITY
                  TO USE, THE SERVICES. UNDER NO CIRCUMSTANCES WILL SHIFT DASH
                  BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY RESULTING FROM
                  HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE
                  SERVICES OR YOUR ACCOUNT OR THE INFORMATION CONTAINED
                </li>
              </ol>
            </ol>
            <p>THEREIN.</p>
            <ol>
              <ol>
                <li font-weight="400">
                  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SHIFT DASH
                  ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS,
                  MISTAKES, OR INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR
                  PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
                  ACCESS TO OR USE OF SHIFT DASH MARKETPLACE; (III) ANY
                  UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
                  AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY
                  INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM SHIFT
                  DASH MARKETPLACE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
                  LIKE THAT MAY BE TRANSMITTED TO OR THROUGH BYND MARKETPLACE BY
                  ANY THIRD PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT
                  OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF
                  ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE
                  AVAILABLE THROUGH SHIFT DASH MARKETPLACE; AND/OR (VII) USER
                  CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF
                  ANY THIRD PARTY. IN NO EVENT SHALL SHIFT DASH, ITS AFFILIATES,
                  AGENTS, DIRECTORS, STAFF MEMBERS, SUPPLIERS, OR LICENSORS BE
                  LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES,
                  OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING
                  THE AMOUNT YOU PAID TO SHIFT DASH HEREUNDER OR $100.00,
                  WHICHEVER IS GREATER.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE
                  ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE,
                  STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF SHIFT DASH HAS
                  BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE FOREGOING
                  LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT
                  PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  SOME COUNTRIES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                  INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS
                  OR EXCLUSIONS MAY NOT APPLY TO YOU. THIS AGREEMENT GIVES YOU
                  SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS
                  WHICH VARY FROM COUNTRY TO COUNTRY. THE EXCLUSIONS AND
                  LIMITATIONS OF LIABILITY UNDER THIS AGREEMENT WILL NOT APPLY
                  TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
                </li>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                The Services are controlled and operated from facilities in the
                United States. Company makes no representations that the Service
                is appropriate or available for use in other locations. Those
                who access or use the Services from other jurisdictions do so at
                their own volition and are entirely responsible for compliance
                with all applicable local laws and regulations, including, but
                not limited to, export and import regulations.
              </li>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>Disputes</strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Arbitration Agreement and Jury Trial Waiver</strong>.
                  Any and all controversies, disputes, demands, counts, claims,
                  or causes of action (including the interpretation and scope of
                  this clause, and the arbitrability of the controversy,
                  dispute, demand, count, claim, or cause of action) between you
                  and us or our employees, agents, successors, or assigns, will
                  exclusively be settled through binding and confidential
                  arbitration. Arbitration will be subject to the Federal
                  Arbitration Act and not any state arbitration law. The
                  arbitration will be conducted before one commercial arbitrator
                  with substantial experience in resolving commercial contract
                  disputes from the American Arbitration Association
                  (&ldquo;AAA&rdquo;). As modified by this Agreement, and unless
                  otherwise agreed upon by the parties in writing, the
                  arbitration will be governed by the AAA&rsquo;s Commercial
                  Arbitration Rules and, if the arbitrator deems them
                  applicable, the Supplementary Procedures for Consumer Related
                  Disputes (collectively &ldquo;Rules and Procedures&rdquo;).
                  You are thus GIVING UP YOUR RIGHT TO GO TO COURT to assert or
                  defend your rights EXCEPT for matters that may be taken to
                  small claims court. Your rights will be determined by a
                  NEUTRAL ARBITRATOR and NOT a judge or jury. You are entitled
                  to a FAIR HEARING, BUT the arbitration procedures are SIMPLER
                  AND MORE LIMITED THAN RULES APPLICABLE IN COURT. Arbitrator
                  decisions are as enforceable as any court order and are
                  subject to VERY LIMITED REVIEW BY A COURT.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Arbitration Process</strong>. You and Shift Dash must
                  abide by the following rules: (a) ANY CLAIMS BROUGHT BY YOU OR
                  SHIFT DASH MUST BE BROUGHT IN THE PARTIES&rsquo; INDIVIDUAL
                  CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                  PURPORTED CLASS OR REPRESENTATIVE PROCEEDING; (b) THE
                  ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON&rsquo;S
                  CLAIMS, MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
                  REPRESENTATIVE OR CLASS PROCEEDING, AND MAY NOT AWARD
                  CLASS-WIDE RELIEF, (c) in the event that you are able to
                  demonstrate that the costs of arbitration will be prohibitive
                  as compared to costs of litigation, we will pay as much of
                  your filing and hearing fees in connection with the
                  arbitration as the arbitrator deems necessary to prevent the
                  arbitration from being cost-prohibitive as compared to the
                  cost of litigation, (d) we also reserve the right in our sole
                  and exclusive discretion to assume responsibility for all of
                  the costs of the arbitration; (e) the arbitrator will honor
                  claims of privilege and privacy recognized at law; (f) the
                  arbitration will be confidential, and neither you nor we may
                  disclose the existence, content or results of any arbitration,
                  except as may be required by law or for purposes of
                  enforcement of the arbitration award; (g) the arbitrator may
                  award any individual relief or individual remedies that are
                  permitted by applicable law; and (h) each side pays its own
                  attorneys&rsquo; fees and expenses unless there is a statutory
                  provision that requires the prevailing party to be paid its
                  fees and litigation expenses, and then in such instance, the
                  fees and costs awarded will be determined by the applicable
                  law.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Small Claims Court</strong>. Notwithstanding the
                  foregoing, either you or Shift Dash may bring an individual
                  action in small claims court. Further, claims of defamation,
                  violation of the Computer Fraud and Abuse Act, and
                  infringement or misappropriation of the other party&rsquo;s
                  patent, copyright, trademark, or trade secret will not be
                  subject to this arbitration agreement. Such claims will be
                  exclusively brought in the state or federal courts located in
                  Atlanta, Georgia. Additionally, notwithstanding this agreement
                  to arbitrate, either party may seek emergency equitable relief
                  before the state or federal courts located in Atlanta, Georgia
                  in order to maintain the status quo pending arbitration, and
                  hereby agrees to submit to the exclusive personal jurisdiction
                  of the courts located within Atlanta, Georgia for such
                  purpose. A request for interim measures will not be deemed a
                  waiver of the right to arbitrate. With the exception of
                  subparts (a) and (b) in section 37.2 above (prohibiting
                  arbitration on a class or collective basis), if any part of
                  this arbitration provision is deemed to be invalid,
                  unenforceable or illegal, or otherwise conflicts with the
                  Rules and Procedures, then the balance of this arbitration
                  provision will remain in effect and will be construed in
                  accordance with its terms as if the invalid, unenforceable,
                  illegal or conflicting provision were not contained herein.
                  If, however, either subpart (a) or (b) is found to be invalid,
                  unenforceable or illegal, then the entirety of this
                  arbitration provision will be null and void, and neither you
                  nor we will be entitled to arbitration. If for any reason a
                  claim proceeds in court rather than in arbitration, the
                  dispute will be exclusively brought in state or federal court
                  in Atlanta, Georgia. For more information on AAA, its Rules
                  and Procedures, and how to file an arbitration claim, you may
                  call AAA at 800-778-7879 or visit the AAA website at&nbsp;
                  <a href="http://www.adr.org./">http://www.adr.org.</a>
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Governing Law and Jurisdiction</strong>. This
                  Agreement is made under and will be governed by and construed
                  in accordance with the laws of the State of Georgia,
                  consistent with the Federal Arbitration Act, without giving
                  effect to any principles that provide for the application of
                  the law of another jurisdiction.
                </li>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>General Provisions</strong>
              </li>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Assignment.</strong> Brand may not assign or delegate
                  its rights or obligations under this Agreement or any rights
                  and licenses granted hereunder, whether by operation of law or
                  otherwise, without Shift Dash's prior written consent, but
                  Shift Dash may assign the foregoing without restriction. Any
                  attempted transfer or assignment in violation hereof shall be
                  null and void.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Headings and Sub-headings.</strong> Headings and
                  sub-headings are inserted for the convenience of the parties
                  only and are not to be considered when interpreting this
                  Agreement.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Severability.</strong> Any provision of this Agreement
                  which is prohibited or unenforceable in any jurisdiction will,
                  as to such jurisdiction, be ineffective to the extent of such
                  prohibition or unenforceability without invaliding the
                  remaining provisions of this Agreement, and any such
                  prohibition or unenforceability in any jurisdiction will not
                  invalidate or render unenforceable such provision in any other
                  jurisdiction.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Independent Contractors.</strong> The Parties are
                  independent contractors. Nothing in this Agreement shall be
                  construed to create a joint venture, partnership, franchise,
                  or an agency relationship between the Parties. Neither Party
                  has the authority, without the other Party's prior written
                  approval, to bind or commit the other Party in any way.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Amendments and Supplements.</strong> This Agreement
                  may be amended, modified and supplemented by Shift Dash at any
                  time, in its sole discretion. These changes take effect
                  immediately upon posting on Shift Dash Site and Brand's
                  continued use or access of the Services constitutes the
                  Brand's acceptance of the modified agreement. Please check
                  this Agreement and these Terms and Conditions, available on
                  Shift Dash Site, periodically for changes. Your continued use
                  of the Services after the posting of any changes to the Terms
                  and Conditions will signify your acceptance of those changes.
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li font-weight="400">
                  <strong>Entire Agreement.</strong> This Agreement, along with
                  Shift Dash Terms of Use, Privacy Policy, and any other legal
                  notices published by Shift Dash on Shift Dash Site,
                  constitutes the entire agreement between the Parties relating
                  to its subject matter and supersedes all prior or
                  contemporaneous oral or written agreements concerning such
                  subject matter. All modifications must be in a writing signed
                  by Shift Dash, except as otherwise provided.
                </li>
              </ol>
            </ol>
            <ol>
              <li font-weight="400">
                <strong>
                  BY REGISTERING FOR A MERCHANT ACCOUNT OR BY USING OR ACCESSING
                  THE SERVICES, MERCHANT UNDERSTANDS, ACKNOWLEDGES AND AGREES
                  THAT MERCHANT WILL ABIDE BY THE TERMS OF THIS AGREEMENT. IF
                  YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, DO NOT
                  REGISTER FOR A MERCHANT ACCOUNT AND IMMEDIATELY STOP (A)
                  ACCESSING SHIFT DASH PRO MARKETPLACE AND (B) SUBMITTING
                  MERCHANT OFFERINGS.
                </strong>
              </li>
            </ol>
            <p></p>
            <p>Schedule 1</p>
            <p>
              <strong>Data Protection Agreement</strong>
            </p>
            <ol>
              <li>
                <strong> INTERPRETATION</strong>
              </li>
            </ol>
            <p>
              In this Addendum, the following words shall have the following
              meanings:
            </p>
            <p>
              <strong>&ldquo;Agreed Purpose&rdquo;</strong> to allow Shift Dash
              to provide access to Shift Dash and the Shift Dash Site to the
              Brand in such a manner as to permit the Brand to take full
              advantage of all the features of the Platform.
            </p>
            <p>
              <strong>&ldquo;Data Protection Authority&rdquo;</strong> means the
              local governmental or other official regulator(s) responsible for
              enforcement of Data Protection Legislation from time-to-time in
              the relevant EEA country or the United Kingdom.
            </p>
            <p>
              <strong>&ldquo;Data Protection Legislation&rdquo;</strong> means
              all applicable laws and regulations relating to the processing of
              personal data and privacy including the General Data Protection
              Regulation 2016 (EU) 2016/679 and the Electronic Communications
              Data Protection Directive (2002/58/EC), together with all codes of
              practice and other guidance on the foregoing issued by any
              relevant Data Protection Authority, all as amended, updated or
              re-enacted from time-to-time.
            </p>
            <p>
              <strong>&ldquo;Shared Personal Data&rdquo;</strong> the personal
              data which is shared between the parties, including names, contact
              information (phone numbers, addresses, email addresses), age date
              of birth, social media data, personal interests, and other
              personal data shared by the Brand on Shift Dash
            </p>
            <p>
              1.1. The terms &lsquo;personal data&rsquo;, &lsquo;data
              subject&rsquo;, &lsquo;processor&rsquo;, &lsquo;controller&rsquo;,
              &lsquo;processing&rsquo;, &lsquo;personal data breach&rsquo;,
              &lsquo;pseudonymisation&rsquo;, &lsquo;special categories of
              data&rsquo; and &lsquo;supervisory authority&rsquo; have the
              meanings set out in the Data Protection Legislation.
            </p>
            <p>
              1.2. Where a term is capitalised in this schedule but not defined,
              it shall have the same meaning as is given to it in the main body
              of this Addendum.
            </p>
            <ol>
              <li>
                <strong> AGREED PURPOSE</strong>
              </li>
            </ol>
            <p>
              2.1. The parties acknowledge that for the purposes of the Data
              Protection Legislation, they shall act as data controllers with
              respect to the Shared Personal Data.
            </p>
            <p>
              2.2. The parties agree to only process Shared Personal Data for
              the Agreed Purpose, and shall not process it in a way which is
              incompatible with the Agreed Purpose.
            </p>
            <ol>
              <li>
                <strong> SHARED PERSONAL DATA</strong>
              </li>
            </ol>
            <p>
              3.1. Each party shall (and shall ensure that any subcontractors
              shall):
            </p>
            <p>
              3.1.1. comply with their obligations under the Data Protection
              Legislation; and
            </p>
            <p>
              3.1.2. obtain all permissions and consents necessary to disclose
              the Shared Personal Data to the other party.
            </p>
            <p>
              3.2. The Shared Personal Data must not be irrelevant or excessive
              with regard to the Agreed Purpose. Neither party shall attempt to
              access, copy, amend, store or delete any personal data owned by
              the other party where any such action is not absolutely necessary
              in order to fulfil the Agreed Purposes.
            </p>
            <p>
              3.3. Each party shall ensure that it only processes Shared
              Personal Data on the basis of one or more of the legal grounds for
              processing contained in the Data Protection Legislation, and that
              the chosen legal ground shall be and remain valid in the context
              of the processing operation(s) to which it relates.
            </p>
            <p>
              3.4. Each party shall ensure that (to the extent of its reasonable
              knowledge and belief) the personal data it discloses to the other
              party is accurate.
            </p>
            <ol>
              <li>
                <strong> RIGHTS OF DATA SUBJECTS</strong>
              </li>
            </ol>
            <p>
              4.1. Each party shall, in respect of Shared Personal Data, ensure
              that their privacy notices are clear and provide sufficient
              information to prospective customers and any other affected data
              subjects for them to understand what of their personal data is
              being shared, the circumstances in which it will be shared, the
              purposes for the data sharing and either the identity of the other
              party, or a description of the type of organization that will
              receive the personal data.
            </p>
            <p>
              4.2. The parties acknowledge that the Data Protection Legislation
              grants data subjects certain rights over their personal data, and
              that the responsibility for complying with such a request falls to
              the party who receives it in respect of the personal data they
              hold about the relevant data subject.
            </p>
            <p>
              4.3. The parties shall maintain a record of any requests made by
              data subjects to exercise their rights. The records shall also
              include an explanation of decisions made and any information that
              was exchanged. Records must include copies of the request, details
              of the data accessed and shared and where relevant, notes of any
              meetings, correspondence or phone calls relating to the request.
            </p>
            <p>
              4.4. The parties agree to provide reasonable assistance as is
              necessary to each other to enable them to comply with any requests
              made by a data subject.
            </p>
            <ol>
              <li>
                <strong> DATA RETENTION</strong>
              </li>
            </ol>
            <p>
              Each party shall ensure that it only retains Shared Personal Data
              for as long as necessary for the purposes it was originally
              collected for, or as otherwise required under the Data Protection
              Legislation or other applicable laws.
            </p>
            <ol>
              <li>
                <strong> TRANSFERS</strong>
              </li>
            </ol>
            <p>
              6.1. For the purposes of this paragraph, transfers of personal
              data shall mean any disclosure of Shared Personal Data to an
              unconnected third party.
            </p>
            <p>
              6.2. Where either party transfers Shared Personal Data in
              accordance with this Addendum:
            </p>
            <p>
              6.2.1. it shall ensure that the transfer of such Shared Personal
              Data is carried out in accordance with a written contract;
            </p>
            <p>
              6.2.2. where the transfer is outside of the EEA and is not to a
              territory approved by the European Commission as providing
              adequate protection, nor to an entity who is a member of the
              &ldquo;Privacy Shield&rdquo; certification scheme, it shall ensure
              that the transfer is otherwise legitimized in accordance with the
              Data Protection Legislation; and
            </p>
            <p>
              6.2.3. it shall remain liable for the actions of any third party
              who receives it as though they were its own.
            </p>
            <ol>
              <li>
                <strong> SECURITY AND TRAINING</strong>
              </li>
            </ol>
            <p>
              7.1. Taking into account the latest standards of security
              measures, the costs of implementation and the nature, scope,
              context and purposes of the data sharing, as well as the risk to
              the rights and freedoms of natural persons, each party shall
              implement and maintain appropriate technical and organizational
              measures to ensure a level of security appropriate to the risk.
            </p>
            <p>
              7.2. It is the responsibility of each party to ensure that its
              staff members are appropriately trained to handle and process the
              Shared Personal Data in accordance with the technical and
              organizational security measures adopted by that party, together
              with any other applicable national data protection laws and
              guidance. The level, content and regularity of such training shall
              be proportionate to the staff members' role, responsibility and
              frequency with respect to their handling and processing of the
              Shared Personal Data.
            </p>
            <p>
              7.3. If either party becomes aware of a personal data breach
              relating to any of the Shared Personal Data, it shall:
            </p>
            <p>
              7.3.1. notify the other party immediately upon becoming aware of
              the personal data breach, including sufficient detail to allow
              that party to conduct its own investigation and assessment;
            </p>
            <p>
              7.3.2. provide the other party with such information and
              assistance as it requires in relation to the personal data breach;
              and
            </p>
            <p>
              7.3.3. document the personal data breach and any related action
              taken.
            </p>
            <ol>
              <li>
                <strong> COSTS</strong>
              </li>
            </ol>
            <p>
              Each party shall perform its obligations under this schedule at
              its own cost.
            </p>
          </Segment>
        </Container>
      </div>
    );
  }
}
