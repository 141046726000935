import React,{Component} from 'react';
import {Container, Grid, Header} from 'semantic-ui-react';
import {calculateAmount} from "../helper";


class ProfileStatistics extends Component{

  render() {
    const {company} = this.props;
    return(
      <Grid columns={3} divided style={{ marginBottom: '0px', marginTop:'0px'}}>
          <Grid.Column style={{padding:'4px'}}>
            <Container textAlign={'center'}>
              <Header size={'large'} className={'statistic-value'}>{company['events_count']}</Header>
              <Header style={{color:'#774b86'}} size={'large'} className={'statistic-label'}>Events</Header>
            </Container>
          </Grid.Column>

          <Grid.Column style={{padding:'4px'}}>
            <Container textAlign={'center'}>
              <Header size={'large'} className={'statistic-value'}>{company['total_hours_booked']}</Header>
              <Header style={{color:'#774b86'}} size={'large'} className={'statistic-label'}>Hours Booked</Header>
            </Container>
          </Grid.Column>

          <Grid.Column style={{padding:'4px'}}>
            <Container textAlign={'center'}>
              <Header size={'large'} className={'statistic-value'}>{calculateAmount(company['total_paid'])}</Header>
              <Header style={{color:'#774b86'}} size={'large'} className={'statistic-label'}>Total Paid</Header>
            </Container>
          </Grid.Column>
        </Grid>
    )
  }
}

export default ProfileStatistics