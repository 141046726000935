import React,{Component} from 'react';
import {connect} from "react-redux";
import {Segment, Form, Loader} from 'semantic-ui-react';
import ServerErrors from './../../Common/ServerErrors';
import {updateTalentProfile} from "../../../actions/talent";


const stateToProps=(state)=>{
    return{
        waitingRequest: state.common.waitingRequest,
        talent: state.talent.profile,
    }
};

const dispatchToProps=(dispatch)=>{
    return{
        updateTalentProfile:(data)=> dispatch(updateTalentProfile(data)),
    }
};

class MeasurementSystem extends Component{
  constructor(props){
    super(props);
    this.state={
    }
  }

  renderServerErrors(){
      const {error, waitingRequest} = this.props;
      if (error && !waitingRequest){
          return <ServerErrors errorMessage={error} />
      }
  }

  render(){
    const {talent, waitingRequest} = this.props;
    const measurementSystems = [
        {key:0, value: 0, text: 'Metric'},
        {key:1, value: 1, text: 'Imperial'},
      ];

    return(
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <span className={'panel-header'}>
            Measurement Settings
          </span>
        </Segment>

        <Segment style={{width: '500px'}}>
          {this.renderServerErrors()}
          <Form size='small'>
            <Form.Select
              options={measurementSystems}
              placeholder='Measurement System'
              name={'measurement_system'}
              value={talent['measurement_system']}
              fieldtype='select'
              onChange={(e, {value}) => this.props.updateTalentProfile({measurement_system: value})}
            />

              { waitingRequest &&  <Loader active inline='centered' size='small' /> }
          </Form>
        </Segment>
      </Segment.Group>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(MeasurementSystem)
