import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NotFound extends Component {
	render(){
		return(
			<div>
			<img src='https://4.bp.blogspot.com/-vZlVGM_MhBQ/VvKxfquqeCI/AAAAAAAAAWs/TesLDdsq2HUMSX5QzzXmiv-Ft29A0ybcA/s1600/page-not-found-meralesson%2B-cover.png' 
				style={{width: '75%', height: 400, display: 'block', margin: 'auto', position: 'relative' }} />
				<center><h1 style={{fontSize: '100px'}}>D'oh! Where'd that page go? </h1></center>
			</div>
		)
	}
}
export default NotFound
