import React,{ Component } from 'react';
import { DragSource } from 'react-dnd'
import { Card, Image, Modal, Header, Button, List, Grid } from 'semantic-ui-react';
import moment from 'moment';

import {DND_ASSIGN_SHIFTS} from '../../../constants/actionTypes';
import {getUserName} from "../../../utils/common";
import {API_URL_BASE} from "../../../constants/constants";
import PhotoGallery from "../../Profile/Talent/PhotoGallery";
import { getGalleryPhoto } from "../../../actions/talent";

const dispatchToProps=(dispatch)=>{
  return{
    getGalleryPhoto:(id)=>dispatch(getGalleryPhoto(id))
  }
}

const source = {
  beginDrag(props) {
    const { applicant, applicantDragStarted } = props;
    applicantDragStarted(applicant);
    return ({ applicant });
  },
  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      props.applicantDragEnded();
      return;
    }

    const { assignByRole, assignByShift } = props;
    const { applicant } = monitor.getItem();
    const { assignType, role, shift } = monitor.getDropResult();

    if (assignType === 'by_role'){
      assignByRole(applicant, role);
    }

    if (assignType === 'by_shift'){
      assignByShift(applicant, shift)
    }

    props.applicantDragEnded();
  },
};

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
  connectDragPreview: connect.dragPreview()
});

class UserCard extends Component{

  constructor(props){
    super(props);

    this.state = {
      talentDetailsModalOpen: false
    }
  }

  getUserPhotoUrl = (user) =>{
    return `${API_URL_BASE}/media/${user.photo}`;
  };

  handleTalentDetailsModalOpen = (e) => {
    e.preventDefault();
    this.setState({ talentDetailsModalOpen: true });
  };

  handleTalentDetailsModalClose = () => this.setState( { talentDetailsModalOpen: false } );
  

  renderUserInfoModalTrigger = (user) =>{
    return (
      <a href={'#'} onClick={this.handleTalentDetailsModalOpen}>{getUserName(user)}</a>
    );
  };

  renderUserInfoModal = (talent) =>{
    let {talentDetailsModalOpen} = this.state;

    return (
      <Modal trigger={ this.renderUserInfoModalTrigger( talent.user ) } closeIcon>
        <Modal.Header>{getUserName(talent.user)}</Modal.Header>
        <Modal.Content image scrolling>
          <Image wrapped size='medium' src={this.getUserPhotoUrl(talent.user)} />
          <Modal.Description>

            <List>
              <List.Item>
                <List.Header>Photo Gallery</List.Header>
                <PhotoGallery talent={talent.user} showIndex={true} showThumbnails= {true} media_url = {''} />
              </List.Item>
              <hr/>
              <List.Item>
                <List.Header>About</List.Header>
                {talent.about}
              </List.Item>
            </List>
            <hr/>

            <Grid columns={4} padded>
              <Grid.Row>
                <Grid.Column width={4} verticalAlign="middle"> Gender</Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                    {talent.gender && talent.gender.initCap()}
                  </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">Bust Size</Grid.Column>
                {
                talent.gender === 'female' &&
                <Grid.Column width={4} verticalAlign="middle">{talent.bust_size}</Grid.Column>
                }
                </Grid.Row>
            </Grid>

            <Grid columns={4} padded>
              <Grid.Row>
                <Grid.Column width={4} verticalAlign="middle"> Ethnicity </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                    {talent.ethnicity}
                  </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle"> Shoe Size </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                    {talent.shoe_size}
                  </Grid.Column>
                </Grid.Row>
            </Grid>

            <Grid columns={4} padded>
              <Grid.Row>
                <Grid.Column width={4} verticalAlign="middle"> Height </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                    {talent.height}
                  </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle"> Shirt Size </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                    {talent.shirt_size}
                  </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid columns={4} padded>
              <Grid.Row>
                <Grid.Column width={4} verticalAlign="middle"> Weight </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                    {talent.weight}
                  </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle"> Hat Size </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                    {talent.hat_size}
                  </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid columns={4} padded>
              <Grid.Row>
                <Grid.Column width={4} verticalAlign="middle"> Hair Color </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                    {talent.hair_color && talent.hair_color.initCap()}
                  </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle"> Glove Size </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                    {talent.glove_size && talent.glove_size.initCap()}
                  </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid columns={4} padded>
              <Grid.Row>
                <Grid.Column width={4} verticalAlign="middle"> Hair Type </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                    {talent.hair_type && talent.hair_type.initCap()}
                  </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle"> Implants </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                    {talent.implants? 'Yes' : 'No'}
                  </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid columns={4} padded>
              <Grid.Row>
                <Grid.Column width={4} verticalAlign="middle"> Eye Color </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  {talent.eye_color && talent.eye_color.initCap()}
                  </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle"> Chest Size </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  {talent.chest_size}
                  </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid columns={4} padded>
              <Grid.Row>
                <Grid.Column width={4} verticalAlign="middle"> Waist Size </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  {talent.waist}
                  </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle"> Cup Size </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  {talent.cup_size}
                  </Grid.Column>
                </Grid.Row>
            </Grid>

            <Grid columns={4} padded>
              <Grid.Row>
                <Grid.Column width={4} verticalAlign="middle"> Pants Size </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  {talent.pant_size}
                  </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle"> Pregnant? </Grid.Column>
                {
                  talent.gender === 'female' &&
                  <Grid.Column width={4} verticalAlign="middle">
                    {talent.is_pregnant? 'Yes' : 'No'}
                  </Grid.Column>
                }
                </Grid.Row>
            </Grid>

            <Grid columns={4} padded>
              <Grid.Row>
                <Grid.Column width={4} verticalAlign="middle"> Hips Size </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  {talent.hip_size}
                  </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle"> Due Date </Grid.Column>
                {
                  talent.gender === 'female' && talent.is_pregnant &&
                <Grid.Column width={4} verticalAlign="middle">
                  {talent.pregnant_due_date && moment(talent.pregnant_due_date).format('ddd MMM D YYYY')}
                  </Grid.Column>
                }
                </Grid.Row>
            </Grid>
            <Grid columns={4} padded>
              <Grid.Row>
                <Grid.Column width={4} verticalAlign="middle"> Tattoos </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  {talent.tattoos && talent.tattoos.initCap()}
                  </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle"> Piercings </Grid.Column>
                <Grid.Column width={4} verticalAlign="middle">
                  {talent.piercings && talent.piercings.initCap()}
                  </Grid.Column>
                </Grid.Row>
              </Grid>

          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  };

  render(){
    const { applicant, isDragging, connectDragSource } = this.props;
    const {talent} = applicant;
    const { user } = talent;
  

    return(
      connectDragSource(
        <div className={`user-card-container ${isDragging? 'user-card-container-dragging' : ''}`}>
          <Card className={ 'user-card' }>
            <Card.Content>
              <Card.Header>
              <Image floated='right' size='mini' rounded label src={ this.getUserPhotoUrl( user ) } />
              {this.renderUserInfoModal(talent)}
              </Card.Header>
              <Card.Meta>{applicant.shifts[0].talent_role.name }</Card.Meta>
            </Card.Content>
          </Card>
        </div>
      )
    )
  }
}

export default DragSource(DND_ASSIGN_SHIFTS, source, collect)(UserCard);
