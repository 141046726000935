import React, { Component } from 'react';
import _ from 'lodash';
import {
  Segment,
  Header,
  Container,
  List,
  Icon,
  Checkbox,
  Button,
  Message,
  Menu} from 'semantic-ui-react';
  import moment from "moment";

class ShiftTime extends Component {
  constructor(props){
    super(props);
    this.state={
      shifts:this.props.shifts,
      showErrorMessage:false
    }
  }

  isShiftChecked = (shift) => {
    let isChecked = false;
    _.each(this.props.selectedShifts, day => {
        _.each(day.shifts, dayShift => {
            if(dayShift.id === shift.id){
                isChecked = true;
            }
        })
    });

    return isChecked;
  };

  isFixedShiftsChecked = (shifts) => {
    let isChecked = false;

    for(let i=0;i<shifts.length;i++){
      let shift=shifts[i];

      _.each(this.props.selectedShifts, day => {
        _.each(day.shifts, dayShift => {
          if(dayShift.id === shift.id){
            isChecked = true;
          }
        })
      });
    }

    return isChecked;
  };

  handleDismiss(event){
    this.setState({showErrorMessage:false});
  }

  goNext = (event) => {
    event.preventDefault();
    if (this.props.selectedShifts.length === 0){
      this.setState({showErrorMessage: true});
      return;
    }

    this.props.selectStep('profile_selection');
  };

  renderShifts = (day, index) => {
    let hourlyShifts = day.shifts.filter((item) => {
      return item.talent_role.pay_type === 'hourly' && !item.is_booked
    });

    let fixedShifts = day.shifts.filter((item) => {
      return item.talent_role.pay_type === 'fixed' && !item.is_booked
    });

    let groupByFixedShifts = _.groupBy(fixedShifts, (item) => {
      return item.talent_role.id
    });

    let fixedShiftsList=Object.keys(groupByFixedShifts).map((key)=> {
      let shifts = groupByFixedShifts[key];
      let anyFixedShift=null;

      let fixedShiftTimes = shifts.map((shift, shiftIndex) => {
        anyFixedShift=shift;

        return (
          <List.Item key={`${index}-${shiftIndex}`}>
            <List.Content>
              {moment.utc(shift['start_time'], 'HH:mm:ss').local().format("hh:mm A")} - {moment.utc(shift['end_time'], 'HH:mm:ss').local().format("hh:mm A")}
            </List.Content>
          </List.Item>
        );
      });

      if (anyFixedShift){
        return(
          <List.Item key={key}>
            <List.Content floated='right'>
              <div style={{display:'inline-block', marginRight:'10px',fontSize:'15px'}}>
                <strong>
                  {anyFixedShift.talent_role.name}
                </strong>
              </div>
              <div style={{display:'inline-block', marginRight:'20px',fontSize:'20px'}}>
                <strong>
                  ${anyFixedShift.talent_role.fixed_rate_talent}
                </strong>
              </div>
              <Checkbox
                toggle
                checked={this.isFixedShiftsChecked(shifts, day)}
                onChange={() => this.props.selectFixedShifts(shifts,day)}
              />
            </List.Content>
            <List.Content>
              {fixedShiftTimes}
            </List.Content>
          </List.Item>
        );
      }
      else{
        return null;
      }
    });

    let hourlyShiftsList = hourlyShifts.map((shift, shiftIndex) => {
      return (
        <List.Item key={`${index}-${shiftIndex}`}>
          <List.Content floated='right'>
            <div style={{display:'inline-block', marginRight:'10px',fontSize:'15px'}}>
              <strong>
                {shift.talent_role.name}
              </strong>
            </div>
            <div style={{display:'inline-block', marginRight:'20px',fontSize:'20px'}}>
              <strong>
                ${shift.talent_role.hourly_rate_talent}/hr
              </strong>
            </div>
            <Checkbox
              toggle
              checked={this.isShiftChecked(shift, day)}
              onChange={() => this.props.selectShift(day, shift)}
            />
          </List.Content>
          <List.Content>
            {moment.utc(shift['start_time'], 'HH:mm:ss').local().format("hh:mm A")} - {moment.utc(shift['end_time'], 'HH:mm:ss').local().format("hh:mm A")}
          </List.Content>
        </List.Item>
      )
    });

    return (
      <Segment.Group key={index}>
        <Segment textAlign={'left'}>
          <Icon name={'calendar'}/>
          {moment.utc(day.date).format("MMM D, YYYY")}
        </Segment>
        <Segment secondary textAlign={'left'}>
          <List divided verticalAlign='middle'>
            {hourlyShiftsList}
            {fixedShiftsList}
          </List>
        </Segment>
      </Segment.Group>
    );
  };

  render() {
    let selectedDaysList=this.props.selectedDays.sort((a,b)=>{
      if (moment(a.date,'YYYY-MM-DD')>moment(b.date,'YYYY-MM-DD')){
        return 1;
      }
      else if (moment(a.date,'YYYY-MM-DD')>moment(b.date,'YYYY-MM-DD')){
        return -1;
      }

      return 0;
    })
    .map((day, index) =>{
      return this.renderShifts(day, index);
    });

    return(
      <Segment attached raised>
        <Container textAlign='center'>
          <Header as={'h5'}>Which shifts are you available to work each day?</Header>
          {this.state.showErrorMessage &&
            <Message
              negative
              onDismiss={(e)=> this.handleDismiss(e)}
              header='Error!'
              content='Please select time'
            />
          }
          <div className={'shift-time-div'}>
            {selectedDaysList}
          </div>
          <Button fluid
            size={'large'}
            content='NEXT'
            color={'blue'}
            onClick={(e)=>this.goNext(e)}
          />

          <Menu secondary>
            <Menu.Item as='a'
                       style={{marginLeft:'auto',marginRight:'auto'}}
                       onClick={() => this.props.selectStep('day_selection')}>
              <Icon name='arrow left' link/>
              Back
            </Menu.Item>
          </Menu>
        </Container>
      </Segment>
    )
  }
}

export default ShiftTime
