import axios from 'axios';

import {API_URL_BASE} from '../constants/constants';
import {
    WAITING_REQUEST,
    LOGIN_SUBMIT_FULFILLED,
    LOGIN_SUBMIT_ERROR, TALENT_UPDATE_SUCESS, COMPANY_UPDATE_SUCCESS, UPDATE_USER, UPDATE_PORTFOLIO_SUCCESS
} from '../constants/actionTypes';
import {common} from '../utils/common';
import { setAuthHeader } from "../utils/common";
import _ from 'lodash';

export const loginUser = (data) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_REQUEST, value:true});

    axios.post(`${API_URL_BASE}/user/login`,data)
      .then((response)=>{
        loginSuccess(response,dispatch);
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,LOGIN_SUBMIT_ERROR);
      })
  }
}


export function loginSuccess(response, dispatch){

    const talent = response.data.talent;
    const company = response.data.company;

    // put user's talent data in redux store
    if (talent && !_.isEmpty(talent)){
        dispatch({type:TALENT_UPDATE_SUCESS, responseData: talent});
        const portfolios = response.data.portfolios;
        dispatch({type: UPDATE_PORTFOLIO_SUCCESS, responseData: portfolios});
    }

    // put user's company data in redux store
    if (company && !_.isEmpty(company)){
        dispatch({type:COMPANY_UPDATE_SUCCESS, responseData: company});
    }

    dispatch({type:LOGIN_SUBMIT_FULFILLED, responseData: response.data});
    // update user in redux store
    dispatch({type:UPDATE_USER, responseData: response.data});

    // set authentication header for axios requests
    if (response.data['is_active']) {
        setAuthHeader();
    }

    dispatch({type:WAITING_REQUEST, waitingRequest: false});
}