import React, { Component } from 'react'
import {connect} from "react-redux";
import {Container, Loader, Dropdown, Modal, Table, Image, Grid, Button} from 'semantic-ui-react';
import PlaidLink from 'react-plaid-link';
import _ from 'lodash';
import moment from 'moment';
import './events.css';
import {getEventInvoice, payInvoiceViaBank, payInvoiceViaCard} from "../../actions/event";
import ServerErrors from "../Common/ServerErrors";
import * as settings from './../../../src/constants/constants';
import StripeCardElement from './StripeCardElement';

const stateToProps=(state)=>{
  const invoiceList = state.event.invoice.data;
  let finalInvoice = (invoiceList && invoiceList.length > 0) ? invoiceList[0] : {};
  let totalDepositAmount = 0;
  let totalAmount = 0;

  _.each(invoiceList, invoice => {
      totalAmount += parseFloat(invoice['total_amount']);
      if (invoice.status === 'paid'){
        totalDepositAmount += parseFloat(invoice['amount_paid']);
      }
  });

  return {
    waitingRequest:state.common.waitingRequest,
    invoice: finalInvoice,
    totalDepositAmount,
    totalAmount,
    error: state.event.invoice.error,
    company: state.company.profile,
    user: state.user.profile
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    getEventInvoice:(eventId)=>dispatch(getEventInvoice(eventId)),
    payInvoiceViaBank:(data)=>dispatch(payInvoiceViaBank(data)),
    payInvoiceViaCard:(data)=>dispatch(payInvoiceViaCard(data)),
  }
};

class EventInvoice extends Component {

  constructor(props){
      super(props);
      this.state = {
        isStripeCardModalOpen: false
      }
  }  

  componentDidMount(){
      const eventId = this.props.match.params['event_id'];
      this.props.getEventInvoice(eventId);
  }

  componentWillReceiveProps(props){
    const {invoice} = props;
    if(invoice.status === 'paid'){
        this.setState({isStripeCardModalOpen: false});
    }
}

  handleOnSuccess = (token, metadata) => {
      this.props.payInvoiceViaBank({
          id: this.props.invoice.id,
          plaid_token: token,
          account_id: metadata.account_id,
          payType: 'final'
      })
  };

  renderInvoice = () => {
      const {invoice, totalAmount, totalDepositAmount, company} = this.props;

      if (!_.isEmpty(invoice)) {
          const amountDue = (parseFloat(totalAmount) - parseFloat(totalDepositAmount)).toFixed(2)
          invoice['due_date'] = moment.utc(invoice['created_at']).add(invoice['days_until_due'], 'day');
          invoice['created_at'] = moment.utc(invoice['created_at']);
          return (
              <Container>
                  <Table basic='very' celled collapsing style={{'float': 'left'}}>
                      <Table.Body>
                          <Table.Row>
                              <Table.Cell>Invoice ID</Table.Cell>
                              <Table.Cell>{invoice.id}</Table.Cell>
                          </Table.Row>

                          <Table.Row>
                              <Table.Cell>Due date</Table.Cell>
                              <Table.Cell>{moment(invoice['due_date']).local().format('ddd, MMM Do, YYYY h:mm:ss A')}</Table.Cell>
                          </Table.Row>

                          <Table.Row>
                              <Table.Cell>Created at</Table.Cell>
                              <Table.Cell>{moment(invoice['created_at']).local().format('ddd, MMM Do, YYYY h:mm:ss A')}</Table.Cell>
                          </Table.Row>
                      </Table.Body>
                  </Table>

                  <Table basic='very' celled collapsing style={{'marginLeft': 'auto'}}>
                      <Table.Body>
                          <Table.Row>
                              <Table.Cell>From</Table.Cell>
                              <Table.Cell>
                                  <strong>Shift Dash</strong>
                                  <p>Georgia, United States</p>
                              </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                              <Table.Cell>For</Table.Cell>
                              <Table.Cell>
                                  <strong>{company.name}</strong>
                                  <p>
                                      {company.street}, {company.city}, {company.state}, {company.country}
                                  </p>
                              </Table.Cell>
                          </Table.Row>
                      </Table.Body>
                  </Table>

                  { invoice['status'] === 'paid' && invoice['all_time_sheets_approved'] &&
                    <Image src={'/assets/images/paid.png'} id={'invoice-paid'}/>
                  }

                  <Table celled padded style={{'marginTop': '50px', 'border': 0}}>
                      <Table.Header>
                          <Table.Row>
                              <Table.HeaderCell>Description</Table.HeaderCell>
                              <Table.HeaderCell>Qty</Table.HeaderCell>
                              <Table.HeaderCell>Unit Price</Table.HeaderCell>
                              <Table.HeaderCell>Total Amount</Table.HeaderCell>
                          </Table.Row>
                      </Table.Header>

                      <Table.Body>
                          {invoice['invoice_line_items'].map(invoiceItem => {
                              const amount = parseFloat(invoiceItem['unit_amount']) * invoiceItem['quantity'];
                              return (
                                  <Table.Row key={invoiceItem['id']}>
                                      <Table.Cell>
                                          {invoiceItem['description']}
                                      </Table.Cell>
                                      <Table.Cell>
                                          {invoiceItem['quantity']}
                                      </Table.Cell>
                                      <Table.Cell>
                                          ${parseFloat(invoiceItem['unit_amount']).toFixed(2)}
                                      </Table.Cell>
                                      <Table.Cell>
                                          ${amount.toFixed(2)}
                                      </Table.Cell>
                                  </Table.Row>
                              )
                          })}
                      </Table.Body>
                  </Table>

                  <Table basic='very' celled collapsing style={{'width': '40%', 'float': 'right'}}>
                      <Table.Body>
                          <Table.Row>
                              <Table.Cell>Subtotal</Table.Cell>
                              <Table.Cell>${invoice['total_amount']}</Table.Cell>
                          </Table.Row>

                          <Table.Row>
                              <Table.Cell>Tax({invoice['tax_percentage']}%)</Table.Cell>
                              <Table.Cell>${invoice['tax'] ? invoice['tax'] : 0}</Table.Cell>
                          </Table.Row>

                      </Table.Body>
                  </Table>

                  <Table basic='very' celled collapsing style={{'width': '40%', 'float': 'right', 'clear': 'both'}}>
                  <Table.Body>
                      <Table.Row>
                          <Table.Cell><strong>Amount Due</strong></Table.Cell>
                          <Table.Cell><strong>${amountDue}</strong></Table.Cell>
                      </Table.Row>

                  </Table.Body>
              </Table>

              </Container>
          )
      }
      else{
          return <div style={{'textAlign': 'center'}}>No invoice found</div>
      }
  };

  handleInvoicePay = (payVia) => {
    this.setState({isStripeCardModalOpen: true});
}

  renderPayButton = () => {
      const {invoice, totalDepositAmount, totalAmount} = this.props;
      const status = invoice ? invoice.status : null;
      const timeSheetsApproved = invoice ? invoice['all_time_sheets_approved'] : null;
      let amountDue = (parseFloat(totalAmount) - parseFloat(totalDepositAmount)).toFixed(2);
      amountDue = invoice ? (amountDue) : 0;
      let buttonTitle = 'Amount due $0';

      const payBtnOptions = [
        {
          key: 'card',
          text: 'via Card',
          value: 'card',
          content: <button onClick={() => this.handleInvoicePay()} className="text ui primary pay-invoice-btn">Via Card</button>
        },
        {
            key: 'bank',
            text: 'via Bank',
            value: 'bank',
            content: (
                <PlaidLink
                clientName="Shift Dash"
                env={settings.PLAID_ENV}
                selectAccount={true}
                product={["auth", "transactions"]}
                publicKey={settings.PLAID_PUBLIC_KEY}
                onExit={this.handleOnExit}
                onSuccess={this.handleOnSuccess}
                className={'ui primary pay-invoice-btn'}
                style={{width: '160px'}}>
                via Bank
              </PlaidLink>
            )
          }
      ]
      

      if (status === 'paid')
          buttonTitle = 'Already paid';
      if (!timeSheetsApproved)
          buttonTitle = 'Time sheets are not approved';

      if(invoice && status !== 'paid' && timeSheetsApproved && (amountDue > 0)) {
         return (
            <Dropdown
                placeholder='Pay Invoice'
                selection
                options={payBtnOptions}
            />
            )
      }

      if(invoice && (status === 'paid' || !timeSheetsApproved || amountDue <= 0)){
          return (
              <Button
                  title={buttonTitle}
                  size={'large'}
                  style={{'background-color': '#ADD8E6'}}
                  >
                  Pay Invoice
              </Button>
          )
      }
  };

  renderLoader = () => {
    const {waitingRequest} = this.props;
    if (waitingRequest){
        return (
            <div style={{'textAlign': 'center'}}>
                <Loader active inline='centered' />
                <br/>
                <span>This may take a while...</span>
            </div>
        )
      }
  }

  render() {
    let {error, waitingRequest, invoice} = this.props;
    return(
      <Container className={'main-container'}>

        {this.renderLoader()}

        <Grid stackable  padded>
        <Grid.Row>
          <Grid.Column>
            <Container fluid style={{textAlign: 'right'}}>
              <Button size='large' >Cancel</Button>
                {this.renderPayButton()}
            </Container>
          </Grid.Column>
        </Grid.Row>
          { error && !waitingRequest && <ServerErrors errorMessage={error} /> }
          { this.renderInvoice() }
        </Grid>

        <Modal 
          open={this.state.isStripeCardModalOpen}
          closeOnDocumentClick={true}
          size={'small'}
          >
                <Modal.Header>Enter Credit Card details</Modal.Header>
                <Modal.Content>
                <Modal.Description>
                    <StripeCardElement invoice={invoice} payType={'final'} submitAction={this.props.payInvoiceViaCard} {...this.props}/>
                </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                <Button onClick={() => this.setState({isStripeCardModalOpen: false})}>
                     Close
                </Button>
                </Modal.Actions>
            </Modal>  
      </Container>
    )
  }
}

export default connect(stateToProps,dispatchToProps)(EventInvoice);
