import React, { Component } from 'react';
import SocialButton from './SocialButton';
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import {Grid, Container, Segment, Button, Form, Divider, Header, Menu, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './signup.css';
import {EMAIL_SIGNUP_OPENED_CLOSED, BACK_FROM_VARIFICATION} from "../../../constants/actionTypes";
import {registerSocialUser} from '../../../actions/signupAction';
import './../Signup/signup.css';
import {common, setAuthHeader} from '../../../utils/common';
import {BLANK_TEXT_ERROR_MESSAGE} from '../../../utils/Constants';
import {loginUser} from '../../../actions/loginAction';
import FieldErrors from '../../Common/FieldErrors';
import ServerErrors from '../../Common/ServerErrors';
import {resendVerificationEmail} from '../../../actions/signupAction';
import {getCompanyName} from '../../../actions/company';
import {loadMessageGroups, loadOneToOneChats} from "../../../actions/messaging";


const stateToProps=(state)=>{
  return{ ...state.signup,
    waitingRequest:state.login.waitingRequest,
    errorMessage:state.login.errorMessage,
    company_name:state.company.profile.name,
    user:state.user.profile
  }
}

const dispatchToProps=(dispatch)=>{
  return{
    setEmailSignupOpened:()=> dispatch({type:EMAIL_SIGNUP_OPENED_CLOSED,value:true}),
    registerSocialUser:(data)=>dispatch(registerSocialUser(data)),
    loginUser:(data)=>dispatch(loginUser(data)),
    resendVerificationEmail:(data)=>dispatch(resendVerificationEmail(data)),
    getCompanyName:(data)=>dispatch(getCompanyName(data)),
    goBack:()=> dispatch({type:BACK_FROM_VARIFICATION,value:true}),
    loadMessageGroups: ()=>dispatch(loadMessageGroups()),
    loadOneToOneChats: ()=>dispatch(loadOneToOneChats())
  }
}


class MainSignup extends Component {
  commonObj=new common();

  constructor(props){
    super(props);
    this.handleChange=this.handleChange.bind(this);
    this.loginUser=this.loginUser.bind(this);
    this.goBack = this.goBack.bind(this)
    this.resendEmail=this.resendEmail.bind(this);
    if (this.props.location.referrer){
        this.redirect_to = this.props.location.referrer.pathname;
    }
    else {
        this.redirect_to = '/signup/next';
    }

    this.signupByEmail=this.signupByEmail.bind(this);

    this.state = {
      form_submitted:false,
      form_error:true,
      email: '',
      password:'',
      server_error:false,
      errors:{
        email:BLANK_TEXT_ERROR_MESSAGE,
        password:BLANK_TEXT_ERROR_MESSAGE
      },

      logged: false,
      user: {},
      provider_id : '',
      type: '',
      provider_name: '',
      last_name: '',
      first_name: '',
      access_token: ''
    }
    this.nodes = {}

    this.onLoginSuccess = this.onLoginSuccess.bind(this)
    this.onLoginFailure = this.onLoginFailure.bind(this)
    this.onLogoutSuccess = this.onLogoutSuccess.bind(this)
    this.onLogoutFailure = this.onLogoutFailure.bind(this)
    this.logout = this.logout.bind(this)
  }

  signupByEmail() {
      this.context.router.history.push('/register')
  }

  gotoNextView = () => {
    const {errorMessage, waitingRequest, user} = this.props;
    if (errorMessage){
      this.setState({form_error: false,server_error:true});
    }

    if (user && !errorMessage && !waitingRequest){
        this.props.history.push(this.redirect_to);
    }
  };

  setNodeRef (provider, node) {
    if (node) {
      this.nodes[ provider ] = node
    }
  }

  onLoginSuccess (user) {

    this.setState({
      logged: true,
      provider_id: user._profile.id,
      email: user._profile.email,
      type: 'talent',
      provider_name: user._provider,
      last_name: user._profile.lastName,
      first_name: user._profile.firstName,
      access_token: user._token.accessToken,
      user
    })

    const { provider_id, email, type, provider_name, last_name, first_name, access_token } = this.state;
    this.props.registerSocialUser({ provider_id, email, type, provider_name, last_name, first_name, access_token });

    this.gotoNextView();
  }

  onLoginFailure (err) {
    console.error(err)

    this.setState({
      logged: false,
      currentProvider: '',
      user: {}
    })
  }

  onLogoutSuccess () {
    this.setState({
      logged: false,
      currentProvider: '',
      user: {}
    })
  }

  onLogoutFailure (err) {
    console.error(err)
  }

  logout () {
    const { logged, currentProvider } = this.state

    if (logged && currentProvider) {
       this.nodes[currentProvider].props.triggerLogout()
    }
  }


  componentWillReceiveProps(nextProps){
    if (nextProps.errorMessage){
      this.setState({form_error: false,server_error:true});
    }

    if (nextProps.user.token && !nextProps.errorMessage && !nextProps.waitingRequest){
      setAuthHeader();
      this.props.loadMessageGroups(); // load group chats from our local API
      this.props.loadOneToOneChats(); // load one-one chats from our local API
      this.props.history.push(this.redirect_to);
    }
  }

  handleChange = (e,{name,value,fieldtype}) => {
    this.commonObj.handleChange(e,this,{name,value,fieldtype});
  }

  loginUser(event){
    event.preventDefault();
    this.setState({form_submitted:true});

    if (this.state.form_error===true || Object.keys(this.state.errors).length > 0){
      return;
    }

    let {email,password } = this.state;
    email = email.trim().toLowerCase();
    this.props.loginUser({ email,password });
  }

  resendEmail(){
    this.props.resendVerificationEmail({email: this.props.email});
  }

  renderServerErrors(){
      if (this.props.emailSignupError){
          return <ServerErrors errorMessage={this.props.emailSignupError} />
      }
      if (this.props.resendEmailError){
          return <ServerErrors errorMessage={this.props.resendEmailError} />
      }
  }

  goBack(){
    this.props.goBack();
    this.props.history.push('/login');
  }

  setNodeRef (provider, node) {
    if (node) {
      this.nodes[ provider ] = node
    }
  }

  renderForm(){
    const {email,password}=this.state;

    if (this.props.emailSignupSuccess){
      return (
        <Form size='large' onSubmit={this.createAccount} error={this.state.server_error}>
        <Segment raised>
            {this.renderServerErrors.bind(this)()}
            <p>
                {
                  this.props.userType === 'talent' ?
                  'Thanks for signing up. Please check your email for a confirmation link.'
                  :
                  'Thanks for your interest in our app. Please check your email for a confirmation link.'
                }
            </p>

            {/*uncomment this for beta release*/}

            {/*<Icon name='envelope outline' size={'huge'} color='blue' />*/}

            {/*<Header size='medium'>We sent a verification email to:</Header>*/}
            {/*<Header size='large' style={{marginTop: '-5px'}}><strong>{this.props.email}</strong></Header>*/}
            {/*<p>*/}
                {/*Click on the verification URL in the email to finalize account*/}
                {/*creation and start building your Shift Dash profile!*/}
            {/*</p>*/}
            {/*<Button fluid*/}
              {/*size={'large'}*/}
              {/*content='RE-SEND EMAIL'*/}
              {/*style={{marginBottom:'25px'}}*/}
              {/*onClick={this.resendEmail}*/}
              {/*loading={this.props.waitingRequest}*/}
            {/*/>*/}
            <Menu secondary>
              <Menu.Item as='a' style={{marginLeft:'auto',marginRight:'auto'}} onClick={this.goBack}>
                <Icon name='arrow left' link/>
                Back
              </Menu.Item>
            </Menu>
        </Segment>
        </Form>
      )
    }

    return(
      <Segment style={{ width: '400px' }}>
                      <Form size='large' onSubmit={this.loginUser} error={(this.state.form_submitted && Object.keys(this.state.errors).length>0) || this.state.server_error}>
                        {this.state.server_error?
                          <ServerErrors errorMessage={this.props.errorMessage} />
                          :null
                        }
                        <Form.Input
                          fluid
                          icon='mail'
                          iconPosition='left'
                          placeholder='Email'
                          name='email'
                          value={email}
                          fieldtype='email'
                          error = {this.state.form_submitted && this.state.errors['email']}
                          onChange={this.handleChange}
                        />
                        <FieldErrors formSubmitted={this.state.form_submitted} errors={this.state.errors} fieldName={'email'}/>
                        <Form.Input
                          fluid
                          icon='lock'
                          iconPosition='left'
                          placeholder='Password'
                          type='password'
                          name='password'
                          value={password}
                          fieldtype='password'
                          error = {this.state.form_submitted && this.state.errors['password']}
                          onChange={this.handleChange}
                        />
                        <FieldErrors formSubmitted={this.state.form_submitted} errors={this.state.errors} fieldName={'password'}/>

                        <Button color='blue' fluid size='large' loading={this.props.waitingRequest} disabled={this.props.waitingRequest}>Sign In</Button>
                        <br/>
                        <Divider horizontal>Or</Divider>
                        <br/>

                    </Form>
                      <SocialButton
                        provider='facebook'
                        appId='174051176589391'
                        onLoginSuccess={this.onLoginSuccess}
                      onLoginFailure={this.onLoginFailure}
                      onLogoutSuccess={this.onLogoutSuccess}
                      getInstance={this.setNodeRef.bind(this, 'facebook')}
                      key={'facebook'}
                      fluid color={'facebook'}
                      size={'large'}
                      content='CONTINUE WITH FACEBOOK'
                      icon='facebook f' labelPosition='left'
                      style={{marginBottom:'25px'}}
                    />
                    <SocialButton
                      provider='google'
                      appId='632902765335-nhliag2gj6qfoofcufqecmeucnlkaq4i.apps.googleusercontent.com'
                      onLoginSuccess={this.onLoginSuccess}
                      onLoginFailure={this.onLoginFailure}
                      onLogoutSuccess={this.onLogoutSuccess}
                      onLogoutFailure={this.onLogoutFailure}
                      getInstance={this.setNodeRef.bind(this, 'google')}
                      key={'google'}
                      fluid color={'google plus'}
                      size={'large'}
                      content='CONTINUE WITH GOOGLE'
                      icon='google' labelPosition='left'
                      style={{marginBottom:'25px'}}
                    />

                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={8}>
                            <Link to="/forgot-password"><span className={'forgot-password-new-here-button'}>Forgot password?</span></Link>
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <Link to="/register"><span className={'forgot-password-new-here-button'}>New here? Sign up</span></Link>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                  </Segment>
    )
  }


  render() {

    return(
      <Container textAlign='center' className={'main-container'}>
        <Grid stackable style={{marginBottom: '0rem'}}>
          <Grid.Column mobile={16} tablet={16} computer={7}>
            <Container style={{ height: '500px' }}>
                <div className={'signup-container-inner'}>
                    { this.renderForm() }
              </div>
          </Container>
          </Grid.Column>
          <Grid.Column computer={9} only='computer' style={{backgroundColor: '#d8c8de'}}>
            <Container>

            </Container>
          </Grid.Column>
        </Grid>
      </Container>
    )
  }
}

MainSignup.contextTypes = {
    router: PropTypes.object.isRequired
};

export default connect(stateToProps,dispatchToProps)(MainSignup);
