import React,{Component} from 'react';
import { withRouter } from "react-router-dom";
import {Segment, Grid, Form, Header, Icon} from 'semantic-ui-react';
import * as moment from 'moment';
import _ from 'lodash';
import './talent-profile.css';
import measurementSystems from '../measurement_units';
import {getUserName} from "../../../utils/common";
import DatePicker from "react-datepicker/es/index";
import {convertWeight, getClosestNumber} from "../../../utils/common";

class EditProfileAbout extends Component{

  constructor(props){
    super(props);
    const talent = this.props.talent.profile;
    const talentMeasurementSystem = talent.measurement_system === 0 ? 'metric' : 'imperial';
    let weight = talent.weight;
    if (weight && talentMeasurementSystem === 'metric') {
        weight = convertWeight(talent.weight, measurementSystems[talentMeasurementSystem]['weight']);
    }
    this.state={
      talentMeasurementSystem,
      form_error:false,
      server_error:false,
      first_name:'',
      errors:{},
      showUploadResume:false,
      resumeFile:null,
      resumeFileName:'Upload Resume',
      talent: {
        overview: talent.overview,
        about: talent.about,
        height : talent.height,
        weight : weight || null,
        hair_color : talent.hair_color,
        hair_type: talent.hair_type,
        is_pregnant: talent.is_pregnant,
        pregnant_due_date: talent.pregnant_due_date,
        tattoos: talent.tattoos || [],
        piercings: talent.piercings || [],
        implants: talent.implants,
        eye_color : talent.eye_color,
        pant_size: talent.pant_size,
        hip_size: talent.hip_size,
        bust_size: talent.bust_size,
        glove_size: talent.glove_size,
        hat_size: talent.hat_size,
        waist : talent.waist,
        shoe_size : talent.shoe_size,
        shirt_size : talent.shirt_size,
        chest_size : talent.chest_size,
        cup_size : talent.cup_size,
        ethnicity : talent.ethnicity,
        gender : talent.gender,
      }
    }
  }

  handleChange = (name, value) => {
    const {talent, talentMeasurementSystem} = this.state;
    talent[name] = value;
    this.setState({talent, formSubmit: true});
    let weight = talent.weight

    //convert weight to pounds before sending to backend if user default system is metric
    if (name === 'weight' && talentMeasurementSystem === 'metric'){
        weight = convertWeight(weight, 'lb')
    }
    this.props.updateTalentProfile({...talent, weight});
  };

  renderFieldError = (fieldName) => {
      const {error} = this.props;
      const {formSubmit} = this.state;
      if (formSubmit && error && error[fieldName]){
          return <span className={'field-error'}  >{error[fieldName]}</span>
      }
  };

  render(){
    const {talent, talentMeasurementSystem} = this.state;
    const tattoosOptions = [
        {key: 0, value: 'face', text: 'Face'},
        {key: 1, value: 'neck', text: 'Neck'},
        {key: 3, value: 'upper-arms(s)', text: 'Upper-Arms(s)'},
        {key: 4, value: 'upper-back', text: 'Upper-Back'},
        {key: 5, value: 'lower-back', text: 'Lower-Back'},
        {key: 6, value: 'chest', text: 'Chest'},
        {key: 7, value: 'wrist', text: 'Wrist'},
        {key: 8, value: 'finger', text: 'Finger'},
        {key: 9, value: 'forearm', text: 'Forearm'},
        {key: 10, value: 'ankle(s)', text: 'Ankle(s)'},
        {key: 11, value: 'feet', text: 'Feet'},
        {key: 12, value: 'shin(s)', text: 'Shin(s)'},
    ];
    const genderOptions=[
    {key: 0,value:'female',text:'Female'},
    {key: 1,value:'male',text:'Male'},
    {key: 2,value:'other',text:'Other'}
  ]

    // these two lines will convert values between imperial and metric system
    // e.g 5'8" to 180 cm and 160 cm to 5'6" to show default in drop down
    const heightOptionsList = _.map(measurementSystems[talentMeasurementSystem]['height'], height => height.value);
    const height = talent.height && getClosestNumber(heightOptionsList, talent.height);
    const chestOptionsList = _.map(measurementSystems[talentMeasurementSystem]['chest'], height => height.value);

    return (
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <Icon name={'user'} size={'large'} style={{marginRight: '10px'}}/>
          <Header size='small' color='grey' style={{display: 'inline'}}>CHARACTERISTICS {/*getUserName(this.props.user)*/}</Header>
        </Segment>
        <Form size='small' error={Object.keys(this.state.errors).length>0 || this.state.server_error}>
            <Segment>
                <div className="ui two column doubling stackable grid container">
                  <div className="column">

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle"> Gender</Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Select
                            options={genderOptions}
                            placeholder='Gender'
                            name={'gender'}
                            value={talent.gender}
                            fieldtype='select'
                            onChange={(e, {name, value}) => this.handleChange(name, value)}
                          />
                            {this.renderFieldError('gender')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle"><p>Height</p></Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Select
                              options={measurementSystems[talentMeasurementSystem]['height']}
                              placeholder='Height'
                              name={'height'}
                              value={height}
                              fieldtype='select'
                              error = {this.renderFieldError('height')}
                              onChange={(e, {name, value}) => this.handleChange(name, value)}
                            />

                            {this.renderFieldError('height')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle">
                              <p>Weight ({measurementSystems[talentMeasurementSystem]['weight']})</p>
                          </Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Input
                                fluid
                                type={'number'}
                                placeholder='Weight'
                                name='weight'
                                value={talent.weight}
                                error = {this.renderFieldError('weight')}
                                onChange={(e, {name, value}) => this.handleChange(name, value)}
                              />
                            {this.renderFieldError('weight')}
                            </Grid.Column>

                          </Grid.Row>
                      </Grid>
                    </div>

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle">Hair Color</Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Select
                              options={measurementSystems[talentMeasurementSystem]['hairColor']}
                              placeholder='Hair Color'
                              name={'hair_color'}
                              value={talent.hair_color}
                              fieldtype='select'
                              error = {this.renderFieldError('hair_color')}
                              onChange={(e, {name, value}) => this.handleChange(name, value)}
                            />
                            {this.renderFieldError('hair_color')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle">Hair Type</Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Select
                                options={measurementSystems[talentMeasurementSystem]['hairType']}
                                placeholder='Hair Type'
                                name={'hair_type'}
                                value={talent.hair_type}
                                fieldtype='select'
                                error = {this.renderFieldError('hair_type')}
                                onChange={(e, {name, value}) => this.handleChange(name, value)}
                              />
                            {this.renderFieldError('hair_type')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle">Eye Color</Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Select
                                options={measurementSystems[talentMeasurementSystem]['eyeColor']}
                                placeholder='Eye Color'
                                name={'eye_color'}
                                value={talent.eye_color}
                                fieldtype='select'
                                error = {this.renderFieldError('eye_color')}
                                onChange={(e, {name, value}) => this.handleChange(name, value)}
                              />
                            {this.renderFieldError('eye_color')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle">Waist</Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Select
                              options={measurementSystems[talentMeasurementSystem]['waist']}
                              placeholder='Waist'
                              name={'waist'}
                              value={talent.waist && getClosestNumber(chestOptionsList, talent.waist)}
                              fieldtype='select'
                              error = {this.renderFieldError('waist')}
                              onChange={(e, {name, value}) => this.handleChange(name, value)}
                            />
                            {this.renderFieldError('waist')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle">Pant Size</Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Select
                              options={measurementSystems[talentMeasurementSystem]['pant']}
                              placeholder='Pant Size'
                              name={'pant_size'}
                              value={talent.pant_size && getClosestNumber(chestOptionsList, talent.pant_size)}
                              fieldtype='select'
                              error = {this.renderFieldError('pant_size')}
                              onChange={(e, {name, value}) => this.handleChange(name, value)}
                            />
                            {this.renderFieldError('pant_size')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle">Hip Size</Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Select
                              options={measurementSystems[talentMeasurementSystem]['hip']}
                              placeholder='Hip Size'
                              name={'hip_size'}
                              value={talent.hip_size && getClosestNumber(chestOptionsList, talent.hip_size)}
                              fieldtype='select'
                              error = {this.renderFieldError('hip_size')}
                              onChange={(e, {name, value}) => this.handleChange(name, value)}
                            />
                            {this.renderFieldError('hip_size')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle">Tattoos</Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Select
                                options={tattoosOptions}
                                multiple
                                placeholder='Tattoos'
                                name='tattoos'
                                value={talent.tattoos}
                                fieldtype='select'
                                error = {this.renderFieldError('tattoos')}
                                onChange={(e, {name, value}) => this.handleChange(name, value)}
                              />
                            {this.renderFieldError('tattoos')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle">Piercings</Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Select
                                options={tattoosOptions}
                                multiple
                                placeholder='Piercings'
                                name='piercings'
                                value={talent.piercings}
                                fieldtype='select'
                                error = {this.renderFieldError('piercings')}
                                onChange={(e, {name, value}) => this.handleChange(name, value)}
                              />
                            {this.renderFieldError('piercings')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                  </div>
                  <div className="column">

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle">Bust Size</Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Select
                              options={measurementSystems[talentMeasurementSystem]['bust']}
                              placeholder='Bust Size'
                              name={'bust_size'}
                              value={talent.bust_size && getClosestNumber(chestOptionsList, talent.bust_size)}
                              fieldtype='select'
                              error = {this.renderFieldError('bust_size')}
                              onChange={(e, {name, value}) => this.handleChange(name, value)}
                            />
                            {this.renderFieldError('bust_size')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle">Shoe Size</Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Select
                              options={measurementSystems[talentMeasurementSystem]['shoe']}
                              placeholder='Shoe Size'
                              name={'shoe_size'}
                              value={talent.shoe_size}
                              fieldtype='select'
                              error = {this.renderFieldError('shoe_size')}
                              onChange={(e, {name, value}) => this.handleChange(name, value)}
                            />
                            {this.renderFieldError('shoe_size')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle">Shirt Size</Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Select
                                options={measurementSystems[talentMeasurementSystem]['shirt']}
                                placeholder='Shirt Size'
                                name='shirt_size'
                                value={talent.shirt_size}
                                fieldtype='select'
                                error = {this.renderFieldError('shirt_size')}
                                onChange={(e, {name, value}) => this.handleChange(name, value)}
                              />
                            {this.renderFieldError('shirt_size')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle">Hat Size</Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Select
                                options={measurementSystems[talentMeasurementSystem]['hat']}
                                placeholder='Hat Size'
                                name='hat_size'
                                value={talent.hat_size}
                                fieldtype='select'
                                error = {this.renderFieldError('hat_size')}
                                onChange={(e, {name, value}) => this.handleChange(name, value)}
                              />
                            {this.renderFieldError('hat_size')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle">Glove Size</Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Select
                                options={measurementSystems[talentMeasurementSystem]['glove']}
                                placeholder='Glove Size'
                                name='glove_size'
                                value={talent.glove_size}
                                fieldtype='select'
                                error = {this.renderFieldError('glove_size')}
                                onChange={(e, {name, value}) => this.handleChange(name, value)}
                              />
                            {this.renderFieldError('glove_size')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                    <div>
                      <Grid columns={2} padded>
                        <Grid.Row>
                          <Grid.Column width={4} verticalAlign="middle">Implants</Grid.Column>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Form.Checkbox
                                name='implants'
                                checked={talent.implants}
                                error = {this.renderFieldError('implants')}
                                onChange={(e, {name}) => this.handleChange(name, !talent.implants)}
                              />
                            {this.renderFieldError('implants')}
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    </div>

                    { talent.gender==="female" &&
                      <div>
                        <Grid columns={2} padded>
                          <Grid.Row>
                            <Grid.Column width={4} verticalAlign="middle">Chest Size</Grid.Column>
                            <Grid.Column width={12} verticalAlign="middle">
                              <Form.Select
                                options={measurementSystems[talentMeasurementSystem]['chest']}
                                placeholder='Chest Size'
                                name={'chest_size'}
                                value={talent.chest_size && getClosestNumber(chestOptionsList, talent.chest_size)}
                                fieldtype='select'
                                error = {this.renderFieldError('chest_size')}
                                onChange={(e, {name, value}) => this.handleChange(name, value)}
                              />
                              {this.renderFieldError('chest_size')}
                              </Grid.Column>
                            </Grid.Row>
                        </Grid>
                      </div>
                    }

                    { talent.gender==="female" &&
                       <div>
                        <Grid columns={2} padded>
                          <Grid.Row>
                            <Grid.Column width={4} verticalAlign="middle">Cup Size</Grid.Column>
                            <Grid.Column width={12} verticalAlign="middle">
                              <Form.Select
                                options={measurementSystems[talentMeasurementSystem]['cup']}
                                placeholder='Cup Size'
                                name={'cup_size'}
                                value={talent.cup_size}
                                fieldtype='select'
                                error = {this.renderFieldError('cup_size')}
                                onChange={(e, {name, value}) => this.handleChange(name, value)}
                              />
                              {this.renderFieldError('cup_size')}
                              </Grid.Column>
                            </Grid.Row>
                        </Grid>
                      </div>
                    }

                    { talent.gender==="female" &&
                       <div>
                        <Grid columns={2} padded>
                          <Grid.Row>
                            <Grid.Column width={4} verticalAlign="middle">Pregnant?</Grid.Column>
                            <Grid.Column width={12} verticalAlign="middle">
                              <Form.Checkbox
                                name={'is_pregnant'}
                                checked={talent.is_pregnant}
                                error = {this.renderFieldError('is_pregnant')}
                                onChange={(e, {name}) => this.handleChange(name, !talent.is_pregnant)}
                              />
                              {this.renderFieldError('is_pregnant')}
                              </Grid.Column>
                            </Grid.Row>
                        </Grid>
                      </div>
                    }

                    { talent.gender === 'female' &&
                        <div>
                        <Grid columns={2} padded>
                          <Grid.Row>
                            <Grid.Column width={4} verticalAlign="middle">Pregnant Due Date</Grid.Column>
                            <Grid.Column width={12} verticalAlign="middle">
                              <DatePicker
                                placeholderText={'Pregnant Due Date'}
                                selected={talent.pregnant_due_date && moment(talent.pregnant_due_date)}
                                name={'pregnant_due_date'}
                                fieldtype='text'
                                error = {this.renderFieldError('pregnant_due_date')}
                                onChange={(date) => this.handleChange('pregnant_due_date', date.format('YYYY-MM-DD'))}
                              />
                              {this.renderFieldError('pregnant_due_date')}
                              </Grid.Column>
                            </Grid.Row>
                        </Grid>
                      </div>
                    }



                  </div>
                </div>
            </Segment>
        </Form>
      </Segment.Group>
    )
  }
}

export default withRouter(EditProfileAbout)
