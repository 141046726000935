import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Dropdown,
  Loader,
  Table,
  Modal,
  Image,
  Grid,
  Button,
  Card,
  Icon,
  Header
} from "semantic-ui-react";
import PlaidLink from "react-plaid-link";
import _ from "lodash";
import moment from "moment";
import "./events.css";
import {
  getEventInvoice,
  payInvoiceViaBank,
  payInvoiceViaCard
} from "../../actions/event";
import ServerErrors from "../Common/ServerErrors";
import DepositHelp from "./DepositHelp";
import * as settings from "./../../../src/constants/constants";
import StripeCardElement from "./StripeCardElement";
import ServerSuccess from "../Common/ServerSuccess";

const REDIRECT_WAIT_IN_MSECONDS = 3000;

const stateToProps = state => {
  const invoiceList = state.event.invoice.data;
  let finalInvoice =
    invoiceList && invoiceList.length > 0 ? invoiceList[0] : {};
  let totalDepositAmount = 0;
  let totalAmount = 0;

  _.each(invoiceList, invoice => {
    totalAmount += parseFloat(invoice["total_amount"]);
    if (invoice.status === "paid") {
      totalDepositAmount += parseFloat(invoice["amount_paid"]);
    }
  });

  return {
    waitingRequest: state.common.waitingRequest,
    invoice: finalInvoice,
    totalDepositAmount,
    totalAmount,
    error: state.event.invoice.error,
    company: state.company.profile,
    user: state.user.profile
  };
};

const dispatchToProps = dispatch => {
  return {
    getEventInvoice: eventId => dispatch(getEventInvoice(eventId)),
    payInvoiceViaBank: data => dispatch(payInvoiceViaBank(data)),
    payInvoiceViaCard: data => dispatch(payInvoiceViaCard(data))
  };
};

class EventDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStripeCardModalOpen: false,
      displayMessage: "",
      redirect: false
    };
  }

  componentDidMount() {
    const eventId = this.props.match.params["event_id"];
    this.props.getEventInvoice(eventId);
  }

  componentWillReceiveProps(props) {
    const { invoice, company } = props;

    if (parseFloat(company.deposit_amount) === 0) {
      this.setState({
        displayMessage: "No Deposit Required ",
        redirect: true
      });
    } else if (invoice.status === "paid") {
      this.setState({
        isStripeCardModalOpen: false,
        displayMessage: "Payment Complete ",
        redirect: true
      });
    }
  }

  handleOnSuccess = (token, metadata) => {
    this.props.payInvoiceViaBank({
      id: this.props.invoice.id,
      plaid_token: token,
      account_id: metadata.account_id,
      payType: "partial"
    });
  };

  renderInvoice = () => {
    const { invoice, totalAmount, totalDepositAmount, company } = this.props;

    if (!_.isEmpty(invoice)) {
      const amountDue = (
        parseFloat(totalAmount) - parseFloat(totalDepositAmount)
      ).toFixed(2);
      invoice["due_date"] = moment
        .utc(invoice["created_at"])
        .add(invoice["days_until_due"], "day");
      invoice["created_at"] = moment.utc(invoice["created_at"]);
      return (
        <Container>
          <Table celled padded style={{ marginTop: "50px", border: 0 }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Qty</Table.HeaderCell>
                <Table.HeaderCell>Unit Price</Table.HeaderCell>
                <Table.HeaderCell>Total Amount</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {invoice["invoice_line_items"].map(invoiceItem => {
                const amount =
                  invoiceItem["unit_amount"] * invoiceItem["quantity"];
                return (
                  <Table.Row key={invoiceItem["id"]}>
                    <Table.Cell>{invoiceItem["description"]}</Table.Cell>
                    <Table.Cell>{invoiceItem["quantity"]}</Table.Cell>
                    <Table.Cell>
                      ${parseFloat(invoiceItem["unit_amount"]).toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>${amount.toFixed(2)}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>

          <Table
            basic="very"
            celled
            collapsing
            style={{ width: "40%", float: "right" }}
          >
            <Table.Body>
              <Table.Row>
                <Table.Cell>Subtotal</Table.Cell>
                <Table.Cell>${totalAmount.toFixed(2)}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Tax ({invoice["tax_percentage"]}%)</Table.Cell>
                <Table.Cell>${invoice["tax"] ? invoice["tax"] : 0}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Table
            basic="very"
            celled
            collapsing
            style={{ width: "40%", float: "right", clear: "both" }}
          >
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <strong>Amount Due</strong>
                </Table.Cell>
                <Table.Cell>
                  <strong>${amountDue}</strong>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>
      );
    } else {
      return <div style={{ textAlign: "center" }}>No invoice found</div>;
    }
  };

  handleDepositPay = payVia => {
    this.setState({ isStripeCardModalOpen: true });
  };

  renderPayButton = () => {
    const { invoice, totalDepositAmount, totalAmount } = this.props;
    const status = invoice ? invoice.status : null;
    let amountDue = (
      parseFloat(totalAmount) - parseFloat(totalDepositAmount)
    ).toFixed(2);
    amountDue = invoice ? amountDue : 0;
    let buttonTitle = "Amount due $0";
    const payBtnOptions = [
      {
        key: "card",
        text: "via Card",
        value: "card",
        content: (
          <button
            onClick={() => this.handleDepositPay()}
            className="text ui primary pay-invoice-btn"
          >
            Via Card
          </button>
        )
      },
      {
        key: "bank",
        text: "via Bank",
        value: "bank",
        content: (
          <PlaidLink
            clientName="Shift Dash"
            env={settings.PLAID_ENV}
            selectAccount={true}
            product={["auth", "transactions"]}
            publicKey={settings.PLAID_PUBLIC_KEY}
            onExit={this.handleOnExit}
            onSuccess={this.handleOnSuccess}
            className={"ui primary pay-invoice-btn"}
            style={{ width: "160px" }}
          >
            via Bank
          </PlaidLink>
        )
      }
    ];

    if (status === "paid") buttonTitle = "Already paid";

    if (invoice && status !== "paid" && amountDue > 0) {
      return (
        <Dropdown
          placeholder="Pay Deposit"
          fluid
          selection
          options={payBtnOptions}
        />
      );
    }

    if (invoice && (status === "paid" || amountDue <= 0)) {
      return (
        <Button
          title={buttonTitle}
          size={"large"}
          style={{ "background-color": "#ADD8E6", width: "160px" }}
        >
          Deposit
        </Button>
      );
    }
  };

  renderLoader = () => {
    const { waitingRequest } = this.props;
    if (waitingRequest) {
      return (
        <div style={{ textAlign: "center" }}>
          <Loader active inline="centered">
            This may take a while...
          </Loader>
        </div>
      );
    }
  };

  // redirect to event detail page after 3 seconds
  redirectToEventDetailPage = () => {
    setTimeout(() => {
      this.props.history.push(`/event/${this.props.match.params["event_id"]}`);
    }, REDIRECT_WAIT_IN_MSECONDS);
  };

  renderMessage = () => (
    <ServerSuccess
      successMessage={`${
        this.state.displayMessage
      }! will be redirected to event detail page in ${REDIRECT_WAIT_IN_MSECONDS /
        1000} seconds`}
    />
  );

  render() {
    let {
      error,
      waitingRequest,
      totalAmount,
      totalDepositAmount,
      invoice,
      company
    } = this.props;

    const status = invoice ? invoice.status : null;

    const amountDue = (
      parseFloat(totalAmount) - parseFloat(totalDepositAmount)
    ).toFixed(2);

    return (
      <Container className={"main-container"}>
        {this.renderLoader()}
        {this.state.redirect ? this.renderMessage() : null}
        {this.state.redirect ? this.redirectToEventDetailPage() : null}
        <Grid stackable>
          <Grid.Row style={{ marginTop: "-10px" }}>
            <Grid.Column computer={9} tablet={16} mobile={16}>
              <Grid.Row>
                <Container>
                  <div className={"deposit-container"}>
                    <Card>
                      <Card.Content>
                        <Card.Header>
                          <Icon name="calendar alternate outline" />
                          {!_.isEmpty(invoice) && invoice.event.name}
                        </Card.Header>
                        <Card.Meta>
                          {!_.isEmpty(invoice) && invoice.event.event_type}
                        </Card.Meta>
                        <Card.Description>
                          <div style={{ textAlign: "center", padding: "15px" }}>
                            Total: <strong>${amountDue}</strong>
                          </div>
                        </Card.Description>
                      </Card.Content>
                      <Card.Content extra>
                        <Container textAlign="center">
                          {this.renderPayButton()}
                        </Container>
                      </Card.Content>
                    </Card>
                  </div>
                </Container>
              </Grid.Row>
              <Grid.Row>
                {error && !waitingRequest && (
                  <ServerErrors errorMessage={error} />
                )}
                {this.renderInvoice()}
              </Grid.Row>
            </Grid.Column>
            <Grid.Column
              computer={7}
              mobile={16}
              tablet={16}
              style={{ backgroundColor: "#d8c8de" }}
            >
              <br />
              <Container textAlign="center">
                <Header as="h2" icon>
                  <Icon name="bullhorn" />
                  Let's find staff!
                  <Header.Subheader>
                    Make the deposit to get started.
                  </Header.Subheader>
                </Header>
              </Container>
              <br />
              <DepositHelp />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Modal
          open={this.state.isStripeCardModalOpen}
          closeOnDocumentClick={true}
          size={"small"}
        >
          <Modal.Header>Enter Credit Card details</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <StripeCardElement
                invoice={invoice}
                payType={"partial"}
                submitAction={this.props.payInvoiceViaCard}
                {...this.props}
              />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => this.setState({ isStripeCardModalOpen: false })}
            >
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}

export default connect(
  stateToProps,
  dispatchToProps
)(EventDeposit);
