import {
  Segment,
  Header,
  List,
  Icon,
  Grid,
  Label,
  Button,
  Divider,
  Table,
  Message,
  Menu,
  Container
} from "semantic-ui-react";
import React from "react";
import _ from "lodash";
import DateBox from "../../../Common/DateBox";
import * as moment from "moment";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";

import {
  formatDateRange,
  getJobEarliestDate,
  getJobEarliestShiftTime,
  getJobLatestDate,
  getJobLatestShiftTime,
  getAppliedAndHiredCountByRole,
  getJobMaxRate,
  getJobMinRate
} from "../../helpers";

export default class Details extends React.Component {
  constructor(props) {
    super(props);

    let formattedRoles = [];
    if (this.props.job.talent_roles){
      formattedRoles = this.props.job.talent_roles.map((role)=>{
        return {...role, activeTab: 'general'};
      });
    }

    this.state = {
      visibleDescChars: 300,
      showReadMore: true,
      job: {...this.props.job, talent_roles: formattedRoles}
    };
  }

  handleItemClick = (e, name, role) => {
    const formattedRoles = this.state.job.talent_roles.map(item => {
      if (item.id === role.id) {
        return { ...role, activeTab: name };
      }

      return item;
    });

    this.setState({
      job: {
        ...this.state.job,
        talent_roles: formattedRoles
      }
    });
  };

  multiDayEvent = days => {
    if (days.length > 1) {
      return (
        <Label as="a" color="olive" ribbon="left">
          {days.length}-day event
        </Label>
      );
    }
  };

  rolePayLabel = role => {
    const pay_type = role.pay_type === "hourly" ? "hourly" : "fixed";
    const pay_amount =
      role.pay_type === "hourly" ? role.hourly_rate_talent : role.fixed_rate_talent;
    return (
      <Label as="a" color={"blue"} style={{ marginLeft: "10px" }}>
        {pay_type}
        <Label.Detail>{pay_amount}</Label.Detail>
      </Label>
    );
  };

  countDown = start_date => {
    const now = moment();
    const end = moment(start_date);
    const days = end.diff(now, "days");
    const hours = end.diff(now, "hours");
    const humanize = end.fromNow();
    // const days = humanize.asDays();

    if (hours > 0 && days < 7) {
      return (
        <Label as="a" color="red" ribbon="left">
          {humanize}
        </Label>
      );
    } else if (days > 7 && days < 21) {
      return (
        <Label as="a" color="olive" ribbon="left">
          {humanize}
        </Label>
      );
    }
  };

  jobLocation = location => {
    const location1 = location.substr(0, location.indexOf(","));
    const location2 = location.substr(
      location.indexOf(",") + 1,
      location.length
    );
    return (
      <Grid columns={2}>
        <Grid.Column width={3}>
          <Icon
            name={"location arrow"}
            color={"yellow"}
            size="big"
            style={{
              textAlign: "center",
              alignSelf: "center"
            }}
          />
        </Grid.Column>
        <Grid.Column width={13}>
          {location1}
          <br />
          {location2}
        </Grid.Column>
      </Grid>
    );
  };

  getRoleList = (job) => {
    return job.talent_roles.map((role)=> {
      const applied = job.applied_hired[`role_${role.id}`] ? job.applied_hired[`role_${role.id}`].applied : 0;
      const hired = job.applied_hired[`role_${role.id}`] ? job.applied_hired[`role_${role.id}`].hired : 0;
      const shiftsByEventDay = _.chain((role.shifts))
        .groupBy((shift)=>{
          return shift.event_day.id;
        }).map((item) =>{
          return item;
        }).value().map((item)=>{
          return item.map((shift,i)=>{
            if (i===0){
              return (
                <React.Fragment key={i}>
                  <div>
                    <Label tag>{moment.utc(shift.event_day.date,'YYYY-MM-DD').local().format('ddd MMM D')}</Label>
                  </div>
                  <div>
                    <Label basic>{moment.utc(shift.start_time,'HH:mm:ss').local().format('hh:mm a')} -  {moment.utc(shift.end_time,'HH:mm:ss').local().format('hh:mm a')}</Label>
                  </div>
                  {i === (item.length-1) && <br/>}
                </React.Fragment>
              )
            }

            if (i === (item.length-1)){
              return (
                <React.Fragment key={i}>
                  <div>
                    <Label basic>{moment.utc(shift.start_time,'HH:mm:ss').local().format('hh:mm a')} -  {moment.utc(shift.end_time,'HH:mm:ss').local().format('hh:mm a')}</Label>
                  </div>
                  <br/>
                </React.Fragment>
              )
            }

            return(
              <div key={i}>
                <Label basic>{moment.utc(shift.start_time,'HH:mm:ss').local().format('hh:mm a')} -  {moment.utc(shift.end_time,'HH:mm:ss').local().format('hh:mm a')}</Label>
              </div>
            )
          });
        });

      return (
        <Segment key={role.id} padded>
          <Label attached='top'>
            <Grid>
              <Grid.Column width={8}>
                <Label circular color={'orange'} style={{marginRight: '10px'}}>{role.count}</Label>
                {role.name}
              </Grid.Column>
              <Grid.Column width={8} textAlign={'right'}>
                <Label.Group>
                  <Label basic as='a' color='blue'>
                    <Icon name={'sign-in alternate'}/>
                    Applied
                    <Label.Detail>{applied}</Label.Detail>
                  </Label>
                  <Label basic as='a' color='blue'>
                    <Icon name={'handshake'}/>
                    Hired
                    <Label.Detail>{hired}</Label.Detail>
                  </Label>
                </Label.Group>
              </Grid.Column>
            </Grid>
          </Label>

          <Container fluid>
            <Grid style={{marginTop: 0, marginBottom: 20}}>
              <Grid.Column width={4}>
                <Menu fluid vertical tabular>
                  <Menu.Item name='general' active={role.activeTab === 'general'} onClick={(e) => this.handleItemClick(e, 'general',role)} />
                  <Menu.Item name='schedule' active={role.activeTab === 'schedule'} onClick={(e) => this.handleItemClick(e,'schedule',role)} />
                </Menu>
              </Grid.Column>

              <Grid.Column stretched width={12}>
                {role.activeTab === 'general' &&
                <Segment>
                  <Header size='small' color='grey' >DUTIES</Header>
                  { ReactHtmlParser(role.duties) }
                  <Header size='small' color='grey' >ATTIRE</Header>
                  <p>{ role.attir }</p>
                  {/* <strong>Gender:</strong> {role.gender.initCap()} */}
                  <br/>
                  {role.height_min !== null && role.height_min !== "" ? <strong>Min. Height:</strong> : null}
                  {role.height_min !== null && role.height_min !== "" ? role.height_min : null}
                  {role.height_min !== null && role.height_min !== "" ? <br/> : null}

                  {role.height_max !== null && role.height_max !== "" ? <strong>Max. Height:</strong> : null}
                  {role.height_max !== null && role.height_max !== "" ? role.height_max : null}
                  {role.height_max !== null && role.height_max !== "" ? <br/> : null}

                  {role.skills && role.skills.length !==0 ? <strong>Skills:</strong>  : null}
                  {role.skills && role.skills.length !==0 ? role.skills.map((item)=> item.name).join(',') : null}
                  {role.skills && role.skills.length !==0 ? <br/> : null}

                  {role.certifications && role.certifications.length !==0 ? <strong>Certifications:</strong>  : null}
                  {role.certifications && role.certifications.length !==0 ? role.certifications.map((item)=> item.name).join(',') : null}
                  {role.certifications && role.certifications.length !==0 ? <br/> : null}
                </Segment>
                }

                {role.activeTab === 'schedule' &&
                <Segment>
                  {shiftsByEventDay}
                </Segment>
                }
              </Grid.Column>
            </Grid>
          </Container>

          <Label attached='bottom'>
            <Grid>
              <Grid.Column width={8}>

              </Grid.Column>
              <Grid.Column width={8} textAlign={'right'}>
                {this.rolePayLabel(role)}
              </Grid.Column>
            </Grid>
          </Label>
        </Segment>
      )
    });
  };

  renderReadMoreButton = () => {
    const { details } = this.props.job;
    if (details && details.length > this.state.visibleDescChars) {
      if (this.state.showReadMore) {
        return (
          <a
            href={"javascript:void(0)"}
            onClick={() =>
              this.setState({
                showReadMore: false
              })
            }
            className={"read-more"}
            color={"pink"}
          >
            Read More
          </a>
        );
      } else {
        return (
          <a
            href={"javascript:void(0)"}
            onClick={() =>
              this.setState({
                showReadMore: true
              })
            }
            className={"read-more"}
            color={"pink"}
          >
            Less
          </a>
        );
      }
    }
  };

  headerText = job => {
    if (job.event_type_name != null) {
      return <Header as={"h3"}>{job.event_type_name}</Header>;
    }
  };

  render() {
    if (_.isEmpty(this.props.job)) {
      return;
    }

    if (
      this.props.job["talent_apply_status"] !== null &&
      _.isEmpty(this.props.applyed)
    ) {
      return null;
    }

    const { job } = this.state;
    job.startDate = getJobEarliestDate(job);
    job.endDate = getJobLatestDate(job);
    job.dateRange = formatDateRange(job);
    job.startTime = getJobEarliestShiftTime(job);
    job.endTime = getJobLatestShiftTime(job);
    job.applied_hired = getAppliedAndHiredCountByRole(job);
    const { showReadMore } = this.state;

    return (
      <Grid.Column mobile={16} tablet={10} computer={10}>
        <Segment.Group>
          <Segment secondary textAlign={"left"}>
            <Grid columns={2}>
              <Grid.Column width={12}>
                <Icon
                  name={"bullhorn"}
                  size={"large"}
                  style={{ marginRight: "10px" }}
                />
                <Header size="small" color="grey" style={{ display: "inline" }}>
                  {job.name}
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign={"right"}>
                <Label as="a" basic color="grey">
                  {job.status}
                </Label>
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment>
            <React.Fragment>
              <Divider horizontal>
                <Header as="h4">
                  <Icon name="info" />
                  Description
                </Header>
              </Divider>

              <Grid columns={2}>
                <Grid.Column width={3}>
                  <DateBox date={job.start_date} />
                  {this.multiDayEvent(job.event_days)}
                  {this.countDown(job.start_date)}
                </Grid.Column>
                <Grid.Column width={13}>
                  <List.Description className="three-line-text-clamp">
                    {showReadMore && job.details
                      ? job.details.slice(0, this.state.visibleDescChars)
                      : job.details}
                  </List.Description>
                  {this.renderReadMoreButton()}
                </Grid.Column>
              </Grid>

              <Divider horizontal>
                <Header as="h4">
                  <Icon name="car" />
                  Travel
                </Header>
              </Divider>
            </React.Fragment>
            <Grid columns={2}>
              <Grid.Column width={8} style={{ alignSelf: "center" }}>
                {this.jobLocation(job["event_days"][0].location)}
              </Grid.Column>
              <Grid.Column width={8}>
                {job["travel_tips"] !== "" && (
                  <Message content={job["travel_tips"]} />
                )}
              </Grid.Column>
            </Grid>
            <Divider horizontal>
              <Header as="h4">
                <Icon name="users" />
                Roles
              </Header>
            </Divider>
            {this.getRoleList(job)}
          </Segment>
        </Segment.Group>
      </Grid.Column>
    );
  }
}
