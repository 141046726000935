import {
  GET_SETTINGS_FULFILLED,
  CONNECT_STRIPE_ACCOUNT_ERROR,
  GET_SETTINGS_FULFILLED_JSON,
  ADD_USER_FULFILLED,
  ADD_USER_FAILED,
  REMOVE_USER_FULFILLED,
  REMOVE_USER_FAILED,
  GET_SOCIAL_PROFILE_FULFILLED,
  GET_SOCIAL_PROFILE_FAILED,
  ADD_SOCIAL_PROFILE_SUCCESS,
  ADD_SOCIAL_PROFILE_ERROR,
  DELETE_SOCIAL_PROFILE_SUCCESS,
  DELETE_SOCIAL_PROFILE_ERROR,
} from "../constants/actionTypes";

const initialState={
  data:{connectedAgencyList:[]},
  companyUsers:{connectedCompanyUsersList:[]},
  errorMessage:null,
  social:[]
}

export default (state=initialState,action)=>{
  switch (action.type){
    case GET_SETTINGS_FULFILLED:{
      const st={
        ...state,
        companyUsers:action.responseData
      }

      return st;
    }

      case CONNECT_STRIPE_ACCOUNT_ERROR:{
          const st={
              ...state,
              stripeConnectError:action.errorMessage
          }

          return st;
      }


    case GET_SETTINGS_FULFILLED_JSON:{
      const st={
        ...state,
        data:action.responseData
      }

      return st;
    }

    case ADD_USER_FULFILLED:{
      const st={
        ...state,
        data:action.responseData,
        errorMessage:null
      }

      return st;
    }
    case ADD_USER_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }
    
    case REMOVE_USER_FULFILLED:{
      const st={
        ...state,
        data:action.responseData
      }

      return st;
    }
    case REMOVE_USER_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }

    case ADD_SOCIAL_PROFILE_SUCCESS:{
      let {social} = state;
      social.push(action.responseData)
      const st={
        ...state,
        social:social,
        errorMessage:null
      }

      return st;
    }
    case ADD_SOCIAL_PROFILE_ERROR:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }

    case GET_SOCIAL_PROFILE_FULFILLED:{
      const st={
        ...state,
        social:action.responseData,
        errorMessage:null
      }

      return st;
    }
    case GET_SOCIAL_PROFILE_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }

    case DELETE_SOCIAL_PROFILE_SUCCESS:{
      let {social} = state;
      for (var i=social.length-1; i>=0; i--) {
          if (social[i].id === action.responseData.id) {
              social.splice(i, 1);
              break;
          }
      }
      const st={
        ...state,
        social:social,
        errorMessage:null
      }

      return st;
    }
    case DELETE_SOCIAL_PROFILE_ERROR:{      
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }

    default:
      return state;
  }
}