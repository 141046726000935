import React,{Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {
  Container,
  Grid,
  Menu,
  Dropdown,
  Button,
  Icon,
  Form,
  Sticky
} from 'semantic-ui-react';
import InfiniteCalendar, {
  Calendar,
  withMultipleDates,
  defaultMultipleDateInterpolation }
from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';

import './jobs-company.css';
import {getJobsData, getJobsDataByDate} from "../../../actions/jobsCompanyAction";
import {isMobileOnly} from "react-device-detect";
import {common} from "../../../utils/common";
import JobList from "./JobList";
import {GET_COMPANY_JOBS_FILTERED_DATA} from "../../../constants/actionTypes";

const stateToProps=(state)=>{
  return{
    data:state.jobsCompany.data,
    token:state.user.profile.token,
    filteredData:state.jobsCompany.filteredData,
    errorMessage:state.jobsCompany.errorMessage
  }
}

const dispatchToProps=(dispatch)=>{
  return{
    getJobsDataByDate:(token,sort,filter)=> dispatch(getJobsDataByDate(token,sort,filter)),
    getJobsData:(token)=> dispatch(getJobsData(token)),
    getJobsFilterData:(filteredData)=> dispatch({type:GET_COMPANY_JOBS_FILTERED_DATA,filteredData:filteredData}),
  }
}

class JobsCompany extends Component{
  constructor(props){
    super(props);
    this.handleSelectFilterChange=this.handleSelectFilterChange.bind(this);
    this.sortedBy = this.sortedBy.bind(this)

    let today = new Date(),
     datecurrent = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();


    this.state={
      searchType:'All',
      searchText:'',
      sortType:'date',
      selectedDate: [],
      id:0,
      defaultDates:[],
      calenderDate : []  //moment(datecurrent,'YYYY-MM-DD')
    }
  }

  jobTypeList = [
    { text: "All Types", value: "All"},
    { text: "Draft", value: "draft" },
    // { text: "Assign Shifts", value: "assign-shifts" },
    { text: "Published", value: "published" },
    { text: "Complete", value: "complete" }
  ];
  commonObj=new common();

  componentDidMount(){
    this.props.getJobsDataByDate(this.props.token,this.state.sortType,this.state.searchType);
  }

  componentWillReceiveProps(nextProps) {
    let dates=[];
    nextProps.filteredData.map((item)=>{
      let i = 0
      while (true) {
        if(moment(item.end_date,'YYYY-MM-DD').diff(moment(item.start_date,'YYYY-MM-DD').add(i, 'days'), 'days')<0)
          break
        dates.push(moment(item.start_date,'YYYY-MM-DD').add(i, 'days'))
        i++;
      }
    });
    this.setState({calenderDate:dates, defaultDates:dates})
  }

  handleContextRef = contextRef => this.setState({ contextRef })

  handleSelectFilterChange(event, {name,value,fieldtype}){
    this.commonObj.handleChange(event,this,{name,value,fieldtype});
    this.props.getJobsDataByDate(this.props.token,this.state.sortType,value);
    this.setState({isLoading:true,searchType:value})
  }

  clearFilters(event) {
    this.setState({
      searchType:'All',
      searchText:'',
    });
    this.props.getJobsDataByDate(this.props.token,this.state.sortType,"All");
  }

  handleChange(event,data){
    this.setState({searchText:data.value});
  }

  search(event){
    if (this.state.searchText===''){
      this.props.getJobsFilterData(this.props.data);
    }
    else{
      const filteredData=this.props.data.filter((item)=>{
        return common.contains(item.title,this.state.searchText);
      })
      this.props.getJobsFilterData(filteredData);
    }
  }

  sortedBy(value){
    this.setState({sortType:value})
    this.props.getJobsDataByDate(this.props.token,value,this.state.searchType);
  }

  calendarSelect(event){
    // let selectedDate=moment(event).format('YYYY-MM-DD');
    let selectedDate = this.state.selectedDate
    this.setState({selectedDate:selectedDate})
    // this.props.getJobsDataByDate(selectedDate);
  }

  goToPage = (id) =>{
    this.props.history.push(`/jobdetails/${id}`);
  }

  onHover = (id) =>{
    let dates=[];
    this.props.filteredData.map((item)=>{
      let i = 0
      if(item.id==id){
        while (true) {
          if(moment(item.end_date,'YYYY-MM-DD').diff(moment(item.start_date,'YYYY-MM-DD').add(i, 'days'), 'days')<0)
            break
          dates.push(moment(item.start_date,'YYYY-MM-DD').add(i, 'days'))
          i++;
        }
      }
    });
    this.setState({id:id})
  }

  onLeave = (id) =>{
    this.setState({calenderDate:this.state.defaultDates,id:0});
  }

  checkDate(date){

    for(var i=0;i<this.props.filteredData.length;i++){
      var startDate = moment(this.props.filteredData[i].start_date, "YYYY-MM-DD")
      , endDate   = moment(this.props.filteredData[i].end_date, "YYYY-MM-DD")
      , date  = moment(date, "YYYY-MM-DD");
      if(startDate <= date && date <= endDate){
        if(this.props.filteredData[i].id==this.state.id){
          if(this.props.filteredData[i].status=="published"){
            return "#ff0000";
          }else{
            return "#21ba45";
          }
        }else if(this.state.id==0){
          if(this.props.filteredData[i].status=="published"){
            return "#ff0000";
          }else{
            return "#21ba45";
          }
        }
      }
    }
    return "#808080";
  }

  render(){

    const { contextRef } = this.state

    let completedJobs = [];
    let notCompletedJobs = [];

    this.props.filteredData.map((item)=>{
      if (item.status==="complete"){
        completedJobs.push(item)
      } else {
        notCompletedJobs.push(item)
      }
    });

    let jobs = notCompletedJobs.concat(completedJobs);

    let {calenderDate}=this.state;
    return(
      <Container className={'main-container'}>
        <Menu
          className={'secondary-fixed-top-menu'}
          stackable
          { ...( isMobileOnly===false && { fixed: 'top' } ) }
        >
          <Menu.Item className={'filter-menu-item location'}>
            <Dropdown
              placeholder='Select Location'
              fluid
              selection
              options={this.jobTypeList}
              name={'searchType'}
              value={this.state.searchType}
              fieldtype={'select'}
              onChange={this.handleSelectFilterChange}/>
          </Menu.Item>
          <Menu.Item className={'filter-menu-item clear-button'}>
            <Button basic icon labelPosition='right' onClick={(e)=>{this.clearFilters(e)}}>
              <Icon name='trash' />
              Clear Filters
            </Button>
          </Menu.Item>
          <Menu.Item className={'filter-menu-item search-input'} position={'right'}>
            <Form size='large' onSubmit={(e)=>this.search(e)} className={'search-form'}>
              <Form.Input
                fluid
                icon={<Icon name='search' link onClick={(e)=>this.search(e)} />}
                placeholder='Search...'
                name='searchText'
                value={this.state.searchText}
                fieldtype='text'
                onChange={(e,{value})=>this.handleChange(e,{value})}
              />
            </Form>
          </Menu.Item>
        </Menu>

        <Grid stackable padded>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={11} computer={11}>
              <div ref={this.handleContextRef} onMouseLeave={(e)=>{this.onLeave()}}>
                <JobList onLeave={this.onLeave} onHover={this.onHover} goToPage={this.goToPage} jobs={jobs} sortedBy={this.sortedBy} history={this.props.history}/>
              </div>
            </Grid.Column>
            <Grid.Column tablet={5} computer={5} only='tablet computer' style={{minHeight:'550px',zIndex:'-1',marginBottom:'30px'}}>
              <Sticky context={contextRef} offset={110}>
                <InfiniteCalendar
                  Component={withMultipleDates(Calendar)}
                  width={'100%'}
                  height={395}
                  theme={{
                    selectionColor: date => {
                      return this.checkDate(date);
                    }
                  }}
                  styles={{position:'sticky',top:'110px'}}
                  selected={calenderDate}
                  interpolateSelection={(selectedDate) => defaultMultipleDateInterpolation(selectedDate, calenderDate)}
                  onSelect={(selectedDate) => defaultMultipleDateInterpolation(null, calenderDate)}
                />
              </Sticky>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}

export default connect(stateToProps,dispatchToProps)(JobsCompany)
