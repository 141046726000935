import React, {Component}  from 'react';
import {compose, withProps} from "recompose";
import {withGoogleMap, Marker, GoogleMap, InfoWindow} from "react-google-maps";
import _ from 'lodash';

class JobsGoogleMap extends Component{

  constructor(props){
      super(props);
      this.state = {
          isOpen: false,
          infoWindowIndex:this.props.index,
      }
  }

  componentDidUpdate() {
    const bounds = new window.google.maps.LatLngBounds();
    if(this.props.jobs.length>0){
      _.each(this.props.jobs, (marker, i) => {
        bounds.extend(new window.google.maps.LatLng(
          parseFloat(marker.lat),
          parseFloat(marker.lng)
        ));
      });
    }else{
      _.each(this.props.work_cities, (marker, i) => {
        if(marker.name==this.props.city){
          bounds.extend(new window.google.maps.LatLng(
            parseFloat(marker.lat),
            parseFloat(marker.lng)
          ));
        }
      });
    }

    this.refs.map.fitBounds(bounds);
  }

  toggleWindow = () => {
      this.setState({
          isOpen: !this.state.isOpen
      });
  };

  showInfoWindow = index => {
      this.setState({infoWindowIndex: this.props.index});
  };

  gotojob = index =>{
    this.props.history.push(`/job/${this.props.jobs[index].id}`);
  }

  renderInfoWindow = job => {
    return (
      <InfoWindow  onCloseClick={this.toggleWindow}>
        <div>
          <h3>
              {job['name']}
              {job['event_type'] ? `(${job['event_type']})` : null}
          </h3>
          <div>{job.startDate ? job.startDate.format('ll') : null} - {job.endDate ? job.endDate.format('ll') : null}</div>
          <div>
              {job.startTime ? job.startTime.local().format("hh:mm A") : null}
              &nbsp;-&nbsp;
              {job.endTime ? job.endTime.local().format("hh:mm A") : null}
          </div>
           {
               job['location'] ?
                   <div> {job['location']}</div>
               : null
           }
        </div>
      </InfoWindow>
    )
  }

  renderMarkers = () => {
    if(this.props.jobs.length>0){
      return this.props.jobs.map((job, index) => {
          return (
              <Marker
                key={index}
                position={{lat: parseFloat(job.lat), lng: parseFloat(job.lng)}}
                onMouseOver={()=>{ this.showInfoWindow(index)} }
                onClick={()=>{ this.gotojob(index)} }
                onMouseOut={()=>this.toggleWindow}
              >

              {this.props.index===index?
                  this.renderInfoWindow(job)
                :
                  null
              }

              </Marker>
          )
      })
    }else{

      return this.props.work_cities.map((city, index) => {
        if(city.name==this.props.city){
          return (
              <Marker
                key={index}
                position={{lat: parseFloat(city.lat), lng: parseFloat(city.lng)}}
                onClick={()=>{ this.showInfoWindow(index)} }
              >

              { (this.props.index === index ) && this.renderInfoWindow(city)}

              </Marker>
          )
        }
      })
    }

  };

  render(){
    let lat = 0;
    let lng = 0;

    const mapOptions = {
          defaultZoom: 20,
          defaultCenter: {lat, lng}
    };

    return(
      <GoogleMap ref={'map'} {...mapOptions}>
          {this.renderMarkers()}
      </GoogleMap>
    )
  }
}

export default compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withGoogleMap
)(JobsGoogleMap)
