import React,{Component} from 'react';
import { withRouter } from "react-router-dom";
import {
  Card,
  Icon,
  Image,
  Button,
  Form,
  Segment,
  Header,
  Modal,
  Checkbox,
  Input,
  Menu} from 'semantic-ui-react';

import {common} from "../../../utils/common";
import FieldErrors from '../../Common/FieldErrors';
import { store } from "../../../store";
import ImageCrop from 'react-image-crop-component';
import 'react-image-crop-component/style.css';


class EditPersonalInfo extends Component{
  commonObj=new common();
  genderOptions=[
    {key:'female',value:'female',text:'Female'},
    {key:'male',value:'male',text:'Male'},
    {key:'other',value:'other',text:'Other'}
  ]

  constructor(props){
    super(props);
    this.gotoBack=this.gotoBack.bind(this);
    this.state={
      talent_id:'',
      imageFile:null,
      imagePreviewUrl:null,
      cropedImage:null,
      form_error:false,
      server_error:false,
      first_name:'',
      last_name:'',
      gender:'',
      date_of_birth:'',
      photo: '',
      errors:{},
      open:false,
      user_id:'',
      edit_show:false,
      savebutton:true,
    }
    this.onCropped = this._onCropped.bind(this)
  }

  componentDidMount(){
    this.getData();
  }

  componentWillReceiveProps(nextProps){
    this.getData()
  }

  getData(){
    const state = store.getState();
    this.setState({
      talent_id:state.user.profile.talent.id,
      first_name: state.user.profile.first_name,
      last_name: state.user.profile.last_name,
      gender: state.talent.profile.gender,
      date_of_birth: state.talent.profile.date_of_birth,
      photo:  state.user.profile.photo===''?'/assets/images/jessica.jpg': state.user.profile.photo,
      errors:{},
      user_id: state.user.profile.id,
    });
  }

  gotoBack(event){
    event.preventDefault();
    this.props.history.push('/talent/profile/'+this.props.talent.id);
  }

  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        imageFile: file,
        imagePreviewUrl: reader.result,
        open: true,
      });
    }
    
    reader.readAsDataURL(file)
  }

  savePhoto(event){
    let data = new FormData();
    data.append('id', this.state.user_id);
    data.append('email', this.props.user.email);
    data.append('photo', this.state.cropedImage);
    this.props.updateTalentProfileUser(data);
    this.setState({imageFile: null, imagePreviewUrl: null,open: false, cropedImage:null, savebutton:true})
  }

  cancelPhoto(event) {
    this.setState({imageFile: null, imagePreviewUrl: null,open: false, cropedImage:null, savebutton:true})
  }

  handleChange = (e,{name,value,fieldtype}) => {
    this.commonObj.handleChange(e,this,{name,value,fieldtype});
  }

  savePersonal(event){
    event.preventDefault();

    if (this.state.form_error===true || Object.keys(this.state.errors).length > 0){
      return;
    }

    let dataTalent = new FormData();
    
    dataTalent.append('gender', this.state.gender);
    dataTalent.append('date_of_birth', this.state.date_of_birth);
    this.props.updateTalentProfile(dataTalent);

    let dataUser = new FormData();
    dataUser.append('id', this.state.user_id);
    dataUser.append('first_name', this.state.first_name);
    dataUser.append('last_name', this.state.last_name);
    this.props.updateTalentProfileUser(dataUser);
    this.props.history.push('/talent/profile/'+this.props.talent.id);
  }


  show(){
    this.setState({open: true})
  }

  goBack(event){
    event.preventDefault();
    this.props.history.push('/talent/profile/'+this.props.talent.id);
  }

  _onCropped(e) {
    this.setState({cropedImage:e.image, savebutton:false})
  }
  render(){
    //
    let {first_name,last_name,gender,date_of_birth,photo}=this.state;

    return(
      <div>
        <Card>
          <label>
            <div onMouseEnter={(e)=>{this.setState({edit_show:true})}} onMouseOut={(e)=>{this.setState({edit_show:false})}}>
              {
                this.state.imagePreviewUrl===null?
                  <Image src={photo} as='a' />
                  :
                  <Image src={this.state.imagePreviewUrl} />
              }
              <Icon name='image' onMouseEnter={(e)=>{this.setState({edit_show:true})}}  color='blue' size='big' style={{position: 'absolute', bottom: '15%', cursor:'pointer', right: '5%', display:this.state.edit_show?'block':'none'}}/>
              <input type="file" style={{display:'none'}} onClick={(event)=> {event.target.value = null}} onChange={(e)=>this.handleImageChange(e)}/>
            </div>
          </label>
          <Card.Content extra>
            <Modal open={this.state.open}>
              <Modal.Content>
              </Modal.Content>
              <div>
                {this.state.imagePreviewUrl && (
                  <div style={{width: "100%", height: "100%"}}>
                      <ImageCrop src={this.state.imagePreviewUrl}
                          setWidth={1000} 
                          setHeight={1000} 
                          square={false} 
                          resize={true}
                          border={"dashed #ffffff 2px"}
                          onCrop={this.onCropped}/>
                  </div>
                )}
              </div>
              <Modal.Actions>
                <Button color='red' onClick={(e)=>this.cancelPhoto(e)}>
                  <Icon name='remove' /> Cancel
                </Button>
                <Button color='green' disabled={this.state.savebutton} onClick={(e)=>this.savePhoto(e)}>
                  <Icon name='checkmark' /> Crop & Save Image
                </Button>
              </Modal.Actions>
            </Modal>
          </Card.Content>
        </Card>
        <Form size='small' onSubmit={(e)=>this.savePersonal(e)} error={Object.keys(this.state.errors).length>0 || this.state.server_error}>
          <Segment raised>
            <Form.Input
              fluid
              icon='user'
              iconPosition='left'
              placeholder='First Name'
              name='first_name'
              value={first_name}
              fieldtype='text'
              error = {this.state.errors && this.state.errors['first_name']}
              onChange={this.handleChange}
            />
            <FieldErrors formSubmitted={true} errors={this.state.errors} fieldName={'first_name'}/>
            <Form.Input
              fluid
              icon='add user'
              iconPosition='left'
              placeholder='Last Name'
              name='last_name'
              value={last_name}
              fieldtype='text'
              error = {this.state.errors && this.state.errors['last_name']}
              onChange={this.handleChange}
            />
            <FieldErrors formSubmitted={true} errors={this.state.errors} fieldName={'last_name'}/>
            <Form.Select
              options={this.genderOptions}
              placeholder='Gender'
              name={'gender'}
              value={gender}
              fieldtype='select'
              onChange={this.handleChange}
            />
            <Form.Input
              fluid
              icon='calendar'
              iconPosition='left'
              placeholder='Date of Birth'
              name='date_of_birth'
              value={date_of_birth}
              fieldtype='date'
              error = {this.state.errors && this.state.errors['date_of_birth']}
              onChange={this.handleChange}
            />
            <FieldErrors formSubmitted={true} errors={this.state.errors} fieldName={'date_of_birth'}/>
            <Button color='blue' size='small'>SAVE</Button>
            <Menu secondary>
              <Menu.Item as='a' style={{marginLeft:'auto',marginRight:'auto'}} onClick={(e)=>this.goBack(e)}>
                <Icon name='arrow left' link/>
                Back
              </Menu.Item>
            </Menu>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default withRouter(EditPersonalInfo)