import _ from "lodash";
import * as moment from "moment";

export const formatDateRange = (job) => {
      /*
      Need to cover these cases
      1. Dec 30-31, 2018 - event ends in same month, same year
      2. Oct 30-Dec 1, 2018 - event split on diff months, same year
      3. Dec 30, 2018-Jan 31, 2019 - event split on diff months, diff years
      */
      const startDate = job.startDate;
      const endDate = job.endDate;
      let dateRange = '';
      // case 1 and case 2
      if (startDate.format('YYYY') === endDate.format('YYYY')){
          // case 1
          if (startDate.format('MMM') ===  endDate.format('MMM')){
              dateRange = `${startDate.format('MMM')} ${startDate.format('D')}`;
              dateRange += `-${endDate.format('D')}, ${endDate.format('YYYY')}`;
          }
          //case 2
          else{
              dateRange = `${startDate.format('MMM')} ${startDate.format('D')}`;
              dateRange += `-${endDate.format('MMM')} ${endDate.format('D')}, ${endDate.format('YYYY')}`;
          }
      }
      // case 3
      else{
          dateRange = `${startDate.format('MMM')} ${startDate.format('D')}, ${startDate.format('YYYY')}`;
          dateRange += `-${endDate.format('MMM')} ${endDate.format('D')}, ${endDate.format('YYYY')}`;
      }

      return dateRange;
  }

export const getJobEarliestDate = (job) => {
    let jobEarliestDate = null;
    _.each(job['event_days'], eventDay => {

          const eventDate = moment.utc(eventDay.date);
          if (!jobEarliestDate){
            jobEarliestDate = eventDate;
          }
          else if (jobEarliestDate.isAfter(eventDate)){
            jobEarliestDate = eventDate;
          }
      });
    return jobEarliestDate;
  }

export const getJobLatestDate = (job) => {
    let jobLatestDate = null;

    _.each(job['event_days'], eventDay => {

          const eventDate = moment.utc(eventDay.date);
          if (!jobLatestDate){
            jobLatestDate = eventDate;
          }
          else if (jobLatestDate.isBefore(eventDate)){
            jobLatestDate = eventDate;
          }
      });
    return jobLatestDate;
  }

export const getJobEarliestShiftTime = (job) => {
      let earliestTime = null;
      _.each(job['event_days'], (eventDay) => {
          _.each(eventDay['shifts'], (shift) => {
              const startTime = moment.utc(shift['start_time'], 'HH:mm:ss').local();
              if (!earliestTime){
                  earliestTime = startTime;
              }
              else if(earliestTime.isAfter(startTime)){
                  earliestTime = startTime;
              }
          });
      });
     return earliestTime;
  }

export const getJobLatestShiftTime = (job) => {
      let latestTime = null;
    _.each(job['event_days'], (eventDay) => {
      _.each(eventDay['shifts'], (shift) => {
          const endTime = moment.utc(shift['end_time'], 'HH:mm:ss').local();
          if (!latestTime){
              latestTime = endTime;
          }
          else if(latestTime.isBefore(endTime)){
              latestTime = endTime;
          }

      });
    });
    return latestTime;
  }

export const getJobMaxRate = (job) => {
    let maxRate = 0;

    _.each(job['event_days'], eventDay => {

      _.each(eventDay['shifts'], shift => {
        let fixedRatePerHour = 0;
        const talentRole = shift['talent_role'];
        if (talentRole['pay_type'].toLowerCase() === 'fixed'){
            const shiftStartTime = moment.utc(`${eventDay.date} ${shift['start_time']}`).local().format('hh:mm A');
            const shiftEndTime = moment.utc(`${eventDay.date} ${shift['end_time']}`).local().format('hh:mm A');
            let duration = moment.duration(moment(shiftEndTime, 'hh:mm A').diff(moment(shiftStartTime, 'hh:mm A')));
            duration = duration.asMinutes();
            const hours = (duration/60).toFixed(2);
            let fixedRatePerHour = (talentRole['fixed_rate_talent']/hours).toFixed(2);
            fixedRatePerHour = parseFloat(fixedRatePerHour);
            fixedRatePerHour = isFinite(fixedRatePerHour) ? fixedRatePerHour : 0;
            maxRate = (maxRate > fixedRatePerHour) ? maxRate : fixedRatePerHour;
          }
        else{
          const hourlyRate = parseFloat(talentRole['hourly_rate_talent']);
          maxRate = (maxRate > hourlyRate) ? maxRate : hourlyRate;
        }
      });
    });

    return maxRate;
  }

export const getJobMinRate = (job) => {
    let minRate = null;
    _.each(job['event_days'], eventDay => {
      _.each(eventDay['shifts'], shift => {
        let fixedRatePerHour = 0;
        const talentRole = shift['talent_role'];
        if (talentRole['pay_type'].toLowerCase() === 'fixed'){
            const shiftStartTime = moment.utc(`${eventDay.date} ${shift['start_time']}`).local().format('hh:mm A');
            const shiftEndTime = moment.utc(`${eventDay.date} ${shift['end_time']}`).local().format('hh:mm A');
            let duration = moment.duration(moment(shiftEndTime, 'hh:mm A').diff(moment(shiftStartTime, 'hh:mm A')));
            duration = duration.asMinutes();
            const hours = (duration/60).toFixed(2);
            let fixedRatePerHour = (talentRole['fixed_rate_talent']/hours).toFixed(2);
            fixedRatePerHour = parseFloat(fixedRatePerHour);
            fixedRatePerHour = isFinite(fixedRatePerHour) ? fixedRatePerHour : 0;
            if (minRate){
              minRate = (minRate < fixedRatePerHour) ? minRate : fixedRatePerHour;
            }else{
                minRate = fixedRatePerHour;
            }
          }
        else{
          const hourlyRate = parseFloat(talentRole['hourly_rate_talent']);
          if(minRate){
              minRate = (minRate < hourlyRate) ? minRate : hourlyRate;
          }else{
              minRate = hourlyRate;
          }
        }
      });
    });

    return minRate;
 }

export const removeEmptyEventsDaysShifts = (events) => {
    /*
        Remove shifts without start/end time and talent role
        Remove Event Days if shifts are 0
        Remove Events if event days are 0
    */
    const filteredEvents = [];
    _.each(events, event => {

        const filteredEventDays = [];

        _.each(event['event_days'], eventDay => {

            const filteredShifts = [];
            _.each(eventDay['shifts'], shift => {

                // add shift only if has start/end time and talent role
                if (shift['start_time'] && shift['end_time'] && shift['talent_role']){
                    filteredShifts.push(eventDay['shift']);
                }
            });

            // add event days only if has more than 0 shifts
            if (filteredShifts.length > 0) {
                filteredEventDays.push(eventDay)
            }

        });


        // add event only if has more than 0 event days
        if (filteredEventDays.length > 0) {
            event['event_days'] = filteredEventDays;
            filteredEvents.push(event);
        }
    });

    return filteredEvents;
};

export const removeEmptyDaysShifts = (job) => {
    /*
        Remove shifts without start/end time and talent role
        Remove Event Days if shifts are 0
    */

    const filteredEventDays = [];

    _.each(job['event_days'], eventDay => {

        const filteredShifts = [];
        _.each(eventDay['shifts'], shift => {

            // add shift only if has start/end time and talent role
            if (shift['start_time'] && shift['end_time'] && shift['talent_role']){
                filteredShifts.push(eventDay['shift']);
            }
        });

        // add event days only if has more than 0 shifts
        if (filteredShifts.length > 0) {
            filteredEventDays.push(eventDay)
        }

    });

    job['event_days'] = filteredEventDays;
    return job;
};

export const getAppliedAndHiredCountByRole = (job) => {
/**
 * This method returns count of applied and hired applicants on given job
 */
  let applied_hired = {}
  if (job.talent_roles) {
    _.each(job.talent_roles, (role) => {
      applied_hired[`role_${role.id}`] = {applied: [], hired: []};
      const _role_obj = applied_hired[`role_${role.id}`];
      if (role.shifts) {
      _.each(role.shifts, (shift) => {
          _.each(shift.applicants, (applicant) => {
            if (applicant.talent) {
              if (applicant.talent.user) {
                const user_id = applicant.talent.user.id;
                if (_.indexOf(_role_obj.applied, user_id) === -1) {
                  _role_obj.applied.push(user_id);
                }
                if (applicant.status === "booked") {
                  if (_.indexOf(_role_obj.hired, user_id) === -1) {
                    _role_obj.hired.push(user_id);
                  }
                }
              }
            }
          })
        })
      }  
      _role_obj.applied = _role_obj.applied.length;
      _role_obj.hired = _role_obj.hired.length;
    });
  }
  return applied_hired;
}

export const getAppliedAndHiredCount = (job) => {
  /**
 * This method returns count of applied and hired applicants on given job
 */
  const applied = [];
  const hired = [];
  if (job.talent_roles) {
    _.each(job.talent_roles, (role) => {
      if (role.shifts) {
      _.each(role.shifts, (shift) => {
          _.each(shift.applicants, (applicant) => {
            if (applicant.talent) {
              if (applicant.talent.user) {
                const user_id = applicant.talent.user.id;
                if (_.indexOf(applied, user_id) === -1) {
                  applied.push(user_id);
                }
                if (applicant.status === "booked") {
                  if (_.indexOf(hired, user_id) === -1) {
                    hired.push(user_id);
                  }
                }
              }
            }
          })
        })
      }  
    });
  }
  return {applied: applied.length, hired: hired.length};
}