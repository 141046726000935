import React, { Component } from 'react';
import {connect} from "react-redux";
import {Segment, Button, Form} from 'semantic-ui-react';
import '../Account/Signup/signup.css';
import {changePassword} from "../../actions/signupAction";
import ServerErrors from '../Common/ServerErrors';
import ServerSuccess from "../Common/ServerSuccess";
import * as SendBird from "sendbird";


const stateToProps=(state)=>{
    return {
        waitingRequest: state.common.waitingRequest,
        error: state.user.changePassword.error,
        success: state.user.changePassword.success,
        message: state.user.changePassword.message
      }
};

const dispatchToProps=(dispatch)=>{
  return{
      changePassword:(data)=>dispatch(changePassword(data)),
      logout:()=>dispatch({type:'USER_LOGOUT'})
  }
};

class ChangePassword extends Component {

  constructor(props){
    super(props);
    this.state = {
        formSubmit: false,
        user: {
            old_password: null,
            new_password: null,
            confirm_new_password: null
        }
    }
  }

  handleChange = (name, value) => {
    const {user} = this.state;
    user[name] = value;
    this.setState({ user, formSubmit: false });
  };

  changePassword = () => {
      this.setState({formSubmit: true});
      let {user} = this.state;
      this.props.changePassword(user);
  };

  renderServerErrors(){
      const { error, message, waitingRequest } = this.props;
      if (!waitingRequest && error && message['non_field_errors'] && this.state.formSubmit){
          return <ServerErrors errorMessage={message['non_field_errors']} />
      }
  }

  renderServerSuccess(){
      const {success, error, waitingRequest} = this.props;
      let {message} = this.props;
      message = `${message.message}. Logging you out.`;
      if (!error && !waitingRequest && success && this.state.formSubmit){
          setTimeout(() =>{
            this.props.logout();

            let sb = SendBird.getInstance();
            if (sb!==null){
              sb.removeChannelHandler('channel');
            }

          }, 1000);
          return <ServerSuccess successMessage={message} />
      }
  }

  renderForm() {
      const {user, formSubmit} = this.state;
      const {message, error} = this.props;
      return (
          <Form size='large' onSubmit={() => this.changePassword()}>
                  <Segment style={{'border': '0px', 'box-shadow': 'none'}}>
                      <Form.Input
                          fluid
                          icon='lock'
                          iconPosition='left'
                          placeholder='current password'
                          name='current_password'
                          value={user['current_password']}
                          type='password'
                          error={formSubmit && error && message['current_password']}
                          onChange={(e, {name, value}) => this.handleChange(name, value)}
                      />
                      { formSubmit && error && <span className={'field-error'}  >{message['current_password']}</span>}

                      <Form.Input
                          fluid
                          icon='lock'
                          iconPosition='left'
                          placeholder='new password'
                          name='new_password'
                          value={user['new_password']}
                          type='password'
                          error={formSubmit && error && message['new_password']}
                          onChange={(e, {name, value}) => this.handleChange(name, value)}
                      />
                      { formSubmit && error && <span className={'field-error'}  >{message['new_password']}</span>}

                      <Form.Input
                          fluid
                          icon='lock'
                          iconPosition='left'
                          placeholder='confirm new password'
                          name='confirm_new_password'
                          value={user['confirm_new_password']}
                          type='password'
                          error={formSubmit && error && message['confirm_new_password']}
                          onChange={(e, {name, value}) => this.handleChange(name, value)}
                      />

                      { formSubmit && error && <span className={'field-error'}  >{message['confirm_new_password']}</span>}

                      <Button color='blue' fluid size='tiny' loading={this.props.waitingRequest}
                              disabled={this.props.waitingRequest}>Update</Button>
                  </Segment>
              </Form>
      )
  }

  render() {
    return(
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <span className={'panel-header'}>
            Change Password
          </span>
        </Segment>
        <Segment style={{width: '500px'}}>
          {this.renderServerErrors()}
          {this.renderServerSuccess()}
          { this.renderForm() }
        </Segment>
      </Segment.Group>
    )
  }

}

export default connect(stateToProps,dispatchToProps)(ChangePassword);
