import {
  List,
  Grid,
  Icon,
  Image,
  Rating,
  Segment,
  Button,
  Item
} from "semantic-ui-react";
import React from "react";
import _ from "lodash";
import connect from "react-redux/es/connect/connect";
import { publishEvent } from "../../../../actions/event";

const renderCompanyContact = company => {
  const { user } = company;
  if (user["first_name"] || user["last_name"]) {
    return `${user["first_name"]} ${user["last_name"]}`;
  }

  return user.email;
};

const dispatchToProps = dispatch => {
  return {
    publishEvent: eventId => dispatch(publishEvent(eventId))
  };
};

const CompanyDetails = props => {
  const {
    company,
    jobStatus,
    job,
    timeSheetSubmitted,
    allTimeSheetsComplete
  } = props;

  console.log("psssspos", props);
  const buttons = [
    {
      id: 0,
      title: "Edit",
      path: `/event/${props.job.id}/update`
    },
    {
      id: 1,
      title: "Publish",
      path: `/event/${props.job.id}/deposit`
    },
    {
      id: 2,
      title: "Assign Shifts",
      path: `/event/${props.job.id}/assign-shifts/`
    },
    {
      id: 3,
      title: "Timesheet",
      path: `/event/${props.job.id}/timesheet`
    },
    {
      id: 4,
      title: "Invoice",
      path: `/event/${props.job.id}/invoice`
    }
  ];

  const displayButtons = (status, isTimeSheetSubmitted) => {
    if (status === "draft") {
      return buttons.filter(b => b.title == "Edit" || b.title == "Publish");
    } else if (status === "published" && allTimeSheetsComplete === true) {
      return buttons.filter(
        b =>
          b.title == "Edit" ||
          b.title == "Assign Shifts" ||
          b.title == "Timesheet" ||
          b.title == "Invoice"
      );
    } else if (status === "published" && timeSheetSubmitted === true) {
      return buttons.filter(
        b =>
          b.title == "Edit" ||
          b.title == "Assign Shifts" ||
          b.title == "Timesheet"
      );
    } else if (status === "published") {
      return buttons.filter(
        b => b.title == "Edit" || b.title == "Assign Shifts"
      );
    } else if (
      status === "cancelled" ||
      status === "complete" ||
      status === "pending"
    ) {
      buttons.forEach(b => {
        b.disabled = true;
      });
      return buttons;
    }
  };

  function publishEvent(event) {
    props.publishEvent(props.job.id);
  }

  return (
    <Segment textAlign={"left"}>
      <List divided relaxed size={"huge"}>
        <List.Item as={"a"}>
          <List.Content>
            <List.Description>
              <Grid textAlign={"left"}>
                <Grid.Row>
                  <Grid.Column width={3}>
                    <Image
                      src={
                        company.photo
                          ? company.photo
                          : "https://api.adorable.io/avatars/248/" +
                            company.email
                      }
                      width={"30"}
                      height={"30"}
                    />
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <strong>{company.name}</strong>
                    <Rating
                      icon="star"
                      rating={company.companyRating}
                      maxRating={5}
                      className={"rating"}
                      disabled={true}
                    />
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Icon name="chevron right" size="large" />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </List.Description>
          </List.Content>
        </List.Item>
        <List.Item as={"a"}>
          <List.Content>
            <List.Description>
              <Grid textAlign={"left"}>
                <Grid.Row>
                  <Grid.Column width={3}>
                    <Image
                      src={
                        company.user.photo
                          ? company.user.photo
                          : "/assets/images/jessica.jpg"
                      }
                      width={"30"}
                      height={"30"}
                    />
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <strong>{renderCompanyContact(company)}</strong>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Icon name="talk outline" size="large" />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </List.Description>
          </List.Content>
        </List.Item>
        <List.Item as={"a"}>
          <List.Content>
            <List.Description>
              <Grid textAlign={"left"}>
                <Grid.Row>
                  <Grid.Column>
                    <Item style={{ float: "left" }}>
                      {displayButtons(jobStatus).map(b => (
                        <Button
                          disabled={b.disabled}
                          onClick={() => props.history.push(b.path)}
                        >
                          {b.title}
                        </Button>
                      ))}
                    </Item>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </List.Description>
          </List.Content>
        </List.Item>
      </List>
    </Segment>
  );
};

export default connect(
  null,
  dispatchToProps
)(CompanyDetails);
