import axios from 'axios';

import {
  WAITING_REQUEST,
  GET_PROFILE_COMPANY_FULFILLED,
  GET_PROFILE_COMPANY_FAILED,
  GET_PROFILE_COMPANY_USER_FAILED,
  GET_PROFILE_COMPANY_USER_FULFILLED,
  GET_PROFILE_COMPANY_USER_FAILED_DATA,
  GET_PROFILE_COMPANY_USER_FULFILLED_DATA
} from '../constants/actionTypes';
import {common} from '../utils/common';
import {API_URL_BASE} from '../constants/constants';




export const getProfileCompanyUser = (id) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_REQUEST, value:true});

    axios.get(`${API_URL_BASE}/company/profile-company-user/${id}`)
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch,GET_PROFILE_COMPANY_USER_FULFILLED_DATA);
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,GET_PROFILE_COMPANY_USER_FAILED_DATA);
      })
  }
}
