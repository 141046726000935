import {
  GET_BOOKINGS_TALENT_FULFILLED,
  GET_BOOKINGS_TALENT_FAILED,
  GET_BOOKINGS_TALENT_FILTERED_DATA, GET_BOOKINGS_TALENT_WAITING
} from "../constants/actionTypes";

const initialState={
  data:[],
  errorMessage:null,
  filteredData:[],
  waitingRequestGetBookingsTalent:false
}

export default (state=initialState,action)=>{
  switch (action.type){
    case GET_BOOKINGS_TALENT_WAITING:{
      return {
        ...state,
        waitingRequestGetBookingsTalent:action.value
      };
    }
    case GET_BOOKINGS_TALENT_FULFILLED:{
      const st={
        ...state,
        data:action.responseData,
        filteredData:action.responseData
      }

      return st;
    }
    case GET_BOOKINGS_TALENT_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }
    case GET_BOOKINGS_TALENT_FILTERED_DATA:{
      const st={
        ...state,
        filteredData:action.filteredData
      }

      return st;
    }
    default:
      return state;
  }
}
