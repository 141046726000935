import {Button, Grid, Icon} from "semantic-ui-react";
import React from "react";

const ReportJob = (props) => {
    return (
        <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign={'center'}>
            <Button icon size={'large'}>
              <Icon name='fa'/>
              Report this job listing
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
};

export default ReportJob;
