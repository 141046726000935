import axios from 'axios';
import moment from 'moment';
import {API_URL_BASE} from '../constants/constants';


import {
  WAITING_REQUEST,
  GET_TALENT_PAYMENTS_FULFILLED,
  GET_TALENT_PAYMENTS_FAILED,
  GET_TALENT_PAYMENTS_FILTERED_DATA,
  GET_STRIPE_ACCOUNT_ERROR,
  CONNECT_STRIPE_ACCOUNT_ERROR
} from '../constants/actionTypes';
import * as actionTypes from '../constants/actionTypes'
import {common} from '../utils/common';
import settings from "../reducers/settings";

export const getPaymentsData = () => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_REQUEST, value:true});
    axios.get(`${API_URL_BASE}/payment/list`)
      .then((response)=>{
        commonObj.handleServerSucess(response,dispatch,GET_TALENT_PAYMENTS_FULFILLED);
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,GET_TALENT_PAYMENTS_FAILED);
      })
  }
}


export const connectStripeAccount = (data) => {
    const commonObj=new common();

    return (dispatch) => {
        dispatch({type:actionTypes.REQUEST_STRIPE_CONNECT, value:true});

        axios.post(`${API_URL_BASE}/payment/stripe/connect-account`, data)
            .then((response)=>{
                const data = {'is_stripe_connected': true};
                dispatch({type: actionTypes.TOGGLE_STRIPE_CONNECT, responseData: data});
                dispatch({type: actionTypes.ADD_STRIPE_ACCOUNT_TO_USER, responseData: response.data})
                dispatch({type:actionTypes.REQUEST_STRIPE_CONNECT, value:false});
                dispatch({type: actionTypes.CONNECT_STRIPE_ACCOUNT_ERROR, errorMessage: null});
            })
            .catch((err) =>{
                commonObj.handleServerError(err, dispatch, actionTypes.CONNECT_STRIPE_ACCOUNT_ERROR);
                dispatch({type: actionTypes.REQUEST_STRIPE_CONNECT, value:false});
            })
    }
}

export const disconnectStripeAccount = () => {
    const commonObj=new common();

    return (dispatch) => {
        dispatch({type:WAITING_REQUEST, value:true});
        axios.get(`${API_URL_BASE}/payment/stripe/disconnect-account`)
            .then((response)=>{
                const data = {'is_stripe_connected': false};
                dispatch({type: actionTypes.TOGGLE_STRIPE_CONNECT, responseData: data})
                dispatch({type:WAITING_REQUEST, value:false});
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch,CONNECT_STRIPE_ACCOUNT_ERROR);
            })
    }
}


export const getStripeAccount = () => {
    const commonObj=new common();

    return (dispatch) => {
        dispatch({type:WAITING_REQUEST, value:true});
        axios.get(`${API_URL_BASE}/payment/stripe/account-details`)
            .then((response)=>{
                dispatch({type: actionTypes.ADD_STRIPE_ACCOUNT_TO_USER, responseData: response.data})
                dispatch({type:WAITING_REQUEST, value:false});
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch,GET_STRIPE_ACCOUNT_ERROR);
            })
    }
}
