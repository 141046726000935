import React,{Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {Segment, Menu, Form, Dimmer, Loader, TextArea, Button, Icon, Confirm} from 'semantic-ui-react';
import ReactTags from "react-tag-autocomplete";

import PhotoGallery from "./PhotoGallery";
import { deleteGalleryPhoto, getGalleryPhoto,saveGalleryPhoto } from "../../../actions/talent";

class Portfolio extends Component{
  constructor(props) {
    super(props);
    this.state = {
      portfolios: this.props.portfolios,
      edit_portfolios: this.props.portfolios,
      activeTabKey: this.props.portfolios[0].id,
      visible_title: false,
      edit_title_key: null,
      load_state: false,
      visibleConfirmDelete: false
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(this.state.portfolios !== nextProps.portfolios) {
      this.setState({
        portfolios: nextProps.portfolios,
        edit_portfolios: nextProps.portfolios,
        edit_title_key: null,
        visible_title: false,
        load_state: false,
        activeTabKey: this.state.visibleConfirmDelete? nextProps.portfolios[0].id : this.state.activeTabKey,
        visibleConfirmDelete: false
      })
    }
  }

  componentDidMount() {
    this.props.getGalleryPhoto(this.state.activeTabKey);
  }

  /**
   * select portfolio among multi
   * get Gallery photos by portfolio id
   *
   * @param e
   * @param name
   */
  selectPortfolio = (e, { name }) => {
    this.setState({
      activeTabKey: name,
      selected_portfolio: this.state.edit_portfolios.find(p => p.id === name)
    });
    this.props.getGalleryPhoto(name);
  };

  /**
   * editable title due to double click
   *
   * @param portfolio
   */
  editTitle = portfolio => {
    this.setState({
      edit_title_key: portfolio.id,
      visible_title: true
    })
  };

  /***
   * update pending state data not store
   *
   * @param e
   * @param id
   * @param value
   * @param name
   */
  updateStateData = (e,id,value,name) => {
    let new_portfolios = this.state.edit_portfolios;
    let updated_one = new_portfolios.find(p => p.id === id);
    updated_one[name] = value;
    this.setState({
      edit_portfolios: new_portfolios
    })
  };

  /**
   * submit data due to press enter key
   *
   * @param e
   * @param id
   */
  submitData = (e, id) => {
    if (e.key === 'Enter') {
      let updated_one = this.state.edit_portfolios.find(p => p.id === id);
      this.setState({load_state: true});
      this.props.updatePortfolio(updated_one);
    }
  };

  /**
   * add certification to list
   *
   * @param cert
   */
  addCertification = (cert) => {
    let selected_one = this.state.edit_portfolios.find(p => p.id === this.state.activeTabKey)
    selected_one.certifications.push(cert);
    this.setState({load_state: true});
    this.props.updatePortfolio(selected_one);
  };

  /***
   * delete certification in list
   *
   * @param index
   */
  deleteCertification = (index) => {
    let selected_one = this.state.edit_portfolios.find(p => p.id === this.state.activeTabKey)
    selected_one.certifications.splice(index, 1);
    this.setState({load_state: true});
    this.props.updatePortfolio(selected_one);
  };

  /**
   * add new portfolio
   *
   */
  addNewPortfolio = () => {
    if(this.state.portfolios.length > 5)
      return;
    this.setState({load_state: true});
    this.props.createPortfolio({title: 'new portfolio', description: '', certifications: []});
  }


  showConfirmDeletePortfolio = () => {
    this.setState({
      visibleConfirmDelete: true
    })
  };

  cancelConfirmDeletePortfolio = e => {
    this.setState({
      visibleConfirmDelete: false
    })
  };


  render() {
    const { edit_portfolios, activeTabKey, visible_title, edit_title_key, load_state } = this.state;
    let selected_portfolio = edit_portfolios.find(p => p.id === activeTabKey);
    return (
      <div>
        <Menu attached='top' tabular>
          {
            edit_portfolios.map((portfolio, index) => {
              return <Menu.Item
                name={portfolio.id}
                active={activeTabKey === portfolio.id}
                onDoubleClick={() => this.editTitle(portfolio)}
                onClick={this.selectPortfolio} >
                {
                  edit_title_key === portfolio.id &&
                  <Dimmer active={load_state} inverted>
                    <Loader inverted></Loader>
                  </Dimmer>
                }
                {
                  visible_title && edit_title_key === portfolio.id
                    ?
                      <Form.Input
                        fluid
                        placeholder='Client/Brand'
                        name='title'
                        value={portfolio.title}
                        fieldtype='text'
                        onChange={(e,{value,name})=>this.updateStateData(e,portfolio.id,value,name)}
                        onKeyDown={(e) => this.submitData(e, portfolio.id)}
                        style={{minWidth:'100px'}}
                        />
                    :
                      <div className='tab-custom' >
                        <span>{portfolio.title}&nbsp;&nbsp;</span>
                        {activeTabKey === portfolio.id && <Icon onClick={() => this.showConfirmDeletePortfolio()} id='delete'  name='delete' />}
                      </div>
                }
              </Menu.Item>
            })
          }
          <Menu.Item onClick={this.addNewPortfolio}><Button>Add Profile</Button></Menu.Item>
        </Menu>

        <Segment attached='bottom'>
          <Dimmer active={load_state} inverted>
            <Loader inverted>Updating</Loader>
          </Dimmer>
          <PhotoGallery talent={this.props.talent} portfolio_id={activeTabKey} addphoto={this.props.saveGalleryPhoto} deletephoto={this.props.deleteGalleryPhoto} isLoggedUser={this.props.isLoggedUser}/>
          <Form>
            <TextArea
              placeholder='description'
              value={selected_portfolio.description}
              onChange={(e) => this.updateStateData(e, activeTabKey, e.target.value, 'description')}
              onKeyDown={(e) => this.submitData(e, activeTabKey)}
              rows="7"
              style={{ width: '100%' }} />
          </Form>
          <ReactTags
            allowNew={true}
            minQueryLength={1}
            placeholder={'Add certifications'}
            tags={selected_portfolio.certifications}
            suggestions={selected_portfolio.certifications}
            handleDelete={this.deleteCertification.bind(this)}
            handleAddition={this.addCertification.bind(this)} />
        </Segment>
        <Confirm
          open={this.state.visibleConfirmDelete}
          content={'Are you sure to delete ' + selected_portfolio.title}
          onCancel={this.cancelConfirmDeletePortfolio}
          onConfirm={() => this.props.deletePortfolio(activeTabKey)}
        />
      </div>
    )
  }
}

const stateToProps=(state)=>{
    return {
      portfolios: state.talent.portfolios,
    };
};

const dispatchToProps=(dispatch)=>{
  return{
    getGalleryPhoto:(id)=>dispatch(getGalleryPhoto(id)),
    saveGalleryPhoto:(data) => dispatch(saveGalleryPhoto(data)),
    deleteGalleryPhoto:(id)=>dispatch(deleteGalleryPhoto(id)),
  }
}
export default connect(stateToProps,dispatchToProps)(Portfolio)
