import React,{Component} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import moment from 'moment';
import {
  Container,
  Segment,
  Header,
  Grid,
  Menu,
  Dropdown,
  Button,
  Icon,
  Loader,
  Form,
  Sticky
} from 'semantic-ui-react';
import InfiniteCalendar, {
  Calendar,
  withMultipleDates,
  defaultMultipleDateInterpolation }
from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';

import './bookings-talent.css';
import {getBookingsData, getBookinsDataByDate} from "../../../actions/bookingsAction";
import {isMobileOnly} from "react-device-detect";
import {common} from "../../../utils/common";
import BookingList from "./BookingList";
import {GET_BOOKINGS_TALENT_FILTERED_DATA} from "../../../constants/actionTypes";
import './bookings-talent.css';
import ContentPlaceHolder from "../../Common/ContentPlaceholder";

const stateToProps=(state)=>{
  return{
    data:state.bookingsTalent.data,
    filteredData:state.bookingsTalent.filteredData,
    errorMessage:state.bookingsTalent.errorMessage,
    user:state.user.profile,
    waitingRequestGetBookingsTalent:state.bookingsTalent.waitingRequestGetBookingsTalent
  }
}

const dispatchToProps=(dispatch)=>{
  return{
    getBookingsData:(token)=> dispatch(getBookingsData(token)),
    getBookinsFiltereData:(filteredData)=> dispatch({type:GET_BOOKINGS_TALENT_FILTERED_DATA,filteredData:filteredData}),
    getBookinsDataByDate:(date)=> dispatch(getBookinsDataByDate(date))
  }
}

class BookingsTalent extends Component{
  constructor(props){
    super(props);
    this.handleSelectFilterChange=this.handleSelectFilterChange.bind(this);

    this.state={
      searchType:'All',
      searchText:'',
      jobsFiltered:this.props.filteredData,
      calenderDate : [],
      id:0,
    }
  }

  bookingTypeList = [
    { text: "All Types", value: "All"},
    { text: "Booked & Pending", value: "BookedPending" },
    { text: "Booked", value: "Booked" },
    { text: "Pending", value: "Pending" }
  ];
  commonObj=new common();

  componentDidMount(){
    this.props.getBookingsData(this.props.user.token);
  }

  componentWillReceiveProps(nextProps){
      let dates=[];
      nextProps.filteredData.map((item)=>{
        let i = 0
        while (true) {
          if(moment(item.end_date,'YYYY-MM-DD').diff(moment(item.start_date,'YYYY-MM-DD').add(i, 'days'), 'days')<0)
            break
          dates.push(moment(item.start_date,'YYYY-MM-DD').add(i, 'days'))
          i++;
        }
      });
      this.setState({jobsFiltered: nextProps.filteredData, calenderDate:dates});
    }

  handleContextRef = contextRef => this.setState({ contextRef })

  handleSelectFilterChange(event, {name,value,fieldtype}){
    this.commonObj.handleChange(event,this,{name,value,fieldtype});
    let searchType = value
    this.setState({searchType}, () => this.filterJobs());
  }

  filterJobs = () => {
    let jobsFiltered = this.props.filteredData;
    const {searchType, searchText} = this.state;
    if (searchText !== '') {
      const searchedJobs = [];
      _.each(jobsFiltered, job => {
        if (
          (job.details && job.details.toLowerCase().includes(searchText)) ||
          (job['event_type'] && job['event_type'].toLowerCase().includes(searchText)) ||
          (job['event_type_name'] && job['event_type_name'].toLowerCase().includes(searchText)) ||
          (job['company']['name'] && job['company']['name'].toLowerCase().includes(searchText))
        ) {
          searchedJobs.push(job);
          return false;
        } else {
          _.each(job['event_days'], eventDay => {
            if ((eventDay.location && eventDay.location.toLowerCase().includes(searchText))) {
              searchedJobs.push(job);
              return false;
            }
          });
        }

      });

      jobsFiltered = searchedJobs;
    }

    if (searchType.toLowerCase() !== 'all') {
      const TypeExistJobs = [];
      if (searchType.toLowerCase() === 'bookedpending') {
        _.each(jobsFiltered, job => {
          if (job['talent_apply_status'].toLowerCase().includes('pending') || job['talent_apply_status'].toLowerCase().includes('booked')) {
            TypeExistJobs.push(job);
          }
        });
      } else {
        _.each(jobsFiltered, job => {
          if (job['talent_apply_status'].toLowerCase().includes(searchType.toLowerCase())) {
            TypeExistJobs.push(job);
          }
        });
      }
      jobsFiltered = TypeExistJobs;
    }

    let dates=[];
    jobsFiltered.map((item)=>{
      let i = 0
      while (true) {
        if(moment(item.end_date,'YYYY-MM-DD').diff(moment(item.start_date,'YYYY-MM-DD').add(i, 'days'), 'days')<0)
          break
        dates.push(moment(item.start_date,'YYYY-MM-DD').add(i, 'days'))
        i++;
      }
    });

    this.setState({jobsFiltered: jobsFiltered, calenderDate:dates});
  };

  clearFilters(event) {
      let searchType='All';
      let searchText=''

    this.setState({ searchType,searchText }, () => this.filterJobs());
  }

  handleChange(event,data){
    this.setState({searchText:data.value});
  }

  search(event){
    if (this.state.searchText===''){
      this.props.getBookinsFiltereData(this.props.data);
    }
    else{
      const filteredData=this.props.data.filter((item)=>{
        return common.contains(item.title,this.state.searchText);
      })
      this.props.getBookinsFiltereData(filteredData);
    }
  }

  checkDate(date){

    for(var i=0;i<this.state.jobsFiltered.length;i++){
      var startDate = moment(this.state.jobsFiltered[i].start_date, "YYYY-MM-DD")
      , endDate   = moment(this.state.jobsFiltered[i].end_date, "YYYY-MM-DD")
      , date  = moment(date, "YYYY-MM-DD");
      if(startDate <= date && date <= endDate){
        if(this.state.id === this.state.jobsFiltered[i].id){
          if(this.state.jobsFiltered[i].talent_apply_status==="pending"){
            return '#b5cc18';
          }else{
            return "#00ff00";
          }
        }else if(this.state.id===0){
          if(this.state.jobsFiltered[i].talent_apply_status==="pending"){
            return '#b5cc18';
          }else{
            return "#00ff00";
          }
        }
      }
    }
    return "#808080";
  }

  goToPage = (id) =>{
    this.props.history.push(`/job/${id}`);
  }

  onHover = (id) =>{
    let dates=[];
      this.state.jobsFiltered.map((item)=>{
        let i = 0
        if(item.id===id){
          while (true) {
            if(moment(item.end_date,'YYYY-MM-DD').diff(moment(item.start_date,'YYYY-MM-DD').add(i, 'days'), 'days')<0)
              break
            dates.push(moment(item.start_date,'YYYY-MM-DD').add(i, 'days'))
            i++;
          }
        }
      });
      this.setState({id:id});
  }

  onLeave = (id) =>{
    this.setState({id:0});
  }

  render(){
    const { contextRef } = this.state
    let {calenderDate}=this.state;
    let booked = [];
    let awaiting_response = [];
    let pending = [];
    let completed = [];
    let not_booked = [];

    this.state.jobsFiltered.map((item)=>{
      if(item.talent_apply_status==="booked"){
        booked.push(item)
      }
      if(item.talent_apply_status==="notbooked"){
        not_booked.push(item)
      }
      if(item.talent_apply_status==="interested" || item.talent_apply_status==="pending_reopened" || item.talent_apply_status==="unavailable"){
        awaiting_response.push(item)
      }
      if(item.talent_apply_status==="pending"){
        pending.push(item)
      }
      if(item.talent_apply_status==="completed"){
        completed.push(item)
      }
    });

    return(
      <Container className={'main-container'}>
        <Menu
          className={'secondary-fixed-top-menu'}
          stackable
          { ...( isMobileOnly===false && { fixed: 'top' } ) }
        >
          <Menu.Item className={'filter-menu-item location'}>
            <Dropdown
              placeholder='Select Location'
              fluid
              selection
              options={this.bookingTypeList}
              name={'searchType'}
              value={this.state.searchType}
              fieldtype={'select'}
              onChange={this.handleSelectFilterChange}/>
          </Menu.Item>
          <Menu.Item className={'filter-menu-item clear-button'}>
            <Button basic icon labelPosition='right' onClick={(e)=>{this.clearFilters(e)}}>
              <Icon name='trash' />
              Clear Filters
            </Button>
          </Menu.Item>
          <Menu.Item className={'filter-menu-item search-input'} position={'right'}>
            <Form size='large' onSubmit={(e)=>this.filterJobs(e)} className={'search-form'}>
              <Form.Input
                fluid
                icon={<Icon name='search' link onClick={(e)=>this.filterJobs(e)} />}
                placeholder='Search...'
                name='searchText'
                value={this.state.searchText}
                fieldtype='text'
                onChange={(e,{value})=>this.handleChange(e,{value})}
              />
            </Form>
          </Menu.Item>
        </Menu>

        <Grid stackable padded>
          <Grid.Column mobile={16} tablet={11} computer={11}>
            <div onMouseLeave={(e)=>{this.onLeave()}} ref={this.handleContextRef}>
                {this.props.waitingRequestGetBookingsTalent ?
                  <ContentPlaceHolder totalParagraphs={3}/>
                  :
                  <React.Fragment>
                    {booked.length===0 && not_booked.length===0 && pending.length===0 && awaiting_response.length===0 && completed.length===0 ?
                      <Container>
                        <Container textAlign='center'>
                          <Segment>
                            <Header icon>
                              <Icon color='blue' name='child' />
                              Ready to earn money for your first event?
                            </Header>
                            <br />
                            <br />
                            <Button color='blue' size='large' primary >
                              Apply to jobs
                              <Icon name='angle right' />
                            </Button>
                          </Segment>
                        </Container>
                      </Container>
                      :
                      null
                    }
                    <BookingList onHover={this.onHover} goToPage={this.goToPage} status={"BOOKED"} bookings={booked}/>
                    <BookingList onHover={this.onHover} goToPage={this.goToPage} status={"NOT BOOKED"} bookings={not_booked}/>
                    <BookingList onHover={this.onHover} goToPage={this.goToPage} status={"PENDING"} bookings={pending}/>
                    <BookingList onHover={this.onHover} goToPage={this.goToPage} status={"Awaiting Response"} bookings={awaiting_response}/>
                    <BookingList onHover={this.onHover} goToPage={this.goToPage} status={"Completed"} bookings={completed}/>

                  </React.Fragment>
                }
            </div>
          </Grid.Column>
          <Grid.Column tablet={5} computer={5} only='tablet computer'style={{minHeight: '550px',zIndex:'-1',marginBottom:'30px'}}>
              <Sticky context={contextRef} offset={110}>
                <InfiniteCalendar
                    Component={withMultipleDates(Calendar)}
                    width={'100%'}
                    height={395}
                    theme={{
                      selectionColor: date => {
                        return this.checkDate(date);
                      }
                    }}
                    selected={calenderDate}
                    interpolateSelection={(selectedDate) => defaultMultipleDateInterpolation(selectedDate, calenderDate)}
                    onSelect={(selectedDate) => defaultMultipleDateInterpolation(null, calenderDate)}
                  />
              </Sticky>
          </Grid.Column>
        </Grid>
      </Container>
    )
  }
}

export default connect(stateToProps,dispatchToProps)(BookingsTalent)
