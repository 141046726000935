import React, { Component } from 'react';
import {connect} from 'react-redux';
import {
  Form,
  Container,
  Button} from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';

import { UPDATE_USER} from "../../../constants/actionTypes";
import {mobileVerifyCode, setNextScreen} from "../../../actions/signupAction";
import ServerErrors from '../../Common/ServerErrors';
import ServerSuccess from "../../Common/ServerSuccess";

const stateToProps=(state)=>{
    return {
        user: state.user.profile,
        codeVerifyMessage: state.signup.codeVerifyMessage,
        codeVerifyFailedMessage: state.signup.codeVerifyFailedMessage,
        waitingRequest: state.common.waitingRequest
    };
}

const dispatchToProps=(dispatch)=>{
  return{
    dispatch,
    mobileVerifyCode: (data) => dispatch(mobileVerifyCode(data)),
    updateUser: (data) => dispatch({type: UPDATE_USER, responseData: data})
  }
}

class VerifyCode extends Component {
  constructor(props){
    super(props);
    this.renderForm = this.renderForm.bind(this);
    this.verifyCode=this.verifyCode.bind(this);
    this.renderServerErrors = this.renderServerErrors.bind(this);
    this.renderServerSuccess = this.renderServerSuccess.bind(this);

    this.state={
      form_error:false,
      server_error:false,
      form_submitted: false,
      verification_code:'',
      verification_code_error:false
    }
  }

    componentWillReceiveProps(nextProps){
        if (this.state.form_submitted &&
            (!nextProps.codeVerifyMessage.success || nextProps.codeVerifyFailedMessage)
        ){
            this.setState({form_error: false,server_error:true});
        }
        else{
            this.setState({server_error: false});
        }
    }

  handleChange = (e) => {
    const state = this.state;

    if (e.target.value===''){
      state[e.target.name+'_error'] = true;
    }
    else{
      state[e.target.name+'_error'] = false;
    }

    state[e.target.name] = e.target.value;
    this.setState(state);

    if (e.target.value.length===7) {
      this.verifyCode(e);
    }
  }

  handleValidation(){
    let formIsValid = true;

    if (this.state.verification_code===''){
      const state = this.state;
      state['verification_code_error']=true;
      this.setState(state);
    }

    if (this.state.verification_code_error===true){
      formIsValid=false;
    }

    return formIsValid;
  }

  verifyCode(event){
    event.preventDefault();
    this.setState({form_submitted: true});
    if (this.handleValidation()===false){
      this.setState({form_error:true});
    }
    else{
      this.setState({form_error:false});
      let code = this.state.verification_code;
      code = code.split('-').join('');
      const {phone} = this.props;
      const data = {phone: phone, token: code}
      this.props.mobileVerifyCode(data);
    }
  }

  renderServerErrors(){
      if (this.state.server_error){
          const {codeVerifyMessage, codeVerifyFailedMessage} = this.props;
          const errorMessage = codeVerifyMessage.message || (codeVerifyFailedMessage && codeVerifyFailedMessage.message) || codeVerifyFailedMessage;
          return <ServerErrors errorMessage={errorMessage} />
      }
  }

  renderServerSuccess(){
      const {codeVerifyMessage} = this.props;
      const isCodeVerified = codeVerifyMessage.success;
      if (isCodeVerified){
            return <ServerSuccess successMessage={codeVerifyMessage.message} />
        }
    }

  renderForm() {
      const {verification_code}=this.state;
      const {submitButtonText} = this.props;
      const isCodeVerified = (this.props.codeVerifyMessage && this.props.codeVerifyMessage.success);
      if (isCodeVerified){
        const data = {'country_code': this.props.country_code, phone: this.props.phone}
        // update in redux store
        this.props.updateUser(data);
        setNextScreen(this.props.dispatch);
      }

      return (
          <Form size='large' error={this.state.form_verify_error || this.state.server_verify_error} style={{marginTop:'40px'}}>
              <Form.Input
                  label="Enter 4-digit code you receive via SMS."
                  error={this.state.verification_code_error}
                  children={
                      <MaskedInput
                          name="verification_code"
                          value={verification_code}
                          onChange={this.handleChange}
                          mask={[/\d/,'-', /\d/,'-', /\d/, '-', /\d/]}
                          guide={false}
                          placeholder="0-0-0-0"
                      />
                  }
              />
              <Button onClick={this.verifyCode} color='blue' fluid size='large'>
                  {submitButtonText ? submitButtonText : 'Next'}
              </Button>
          </Form>
      )
  }

  render() {
    return(
      <Container>
          {this.renderForm()}
          {this.renderServerErrors()}
          {this.renderServerSuccess()}
      </Container>
          )
  }
}

export default connect(stateToProps,dispatchToProps)(VerifyCode)
