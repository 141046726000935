import React,{Component} from 'react';
import {
  Container,
  Segment,
  Button,
  Icon,
  Grid,
  Menu,
  Header,
  Label,
  Loader,} from 'semantic-ui-react';
import {isMobileOnly} from 'react-device-detect';
import * as moment from "moment";
import DateBox from '../../Common/DateBox';
import ParticipantOverview from './ParticipantOverview';
import {publishEvent} from "../../../actions/event";
import connect from "react-redux/es/connect/connect";
import {EVENT_CREATION_STEP, CREATE_NEW_EVENT} from "../../../constants/actionTypes";
import {getAppliedAndHiredCount} from '../helpers';

const dispatchToProps=(dispatch)=>{
  return{
    publishEvent: (eventId) => dispatch(publishEvent(eventId)),
    eventFirstStep:()=>dispatch({type:EVENT_CREATION_STEP,value:1}),
    createNewEvent:()=>dispatch({type:CREATE_NEW_EVENT,value:1}),
  }
}

class JobList extends Component{
  constructor(props){
    super(props);
    this.state={
      isLoading:true,
      sortTyp:'date',
    }
    this.isLoading = this.isLoading.bind(this)
    this.handleSelectFilterChange = this.handleSelectFilterChange.bind(this)
  }
  sortList = [
    { text: "Date", value: "date"},
    { text: "City", value: "city"},
  ];

  handleSelectFilterChange(event, {name,value,fieldtype}){
    this.setState({sortTyp:value})
    this.props.sortedBy(value)
  }

  gotoEdit=(e,event)=>{
    e.preventDefault();
    this.props.history.push(`/event/${event.id}/update`);
  }

  gotoTimesheet=(e,event)=>{
    e.preventDefault();
    this.props.history.push(`/event/${event.id}/timesheet`);
  }

  gotoInvoice=(e,event)=>{
      e.preventDefault();
      this.props.history.push(`/event/${event.id}/invoice`);
  }

  gotoDeposit=(e,event)=>{
      e.preventDefault();
      this.props.history.push(`/event/${event.id}/deposit`);
  }

  gotoEventAssignShifts=(e,event)=>{
    this.props.history.push(`/event/${event.id}/assign-shifts`);
  }

  goEventPage = (id)=>{
    this.props.history.push(`/event/${id}`);
  }

  isLoading(){
    if(this.state.isLoading){
      setTimeout(
          function() {
              this.setState({isLoading:false})
          }
          .bind(this),
          3000
      );
    }
  }

  publishEvent = (event) => {
      this.props.publishEvent(event.id);
  }

  createEvent = () =>{
    this.props.eventFirstStep();
    this.props.createNewEvent();
    this.props.history.push(`/events/create`);
  }

  multiDayEvent = (days) => {
    if (days.length > 1){
      return (
        <Label as='a' color='olive' ribbon='left'>
          {days.length}-day event
        </Label>
      )
    }
    return
  }

  countDown = (start_date) => {
    const now = moment();
    const end = moment(start_date);
    const days = end.diff(now, 'days');
    const hours = end.diff(now, 'hours');
    const humanize = end.fromNow();
    // const days = humanize.asDays();

    if (hours > 0 && days < 7){
      return (
        <Label as='a' color='red' ribbon='left'>
          {humanize}
        </Label>
      )
    } else if (days > 7 && days < 21){
      return (
        <Label as='a' color='olive' ribbon='left'>
          {humanize}
        </Label>
      )
    }
    return
  }

  render(){
    let list=[];

    if(this.props.jobs.length>0){
      list=this.props.jobs.map((item,index)=>{
        const applied_hired_count = getAppliedAndHiredCount(item);
        return (
          <Container key={item.id}>
            <Grid key={index} textAlign={'left'} style={{cursor:'pointer'}} onMouseEnter={(e)=>{this.props.onHover(item.id)}}>
              <Grid.Row>
                <Grid.Column width={3} onClick={(e)=>this.props.goToPage(item.id)}>
                  <DateBox date={item.start_date} />
                  { this.multiDayEvent(item.event_days) }
                  { this.countDown(item.start_date) }
                </Grid.Column>
                <Grid.Column width={13}>
                  <Grid columns={2}>
                    <Grid.Column width={12}>
                      <Header as={'h3'}>
                        <a onClick={(e)=>this.goEventPage(item.id)} className={'job-title'}>{item.name}</a>
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={4} textAlign={'right'} onClick={(e)=>this.props.goToPage(item.id)}>
                      <Label as='a' basic color='grey'>
                        {item.status}
                      </Label>
                    </Grid.Column>
                  </Grid>

                  <Label.Group onClick={(e)=>this.props.goToPage(item.id)}>
                    <Label basic as='a' color='blue'>
                      <Icon name={'sign-in alternate'}/>
                      Applied
                      <Label.Detail>{applied_hired_count.applied}</Label.Detail>
                    </Label>
                    <Label basic as='a' color='blue'>
                      <Icon name={'handshake'}/>
                      Hired
                      <Label.Detail>{applied_hired_count.hired}</Label.Detail>
                    </Label>
                  </Label.Group>
                  <div onClick={(e)=>this.props.goToPage(item.id)}>
                    <ParticipantOverview event={item}/>

                    <Header as={'h5'} textAlign={'left'}>
                      <Icon name={'location arrow'} color={'yellow'}/>
                      {item.event_days[0].location}
                    </Header>
                  </div>

                </Grid.Column>
              </Grid.Row>
            </Grid>
            {index<this.props.jobs.length-1 && <hr/>}
          </Container>
        )

      });

    }
    else{

      list=<Container>
        {this.isLoading()}
        <Loader active inline='centered' size='huge' style={{display:this.state.isLoading ? 'block' : 'none'}} />
        <Container textAlign='center' style={{display:this.state.isLoading ? 'none':'block'}}>
          <Segment>
            <Header icon>
              <Icon color='blue' name='bullhorn' />
              Creating your first event is easy!
            </Header>
            <br />
            <br />
            <Button color='blue' size='huge' primary onClick={(e)=>this.createEvent()}>
              Let's get started
              <Icon name='angle right' />
            </Button>
          </Segment>
        </Container>
      </Container>

    }
    return (
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <Grid verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={10} computer={10} textAlign={'left'}>

                <Menu.Item name="profile" >
                  <Button huge='true' icon color='blue' labelPosition='left' onClick={(e)=>this.createEvent()}>
                    <Icon name='plus' />
                    New Event
                  </Button>
                </Menu.Item>

              </Grid.Column>
              <Grid.Column mobile={16} tablet={6} computer={6}
               {...(isMobileOnly===true && {textAlign:'left'})}
               {...(isMobileOnly===false && {textAlign:'right'})}
              >
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment>
          {list}
        </Segment>
      </Segment.Group>
    )
  }
}

export default connect(null, dispatchToProps)(JobList)
