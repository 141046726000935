import React, { Component } from "react";
import _ from "lodash";
import { List, Image, Label } from "semantic-ui-react";

import {API_URL_BASE} from '../../../constants/constants';

export default class ParticipantOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: this.props.event,
      applicants: [],
      roles: this.props.event.talent_roles
    };
  }

  getApplicants = selectedRoleIds => {
    const { event } = this.state;
    const filteredApplicantsBySelectedRoles = [];
    let selectedRoles = event["talent_roles"].filter(
      role =>
        !!selectedRoleIds.find(selectedRoleId => selectedRoleId === role.id)
    );

    selectedRoles.map(role => {
      _.each(role.shifts, shift => {
        _.each(shift.applicants, applicant => {
          const foundApplicant = filteredApplicantsBySelectedRoles.find(
            app => app.talent.id === applicant.talent.id
          );
          if (!foundApplicant) {
            if (applicant.status === "booked")
              filteredApplicantsBySelectedRoles.push({
                talent: applicant.talent,
                shifts: [shift],
                status: applicant.status
              });
          } else {
            foundApplicant.shifts.push(shift);
          }
        });
      });
    });

    let users = filteredApplicantsBySelectedRoles.map(item => {
      return (
        <List.Item>
          <Image
            avatar
            src={
              item.talent.user.photo === "" || item.talent.user.photo === null
                ? "https://api.adorable.io/avatars/280/" +
                  item.talent.user.email
                : API_URL_BASE + '/media/' + item.talent.user.photo
            }
          />
          <List.Content>
            <List.Header>{item.talent.user.first_name}</List.Header>
          </List.Content>
        </List.Item>
      );
    });
    if (selectedRoles[0].count > users.length) {
      users.push(
        <List.Item>
          <List.Content>+{selectedRoles[0].count - users.length}</List.Content>
        </List.Item>
      );
    }
    return users;
  };
  render() {
    const colors = [
      "red",
      "orange",
      "yellow",
      "olive",
      "green",
      "teal",
      "blue",
      "violet",
      "purple",
      "pink",
      "brown",
      "grey",
      "black"
    ];

    let roles = this.state.roles.map((role, index) => {
      return (
        <List.Item>
          <Label colors={colors[index]} horizontal>
            {role.name}
          </Label>
          <List horizontal>{this.getApplicants([role.id])}</List>
        </List.Item>
      );
    });

    return (
      <List divided>
        <List.Item>
          <Label color="red" horizontal>
            Organizers
          </Label>
          <Image
            avatar
            src={
              this.props.event.company.user.photo === "" ||
              this.props.event.company.user.photo === null
                ? "https://api.adorable.io/avatars/280/" +
                  this.props.event.company.user.email
                : this.props.event.company.user.photo
            }
          />
          <List.Content>
            <List.Header>
              {this.props.event.company.user.first_name}
            </List.Header>
            {this.props.event.company.name}
          </List.Content>
        </List.Item>
        {roles}
      </List>
    );
  }
}
