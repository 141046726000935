import React,{Component} from 'react';
import {connect} from "react-redux";
import {Container, Grid, Menu, Dropdown, Button, Icon, Form, Header, Segment, Loader, Image} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import './payment.css';
import {
    connectStripeAccount,
    disconnectStripeAccount,
    getPaymentsData,
    getStripeAccount
} from "../../../actions/paymentsAction";
import {isMobileOnly} from "react-device-detect";
import PaymentList from "./PaymentList";
import {parse} from "qs";
import {SITE_URL, STRIPE_CLIENT_ID} from "../../../constants/constants";
import ServerErrors from "../../Common/ServerErrors";

const stateToProps=(state)=>{
  const user = state.user.profile;
  const payments = [];
  const paymentHistory = {};
  _.each(state.paymentsTalent.data, transaction => {
      const payment = {
          id: transaction.id,
          client: transaction.event.company.name,
          eventName: transaction.event.name,
          eventId: transaction.event.id,
          amount: transaction.amount,
          hours: transaction['worked_hours'],
          status: transaction.status === 'completed' ? 'paid' : 'pending',
          date: moment(transaction['created_at']).format('YYYY-MM-DD h:mm A'),
          paidDate: moment(transaction['paid_at']).format('YYYY-MM-DD h:mm A')
      };

      payments.push(payment);
  });

    let paymentsCount = 0;
    let totalAmount = 0;
  _.each(payments, payment => {
     paymentsCount += 1;
     totalAmount += parseFloat(payment.amount);
  });

  paymentHistory['paymentsCount'] = paymentsCount;
  paymentHistory['totalAmount'] = totalAmount.toFixed(2);

  const stripeAccount = state.user.profile.stripe_account;
  const isStripeConnected = user['is_stripe_connected'] && !_.isEmpty(stripeAccount);

  return{
    payments,
    paymentHistory,
    errorMessage:state.paymentsTalent.errorMessage,
    user: state.user.profile,
    stripeAccount: state.user.profile.stripe_account,
    isStripeConnected,
    stripeAccountConnecting: state.company.waitingRequest,
    stripeConnectError:state.settings.stripeConnectError,
    router: state.router,
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    getPaymentsData:()=> dispatch(getPaymentsData()),
    getStripeAccount:()=> dispatch(getStripeAccount()),
    connectStripeAccount:(data)=> dispatch(connectStripeAccount(data)),
    disconnectStripeAccount:()=> dispatch(disconnectStripeAccount()),
  }
};

class PaymentTalent extends Component{
  constructor(props){
    super(props);
    this.state={
      payments: [],
      paymentsFiltered: [],
      filter: {
          text: '',
          paymentStatus: 'all'
      },
      sortBy: 'date',
    };

    this.paymentStatusList = [
      { text: "All", value: "all"},
      { text: "Paid", value: "paid" },
      { text: "Pending", value: "pending" }
    ];

    this.sortByList = [
        {text: 'Date', value: 'date'},
        {text: 'Pay', value: 'Pay'}
    ]
  }

  componentWillMount(){
      const {isStripeConnected} = this.props;
      const queryString = parse(this.props.router.location.search.substr(1));
      if (isStripeConnected){
          this.props.getStripeAccount();
      }
      else if (queryString['scope'] && queryString['code']){
          this.props.history.push(this.props.history.location.pathname);
          this.props.connectStripeAccount({'code': queryString['code']});
      }
  }

  componentWillReceiveProps(nextProps){
     if(nextProps.payments){
       this.setState({
           payments: nextProps.payments,
           paymentsFiltered: nextProps.payments
       })
     }

     this.setFilter('paymentStatus', 'all');
  }

  componentDidMount(){
    this.props.getPaymentsData();
  }

  setFilter = (type, value) => {
      const {filter} = this.state;
      filter[type] = value;
      this.setState({filter}, () => this.filterPayments());
  };

  filterPayments = () => {
      let paymentsFiltered = this.state.payments;
      let { text, paymentStatus } = this.state.filter;
      paymentStatus = paymentStatus.trim().toLowerCase();

      // filter by payment status
     if (paymentStatus !== 'all'){
         paymentsFiltered = paymentsFiltered.filter(payment => payment.status.toLowerCase().includes(paymentStatus))
      }

      // filter by search text
      if (text !== ''){
         text = text.trim().toLowerCase();
         const searchedPayments = [];
          _.each(paymentsFiltered, payment => {
                 if (
                     (payment.client.toLowerCase().includes(text)) ||
                     (payment.eventName.toLowerCase().includes(text)) ||
                     (payment.amount.toString().toLowerCase().includes(text)) ||
                     (payment.hours.toString().toLowerCase().includes(text))
                 ){
                     searchedPayments.push(payment);
                 }
            });

          paymentsFiltered = searchedPayments;
      }

      this.setState({paymentsFiltered}, () => this.sortPayments());

  };

  clearFilters = () => {
    const {filter} = this.state;
    filter.text = '';
    filter.paymentStatus= 'all';
    this.setState({filter, sortBy: 'date'}, () => this.filterPayments());
  };

  sortPayments = () => {
    let {sortBy, paymentsFiltered} = this.state;
    sortBy = sortBy.toLowerCase();
    let sortedPayments = paymentsFiltered;
    if (sortBy === 'date'){
      sortedPayments = paymentsFiltered.sort((payment1, payment2) => payment1.date > payment2.date);
    }

    if (sortBy === 'pay'){
      sortedPayments = paymentsFiltered.sort((payment1, payment2) => payment1.amount > payment2.amount);
    }

    this.setState({paymentsFiltered: sortedPayments});
  };

  sortBy = (sortBy) => {
    this.setState({ sortBy }, () => this.sortPayments() );
  };

  renderConnectButton = () => {
     const {isStripeConnected, stripeAccountConnecting, stripeAccountLoading} = this.props;

     if (stripeAccountConnecting || stripeAccountLoading){
          return <Loader active inline='centered' />
     }

     if (isStripeConnected){
         return (
             <Menu secondary>
              <Menu.Item as='a' style={{marginLeft:'auto',marginRight:'auto'}} onClick={() => this.props.disconnectStripeAccount()}>
                Disconnect
                <Icon name='arrow right' link/>
              </Menu.Item>
            </Menu>
         )
     }

     return (
             <Menu secondary>
              <Menu.Item as='a' style={{marginLeft:'auto',marginRight:'auto'}}>
                <a href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${SITE_URL}/payments`}>
                Connect
                </a>
                <Icon name='arrow right' link/>
              </Menu.Item>
            </Menu>
         )

  };

  renderAccountDetails = () => {
      const {user, isStripeConnected} = this.props;
      const stripeAccount = user['stripe_account'];
      if (isStripeConnected){
          return (
              <div>
                  <div style={{textAlign:'left'}}>Account ID: {stripeAccount['account_id']}</div>
                  <div style={{textAlign:'left'}}>Customer ID: {stripeAccount['customer_id']}</div>
              </div>
          )
      }
      return <div style={{'textAlign':'left'}}>Not connected</div>
  };

  render(){
    const {paymentHistory, stripeConnectError} = this.props;
    return(
      <Container className={'main-container'}>
        <Menu
          className={'secondary-fixed-top-menu'}
          stackable
          { ...( isMobileOnly===false && { fixed: 'top' } ) }
        >
          <Menu.Item className={'filter-menu-item location'}>
            <Dropdown
              placeholder='payment status'
              fluid
              selection
              options={this.paymentStatusList}
              name={'paymentStatus'}
              value={this.state.filter.paymentStatus}
              fieldtype={'select'}
              onChange={(e, {name, value}) => this.setFilter(name, value)}
            />
          </Menu.Item>
          <Menu.Item className={'filter-menu-item clear-button'}>
            <Button basic icon labelPosition='right' onClick={(e)=>{this.clearFilters(e)}}>
              <Icon name='trash' />
              Clear Filters
            </Button>
          </Menu.Item>
          <Menu.Item className={'filter-menu-item search-input'} position={'right'}>
            <Form size='large' onSubmit={(e)=>this.search(e)} className={'search-form'}>
              <Form.Input
                fluid
                icon={<Icon name='search' link onClick={(e, {name, value}) => this.setFilter(name, value)} />}
                placeholder='Search...'
                name='text'
                value={this.state.filter.text}
                fieldtype='text'
                onChange={(e, {name, value}) => this.setFilter(name, value)}
              />
            </Form>
          </Menu.Item>
        </Menu>
        <Grid stackable padded>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={11} computer={11}>
              <PaymentList
                  payments={this.state.paymentsFiltered}
                  sortByList={this.sortByList}
                  sortBy={this.sortBy}
                  sortFilterValue={this.state.sortBy}
                  {...this.props}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={5} textAlign={'center'}>
              <Segment>
                <Header as={'h3'} textAlign={'center'}>Connect Bank</Header>
                <hr/>
                <Header as={'h4'} textAlign={'left'}>Stripe Account</Header>

                  {this.renderAccountDetails()}

                <hr style={{marginTop:'28px'}}/>

                {this.renderConnectButton()}
                {stripeConnectError && <ServerErrors errorMessage={stripeConnectError} />}

              </Segment>
              <Segment textAlign={'center'}>
                <Header as={'h3'}>Payment History</Header>
                <hr/>
                <Header as={'h2'}>${paymentHistory.totalAmount}</Header>
                <span color={'gray'}>{paymentHistory.paymentsCount} Payments</span>
              </Segment>
              <a href="" style={{marginLeft:'auto',marginRight:'auto'}}>
                <Icon name={'flag'}/>
                Report a Payment Issue
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}

export default connect(stateToProps,dispatchToProps)(PaymentTalent)
