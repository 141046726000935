import React, { Component } from 'react';
import Routes from '../../routes';
import {setAuthHeader } from "../../utils/common";
import {connect} from "react-redux";
import DocumentTitle from 'react-document-title';

import NavBar from '../Navbar';
import Footer from '../Common/Footer.js'
import * as SendBird from "sendbird";
import {SEND_BIRD_APP_ID} from "../../constants/constants";
import {loadMessageGroups, loadOneToOneChats, updateChannelUrl} from "../../actions/messaging";
import {NEW_MESSAGE_ARRIVED,UPDATE_CHANNEL_LIST_ON_REDUX,UPDATE_ONE_TO_ONE_CHANNEL_LIST_ON_REDUX,CHANNEL_LIST_SORTED} from "../../constants/actionTypes";
import {getUserName} from '../../utils/common';
import {updateMessageNotifications} from "../Message/common";

const leftItems = [
  { as: "a", content: "Jobs", key: "jobs" },
  { as: "a", content: "Bookings", key: "bookings" },
  { as: "a", content: "Messages", key: "messages" },
  { as: "a", content: "Payments", key: "payments" }
];
const rightItems = [
  { as: "a", content: "Register", key: "register" }
];

const stateToProps=(state)=>{
  return{
    documentTitle:state.common.documentTitle,
    unreadMessageCount:state.messaging.unreadMessageCount,
    user:state.login.user,
    messageGroups: state.messaging.messageGroups,
    oneToOneChats: state.messaging.oneToOneChats,
    loggedInUser: state.user.profile,
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    loadMessageGroups: ()=>dispatch(loadMessageGroups()),
    loadOneToOneChats: ()=>dispatch(loadOneToOneChats()),
    setMessageNotification:(count)=>dispatch({type:NEW_MESSAGE_ARRIVED,value:count}),
    updateChannelUrl: (id, data)=>dispatch(updateChannelUrl(id, data)),
    updateChannelListOnRedux:(channel)=>dispatch({type:UPDATE_CHANNEL_LIST_ON_REDUX,value:channel}),
    updateOneToOneChannelListOnRedux:(channel)=>dispatch({type:UPDATE_ONE_TO_ONE_CHANNEL_LIST_ON_REDUX,value:channel}),
    channelListSorted:()=> dispatch({type: CHANNEL_LIST_SORTED})
  }
};

class App extends Component {
  sb=null;
  channelHandler=null;

  constructor(props){
    super(props);

    this.state={
      channels:[]
    }
  }

  componentWillMount() {
    setAuthHeader();
  }

  componentDidMount(){
    // let testCounter=4;
    // console.log(`App reload issue testing ${testCounter}`);

    if (this.props.loggedInUser && this.props.loggedInUser.id){
      this.props.loadMessageGroups(); // load group chats from our local API
      this.props.loadOneToOneChats(); // load one-one chats from our local API
    }
  }

  componentWillReceiveProps(nextProps) {
    let {loggedInUser}=this.props;
    if (loggedInUser && nextProps.loggedInUser) {
      if (loggedInUser.id===undefined && nextProps.loggedInUser.id && nextProps.loggedInUser.id>0){
        setAuthHeader();
        this.props.loadMessageGroups(); // load group chats from our local API
        this.props.loadOneToOneChats(); // load one-one chats from our local API
      }
    }
    

    if ((this.props.messageGroups.length!==nextProps.messageGroups.length) || (this.props.oneToOneChats.length!==nextProps.oneToOneChats.length)) {
      if (this.sb===null){
        this.sb = new SendBird({'appId': SEND_BIRD_APP_ID});
        this.channelHandler = new this.sb.ChannelHandler();
        this.channelHandler.onMessageReceived = (channel, message) => this.onMessageReceived(channel, message);
        this.sb.addChannelHandler('channel', this.channelHandler);

        window.globalVars = {
          sendGridChanelHandler: this.channelHandler
        };
      }

      updateMessageNotifications(this.sb,this.props.loggedInUser,this.props.setMessageNotification);
    }

    if (this.props.messageGroups.length!==nextProps.messageGroups.length) {
      let promiseArr = [];

      nextProps.messageGroups.forEach((group) => {
        const promiseCreateChannel = this.createGroupChannel(group);
        promiseArr.push(promiseCreateChannel);
      });

      Promise.all(promiseArr)
        .then(data => {
          this.props.channelListSorted();
        }).catch(function(rej) {
          console.log(rej);
        });
    }

    if (this.props.oneToOneChats.length!==nextProps.oneToOneChats.length){
      nextProps.oneToOneChats.forEach((oneToOneChat)=>{
        let participant = oneToOneChat.participants.find(user => {
          return user.id !== this.props.loggedInUser.id;
        });

        if (participant){
          this.createOneToOneChat(participant);
        }
      });
    }
  }

  componentWillUnmount(){
    this.sb.removeChannelHandler('channel');
  }

  onMessageReceived = (channel,message) => {
    updateMessageNotifications(this.sb,this.props.loggedInUser,this.props.setMessageNotification);
    this.props.updateChannelListOnRedux(channel);
    this.props.updateOneToOneChannelListOnRedux(channel);
  };

  createOneToOneChat = (participant) => {
    const channelName = getUserName(participant);
    const {loggedInUser} = this.props;
    const loggedInUserId = loggedInUser.email;

    this.sb.connect(loggedInUserId, (user, error) => {
      if (error) {
        console.error(error);
      }

      this.sb.GroupChannel.createChannelWithUserIds([loggedInUserId, participant.email], true, channelName, participant.photo, '', '', (channel, error) => {
        if (error) {
          console.error(error);
          return;
        }

        this.props.updateOneToOneChannelListOnRedux(channel);
      });
    });
  };

  createGroupChannel = (group) => {
    return new Promise((resolve, reject) => {
      const {loggedInUser} = this.props;
      const channelName = group.event.name;
      const loggedInUserId = loggedInUser.email;

      const participants=group.participants.map((item)=>{
        return item.email;
      });

      let params = new this.sb.GroupChannelParams();
      params.isPublic = true;
      params.isEphemeral = false;
      params.isDistinct = false;
      params.addUserIds(participants);
      params.name = channelName;
      params.coverUrl = '';
      params.customType = '';

      this.sb.connect(loggedInUserId, (user, error) => {
        if (error) {
          console.error(error);
          reject();
          return;
        }

        if (group['channel_url']) {
          this.sb.GroupChannel.getChannel(group['channel_url'], (channel, error) => {
            // if channel not exist on sendbird, then create it otherwise join it
            if (!channel){
              this.sb.GroupChannel.createChannel(params, (channel, error) => {
                if (error) {
                  console.error(error);
                  reject();
                  return;
                }

                this.props.updateChannelUrl(group.id, {'channel_url': channel.url});
                this.props.updateChannelListOnRedux(channel);
                resolve();
              });
            } else {
              channel.join(function(response, error) {
                if (error) {
                  console.error(error);
                  reject();
                }
              });

              this.props.updateChannelListOnRedux(channel);
              resolve();
            }
          });
        }
        else {
          this.sb.GroupChannel.createChannel(params, (channel, error) => {
            if (error) {
              console.error(error);
              reject();
              return;
            }

            this.props.updateChannelUrl(group.id, {'channel_url': channel.url});
            this.props.updateChannelListOnRedux(channel);
            resolve();
          });
        }
      });
    });
  };

  render() {
    let title=this.props.documentTitle;

    if (this.props.unreadMessageCount>0){
      title='(' + this.props.unreadMessageCount + ') ' + this.props.documentTitle;
    }

    return(
      <DocumentTitle title={title}>
        <div>
          <NavBar leftItems={leftItems} rightItems={rightItems}>
            <Routes/>
          </NavBar>
          <Footer/>
        </div>
      </DocumentTitle>
    )}
}

export default connect(stateToProps,dispatchToProps)(App);
