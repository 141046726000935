import React, { Component } from 'react'
import { connect } from "react-redux";
import CompanySettings from './Company';
import TalentSettings from './Talent';


const stateToProps=(state)=>{
  return{
    user: state.user.profile
  }
};

class Settings extends Component {

    render() {
      const { user } = this.props;
      return user.type.toLowerCase() === 'talent' ?
             <TalentSettings {...this.props} /> :
             <CompanySettings {...this.props} />
    }
}

export default connect(stateToProps, null)(Settings);
