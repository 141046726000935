import React,{Component} from 'react';
import {Segment, Button, Item, Grid, Header, Dropdown} from 'semantic-ui-react';
import {isMobileOnly} from 'react-device-detect';
import moment from 'moment';
import './payment.css';

class PaymentList extends Component{
  render(){
    const list=this.props.payments.map((item,index)=>{
      return (
        <div key={index} style={{marginBottom:'14px'}}>
          <Item>
            <Item.Content>
              <Item.Header>
                <Grid textAlign={'left'}>
                  <Grid.Row>
                    <Grid.Column width={10} >
                      <Header as={'h3'}>
                        <a href={'javascript:void(0)'} onClick={() => this.props.history.push(`/event/${item.eventId}`)}  className={'job-title'}>{item.eventName}</a>
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      {<Button basic className={'status-button'} color={'green'} floated={'right'}>${item.amount}</Button>}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Item.Header>
              <Item.Description className={'item-client-name'}>
                <Header as={'h5'} color={'grey'} textAlign={'left'}>
                    {item.type === 'talent_payment' ? item.talent : 'Deposit'}
                </Header>
              </Item.Description>
              <Item.Description>
                <Header as={'h5'} color={'blue'} style={{marginTop:'8px'}}>{item.hours} Total Hours</Header>
                  <span className={'paid-on'}>
                    {
                      item.type === 'talent_payment' ?
                          (item.status === 'completed' ? `Paid at ${moment(item.paidDate).format('LLLL')}` : `Scheduled at ${moment.utc(item.date).format('LLLL')}`)
                      : (item.status === 'completed' ? `Paid at ${moment(item.paidDate).format('LLLL')}` : `Pending`)
                    }
                  </span>
              </Item.Description>
            </Item.Content>
          </Item>
          {index<this.props.payments.length-1 && <hr/>}
        </div>
      )
    });

    return (
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <Grid verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={6} computer={6}
               {...(isMobileOnly===true && {textAlign:'left'})}
              >
                <span>Sort by </span>
                <Dropdown
                  placeholder='sort'
                  selection
                  options={this.props.sortByList}
                  name={'sort'}
                  value={this.props.sortFilterValue}
                  fieldtype={'select'}
                  onChange={(e, {value}) => this.props.sortBy(value)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment>
          { list.length > 0 ? list : 'No payments yet' }
        </Segment>
      </Segment.Group>
    )
  }
}

export default PaymentList
