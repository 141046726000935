import axios from 'axios';

import {API_URL_BASE} from '../constants/constants';
import * as actionType from '../constants/actionTypes';
import {common} from '../utils/common';


export const getCompanyProfile =(id)=>{
    const commonObj=new common();
    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.get(`${API_URL_BASE}/company/public-profile/${id}`)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.GET_COMPANY_PROFILE_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.GET_COMPANY_PROFILE_ERROR);
            })
    }
}

export const getTalentProfile =(id)=>{
    const commonObj=new common();
    
    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.get(`${API_URL_BASE}/talent/public-profile/${id}`)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.GET_TALENT_PROFILE_SUCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.GET_TALENT_PROFILE_FAILED);
            })
    }
}