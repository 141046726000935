import React from "react";
import {
  BLANK_TEXT_ERROR_MESSAGE,
  INVALID_DATE_ERROR_MESSAGE,
  INVALID_EMAIL_ADDRESS_ERROR_MESSAGE,
  INVALID_PASSWORD_ERROR_MESSAGE,
  INVALID_PHONE_NUMBER_ERROR_MESSAGE
} from "./Constants";
import { List, ListItem } from "semantic-ui-react";
import { store } from "../store";
import _ from "lodash";

import { WAITING_REQUEST } from "../constants/actionTypes";
import axios from "axios/index";

export class common {
  handleChange = (e, obj, { name, value, fieldtype }) => {
    const state = obj.state;
    state["form_error"] = false;

    if (state.hasOwnProperty("errors")) {
      delete state["errors"][name];
    }

    if (
      fieldtype === "text" ||
      fieldtype === "radio" ||
      fieldtype === "select"
    ) {
      if (value === "") {
        state["form_error"] = true;
        state["errors"][name] = BLANK_TEXT_ERROR_MESSAGE;
      }
    }

    if (fieldtype === "email") {
      // eslint-disable-next-line
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (value === "") {
        state["form_error"] = true;
        state["errors"][name] = BLANK_TEXT_ERROR_MESSAGE;
      } else if (!emailRegex.test(value)) {
        state["form_error"] = true;
        state["errors"][name] = INVALID_EMAIL_ADDRESS_ERROR_MESSAGE;
      }
    }

    if (fieldtype === "password") {
      if (value === "") {
        state["form_error"] = true;
        state["errors"][name] = BLANK_TEXT_ERROR_MESSAGE;
      }
      // Minimum eight characters, at least one letter and one number:
      else if (
        !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+]{8,}$/.test(value)
      ) {
        state["form_error"] = true;
        state["errors"][name] = INVALID_PASSWORD_ERROR_MESSAGE;
      }
    }

    if (fieldtype === "phone") {
      if (value === "") {
        state["form_error"] = true;
        state["errors"][name] = BLANK_TEXT_ERROR_MESSAGE;
      } else if (!/^\d{8,12}$/.test(value)) {
        state["form_error"] = true;
        state["errors"][name] = INVALID_PHONE_NUMBER_ERROR_MESSAGE;
      }
    }

    if (fieldtype === "date") {
      if (value === "") {
        state["form_error"] = true;
        state["errors"][name] = BLANK_TEXT_ERROR_MESSAGE;
      }
      // else if (!/^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/.test(value)){
      else if (
        !/^((19|20)?[0-9]{2}[- /.](0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01]))*$/.test(
          value
        )
      ) {
        state["form_error"] = true;
        state["errors"][name] = INVALID_DATE_ERROR_MESSAGE;
      }
    }

    state[name] = value;
    obj.setState(state);
  };

  handleServerSucess(response, dispatch, constantName) {
    dispatch({ type: constantName, responseData: response.data });
    dispatch({ type: WAITING_REQUEST, value: false });
  }

  handleServerError = (err, dispatch, constantName) => {
    if (typeof err.response === "undefined") {
      dispatch({ type: constantName, errorMessage: "Network error!" });
    } else {
      if (err.response.status === 500) {
        dispatch({
          type: constantName,
          errorMessage: "Internal server error!"
        });
      } else {
        dispatch({ type: constantName, errorMessage: err.response.data });
      }
    }

    dispatch({ type: WAITING_REQUEST, value: false });
  };

  serverSucessHandler(data, dispatch, constantName, waitingConstantName) {
    dispatch({ type: constantName, responseData: data });
    dispatch({ type: waitingConstantName, value: false });
  }

  serverErrorHandler = (err, dispatch, constantName, waitingConstantName) => {
    if (typeof err.response === "undefined") {
      dispatch({ type: constantName, errorMessage: "Network error!" });
    } else {
      if (err.response.status === 500) {
        dispatch({
          type: constantName,
          errorMessage: "Internal server error!"
        });
      } else {
        dispatch({ type: constantName, errorMessage: err.response.data });
      }
    }

    dispatch({ type: waitingConstantName, value: false });
  };

  static contains(a, b) {
    return (
      a
        .toString()
        .toLowerCase()
        .replace(/\s/g, "")
        .indexOf(
          b
            .toString()
            .toLowerCase()
            .replace(/\s/g, "")
        ) !== -1
    );
  }
}

export function handleInputChange(
  e,
  obj,
  { name, value, fieldtype, required }
) {
  const state = obj.state;
  state["form_error"] = false;

  if (state.hasOwnProperty("errors")) {
    delete state["errors"][name];
  }

  if (fieldtype === "text" || fieldtype === "radio" || fieldtype === "select") {
    if (required && value === "") {
      state["form_error"] = true;
      state["errors"][name] = BLANK_TEXT_ERROR_MESSAGE;
    }
  }

  if (fieldtype === "email") {
    // eslint-disable-next-line
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (required && value === "") {
      state["form_error"] = true;
      state["errors"][name] = BLANK_TEXT_ERROR_MESSAGE;
    } else if (!emailRegex.test(value)) {
      state["form_error"] = true;
      state["errors"][name] = INVALID_EMAIL_ADDRESS_ERROR_MESSAGE;
    }
  }

  if (fieldtype === "password") {
    if (required && value === "") {
      state["form_error"] = true;
      state["errors"][name] = BLANK_TEXT_ERROR_MESSAGE;
    }
    // Minimum eight characters, at least one letter and one number:
    else if (
      !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+]{8,}$/.test(value)
    ) {
      state["form_error"] = true;
      state["errors"][name] = INVALID_PASSWORD_ERROR_MESSAGE;
    }
  }

  if (fieldtype === "phone") {
    if (required && value === "") {
      state["form_error"] = true;
      state["errors"][name] = BLANK_TEXT_ERROR_MESSAGE;
    } else if (!/^\d{8,12}$/.test(value)) {
      state["form_error"] = true;
      state["errors"][name] = INVALID_PHONE_NUMBER_ERROR_MESSAGE;
    }
  }

  if (fieldtype === "date") {
    if (required && value === "") {
      state["form_error"] = true;
      state["errors"][name] = BLANK_TEXT_ERROR_MESSAGE;
    }
    // else if (!/^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/.test(value)){
    else if (
      !/^((19|20)?[0-9]{2}[- /.](0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01]))*$/.test(
        value
      )
    ) {
      state["form_error"] = true;
      state["errors"][name] = INVALID_DATE_ERROR_MESSAGE;
    }
  }

  state[name] = value;
  obj.setState(state);
}

export function getUserName(user) {
  return user["first_name"] || user["last_name"]
    ? `${user["first_name"] !== null ? user["first_name"] : ""} ${
        user["last_name"] !== null ? user["last_name"].charAt(0) : ""
      }`
    : user["email"];
}

export function setAuthHeader() {
  deleteAuthHeader();
  const user = store.getState().user.profile;
  if (!_.isEmpty(user) && user.token) {
    axios.defaults.headers.common["Authorization"] = `Token ${user.token}`;
  }
}

export function deleteAuthHeader() {
  axios.defaults.headers.common["Authorization"] = "";
  delete axios.defaults.headers.common["Authorization"];
}

export function xssEscape(target) {
  if (typeof target === "string") {
    return target
      .split("&")
      .join("&amp;")
      .split("#")
      .join("&#35;")
      .split("<")
      .join("&lt;")
      .split(">")
      .join("&gt;")
      .split('"')
      .join("&quot;")
      .split("'")
      .join("&apos;")
      .split("+")
      .join("&#43;")
      .split("-")
      .join("&#45;")
      .split("(")
      .join("&#40;")
      .split(")")
      .join("&#41;")
      .split("%")
      .join("&#37;");
  } else {
    return target;
  }
}

String.prototype.initCap = function() {
  return this.toLowerCase().replace(/(?:^|\s)[a-z]/g, function(m) {
    return m.toUpperCase();
  });
};

export const getClosestNumber = (arr, num) => {
  let curr = arr[0];
  let diff = Math.abs(num - curr);
  for (let val = 0; val < arr.length; val++) {
    const newDiff = Math.abs(num - arr[val]);
    if (newDiff < diff) {
      diff = newDiff;
      curr = arr[val];
    }
  }
  return curr;
};

export const convertWeight = (weight, unit) => {
  if (unit === "lb") {
    const weightInLb = weight * 2.20462;
    return weightInLb.toFixed(2);
  }

  if (unit === "kg") {
    const weightInKg = weight * 0.453592;
    return weightInKg.toFixed(2);
  }
};

/*
this function converts an object or array into a string separated by dot (.)
if data passed is string, it will be returned as it is
*/
export const convertToString = data => {
  // check if data is of type object {} or array []
  if (typeof data === "object" && typeof data != null) {
    if (Array.isArray(data)) {
      const messages = [];
      data.map((error, index) => {
        messages.push(
          <ListItem key={index}>{convertToString(error)}</ListItem>
        );
      });
      return <List>{messages}</List>;
    }

    // else type of data is object {}
    else {
      let messages = [];
      for (let key in data) {
        messages.push(
          <ListItem key={key}>
            {key}: {convertToString(data[key])}
          </ListItem>
        );
      }
      return <List>{messages}</List>;
    }
  }

  // otherwise data is string
  else {
    return data;
  }
};


export const formatBytes = (num) => {
  var neg = num < 0;

    var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    if (neg){
        num = -num;
    }

    if (num < 1){
        return (neg ? '-' : '') + num + ' B';
    }
    
    var exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
    
    num = Number((num / Math.pow(1000, exponent)).toFixed(2));
    
    var unit = units[exponent];

    return (neg ? '-' : '') + num + ' ' + unit;
}
