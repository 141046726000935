import {
  EMAIL_SIGNUP_MESSAGE_OPEN_CLOSED,
  EMAIL_SIGNUP_OPENED_CLOSED, WAITING_REQUEST,
  SIGNUP_NEXT, TALENT_JOB_APPLY_NEXT, IS_MODAL_OPENED
} from "../constants/actionTypes";

const initialState={
  documentTitle:'Shift Dash',
  waitingRequest:false,
  isEmailSignupOpened:false,
  isEmailSignupMessageOpened:false,
  signupNext:'mobile_verify', // mobile_verify
  jobApplyNext:'shift_day',
  isModalOpened:false
}

export default (state=initialState,action)=>{
  switch (action.type){
    case WAITING_REQUEST:{
      const st={...state,waitingRequest:action.value};
      return st;
    }
    case EMAIL_SIGNUP_OPENED_CLOSED:{
      const st={...state,isEmailSignupOpened:action.value};
      return st;
    }
    case EMAIL_SIGNUP_MESSAGE_OPEN_CLOSED:{
      const st={...state,isEmailSignupMessageOpened:action.value};
      return st;
    }
    case SIGNUP_NEXT:{
      const st={...state,signupNext:action.value};
      return st;
    }
  case TALENT_JOB_APPLY_NEXT:{
      const st={...state,jobApplyNext:action.value};
      return st;
    }
    case IS_MODAL_OPENED:{
      const st={...state,isModalOpened:action.value};
      return st;
    }
    default:
      return state;
  }
}
