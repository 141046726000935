import React, { Component } from "react";
import { Container, Grid, Header, List } from "semantic-ui-react";

class Footer extends Component {
  render() {
    return (
      <Container
        fluid
        className={"footer-container"}
        style={{ backgroundColor: "#d4d4d5" }}
      >
        <Container>
          <Grid columns={4} stackable padded>
            <Grid.Column width={5}>
              <Header as={"h2"} className="tp-text">
                shiftdash.com
              </Header>
              <div className="smedia">
                <a
                  href="https://facebook.com/shiftdashapp"
                  target="_blank"
                  style={{ marginRight: "20px" }}
                >
                  <img
                    src={"../../../assets/images/home/fbpurple24.png"}
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://twitter.com/shiftdash"
                  target="_blank"
                  style={{ marginRight: "20px" }}
                >
                  <img
                    src={"../../../assets/images/home/iconmonstr-twitter24.png"}
                    alt="Twitter"
                  />
                </a>
                <a
                  href="https://www.instagram.com/shiftdash/"
                  target="_blank"
                  style={{ marginRight: "20px" }}
                >
                <img
                    src={"../../../assets/images/home/iconmonstr-insta24.png"}
                    alt="Instagram"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/shiftdash/about/"
                  target="_blank"
                  style={{ marginRight: "20px" }}
                >
                <img
                  src={"../../../assets/images/home/iconmonstr-linkedin-24.png"}
                  alt="Linkedin"
                />
                </a>
              </div>
            </Grid.Column>
            <Grid.Column width={4}>
              <List relaxed>
                <List.Item className={"footer-links"}>
                  <a
                    href={"http://shiftdash.freshdesk.com/support/solutions"}
                    target="_blank"
                  >
                    Help
                  </a>
                </List.Item>
                <List.Item className={"footer-links"}>
                  <a
                    href={"https://shiftdash.freshdesk.com/support/tickets/new"}
                    target="_blank"
                  >
                    Contact
                  </a>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4}>
              <List relaxed>
                <List.Item className={"footer-links"}>
                  <a href={"/terms"} target={"_blank"}>
                    Terms
                  </a>
                </List.Item>
                <List.Item className={"footer-links"}>
                  <a href={"/privacy"} target={"_blank"}>
                    Privacy
                  </a>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <List relaxed>
                <List.Item className={"footer-links"}>
                  <a href="https://facebook.com/shift.dash" target="_blank">Facebook</a>
                </List.Item>
                <List.Item className={"footer-links"}>
                  <a href="https://twitter.com/shiftdash" target="_blank">Twitter</a>
                </List.Item>
                <List.Item className={"footer-links"}>
                  <a href="https://www.instagram.com/shiftdash/" target="_blank">Instagram</a>
                </List.Item>
                <List.Item className={"footer-links"}>
                  <a href="https://www.linkedin.com/company/shiftdash/about/" target="_blank">Linkedin</a>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </Container>
        <Container
          textAlign={"center"}
          style={{ marginTop: "40px", color: "#868e96" }}
        >
          Made with <span className="heart">❤</span> in Atlanta
        </Container>
      </Container>
    );
  }
}

export default Footer;
