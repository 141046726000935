import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from "lodash";
import {
  Container,
  Icon,
  Image,
  Menu,
  Sidebar,
  Responsive,
  Label} from 'semantic-ui-react';
import {connect} from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './navbar.css';
import * as SendBird from "sendbird";

const stateToProps=(state)=>{
    return {
        user: state.user.profile,
        company: state.company.profile,
        router: state.router,
        unreadMessageCount:state.messaging.unreadMessageCount
    };
}

const dispatchToProps=(dispatch)=>{
    return{
        logout:()=>dispatch({type:'USER_LOGOUT'}),
    }
}
const LoggedinasToaster = ({user, logout}) => (
  <div>
    Logged in as {user.first_name} {user.last_name} ({user.email}) <a onClick={logout}>Logout</a>
  </div>
)

const NavBarMobile = ({
  children,
  leftItems,
  onPusherClick,
  onToggle,
  rightItems,
  visible
}) => (
  <Sidebar.Pushable>
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      items={leftItems}
      vertical
      visible={visible}
    />
    <Sidebar.Pusher
      dimmed={visible}
      onClick={onPusherClick}
      style={{ /*minHeight: "100vh"*/ }}
    >
      <Menu fixed="top" inverted>
        <Menu.Item>
          <Link to={'/'}>
            <Image size="mini" src="/assets/images/logo.png" />
          </Link>
        </Menu.Item>
        <Menu.Item onClick={onToggle}>
          <Icon name="sidebar" />
        </Menu.Item>
        <Menu.Menu position="right">
          {_.map(rightItems, item => <Menu.Item {...item} />)}
        </Menu.Menu>
      </Menu>
      {children}
    </Sidebar.Pusher>
  </Sidebar.Pushable>
);

const NavBarDesktop = ({ leftItems, rightItems,unreadMessageCount }) => (
  <Menu fixed="top" inverted>
    <Menu.Item>
      <Link to={'/'}>
          <Image
              size='small'
              src='/assets/images/logo-nav.png'
              style={{ marginRight: '.5em', width: '200px' }}
          />
      </Link>

    </Menu.Item>
    {_.map(leftItems, (item) => {

      //  Menu.item throws warning when passing both content prop and child component
      //  just drop content attr and pass rest of the props to Menu.Item
      const itemProps = Object.assign({}, item);
      delete itemProps['content'];
      return (
        <Menu.Item key={item.key} {...itemProps}>
          {item.content}
          {item.key==='messages' && unreadMessageCount>0 &&
            <Label color='red' floating style={{top:'0',left:'96%'}}>
              {unreadMessageCount}
            </Label>
          }
        </Menu.Item>
        )
      }
    )}
    <Menu.Menu position="right">
      {_.map(rightItems, item => <Menu.Item as={Link} {...item} />)}
    </Menu.Menu>
  </Menu>
);

const NavBarChildren = ({ children }) => (
  <div className={'top-container'}>{children}</div>
);

class NavBar extends Component {

  constructor(props){
      super(props);
      this.userLogout = this.userLogout.bind(this);
      this.renderLoginButton = this.renderLoginButton.bind(this);
      this.renderMobileLoginButton = this.renderMobileLoginButton.bind(this);
      this.leftMenu = this.leftMenu.bind(this);
      this.renderLeftMenu();
      this.renderLeftMenu = this.renderLeftMenu.bind(this);
      this.rightItems = [
          { as: this.renderLoginButton, key: "register" }
      ];
      this.mobileRightItems = [
          { as: this.renderMobileLoginButton, key: "register" }
      ];

  }

  renderLeftMenu(){
    if (!_.isEmpty(this.props.user)){
      this.renderLoginButton();
      this.renderMobileLoginButton();
    }
    else {
      this.leftMenu()
    }
  }

  leftMenu(){
    this.leftItems = [
        { as: Link, to: '/mission', content: "Mission", key: "mission" },
        { as: Link, to: '/blog', content: "Blog", key: "blog" },
        { as: Link, to: '/faq', content: "FAQ", key: "faq" },
        { as: Link, to: '/help', content: "Help", key: "help" },
    ];

    this.mobileLeftItems = [
        { as: Link, to: '/mission', content: "Mission", key: "mission", onClick:this.handleToggle },
        { as: Link, to: '/blog', content: "Blog", key: "blog", onClick:this.handleToggle },
        { as: Link, to: '/faq', content: "FAQ", key: "faq", onClick:this.handleToggle },
        { as: Link, to: '/help', content: "Help", key: "help", onClick:this.handleToggle },
    ];
    
    // REMOVE to add back the links above
    this.leftItems = [];
    this.mobileLeftItems = [];
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.renderLeftMenu()
  }

  componentDidMount() {
    if (this.props.user.loginas){
      toast(<LoggedinasToaster user={this.props.user} logout={this.userLogout}/>, {
        className: 'loggedinas-toast'
      });
    }
  }

  userLogout(){
      this.props.logout();

      let sb = SendBird.getInstance();
      if (sb!==null){
        sb.removeChannelHandler('channel');
      }

      this.leftMenu();
      this.context.router.history.push('/login');
  }

  renderLoginButton(){
      if (this.props.user && !_.isEmpty(this.props.user.type)){
          if (this.props.user.type === 'talent'){
            this.leftItems = [
              { as: Link, to:'/jobs', content: "Jobs", key: "jobs" },
              { as: Link, to:'/bookings', content: "Bookings", key: "bookings" },
              { as: Link, to:'/messages', content: "Messages", key: "messages" },
              { as: Link, to:'/payments', content: "Payments", key: "payments" }
            ];
          }
          else if (this.props.user.type === 'company' || this.props.user.type === 'agency'){
            this.leftItems = [
              { as: Link, to:'/events', content: "Events", key: "events" },
              { as: Link, to:'/messages', content: "Messages", key: "messages" },
              { as: Link, to:'/payments', content: "Payments", key: "payments" }
            ];
          }

          let thumbnail="https://api.adorable.io/avatars/32/" + this.props.user.email;
          if(this.props.user.photo!=="" && this.props.user.photo!==null)
          {
            thumbnail = this.props.user.thumbnail
          }

          return (
            <Menu.Menu position="right">
            {this.props.user.type === 'company' && this.props.company.name !== ''?
              <Menu.Item as={ Link } to={"/profile-company/"+this.props.user.company.id}>
                  {this.props.company.name}
              </Menu.Item>
              :""
            }
              <Menu.Item as={ Link } to={this.props.user.type === "talent"? "/talent/profile/"+this.props.user.talent.id: "/profile-company-user/"+this.props.user.id}>
                  <Image src={thumbnail} style={{width:'32px', height:'32px', borderRadius:'50%', marginRight: '5px'}} rounded/>
                  &nbsp;&nbsp;
                  {this.props.user.first_name}
              </Menu.Item>
              <Menu.Item as={ Link } to={'/settings'}>
                <Icon name="settings" style={{marginRight:"10px", marginLeft:"10px"}} />
              </Menu.Item>
              <Menu.Item onClick={this.userLogout}>
                <Icon name="log out" />
              </Menu.Item>
            </Menu.Menu>
          )
      }

      return (
        <Menu.Menu position="right">
          <Menu.Item as={ Link } to='/login'>
              Login
          </Menu.Item>
          <Menu.Item as={ Link } to='/register'>
              Register
          </Menu.Item>
        </Menu.Menu>
      )
  }

  renderMobileLoginButton(){
      if (!_.isEmpty(this.props.user.type)){
          let thumbnail="https://api.adorable.io/avatars/32/" + this.props.user.email;
          if(this.props.user.photo!=="" && this.props.user.photo!==null)
          {
            thumbnail = this.props.user.thumbnail
          }
          if (this.props.user.type === 'talent'){
            this.mobileLeftItems = [
              { as: Link, to:'/jobs', content: "Jobs", key: "jobs", onClick:this.handleToggle },
              { as: Link, to:'/bookings', content: "Bookings", key: "bookings", onClick:this.handleToggle },
              { as: Link, to:'/messages', content: "Messages", key: "messages", onClick:this.handleToggle },
              { as: Link, to:'/payments', content: "Payments", key: "payments", onClick:this.handleToggle },
              { as: Link, to:this.props.user.type === "talent"? "/talent/profile/"+this.props.user.talent.id: "/profile-company-user/"+this.props.user.id, content: <Menu.Item style={{position:'relative', padding:'0px'}}><Image src={thumbnail} style={{width:'32px', height:'32px', borderRadius:'50%', marginRight: '0px'}} rounded/>&nbsp;&nbsp;<span style={{position:'absolute', top:'10px'}}>{this.props.user.first_name}</span></Menu.Item>, key: "profile", onClick:this.handleToggle }
            ];
          }
          else if (this.props.user.type === 'company' || this.props.user.type === 'agency'){
            this.mobileLeftItems = [
              { as: Link, to:'/events', content: "Events", key: "events", onClick:this.handleToggle },
              { as: Link, to:'/messages', content: "Messages", key: "messages", onClick:this.handleToggle },
              { as: Link, to:'/payments', content: "Payments", key: "payments", onClick:this.handleToggle },
              { as: Link, to:"/profile-company/"+this.props.user.company.id, content: <Menu.Item as={ Link } to={"/profile-company/"+this.props.user.company.id}>{this.props.company.name}</Menu.Item>, key: "company-profile", onClick:this.handleToggle },
              { as: Link, to:this.props.user.type === "talent"? "/talent/profile/"+this.props.user.talent.id: "/profile-company-user/"+this.props.user.id, content: <Menu.Item style={{position:'relative', padding:'0px'}}><Image src={thumbnail} style={{width:'32px', height:'32px', borderRadius:'50%', marginRight: '0px'}} rounded/>&nbsp;&nbsp;<span style={{position:'absolute', top:'10px'}}>{this.props.user.first_name}</span></Menu.Item>, key: "profile", onClick:this.handleToggle }
            ];
          }

          return (
            <Menu.Menu position="right">
              <Menu.Item as={ Link } to={'/settings'}>
                <Icon name="settings" style={{marginRight:"10px", marginLeft:"10px"}} />
              </Menu.Item>
              <Menu.Item onClick={this.userLogout}>
                <Icon name="log out" />
              </Menu.Item>
            </Menu.Menu>
          )
      }


      return (
        <Menu.Menu position="right">
          <Menu.Item as={ Link } to='/login'>
              Login
          </Menu.Item>
          <Menu.Item as={ Link } to='/register'>
              Register
          </Menu.Item>
        </Menu.Menu>
      )
  }

  state = {
    visible: false
  };

  handlePusher = () => {
    const { visible } = this.state;

    if (visible) this.setState({ visible: false });
  };

  handleToggle = () => this.setState({ visible: !this.state.visible });

  render() {
    const { children } = this.props;
    const { visible } = this.state;

    return (
      <div>
        <ToastContainer position="top-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable/>
        <Responsive {...Responsive.onlyMobile}>
          <NavBarMobile
            leftItems={this.mobileLeftItems}
            onPusherClick={this.handlePusher}
            onToggle={this.handleToggle}
            rightItems={this.mobileRightItems}
            visible={visible}
          >
            {children}
          </NavBarMobile>
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <NavBarDesktop leftItems={this.leftItems} rightItems={this.rightItems} unreadMessageCount={this.props.unreadMessageCount}/>
          <NavBarChildren>{children}</NavBarChildren>
        </Responsive>
      </div>
    );
  }
}

export default connect(stateToProps, dispatchToProps)(NavBar)

NavBar.contextTypes = {
    router: PropTypes.object.isRequired
};
