import React,{Component} from 'react';
import { withRouter } from "react-router-dom";
import {
  Segment,
  Button,
  Item,
  Header,
  Form} from 'semantic-ui-react';

import {common} from "../../../utils/common";
import FieldErrors from '../../Common/FieldErrors';
import { store } from "../../../store";


class EditCompanyAbout extends Component{
  commonObj=new common();

  constructor(props){
    super(props);

    this.state={
      form_error:false,
      server_error:false,
      about:'',
      name:'',
      company_id:'',
      lat:'',
      lng:'',
      errors:{},
      showUploadResume:false,
      resumeFile:null,
      resumeFileName:'Upload Resume'
    }
  }

  componentDidMount(){
    this.getData();
  }

  componentWillReceiveProps(nextProps){
    this.getData()
  }

  getData(){
    const state = store.getState();
    this.setState({
      company_id: state.company.profile.id,
      about: state.company.profile.about,
      name: state.company.profile.name,
      lat: state.company.profile.lat,
      lng: state.company.profile.lng,
      errors:{}
    });
  }

  handleChange = (e,{name,value,fieldtype}) => {
    this.commonObj.handleChange(e,this,{name,value,fieldtype});
  }

  saveAboutMe(event){
    event.preventDefault();

    if (this.state.form_error===true || Object.keys(this.state.errors).length > 0){
      return;
    }

    let data = new FormData();
    data.append('id', this.state.company_id);
    data.append('about', this.state.about);
    data.append('name', this.state.name);
    data.append('lat', this.state.lat);
    data.append('lng', this.state.lng);

    this.props.updateCompanyProfile(data);

    // const { headline,description } = this.state;
    this.props.history.push('/profile-company/'+ this.state.company_id);
  }


  render(){
    let {about}=this.state;

    return (
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <Header size='small' color='grey' style={{display: 'inline'}}>
            ABOUT {this.state.name}
          </Header>
        </Segment>
        <Segment textAlign={'left'}>
          <Item>
            <Form size='small' onSubmit={(e)=>this.saveAboutMe(e)} error={Object.keys(this.state.errors).length>0 || this.state.server_error}>
              <Form.TextArea
                label='Description'
                placeholder='Description'
                rows={4}
                name='about'
                value={about}
                fieldtype='text'
                onChange={this.handleChange}
              />
              <FieldErrors formSubmitted={true} errors={this.state.errors} fieldName={'about'}/>
              {
                /*this.state.showUploadResume?
                  <label className={'ui icon button basic'} style={{marginTop:'-5px',marginBottom:'17px',padding:'15px'}}>
                    <Icon name='upload' style={{color:'#774b86'}} className={'resume-upload-icon'}/>
                    {this.state.resumeFileName}
                    <Icon name={'close'} className={'resume-upload-cross'} onClick={(e)=>this.showResumeButton(e,false)}/>
                    <input type="file" style={{display:'none'}} onChange={(e)=>this.handleResumeChange(e)}/>
                  </label>
                  :
                  <Button basic style={{marginTop:'-5px',marginBottom:'17px'}}>
                    <Image src={'/assets/images/pdf-resume-icon.png'} className={'pdf-resume-img'}/>
                    View {this.props.user.firstName}'s Resume
                    <Icon name={'close'} style={{marginLeft:'10px'}} onClick={(e)=>this.showResumeButton(e,true)}/>
                  </Button>*/
              }
              <Form.Field control={Button} content='SAVE' color='blue' size={'small'} style={{marginLeft:'1px'}}/>
              {/*<Button color='blue' size='small' floated={'right'}>SAVE</Button>*/}
            </Form>
          </Item>
        </Segment>
      </Segment.Group>
    )
  }
}

export default withRouter(EditCompanyAbout)
