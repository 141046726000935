import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
import { store } from "../store";


export const isAuthenticated = () => {
    // this function will check if user exist in redux store
    // also if user has token
    // returns True or False
    const user = store.getState().user.profile;
    // console.log(user)
    if (!_.isEmpty(user) && user.token) {
        return true;
    }

    return false;
};

const SignupProcessRoute = ({ component: Component, ...rest }) => (
    // this function will make sure that current matched route is protected route or not
    // If matched route is protected and user isn't logged in, it will redirect user to
    // login page and pass the protected route as a prop so user can get back to same route
    // after login and go to nex step
    <Route {...rest} render={(props) => (
        isAuthenticated() === true
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/login',
                search: '?retUrl='+props.location.pathname,
                referrer: { pathname: props.location.pathname }
            }} />
    )} />
)

export default SignupProcessRoute
