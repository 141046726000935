import * as actionType from "../constants/actionTypes";

const initialState={
  waitingRequest:false,
  success: false,
  error: null,
  message: null,
  profile: {
      name: '',
      street: '',
      city: '',
      state: '',
      country: '',
      lat: '',
      lng: '',
      contacts: []
  },
};

export default (state=initialState,action) =>{
  switch (action.type){
      case actionType.COMPANY_UPDATE_SUCCESS:{
          return {
            ...state,
            success: true,
            error: null,
            profile: {...state.profile, ...action.responseData}
          };
    }
      case actionType.COMPANY_UPDATE_ERROR:{
          return {
            ...state,
              success: false,
              error: action.errorMessage
          };
    }
      case actionType.GET_COMPANY_PROFILE_SUCCESS:{
          return {
              ...state,
              success: true,
              error: null,
              profile: action.responseData //{...state.profile, ...action.responseData}
          };
      }
      case actionType.GET_COMPANY_PROFILE_ERROR:{
          return {
              ...state,
              success: false,
              error: action.errorMessage
          };
      }


      case actionType.REQUEST_STRIPE_CONNECT:
          return {
              ...state,
              waitingRequest: action.value
          };

      default:
        return state;
  }
}