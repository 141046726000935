// Original source code here: https://github.com/vayser/react-js-pagination

import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import {Grid, Icon, Label,Menu} from "semantic-ui-react";
import moment from 'moment';

import './pagination.css';

export default class Page extends Component {
    static propTypes = {
        pageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        pageNumber: PropTypes.number.isRequired,
        onClick: PropTypes.func.isRequired,
        isActive: PropTypes.bool.isRequired,
        isDisabled: PropTypes.bool,
        activeClass: PropTypes.string,
        activeLinkClass: PropTypes.string,
        itemClass: PropTypes.string,
        linkClass: PropTypes.string,
        href: PropTypes.string,
        isNavigationPage:PropTypes.bool,
        eventDay:PropTypes.object,
        disabledClass:PropTypes.string
    };

    static defaultProps = {
        activeClass: "active",
        itemClass: undefined,
        linkClass: undefined,
        activeLinkCLass: undefined,
        isActive: false,
        isDisabled: false,
        href: "#",
        isNavigationPage:false
    };

    handleClick=(e)=> {
        const { isDisabled, pageNumber } = this.props;
        e.preventDefault();
        if (isDisabled) {
            return;
        }
        this.props.onClick(pageNumber);
    }

    render() {
        let {
            pageText,
            pageNumber,
            activeClass,
            itemClass,
            linkClass,
            activeLinkClass,
            isActive,
            isDisabled,
            href,
            disabledClass,
            eventDay
        } = this.props;

        const css = cx(itemClass, {
            [activeClass]: isActive,
            [disabledClass]: isDisabled
        });

        const linkCss = cx(linkClass, {
            [activeLinkClass]: isActive
        });

        let colorName='red';
        if (isActive){
          colorName='red';
        }
        else{
          colorName='teal'
        }

        // return (
        //     <li className={css} onClick={(e)=>this.handleClick(e)}>
        //         <a className={linkCss} href={href}>
        //             {pageText}
        //         </a>
        //     </li>
        // );

        let elem=this.props.isNavigationPage===true ?
          (<Menu.Item as='a' className={isDisabled?'disabled-class':''} onClick={(e)=>this.handleClick(e)}>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <p style={{fontSize:25}}>{pageText}</p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Menu.Item>)
          :
          (<Menu.Item as='a' onClick={(e)=>this.handleClick(e)}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Icon name='calendar outline' />
                </Grid.Column>
                <Grid.Column>
                  <p style={{color:'red'}}>{moment(eventDay.date,'YYYY-MM-DD').format('MMM')}</p>
                  <p style={{fontSize:25}}>{moment(eventDay.date,'YYYY-MM-DD').format('DD')}</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Label color={colorName} floating>
              {eventDay.slotsRemaining}
            </Label>
          </Menu.Item>);

      return(
        elem
      )
    }
}
