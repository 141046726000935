import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Segment, Header, Container, Form, Button, Checkbox} from 'semantic-ui-react';
import {SIGNUP_NEXT} from "../../../constants/actionTypes";
import { updateTalentProfile } from "../../../actions/talent";
import ServerErrors from '../../Common/ServerErrors';
import moment from 'moment';

const stateToProps=(state)=>{
    return {
      ...state.talent,
        waitingRequest: state.common.waitingRequest
    };
};

const dispatchToProps=(dispatch)=>{
  return{
    gotoNext:()=>dispatch({type:SIGNUP_NEXT, value:'area'}),
    updateTalentProfile: (data)=>dispatch(updateTalentProfile(data))
  }
};

class SignupGenderBirthday extends Component {
  countryOptions=[];

  constructor(props){
    super(props);
    this.generateMonths();

    this.submitForm=this.submitForm.bind(this);
    this.handleChange=this.handleChange.bind(this);
    const { gender, date_of_birth } = this.props.profile;
    let dob_arr = [];
    if (date_of_birth){
        dob_arr = date_of_birth.split('-');
    }

    this.state={
      gender: gender || 'female',
      birth_day: dob_arr[2] || '',
      birth_day_error:false,
      birth_month: dob_arr[1] || '',
      birth_month_error:false,
      birth_year: dob_arr[0] || '',
      birth_year_error:false,
      formSubmitted: false,
      year_age_error: false,
      legal_checkbox: false
    }
  }

  generateMonths(){
    this.countryOptions.push({ key: 0, value: '01', text: 'January' });
    this.countryOptions.push({ key: 1, value: '02', text: 'February' });
    this.countryOptions.push({ key: 2, value: '03', text: 'March' });
    this.countryOptions.push({ key: 3, value: '04', text: 'April' });
    this.countryOptions.push({ key: 4, value: '05', text: 'May' });
    this.countryOptions.push({ key: 5, value: '06', text: 'June' });
    this.countryOptions.push({ key: 6, value: '07', text: 'July' });
    this.countryOptions.push({ key: 7, value: '08', text: 'August' });
    this.countryOptions.push({ key: 8, value: '09', text: 'September' });
    this.countryOptions.push({ key: 9, value: '10', text: 'October' });
    this.countryOptions.push({ key: 10, value: '11', text: 'November' });
    this.countryOptions.push({ key: 11, value: '12', text: 'December' });
  }

  handleGenderButtons(event,value){
   event.preventDefault();
   this.setState({gender:value});
  }

  toggle = event => this.setState(state => ({legal_checkbox: !state.legal_checkbox}))

  handleChange = (e,{name,value}) => {
    const state = this.state;
    state[`${name}_error`] = !value;

    if (name === 'birth_day'){
      if (value.length > 2){
        value = value.substr(0, 2);
      }

      state['birth_day_error'] = parseInt(value) > 31 || parseInt(value) < 1;
    }

    if (name === 'birth_year'){
      if (value.length > 4) {
          value = value.substr(0, 4);
      }
    }

    state[name] = value;
    this.setState(state, () => this.validateAge());
  };

  validateAge = () => {
    const state = this.state;
    const {birth_year, birth_month, birth_day} = state;
    if (birth_year && birth_year.length === 4) {
        const currentYear = new Date().getFullYear();
        state['birth_year_error'] = parseInt(birth_year) > currentYear || parseInt(birth_year) < (currentYear - 100);

        const date_of_birth = `${birth_year}-${birth_month || '01' }-${birth_day || '01'}`;
        state['year_age_error'] = moment().diff(date_of_birth, 'years') <= 13;
    }

    this.setState(state);
  };

  formValidated = () => {
    const {birth_day, birth_month, birth_year, year_age_error, birth_month_error, birth_day_error, birth_year_error, legal_checkbox} = this.state;
    if (!birth_month){
      this.setState({birth_month_error: true});
      return false;
    }
    if (!legal_checkbox){
      return false;
    }

    return birth_day && birth_month && birth_year && legal_checkbox &&
           !year_age_error && !birth_month_error && !birth_day_error && !birth_year_error;
  };

  submitForm = () => {
    const {birth_day, birth_month, birth_year, gender} = this.state;
    this.setState({formSubmitted: true});
    console.log(this.formValidated());
    console.log(this.state);
    if (!this.formValidated()){
      this.setState({form_error:true});
      return false;
    }

    console.log('why ehre');
    this.setState({form_error:false});
    const date_of_birth = `${birth_year}-${birth_month}-${birth_day}`;
    const data = {gender, date_of_birth};
    this.props.updateTalentProfile(data);
  };

  userNotAllowErrors = () => {
    if (this.state.year_age_error) {
      return <ServerErrors errorMessage="We don’t allow users less than 13 years old." />
    }
  };

  renderServerErrors = () => {
    if (this.props.error && this.state.formSubmitted){
      return <ServerErrors errorMessage={this.props.error} />
    }
  }

  renderServerSuccess = () => {
      const {formSubmitted, form_error} = this.state;
      console.log('success');
      console.log(this.props);
      console.log(this.state);
      if (this.props.success && formSubmitted && !form_error){
        this.props.gotoNext();
      }
  };

  render() {
    const {birth_month,birth_day,birth_year, gender}=this.state;
    return(
      <Segment attached raised style={{paddingTop:'40px',paddingBottom:'40px'}}>
        <Header size={'large'}>Great! Select a gender and enter your birthday.</Header>
        <Container textAlign='center' className={'signup-container'} style={{marginTop: '32px'}}>

            {this.userNotAllowErrors()}
            {this.renderServerErrors()}
            {this.renderServerSuccess()}

            <Form size='large' error={this.state.form_error || this.state.server_error}>
            <Button.Group style={{marginBottom: '20px'}}>
              <Button type={'button'} active={gender==='female'} onClick={(e)=>this.handleGenderButtons(e,'female')}>FEMALE</Button>
              <Button type={'button'} active={gender==='male'} onClick={(e)=>this.handleGenderButtons(e,'male')}>MALE</Button>
              <Button type={'button'} active={gender==='other'} onClick={(e)=>this.handleGenderButtons(e,'other')}>OTHER</Button>
            </Button.Group>
            <Form.Group inline widths='equal' style={{marginBotton:'10px'}}>
              <Form.Select
                fluid
                options={this.countryOptions}
                placeholder='MM'
                name='birth_month'
                value={birth_month}
                onChange={this.handleChange}
                error={this.state.birth_month_error}
              />
              <Form.Input
                fluid
                type={'number'}
                placeholder='DD'
                name='birth_day'
                value={birth_day}
                maxLength={2}
                onChange={this.handleChange}
                error={this.state.birth_day_error}
                required={true}
              />
              <Form.Input
                fluid
                type={'number'}
                placeholder='YYYY'
                name='birth_year'
                value={birth_year}
                maxLength={4}
                onChange={this.handleChange}
                error={this.state.birth_year_error}
                required={true}
              />
            </Form.Group>
            <Form.Field>
              <Checkbox
                // error={this.renderFieldError('legal_checkbox')}
                required={true}
                onChange={this.toggle}
                // isChecked={false}
                label="I attest, under penalty of perjury, that I am legally allowed to work and be paid in the United States."
              />
            </Form.Field>
            <p style={{color: '#a2a8af', fontSize: '13px', fontWeight: '600', marginBottom:'1px'}}>Your gender and birthday will not be made public.</p>
            <Segment as='a' href={'/terms'} target="_blank" className={'custom-anchor-tag'}>View our Terms & Conditions</Segment>
            <Button type={'button'}  onClick={() => this.submitForm()} color='blue' fluid size='large' loading={this.props.waitingRequest} style={{marginTop:'40px'}}>Next</Button>
          </Form>
        </Container>
      </Segment>
    )
  }
}

export default connect(stateToProps,dispatchToProps)( SignupGenderBirthday)
