function truncateNumber(num) {
  num = String(num);
  if(num.indexOf('.') !== -1) {
        const numArr = num.split(".");
        if (numArr.length === 1)
          return Number(num);
        else
            return Number(`${numArr[0]}.${numArr[1].charAt(0)}`);
  }else {
    return Number(num);
  }
}

export const calculateAmount = (amount) => {
      amount = parseFloat(amount);

      if (amount < 1){
          return `$${amount}`;
      }

      if (amount > 0 && amount < 100){
          return `<$${100}`;
      }

      if (amount >= 100 && amount < 1000){
          // convert down to nearest 100
          amount = Math.floor(amount/100) * 100;
          return `$${amount}+`;
      }

      // convert kilo
      amount = amount/1000;
      return `$${truncateNumber(amount)}k+`;

  };
