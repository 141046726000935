import React,{Component} from 'react';
import {Container, Grid, Segment, Menu, Header, Icon, Message} from 'semantic-ui-react';
import {connect} from "react-redux";
import './settings-talent.css';
import SocialSettings from './SocialSettings';
import NotificationSettings from './NotificationSettings';
import AdminUsers from './AdminUsers';
import PaymentSettings from './PaymentSettings';
import CalendarSettings from '../CalendarSettings';
import UserInformation from '../UserInformation';
import ChangePassword from '../ChangePassword';
import CompanyInformation from './CompanyInformation';
import {parse} from "qs";


const stateToProps=(state)=>{
    return{
        router: state.router,
    }
};


class SettingsCompany extends Component{
  constructor(props){
    super(props);
    this.state = {
      activeTab: '',
      queryString: '',
      lightBlueMessageVisible:false,
      blueMessageVisible:false,
      yellowMessageVisible:false
    }
  }

  componentWillMount(){
    this.queryString = parse(this.props.router.location.search.substr(1));
    let {activeTab} = this.props.match.params;
    activeTab = (activeTab !== undefined) ? activeTab : '';
    this.setState({activeTab});
    this.props.history.push(`/settings/${activeTab}`);
  }

  handleItemClick = (tab) => {
      this.setState({activeTab: tab});
      this.props.history.push(`/settings/${tab}`);
  };

  handleDismiss = (event,name) => {
    if (name==='light-blue'){
      this.setState({ lightBlueMessageVisible: false });
    }
    if (name==='blue'){
      this.setState({ blueMessageVisible: false });
    }
    if (name==='yellow'){
      this.setState({ yellowMessageVisible: false });
    }
  }

  render() {
    const { activeTab } = this.state;

    return (
      <Container className={'main-container'}>
        <Grid stackable  padded>
          {
            (this.state.lightBlueMessageVisible===true ||
              this.state.blueMessageVisible===true ||
              this.state.yellowMessageVisible===true) &&
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                {this.state.lightBlueMessageVisible===true &&
                <Message info
                         onDismiss={(e)=>this.handleDismiss(e,'light-blue')}
                         header='This is a system notification with a short title line!'
                         content='This is a longer explanation of what this notification is about along with some descriptive text about how to take action.'
                />
                }
                {this.state.blueMessageVisible === true &&
                <Message color={'blue'}
                         onDismiss={(e) => this.handleDismiss(e, 'blue')}
                         header='This is a system notification with a short title line!'
                         content='This is a longer explanation of what this notification is about along with some descriptive text about how to take action.'
                />
                }
                {this.state.yellowMessageVisible === true &&
                <Message warning
                         onDismiss={(e) => this.handleDismiss(e, 'yellow')}
                         header='This is a system notification with a short title line!'
                         content='This is a longer explanation of what this notification is about along with some descriptive text about how to take action.'
                />
                }
              </Grid.Column>
            </Grid.Row>
          }
          <Grid.Row>
            <Grid.Column mobile={16} tablet={4} computer={4}>
              <Segment>
                <Header as='h4'>Settings</Header>
                <hr/>
                <Menu secondary vertical>
                  <Menu.Item name='payments' active={!activeTab} onClick={(e, {name}) => this.handleItemClick('') } />
                  <Menu.Item name='company-users' active={activeTab === 'company-users'} onClick={(e, {name}) => this.handleItemClick(name) }>
                    Users
                  </Menu.Item>
                  <Menu.Item name='notifications' active={activeTab === 'notifications'} onClick={(e, {name}) => this.handleItemClick(name) } />
                  <Menu.Item name='social' active={activeTab === 'social'} onClick={(e, {name}) => this.handleItemClick(name) } />
                  <Menu.Item name='calendar' active={activeTab === 'calendar'} onClick={(e, {name}) => this.handleItemClick(name) } />
                  <Menu.Item name='user-information' active={activeTab === 'user-information'} onClick={(e, {name}) => this.handleItemClick(name) } />
                  <Menu.Item name='change-password' active={activeTab === 'change-password'} onClick={(e, {name}) => this.handleItemClick(name) } />
                  <Menu.Item name='company-information' active={activeTab === 'company-information'} onClick={(e, {name}) => this.handleItemClick(name) } />
                </Menu>
                <hr/>
                <a href='' onClick={(e)=>this.signout(e)}>
                  <Icon name='log out'/>
                  SIGN OUT
                </a>
              </Segment>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={12} computer={12}>
              {!this.state.activeTab && <PaymentSettings queryString={this.queryString}/>}
              {this.state.activeTab==='payments' && <PaymentSettings queryString={this.queryString}/>}
              {this.state.activeTab==='company-users' && <AdminUsers {...this.props}/>}
              {this.state.activeTab==='notifications' && <NotificationSettings queryString={this.queryString}/>}
              {this.state.activeTab==='social' && <SocialSettings queryString={this.queryString}/>}
              {this.state.activeTab==='calendar' && <CalendarSettings queryString={this.queryString}/>}
              {this.state.activeTab==='user-information' && <UserInformation queryString={this.queryString}/>}
              {this.state.activeTab==='change-password' && <ChangePassword {...this.props} />}
              {this.state.activeTab==='company-information' && <CompanyInformation />}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}

export default connect(stateToProps, null)(SettingsCompany)
