import React, { Component } from 'react';
import { Segment, Header } from 'semantic-ui-react';
import moment from 'moment';

import './css/date-box.css';

class DateBox extends Component{
  constructor(props){
    super(props);
  }
  render() {

    return(
      <Segment textAlign={'center'} className={'box'}>
        <Header as={'h6'} className={'day-text'}>{moment(this.props.date).format('dddd').toUpperCase()}</Header>
        <Header as={'h1'} className={'date-text'}>{moment(this.props.date).format('DD')}</Header>
        <Header as={'h4'} className={'month-text'}>{moment(this.props.date).format('MMM').toUpperCase()}</Header>
      </Segment>
    )
  }
}

export default DateBox
