import React from 'react';
import {Button, Dimmer, Form, Grid, Loader, Modal, Header, Container, Accordion, Icon} from "semantic-ui-react";
import ReactTags from "react-tag-autocomplete";
import {connect} from "react-redux";
import moment from 'moment-timezone';
import _ from 'lodash';
import ServerErrors from "../../Common/ServerErrors";
import FieldErrors from "../../Common/FieldErrors";
import {BLANK_TEXT_ERROR_MESSAGE} from "../../../utils/Constants";
import {addCertification, addSkill} from "../../../actions/talent";
import InfiniteCalendar from "react-infinite-calendar";
import Calendar from "../../../libs/react-big-calendar/src";
import withDragAndDrop from "../../../libs/react-big-calendar/src/addons/dragAndDrop";
import {handleInputChange} from "../../../utils/common";
import ReactHtmlParser from 'react-html-parser';
import moment_timezone from 'moment-timezone';
import TimezonePicker from '../../../libs/react-timezone';

Calendar.setLocalizer(Calendar.momentLocalizer(moment));
const DnDCalendar = withDragAndDrop(Calendar);

const heighOptions = [
  {key:"",value:"",text:"Choose Height"},
  {key:"5'0''",value:"5'0''",text:"5'0''"},
  {key:"5'1''",value:"5'1''",text:"5'1''"},
  {key:"5'2''",value:"5'2''",text:"5'2''"},
  {key:"5'3''",value:"5'3''",text:"5'3''"},
  {key:"5'4''",value:"5'4''",text:"5'4''"},
  {key:"5'5''",value:"5'5''",text:"5'5''"},
  {key:"5'6''",value:"5'6''",text:"5'6''"},
  {key:"5'7''",value:"5'7''",text:"5'7''"},
  {key:"5'8''",value:"5'8''",text:"5'8''"},
  {key:"5'9''",value:"5'9''",text:"5'9''"},
  {key:"6'0''",value:"6'0''",text:"6'0''"},
  {key:"6'1''",value:"6'1''",text:"6'1''"},
  {key:"6'2''",value:"6'2''",text:"6'2''"},
  {key:"6'3''",value:"6'3''",text:"6'3''"},
  {key:"6'4''",value:"6'4''",text:"6'4''"},
  {key:"6'5''",value:"6'5''",text:"6'5''"},
  {key:"6'6''",value:"6'6''",text:"6'6''"},
  {key:"6'7''",value:"6'7''",text:"6'7''"},
  {key:"6'8''",value:"6'8''",text:"6'8''"},
  {key:"6'9''",value:"6'9''",text:"6'9''"},
  {key:"7'0''",value:"7'0''",text:"7'0''"}
];

const stateToProps=(state)=>{
  return{
    skills: state.talent.skills,
    certifications: state.talent.certifications,
    addSkillWaiting:state.talent.addSkillWaiting,
    addCertificationWaiting:state.talent.addCertificationWaiting
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    addSkill: (data) => dispatch(addSkill(data)),
    addCertification: (data) => dispatch(addCertification(data))
  }
};

class RoleModal extends React.Component{
  constructor(props){
    super(props);
    const {event, role, skills, certifications, startDate} = this.props;
    const selectedSkills = role['skills'];
    let skillSuggestions = skills;

    // exclude selected skills from suggestions drop down
    if (selectedSkills.length > 0){
      skillSuggestions = skillSuggestions.filter(skill => selectedSkills.find(selectedSkill => selectedSkill.id !== skill.id));
    }

    const selectedCertifications = role['certifications'];
    //exclude selected certs from suggestions drop down
    let certificationSuggestions = certifications;
    if (selectedCertifications.length > 0){
        certificationSuggestions = certifications.filter(cert => selectedCertifications.find(selectedCert => selectedCert.id !== cert.id));
    }
    this.state  = {
      isNewRole:this.props.isNewRole,
      formSubmitted:false,
      form_error:true,
      server_error:false,
      role,
      skillSuggestions,
      certificationSuggestions,
      selectedDate: startDate && moment(startDate).format('YYYY-MM-DD'),
      selectedDateShifts:[],
      selectedShift:null,
      deletedShifts:[],
      errors:{
        count:BLANK_TEXT_ERROR_MESSAGE,
        name:BLANK_TEXT_ERROR_MESSAGE,
      },
      accordionIndex: 0,
      eventTimezone: !_.isEmpty(event) && event.timezone ? event.timezone : moment.tz.guess()
    };

  }

  componentDidMount(){
    if (this.props.isNewRole===false){
      this.setState({errors:{},form_error:false});
    }
    document.getElementById('my-div').scrollTop=0;
    this.setDefaultTimezone();
  }

  setDefaultTimezone = () => {
    // moment_timezone.tz.setDefault(this.state.eventTimezone);
    Calendar.momentLocalizer(moment_timezone);
    this.forceUpdate()
  };

  handleChange = (e,{name,value,fieldtype,required}) => {
    const {role} = this.state;
    role[name] = value;
    handleInputChange(e, this, {name, value, fieldtype, required});
  }

  handleTimezoneChange = (timezone) => {
    this.setState(
        {eventTimezone: timezone},
        () => {
          // this.updateTimeSlotsTimezone();
          this.setDefaultTimezone();
        }
        );
  };

  handleAccordianClick = (e, titleProps) => {
    const { index } = titleProps
    const { accordionIndex } = this.state
    const newIndex = accordionIndex === index ? -1 : index

    this.setState({ accordionIndex: newIndex })
  }

  removeSkill (i) {
    const {role, skillSuggestions} = this.state;
    const deletedSkill = role.skills.find((skill, index) => index === i);
    skillSuggestions.push(deletedSkill);
    role.skills.splice(i, 1);
    this.setState({ role, skillSuggestions });
  }

  addSkill (skill) {
     const {role} = this.state;
     let {skillSuggestions} = this.state;
     role.skills.push(skill);
     skillSuggestions = skillSuggestions.filter(s => s.id !== skill.id);
     this.setState({role, skillSuggestions});
  }

  removeCertification (i) {
    const {role, certificationSuggestions} = this.state;
    const deletedCert = role.certifications.find((cert, index) => index === i);
    certificationSuggestions.push(deletedCert);
    role.certifications.splice(i, 1);
    this.setState({ role, certificationSuggestions });
  }

  addCertification (certification) {
     const {role} = this.state;
     let {certificationSuggestions} = this.state;
     role.certifications.push(certification);
     certificationSuggestions = certificationSuggestions.filter(c => c.id !== certification.id);
     this.setState({role, certificationSuggestions});
  }

  selectGender(event,item){
    this.setState({gender:item.value})
  }

  addRole=(event)=>{
    const duties = document.getElementById('duty').innerHTML;

    event.preventDefault();
    this.setState({formSubmitted:true});
    this.setState({server_error:true});

    if (this.state.form_error===true || Object.keys(this.state.errors).length > 0){
      return;
    }

    const {role, isNewRole, eventTimezone}=this.state;
    role['duties'] = duties;
    this.props.addEditRole(isNewRole, role, eventTimezone);
  };

  closeModal=()=>{
    this.props.closeRoleModal();
  };

  eventDateSelect=(date)=>{
    const {role} = this.state;
    const selectedDateShifts = role.shifts.filter(shift => shift.date === date);
    this.setState({selectedDate: moment(date).format('YYYY-MM-DD'), selectedDateShifts});
  };

  onNavigate=()=>{};

  onEventResize = (type, { event, start, end }) => {
    const {role} = this.state;
    role['shifts'] = role.shifts.map(shift => {
       if (shift.id === event.id){
          console.log(start);
          console.log(moment.utc(start).format('HH:mm:ss'));
          return {
              ...shift,
              start,
              end,
              start_time: moment.utc(start).format('HH:mm:ss'),
              end_time: moment.utc(end).format('HH:mm:ss')
          }
       }

       return shift;
    });

    this.setState({ role });
  };

  onEventDrop = ({ event, start, end}) => {
    const {role} = this.state;
    role['shifts'] = role.shifts.map(shift => {
      if (shift.id === event.id){
        return {
          ...shift,
            start,
            end,
            start_time: moment.utc(start).format('HH:mm:ss'),
            end_time: moment.utc(end).format('HH:mm:ss')
        }
      }
      return shift;
    });

    this.setState({ role });
  };

  onSelectSlot=(data)=>{
    const {role} = this.state;
    const newRole = {
        isNew: true,
        event_date: moment(this.state.selectedDate).format('YYYY-MM-DD'),
        start: data.start,
        end: data.end,
        start_time: moment.utc(data.start).format('HH:mm:ss'),
        end_time: moment.utc(data.end).format('HH:mm:ss')
    };

    role['shifts'].push(newRole);
    this.setState({ role });
  };

  onSelectEvent=(shift)=>{
    this.setState({selectedShift:shift});
  };

  deleteShift=()=>{
    const {role} = this.state;
    let {selectedShift}=this.state;
    let shiftId=selectedShift.id;
    let deletedShifts=this.state.deletedShifts.slice();

    role['shifts'] =_.reject(role['shifts'], shift => {
      if (shift.isNew){
        return moment(shift.start).format('hh:ss')===moment(selectedShift.start).format('hh:ss') && moment(shift.end).format('hh:ss')===moment(selectedShift.end).format('hh:ss')
      }
      else if (!shift.isNew && shiftId){
        return shift.id.toString()===shiftId.toString();
      }
      return false;
    });
    deletedShifts.push(this.state.selectedShift)

    this.setState({ role, deletedShifts });
  };

  render(){
    const {accordionIndex, role, certificationSuggestions, skillSuggestions, formSubmitted, errors, server_error, eventTimezone } = this.state;
    const {error} = this.props;

    const payTypeOptions = [
      { key: 0, value: 'hourly', text: 'Hourly'},
      { key: 1, value: 'fixed', text: 'Fixed (Daily)'}
    ];

    let genders=[
        {value: 'female', text: 'Female'},
        {value: 'male', text: 'Male'},
        {value: 'any', text: 'No Preference'}
        ];

    genders = genders.map((gender,index)=>{
      if (gender.value===gender){
        return (
          <Button key={index} active={true} onClick={(e)=>this.selectGender(e, gender)}>{gender.text}</Button>
        )
      }
      else{
        return (
          <Button key={index} active={false} onClick={(e)=>this.selectGender(e, gender)}>{gender.text}</Button>
        )
      }
    });

    // a bit of hack to stop new Date() from converting date to user's timezone
    let selectedDate = this.state.selectedDate.split('-');
    const year = parseInt(selectedDate[0]);
    const month = parseInt(selectedDate[1]) - 1;
    const day = parseInt(selectedDate[2]);
    selectedDate = new Date(year, month, day);
    selectedDate = moment_timezone.tz(moment({year, month, day}).format(), eventTimezone);
    selectedDate = selectedDate.toDate();

    return(
      <Modal open className={'role-modal'} closeOnEscape={true} closeOnDimmerClick={false} onClose={this.closeModal} closeIcon>
        <Modal.Header>
            <Header size={'small'}> {this.props.isNewRole ? 'New Role' : 'Edit Role'}</Header>
        </Modal.Header>
        <Modal.Content id='my-div' scrolling>
        <Form error={(formSubmitted && Object.keys(errors).length>0) || server_error}>
          {error && <ServerErrors errorMessage={error}/>}
          <Grid>
            <Grid.Row>
            <Accordion styled fluid>
              <Accordion.Title active={this.state.accordionIndex === 0} index={0} onClick={this.handleAccordianClick}>
                <Icon name='dropdown' />
                Details
              </Accordion.Title>
              <Accordion.Content active={accordionIndex === 0}>
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={8} tablet={8} computer={8}>
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <label>Number of staff for this role:</label>
                        <Form.Input style={{marginBottom:'10px'}}
                          type='number'
                          placeholder='2'
                          name='count'
                          value={role.count}
                          fieldtype='text'
                          error={this.state.formSubmitted && this.state.errors['count']}
                          onChange={this.handleChange}
                          required={true}/>
                        <FieldErrors formSubmitted={formSubmitted} errors={errors} fieldName={'count'}/>
                      </Form.Field>

                      <Form.Field>
                        <label>Role Name</label>
                        <Form.Input style={{marginBottom:'10px'}}
                          type='text'
                          placeholder='Brand Ambassador'
                          name='name'
                          value={role.name}
                          fieldtype='text'
                          error={this.state.formSubmitted && this.state.errors['name']}
                          onChange={this.handleChange}
                          required={true}/>
                        <FieldErrors formSubmitted={formSubmitted} errors={errors} fieldName={'name'}/>
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={8} computer={8}>
                    <Form.Field>
                      <label>Duties</label>

                      <section className="editable_text_area" id="duty" error={this.state.formSubmitted && this.state.errors['duties']} ref="googleInput" contentEditable="true">
                      {
                        role.duties===''?
                          <ul>
                              <li id='duties' ></li>
                          </ul>
                        :
                          ReactHtmlParser(role.duties)
                      }
                      </section>
                      <FieldErrors formSubmitted={formSubmitted} errors={errors} fieldName={'duties'}/>
                    </Form.Field>
                    <Form.Field>
                      <label>Attire</label>
                      <Form.Input style={{marginBottom:'10px'}}
                        type='text'
                        placeholder='A branded shirt will be provided. Please wear khaki pants and comfortable shoes.'
                        name='attir'
                        value={role.attir}
                        fieldtype='text'
                        error={this.state.formSubmitted && this.state.errors['attir']}
                        onChange={this.handleChange}
                        required={false}/>
                      <FieldErrors formSubmitted={formSubmitted} errors={errors} fieldName={'attir'}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              </Accordion.Content>

              <Accordion.Title active={this.state.accordionIndex === 1} index={1} onClick={this.handleAccordianClick}>
                <Icon name='dropdown' />
                Additional Preferences
              </Accordion.Title>
              <Accordion.Content active={accordionIndex === 1}>
              <Container>
                <Button.Group style={{marginBottom:'10px'}}>
                  {genders}
                </Button.Group>

                <Form.Group widths='equal'>
                  <Form.Field>
                    <label>Height</label>
                    <Form.Select style={{marginBottom:'10px'}}
                     name={'height_min'}
                     value={role.height_min}
                     placeholder='Height (min)'
                     options={heighOptions}
                     onChange={this.handleChange}
                     fieldtype='select'
                     required={false}/>
                  </Form.Field>
                  <Form.Field>
                    <label>&nbsp;</label>
                    <Form.Select style={{marginBottom:'10px'}}
                     name={'height_max'}
                     value={role.height_max}
                     placeholder='Height (max)'
                     options={heighOptions}
                     onChange={this.handleChange}
                     fieldtype='select'
                     required={false}/>
                  </Form.Field>
                </Form.Group>
                <Dimmer active={this.props.addSkillWaiting || this.props.addCertificationWaiting}>
                  <Loader>Saving new skill</Loader>
                </Dimmer>
                <Form.Group widths='equal'>
                  <Form.Field>
                    <label>Skills</label>
                    <ReactTags
                      allowNew={true}
                      minQueryLength={1}
                      placeholder={'Add skills'}
                      tags={role.skills}
                      suggestions={skillSuggestions}
                      handleDelete={this.removeSkill.bind(this)}
                      handleAddition={this.addSkill.bind(this)}/>
                  </Form.Field>
                  <Form.Field>
                    <label>Certifications</label>
                    <ReactTags
                      allowNew={true}
                      minQueryLength={1}
                      placeholder={'Add certifications'}
                      tags={role.certifications}
                      suggestions={certificationSuggestions}
                      handleDelete={this.removeCertification.bind(this)}
                      handleAddition={this.addCertification.bind(this)}/>
                  </Form.Field>
                </Form.Group>
              </Container>
              </Accordion.Content>

              <Accordion.Title active={this.state.accordionIndex === 2} index={2} onClick={this.handleAccordianClick}>
                <Icon name='dropdown' />
                Role Schedule
              </Accordion.Title>
              <Accordion.Content active={true}>
              <Grid>
                <Grid.Row>

                  <Grid.Column mobile={16} tablet={16} computer={16}>
                      <p>{eventTimezone}</p>
                     <TimezonePicker
                        value={eventTimezone}
                        onChange={timezone => this.handleTimezoneChange(timezone)}
                        inputProps={{ placeholder: 'Select Timezone.'}}
                        style={{zIndex: 999999, width: '50%', marginBottom: '20px'}}
                      />
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={6} style={{paddingRight:'0'}}>
                    <InfiniteCalendar
                      minDate={this.props.startDate && moment(this.props.startDate).toDate()}
                      maxDate={this.props.endDate && moment(this.props.endDate).toDate()}
                      selected={this.state.selectedDate}
                      theme={{ selectionColor: 'rgb(138, 84, 156)'}}
                      onSelect={this.eventDateSelect}
                      height={350}
                      style={{marginBottom:'0px'}}/>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={10}>
                    <div className={'dnd-calendar-container'}>
                      <DnDCalendar
                        step={15}
                        timeslots={8}
                        date={selectedDate}
                        onNavigate={this.onNavigate}
                        defaultDate={selectedDate}
                        views={["day"]}
                        defaultView="day"
                        events={role.shifts}
                        onEventDrop={this.onEventDrop}
                        onEventResize={this.onEventResize}
                        onSelectSlot={this.onSelectSlot}
                        selectable={true}
                        resizable
                        scrollToTime={new Date(new Date().setHours(19))}
                        onSelectEvent={shift => this.onSelectEvent(shift)}
                        onDeleteClick={() => this.deleteShift()}
                        deleteEventLoading={this.props.deleteShiftLoading}
                        style={{ height: "350"}}/>
                    </div>
                  </Grid.Column>

                </Grid.Row>
              </Grid>
              </Accordion.Content>
            </Accordion>

            </Grid.Row>


          </Grid>
        </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color='blue' onClick={(e)=>this.addRole(e)}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default connect(stateToProps,dispatchToProps)(RoleModal) ;
