import React, { Component } from 'react';
import {connect} from "react-redux";
import {
  Container,
  Menu,
  Message,
  Segment,
  Button,
  Icon,
  Grid,
  Form,
  Image,
  Header,
  Divider} from 'semantic-ui-react';

import SocialButton from './SocialButton';


import './signup.css';
import {common} from '../../../utils/common';
import {BLANK_TEXT_ERROR_MESSAGE} from '../../../utils/Constants';
import * as settings from '../../../../src/constants/constants';
import {registerUser, resendVerificationEmail} from '../../../actions/signupAction';
import FieldErrors from '../../Common/FieldErrors';
import ServerErrors from '../../Common/ServerErrors';
import {getCompanyName} from '../../../actions/company';
let Recaptcha = require('react-recaptcha');

const stateToProps=(state)=>{
  return { ...state.signup,
    company_name:state.company.profile.name
  }
}

const dispatchToProps=(dispatch)=>{
  return{
    registerUser:(data)=>dispatch(registerUser(data)),
      resendVerificationEmail:(data)=>dispatch(resendVerificationEmail(data)),
      getCompanyName:(data)=>dispatch(getCompanyName(data))
  }
}

class EmailSignup extends Component {
  commonObj=new common();

  constructor(props){
    super(props);

    this.goBack=this.goBack.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.createAccount=this.createAccount.bind(this);
    this.resendEmail=this.resendEmail.bind(this);
    if(Object.keys(this.props.match.params).length > 0){
      var com_id=this.props.match.params.com_id;
      var user_email=this.props.match.params.email;
      user_email=user_email.replace(/%/g, "@");
      this.props.getCompanyName(com_id)
    }

    this.state = {
      form_submitted:false,
      form_error:true,
      first_name: '',
      last_name: '',
      company_name:'a',
      email: user_email,
      password:'',
      is_talent:true,
      type: (com_id) ? 'company' :'talent',
      server_error:false,
      errors:{
        first_name:BLANK_TEXT_ERROR_MESSAGE,
        last_name:BLANK_TEXT_ERROR_MESSAGE,
        email:BLANK_TEXT_ERROR_MESSAGE,
        password:BLANK_TEXT_ERROR_MESSAGE,
      },
      is_inv_user: (com_id) ? true : false,
      verified_by_captcha: false
    };

    this.onLoginSuccess = this.onLoginSuccess.bind(this)
    this.onLoginFailure = this.onLoginFailure.bind(this)
    this.onLogoutSuccess = this.onLogoutSuccess.bind(this)
    this.onLogoutFailure = this.onLogoutFailure.bind(this)
    // this.logout = this.logout.bind(this)
  }

  handleChange = (e,{name,value,fieldtype}) => {
    this.commonObj.handleChange(e,this,{name,value,fieldtype});
    if(value==="talent"){
      this.setState({is_talent:true});
    }else if(value==="company"){
      this.setState({is_talent:false, company_name:''});
    }
  }

  goBack(){
    this.props.history.push('/login');
  }

  createAccount(event){
     event.preventDefault();
    this.setState({form_submitted:true});
    this.setState({server_error:true});

    if (this.state.form_error===true || Object.keys(this.state.errors).length > 0 || !this.state.verified_by_captcha){
      return;
    }

    let { first_name, last_name, email,password,type, company_name } = this.state;
    email = email.trim().toLowerCase()
    this.props.registerUser({ first_name, last_name, email, password, type, company_name });
  }

  resendEmail(){
    this.props.resendVerificationEmail({email: this.state.email});
  }

  renderServerErrors(){
      if (this.props.emailSignupError){
          return <ServerErrors errorMessage={this.props.emailSignupError} />
      }
      if (this.props.resendEmailError){
          return <ServerErrors errorMessage={this.props.resendEmailError} />
      }
  }

  componentWillReceiveProps(nextProps){
      if (nextProps.emailSignupSuccess){
        this.setState({server_error:false});
        this.props.history.push('/login');
      }
  }

  setNodeRef (provider, node) {
    if (node) {
      this.nodes[ provider ] = node
    }
  }

  onLoginSuccess (user) {

    this.setState({
      logged: true,
      provider_id: user._profile.id,
      email: user._profile.email,
      type: 'talent',
      provider_name: user._provider,
      last_name: user._profile.lastName,
      first_name: user._profile.firstName,
      access_token: user._token.accessToken,
      user
    })

    var name = 'first_name', value = user._profile.firstName, fieldtype = 'text';
    this.commonObj.handleChange('event',this,{name,value,fieldtype});

    name = 'last_name';
    value = user._profile.lastName;
    fieldtype = 'text'
    this.commonObj.handleChange('event',this,{name,value,fieldtype});

    name = 'email';
    value = user._profile.email;
    fieldtype = 'email'
    this.commonObj.handleChange('event',this,{name,value,fieldtype});

    const { provider_id, email, type, provider_name, last_name, first_name, access_token } = this.state;
    this.props.registerSocialUser({ provider_id, email, type, provider_name, last_name, first_name, access_token });

    this.gotoNextView();
  }

  onLoginFailure (err) {
    console.error(err)

    this.setState({
      logged: false,
      currentProvider: '',
      user: {}
    })
  }

  onLogoutSuccess () {
    this.setState({
      logged: false,
      currentProvider: '',
      user: {}
    })
  }

  onLogoutFailure (err) {
    console.error(err)
  }

  logout () {
    const { logged, currentProvider } = this.state

    if (logged && currentProvider) {
       this.nodes[currentProvider].props.triggerLogout()
    }
  }

  onloadCaptchaCallback = () => {
    console.log('captcha loaded')
  }

  verifyCaptchaCallback = response => {
    if(response) {
      this.setState({
        verified_by_captcha: true
      })
    }
  }

  renderForm(){
    const {first_name,last_name,email,password,type, company_name}=this.state;

    if (this.props.emailSignupSuccess){
      return (
        <Form size='large' onSubmit={this.createAccount} error={this.state.server_error}>
        <Segment raised>
            {this.renderServerErrors.bind(this)()}
            <p>
                {
                  this.props.userType === 'talent' ?
                  'Thanks for signing up. Please check your email for a confirmation link.'
                  :
                  'Thanks for your interest in our app. Thanks for signing up. Please check your email for a confirmation link.'
                }
            </p>

            {/*uncomment this for beta release*/}
            {/*<Icon name='envelope outline' size={'huge'} color='blue' />*/}

            {/*<Header size='medium'>We sent a verification email to:</Header>*/}
            {/*<Header size='large' style={{marginTop: '-5px'}}><strong>{this.state.email}</strong></Header>*/}
            {/*<p>*/}
                {/*Click on the verification URL in the email to finalize account*/}
                {/*creation and start building your Shift Dash profile!*/}
            {/*</p>*/}
            {/*<Button fluid*/}
              {/*size={'large'}*/}
              {/*content='RE-SEND EMAIL'*/}
              {/*style={{marginBottom:'25px'}}*/}
              {/*onClick={this.resendEmail}*/}
              {/*loading={this.props.waitingRequest}*/}
            {/*/>*/}
            <Menu secondary>
              <Menu.Item as='a' style={{marginLeft:'auto',marginRight:'auto'}} onClick={this.goBack}>
                <Icon name='arrow left' link/>
                Back
              </Menu.Item>
            </Menu>
        </Segment>
        </Form>
      )
    }

    return(
      <Form size='large' error={(this.state.form_submitted && Object.keys(this.state.errors).length>0) || this.state.server_error}>
        <Segment style={{width:'400px'}}>
            {this.renderServerErrors.bind(this)()}

            {(this.state.is_inv_user) ?
              null
             :
              <Form.Group inline widths="equal">
                <Form.Radio name='type' label='Talent' value='talent' checked={type === 'talent'} fieldtype='radio' onChange={this.handleChange} error={this.state.errors.hasOwnProperty('type')} />
                <Form.Radio name='type' label='Company' value='company' checked={type === 'company'} fieldtype='radio' onChange={this.handleChange} error={this.state.errors.hasOwnProperty('type')}/>
              </Form.Group>
            }
            <br />
            <div style={{display:this.state.is_talent? 'none': 'block'}}>
              <Form.Input
                fluid
                icon='briefcase'
                iconPosition='left'
                placeholder='Company Name'
                name='company_name'
                value={company_name}
                fieldtype='text'
                error = {this.state.form_submitted && this.state.errors['company_name']}
                onChange={this.handleChange}
              />
            </div>
            <FieldErrors formSubmitted={this.state.form_submitted} errors={this.state.errors} fieldName={'type'} fieldType={'radio'}/>
            <div style={{display:this.state.is_talent? 'block': 'none'}}>
              <SocialButton
                provider='facebook'
                appId='174051176589391'
                onLoginSuccess={this.onLoginSuccess}
                onLoginFailure={this.onLoginFailure}
                onLogoutSuccess={this.onLogoutSuccess}
                getInstance={this.setNodeRef.bind(this, 'facebook')}
                key={'facebook'}
                fluid color={'facebook'}
                size={'large'}
                content='CONTINUE WITH FACEBOOK'
                icon='facebook f' labelPosition='left'
                style={{marginBottom:'25px'}}
                type={'button'}
              />
              <SocialButton
                provider='google'
                appId='632902765335-nhliag2gj6qfoofcufqecmeucnlkaq4i.apps.googleusercontent.com'
                onLoginSuccess={this.onLoginSuccess}
                onLoginFailure={this.onLoginFailure}
                onLogoutSuccess={this.onLogoutSuccess}
                onLogoutFailure={this.onLogoutFailure}
                getInstance={this.setNodeRef.bind(this, 'google')}
                key={'google'}
                fluid color={'google plus'}
                size={'large'}
                content='CONTINUE WITH GOOGLE'
                icon='google' labelPosition='left'
                style={{marginBottom:'25px'}}
                type={'button'}
              />
              <Divider horizontal>Or</Divider>
            </div>

            <h3>{this.props.company_name}</h3>
            <Form.Group widths="equal">
              <Form.Input
              fluid
              icon='user'
              iconPosition='left'
              placeholder='First Name'
              name='first_name'
              value={first_name}
              fieldtype='text'
              error = {this.state.form_submitted && this.state.errors['first_name']}
              onChange={this.handleChange}
            />
            <FieldErrors formSubmitted={this.state.form_submitted} errors={this.state.errors} fieldName={'first_name'}/>
            <Form.Input
              fluid
              placeholder='Last Name'
              name='last_name'
              value={last_name}
              fieldtype='text'
              error = {this.state.form_submitted && this.state.errors['last_name']}
              onChange={this.handleChange}
            />
            <FieldErrors formSubmitted={this.state.form_submitted} errors={this.state.errors} fieldName={'last_name'}/>
          </Form.Group>
          <Form.Input
            fluid
            icon='mail'
            iconPosition='left'
            placeholder='Email'
            name='email'
            value={email}
            fieldtype='email'
            error = {this.state.form_submitted && this.state.errors['email']}
            onChange={this.handleChange}
          />
          <FieldErrors formSubmitted={this.state.form_submitted} errors={this.state.errors} fieldName={'email'}/>
          <Form.Input
            fluid
            icon='lock'
            iconPosition='left'
            placeholder='Password'
            type='password'
            name='password'
            value={password}
            fieldtype='password'
            error = {this.state.form_submitted && this.state.errors['passwordy']}
            onChange={this.handleChange}
          />
          <FieldErrors formSubmitted={this.state.form_submitted} errors={this.state.errors} fieldName={'password'}/>

          <Button color='blue' fluid size='large' onClick={this.createAccount} loading={this.props.waitingRequest} disabled={this.props.waitingRequest}>CREATE ACCOUNT</Button>
          <div className='recaptcha-custom'>
            <Recaptcha
              sitekey={settings.CAPTACHA_SITE_KEY}
              render="explicit"
              onloadCallback={this.onloadCaptchaCallback}
              verifyCallback={this.verifyCaptchaCallback}
            />
          </div>
          {
            this.state.form_submitted && !this.state.verified_by_captcha && (
              <Message negative>
                <span>Please confirm you are not robot</span>
              </Message>
            )
          }
          <Menu secondary>
            <Menu.Item as='a' style={{marginLeft:'auto',marginRight:'auto'}} onClick={this.goBack}>
              <Icon name='arrow left' link/>
              Back
            </Menu.Item>
          </Menu>
        </Segment>
      </Form>
    )
  }

  render() {
    return(
      <Container textAlign='center' className={'main-container'}>
        <Grid stackable style={{marginBottom: '0rem'}}>
          <Grid.Column mobile={16} tablet={16} computer={7}>
            <Container style={{ height: '600px' }}>
              <div className={'signup-container-inner'}>
                  { this.renderForm() }
              </div>
            </Container>
          </Grid.Column>
          <Grid.Column computer={9} only='computer' style={{backgroundColor: '#d8c8de'}}>
            <Container>

            </Container>
          </Grid.Column>
        </Grid>
      </Container>
    )
  }

}

export default connect(stateToProps,dispatchToProps)(EmailSignup);
