import React, { Component } from 'react';
import {
  Container,
  Header,
  Grid
} from 'semantic-ui-react';
import '../Common/js/script.js';
import './mission.css';

class Mission extends Component {
  render() {
    return(
      <React.Fragment>
        <Container className={'mission-container'} fluid textAlign={'center'}>
          <Header as={'h1'} style={{fontSize:'3.5rem'}}>Our Mission</Header>
          <span style={{marginTop:'100px'}}>We facilitate a seamless experiential marketing experience</span>
        </Container>
        <Container  textAlign={'center'} style={{padding:'80px 0'}}>
          <Header as={'h2'} style={{fontSize:'3.5rem'}}>Our customers are #1</Header>
          <span style={{marginTop:'100px'}}>See how Shift Dash has changed the way brands staff their events.</span>
        </Container>
        <Container className={'mission-feature-container'} textAlign={'center'}>
          <Grid centered doubling columns={3} stackable textAlign={'center'}>
            <Grid.Column>
              <article className="post">
                <div className="post_media_wrapper">
                  <a className="title" href="blog-single.html">
                    <img src={'assets/images/home/blog-1.jpg'} alt="not available"/>
                  </a>
                </div>
                <div className="entry-content clearfix">
                  <a href="blog-single.html">
                    <h3 className="post_title">Content Planning and Strategy</h3>
                  </a>
                  <div className="post-meta">
                    <small className="author"> by <a href={null}>Kristen G </a></small>
                    <small className="time"> on <a href={null}>July 10 </a></small>
                  </div>
                  <p className={'post-desc'}> Seamlessly restore wireless ideas before interdependent e-tailers. Conveniently morph emerging
                    quality vectors for progressive catalysts for change utilize fully tested... </p>
                </div>
                <a href="blog-single.html" className={'continue-reading'}>Continue reading →</a>
              </article>
            </Grid.Column>
            <Grid.Column>
              <article className="post">
                <div className="post_media_wrapper">
                  <img src={'assets/images/home/blog-2.jpg'} alt="not available"/>
                </div>
                <div className="entry-content clearfix">
                  <a href="blog-single.html">
                    <h3 className="post_title">Work Desk Inspirations</h3>
                  </a>
                  <div className="post-meta">
                    <small className="author"> by <a href={null}>Kristen G </a></small>
                    <small className="time"> on <a href={null}>July 10 </a></small>
                  </div>
                  <p className={'post-desc'}> Seamlessly restore wireless ideas before interdependent e-tailers. Conveniently morph emerging
                    quality vectors for progressive catalysts for change utilize fully tested... </p>
                </div>
                <a href="blog-single.html" className={'continue-reading'}>Continue reading →</a>
              </article>
            </Grid.Column>
            <Grid.Column>
              <article className="post">
                <div className="post_media_wrapper">
                  <img src={'assets/images/home/blog-3.jpg'} alt="not available"/>
                </div>
                <div className="entry-content clearfix">
                  <a href="blog-single.html">
                    <h3 className="post_title">Success and Team Work</h3>
                  </a>
                  <div className="post-meta">
                    <small className="author"> by <a href={null} className="link-muted">Kristen G </a></small>
                    <small className="time"> on <a href={null} className="link-muted">July 15 </a></small>
                  </div>
                  <p className={'post-desc'}> Seamlessly restore wireless ideas before interdependent e-tailers. Conveniently morph emerging
                    quality vectors for progressive catalysts for change utilize fully tested... </p>
                </div>
                <a href="blog-single.html" className={'continue-reading'}>Continue reading →</a>
              </article>
            </Grid.Column>
          </Grid>
        </Container>
      </React.Fragment>
    )
  }
}

export default Mission
