import React,{Component} from 'react';
import {
  Segment,
  List,
  Accordion,
  Icon
} from 'semantic-ui-react';

class DocumentSettings extends Component{
  constructor(props){
    super(props);

    this.state = { activeIndex: 0 }
  }

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render(){
    let list=[];
    const { activeIndex } = this.state;

    if (this.props.importantDocuments){
      list=this.props.importantDocuments.map((item,index)=>{
        let docs=item.documents.map((docItem,docIndex)=>{
          return(
            <List.Item key={docIndex}>
              <List.Content>
                <List.Description style={{marginLeft:'10px'}}>
                  <strong>{docItem.title}</strong>
                  <input type={'file'} style={{float:'right'}}/>
                </List.Description>
              </List.Content>
            </List.Item>
          )
        });

        return(
          <div key={index}>
            <Accordion.Title active={activeIndex === index} index={index} onClick={this.handleAccordionClick}>
              <Icon name='dropdown' />
              {item.title}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === index}>
              <List>
                {docs}
              </List>
            </Accordion.Content>
          </div>
        )
      });
    }

    return(
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <span>
            DOCUMENTS
          </span>
        </Segment>
        <Segment textAlign={'left'}>
          <Accordion styled={true} fluid={true}>
            {list}
          </Accordion>
        </Segment>
      </Segment.Group>
    )
  }
}

export default DocumentSettings