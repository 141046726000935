import axios from 'axios';
import {API_URL_BASE} from '../constants/constants';

import {
  GET_TALENT_JOBS_WAITING,
  GET_TALENT_JOBS_FULFILLED,
  GET_TALENT_JOBS_FAILED
} from '../constants/actionTypes';
import {common} from '../utils/common';

export const getJobsData = () => {
  const commonObj = new common();

  return (dispatch) => {
    dispatch({type: GET_TALENT_JOBS_WAITING, value: true});

    axios.get(`${API_URL_BASE}/event/list/talent?status=published`)
      .then((response) => {
        commonObj.handleServerSucess(response, dispatch, GET_TALENT_JOBS_FULFILLED);
        dispatch({type: GET_TALENT_JOBS_WAITING, value: false});

      })
      .catch((err) => {
        commonObj.handleServerError(err, dispatch, GET_TALENT_JOBS_FAILED);
        dispatch({type: GET_TALENT_JOBS_WAITING, value: false});
      })
  }
}
