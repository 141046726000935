import axios from 'axios';
import {API_URL_BASE} from '../constants/constants';
import * as actionTypes from '../constants/actionTypes';
import {common} from '../utils/common';

export const loadMessageGroups = (data) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:actionTypes.WAITING_REQUEST, value:true});

    axios.get(`${API_URL_BASE}/messaging/my-groups`)
      .then((response)=>{
         commonObj.handleServerSucess(response, dispatch, actionTypes.MESSAGING_GROUPS_LOAD_SUCCESS);
      })
      .catch((err) =>{
        commonObj.handleServerError(err, dispatch, actionTypes.MESSAGING_GROUPS_LOAD_ERROR);
      })
  }
};


export const loadOneToOneChats = () => {
  const commonObj=new common();
  return (dispatch) => {
    dispatch({type:actionTypes.WAITING_REQUEST, value:true});

    axios.get(`${API_URL_BASE}/messaging/my-one-to-one-chats`)
      .then((response)=>{
         commonObj.handleServerSucess(response, dispatch, actionTypes.ONE_TO_ONE_CHATS_LOAD_SUCCESS);
      })
      .catch((err) =>{
        commonObj.handleServerError(err, dispatch, actionTypes.ONE_TO_ONE_CHATS_LOAD_ERROR);
      })
  }
};

export const getParticipant =(id)=>{
    const commonObj=new common();

    return (dispatch)=>{
        dispatch({type: actionTypes.WAITING_REQUEST, value:true});

        axios.get(`${API_URL_BASE}/user/profile/${id}`)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionTypes.ONE_TO_ONE_CHAT_PARTICIPANT_GET_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionTypes.ONE_TO_ONE_CHAT_PARTICIPANT_GET_ERROR);
            })
    }
};


export const updateChannelUrl = (id, data) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:actionTypes.WAITING_REQUEST, value:true});

    axios.put(`${API_URL_BASE}/messaging/update-channel-url/${id}`, data)
      .then((response)=>{
         commonObj.handleServerSucess(response, dispatch, actionTypes.UPDATE_CHANNEL_URL_SUCCESS);
      })
      .catch((err) =>{
        commonObj.handleServerError(err, dispatch, actionTypes.UPDATE_CHANNEL_URL_ERROR);
      })
  }
};

export const createOneToOneChat = (data) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:actionTypes.WAITING_REQUEST, value:true});

    axios.post(`${API_URL_BASE}/messaging/create-one-to-one-chat`, data)
      .then((response)=>{
         commonObj.handleServerSucess(response, dispatch, actionTypes.CREATE_ONE_TO_ONE_CHAT_SUCCESS);
         dispatch(loadOneToOneChats());
      })
      .catch((err) =>{
        commonObj.handleServerError(err, dispatch, actionTypes.CREATE_ONE_TO_ONE_CHAT_ERROR);
      })
  }
};