import React from 'react';
import {Placeholder} from "semantic-ui-react";

const ContentPlaceHolder=(props)=>{
  let paragraphs=[];
  for(let i=0;i<props.totalParagraphs;i++){
    paragraphs.push(
      <Placeholder.Paragraph key={i}>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    );
  }

  return (
    <Placeholder fluid>
      {paragraphs}
    </Placeholder>
  )
}

export default ContentPlaceHolder;
