import React, { Component } from "react";
import { Container, Segment, Message } from "semantic-ui-react";

class Policy extends Component {
  render() {
    return (
      <div>
        <Container
          textAlign="left"
          className={"main-container signup-container"}
        >
          <Segment>
            <h2>Privacy Policy</h2>{" "}
            <p>
              {" "}
              By accessing this website http://shiftdash.com (the “Site”), you
              agree to the terms of this Privacy Policy (the “Privacy Policy”).
              All capitalized terms used herein and not otherwise defined herein
              shall have the meaning assigned to such terms in the User
              Agreement.{" "}
            </p>{" "}
            <p>
              This Privacy Policy includes and hereby incorporates by reference
              the following important agreements, as they may be in effect and
              modified from time to time: User Agreement and Site Terms of Use.
              These agreements are collectively, with this Agreement, called the
              “Terms of Service”.{" "}
            </p>{" "}
            <p>
              This page informs you of our policies regarding the collection,
              use and disclosure of Personal Information when you use our Site
              and Site Services. We will not use or share your information with
              anyone except as described in this Privacy Policy.{" "}
            </p>{" "}
            <p>
              We use your Personal Information for providing and improving the
              Service. By using the Service, you agree to the collection and use
              of information in accordance with this Privacy Policy. Unless
              otherwise defined in this Privacy Policy, terms used in this
              Privacy Policy have the same meanings as in our Terms of Service,
              accessible at http://shiftdash.com/terms.{" "}
            </p>{" "}
            <p>
              <li>We respect your privacy.</li>
              <li>
                Security is important to us. We strive to use commercially
                acceptable means to protect your information, but we cannot
                guarantee its absolute security.
              </li>
              <li>
                We collect information you give us when you register or use our
                online forms. We track the web pages you visit through the use
                of cookies.{" "}
              </li>
              <li>
                We may use your information to improve the performance and
                accuracy of our website and system. We will only do so if we can
                preserve the confidentiality of user identifiable information.
              </li>
              <li>
                If you choose to post publicly on our blog or otherwise
                explicitly give us permission to share your identifiable
                information with others, this information may become public.
              </li>
              <li>You can opt out of marketing communications we send.</li>
              <li>We will not sell your information to a third party.</li>
              <li>
                {" "}
                We may link to other websites. We cannot vouch for their privacy
                policies.
              </li>
              <li>
                We will post updates to our Privacy Policy on our website.
              </li>
              <li>Use of the website is subject to the Terms of Service.</li>
              <li>
                Please contact us if you would like to learn more about our
                company and our policies.
              </li>
            </p>{" "}
          </Segment>
        </Container>
      </div>
    );
  }
}

export default Policy;
