import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { store } from "../store";
import _ from "lodash";
import AuthenticatedRoute from "./authenticated.route";
import CompanyRoute from "./company.route";
import TalentRoute from "./talent.route";
import SignupProcessRoute from "./signupProcess.route";

import Home from "../components/Home";
import SignupVerify from "../components/Account/SignupVerify";
import LoginAs from "../components/Account/Loginas";
import MainSignup from "../components/Account/Signup/MainSignup";
import EmailSignup from "../components/Account/Signup/EmailSignup";
import SignupNext from "../components/Account/SignupNext";
import BookingsTalent from "../components/Bookings/Talent";
import Mission from "../components/Mission";
// import Contact from "../components/Contact";
import ForgotPassword from "../components/Account/ForgotPassword";
import ResetPassword from "../components/Account/ResetPassword";
import CreateEvent from "../components/Events/CreateEvent";
import EventInvoice from "../components/Events/EventInvoice";
import Settings from "../components/Settings";
import TalentProfile from "../components/Profile/Talent";
import CompanyProfile from "../components/Profile/Company";
import CompanyUserProfile from "../components/Profile/Company/User";
import JobsTalent from "../components/Jobs/Talent";
import JobsCompany from "../components/Jobs/Company";
import JobDetailsTalent from "../components/Jobs/Talent/Details";
import MessageComponent from "../components/Message";
import PaymentTalent from "../components/Payment/Talent";
import PaymentCompany from "../components/Payment/Company";
import AssignShifts from "../components/Company/AssignShifts";
import NotFound from "../components/Common/notfound";
import JobDetailsCompany from "../components/Jobs/Company/Details";
import TimesheetCompany from "../components/Timesheet/Company";
import TimesheetTalent from "../components/Timesheet/Talent";
import EventDeposit from "../components/Events/EventDeposit";
import PrivacyPolicy from "../components/Legal/Policy";
import UserAgreement from "../components/Legal/Terms";
import Rating from "../components/Jobs/Rating";

const Routes = () => {
  const user = store.getState().user.profile;
  const userType = !_.isEmpty(user) && user.type;

  return (
    <Switch>
      {/*Public routes    */}
      <Route exact path="/" component={Home} />
      <Route exact path="/login" component={MainSignup} />
      <Route exact path="/register" component={EmailSignup} />
      <Route
        exact
        path="/user/register-contact/:com_id/:email/:token"
        component={EmailSignup}
      />
      <Route
        exact
        path="/user/verify-email/:uid/:token/:newEmail?"
        component={SignupVerify}
      />
      <Route
        exact
        path="/user/loginas"
        component={LoginAs}
      />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route
        exact
        path="/user/reset-password/:uid/:token"
        component={ResetPassword}
      />
      <Route exact path="/mission" component={Mission} />
      {/* <Route exact path='/help' component={() => window.location = 'https://support.shiftdash.com/portal/'}/> */}
      {/* <Route exact path="/contact" component={ Contact } /> */}
      <Route exact path="/privacy" component={PrivacyPolicy} />
      <Route exact path="/terms" component={UserAgreement} />
      {
        <Route
          exact
          path="/blog"
          component={() => (window.location = "http://blog.shiftdash.com/")}
        />
      }
      {/*common authenticated routes for both company and talent*/}
      <SignupProcessRoute path="/signup/next" component={SignupNext} />
      <AuthenticatedRoute
        path="/messages/:participant?"
        component={MessageComponent}
      />
      <AuthenticatedRoute
        exact
        path="/payments"
        component={
          userType && userType === "talent" ? PaymentTalent : PaymentCompany
        }
      />
      <AuthenticatedRoute
        exact
        path="/settings/:activeTab?"
        component={Settings}
      />
      {/*company allowed routes*/}
      <CompanyRoute exact path="/events" component={JobsCompany} />
      <CompanyRoute exact path="/events/create" component={CreateEvent} />
      <CompanyRoute exact path="/event/:id" component={JobDetailsCompany} />
      <CompanyRoute
        exact
        path="/event/:event_id/assign-shifts"
        component={AssignShifts}
      />
      <CompanyRoute exact path="/event/:id/update" component={CreateEvent} />
      <CompanyRoute
        exact
        path="/event/:event_id/invoice"
        component={EventInvoice}
      />
      <CompanyRoute
        exact
        path="/event/:event_id/deposit"
        component={EventDeposit}
      />
      <CompanyRoute
        exact
        path="/event/:event_id/timesheet"
        component={TimesheetCompany}
      />
      <AuthenticatedRoute
        exact
        path="/profile-company-user/:id/:mode?"
        component={CompanyUserProfile}
      />
      <AuthenticatedRoute
        exact
        path="/profile-company/:id/:mode?"
        component={CompanyProfile}
      />
      <CompanyRoute
        exact
        path="/jobdetails/:id/:source?"
        component={JobDetailsCompany}
      />
      {/*talent allowed routes*/}
      <TalentRoute exact path="/jobs" component={JobsTalent} />
      <AuthenticatedRoute exact path="/job/:id/rating" component={Rating} />
      */}
      <TalentRoute
        exact
        path="/job/:event_id/timesheet"
        component={TimesheetTalent}
      />
      <TalentRoute
        exact
        path="/job/:id/:source?"
        component={JobDetailsTalent}
      />
      <TalentRoute exact path="/bookings" component={BookingsTalent} />
      <AuthenticatedRoute
        exact
        path="/talent/profile/:id/:mode?"
        component={TalentProfile}
      />
      */}
      {/*redirect routes*/}
      <Redirect from="/profile-company" to="/profile-company/view" />
      <Route exact path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
