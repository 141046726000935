import React,{Component} from 'react';
import {connect} from "react-redux";
import SyncGoogleCalendar from './SyncGoogleCalendar';

class CalendarSettings extends Component{

  render(){

    return(
      <SyncGoogleCalendar {...this.props}/>
    )
  }
}

export default connect(null, null)(CalendarSettings)
