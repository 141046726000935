import React,{Component} from 'react';
import {Container, Segment, Grid, Header} from 'semantic-ui-react';
import {calculateAmount} from "../helper";

class ProfileStatistics extends Component{
  render() {
    const talent = this.props.talent.profile;
    return (
        <Segment textAlign={'left'} className={'statistics-segment'} disabled={this.props.isDisable}>
          <Grid columns={3} divided style={{ marginBottom: '0px', marginTop:'0px'}}>
            <Grid.Column>
            <Container textAlign={'center'}>
              <Header size={'large'} className={'statistic-value'}>{talent['jobs_count']}</Header>
              <Header size={'large'} className={'statistic-label'}>Jobs</Header>
            </Container>
          </Grid.Column>

            <Grid.Column>
            <Container textAlign={'center'}>
              <Header size={'large'} className={'statistic-value'}>{talent['total_hours_worked']}</Header>
              <Header size={'large'} className={'statistic-label'}>Hours</Header>
            </Container>
          </Grid.Column>

            <Grid.Column>
            <Container textAlign={'center'}>
              <Header size={'large'} className={'statistic-value'}>{calculateAmount(talent['total_earning'])}</Header>
              <Header size={'large'} className={'statistic-label'}>Earned</Header>
            </Container>
          </Grid.Column>
          </Grid>
        </Segment>
    )
  }
}

export default ProfileStatistics
