import React,{Component} from 'react';
import { withRouter } from "react-router-dom";
import {
  Container,
  Header,
  Card,
  Rating,
  Icon,
  Modal,
  Button,
  Grid,
  Image} from 'semantic-ui-react';

import UserList from './UserList';
import ProfileStatistics from './ProfileStatistics';
import { store } from "../../../store";
import ImageCrop from 'react-image-crop-component';
import 'react-image-crop-component/style.css';


class CompanyInfo extends Component{
  constructor(props){
    super(props);
    this.gotoEdit=this.gotoEdit.bind(this);
    this.goToUser=this.goToUser.bind(this);

    this.state={
      edit_show:false,
      imageFile:null,
      imagePreviewUrl:null,
      cropedImage:null,
      open:false,
      company_id:this.props.company.id
    }

    this.gotoEdit=this.gotoEdit.bind(this);
    this.savePhoto=this.savePhoto.bind(this);
    this.onCropped = this._onCropped.bind(this)
  }

  gotoEdit(event){
    event.preventDefault();
    this.props.history.push('/profile-company/'+ this.props.company.id +'/edit');
  }

  goToUser(e){
    this.props.history.push('/profile-company-user/'+e.target.id);
  }


  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        imageFile: file,
        imagePreviewUrl: reader.result,
        open: true,
      });
    }

    reader.readAsDataURL(file)
  }

  cancelPhoto(event) {
    this.setState({imageFile: null, imagePreviewUrl: null,open: false, cropedImage:null, savebutton:true})
  }

  savePhoto(event){
    let data = new FormData();
    data.append('id', this.props.company.id);
    data.append('photo', this.state.cropedImage);

    this.props.updateCompanyProfile(data);
    this.setState({imageFile: null, imagePreviewUrl: null,open: false, cropedImage:null, savebutton:true})
    // window.location.reload(true);
    // setTimeout(() => window.location.reload(true), 1000);
  }

   _onCropped(e) {
    this.setState({cropedImage:e.image, savebutton:false})
  }

  render(){
    const companyLogo = '/assets/images/company-logo.png'

    return(
      <Card className="card-hover" style={{width:'100%', marginBottom: '150px'}}>
        <Card.Content textAlign={'left'}>
          <Card.Header>
              {this.props.isLoggedUser ?
                  <label style={{cursor: this.state.edit_show ? 'pointer' : ''}} onMouseOver={(e)=>{this.setState({edit_show:true})}} onMouseOut={(e)=>{this.setState({edit_show:false})}} >
                    <Container align="center" style={{backgroundColor:'black'}}>
                      <Image src={this.props.company.photo===null ? companyLogo : this.props.company.photo} style={{width:'100%', height:'100%',borderRadius:'5px'}} className={'company-photo'} />
                    </Container>
                    <Icon name='camera' size='big' style={{color: 'rgba(138, 84, 156, 0.3)', position: 'absolute', top: '5%', right: '5%', display:this.state.edit_show ? 'block' : 'none'}}/>
                    <input type="file" style={{display:'none'}} onClick={(event)=> {event.target.value = null}} onChange={(e)=>this.handleImageChange(e)}/>
                  </label>
                :
                  <Image src={this.props.company.photo===null ? companyLogo : this.props.company.photo} className={'company-photo'} />
              }
          </Card.Header>
              <Modal open={this.state.open}>
                <Modal.Content>
                  </Modal.Content>
                  <div>
                    {this.state.imagePreviewUrl && (
                      <div style={{width: "100%", height: "100%"}}>
                          <ImageCrop src={this.state.imagePreviewUrl}
                            setWidth={1000}
                            setHeight={1000}
                            square={false}
                            resize={true}
                            border={"dashed #ffffff 2px"}
                            onCrop={this.onCropped}/>
                      </div>
                    )}
                  </div>
                  <Modal.Actions>
                    <Button color='red' onClick={(e)=>this.cancelPhoto(e)}>
                      <Icon name='remove' /> Cancel
                    </Button>
                    <Button color='green' disabled={this.state.savebutton} onClick={(e)=>this.savePhoto(e)}>
                      <Icon name='checkmark' /> Crop & Save Image
                    </Button>
                  </Modal.Actions>
              </Modal>
            <Card.Description>
              <Header as='h2' content={this.props.company.name} marginTop={'30px'}/>
              <hr />
              <Grid>
                <Grid.Row verticalAlign='middle'>
                  <Grid.Column width={3} verticalAlign='middle'>
                    <Icon size={'large'} name={'marker'} style={{color:'#774b86'}}/>
                  </Grid.Column>
                  <Grid.Column width={13} verticalAlign='middle'>
                    <div style={{fontSize:'12px'}}>{this.props.company.street}</div>
                    <div style={{fontSize:'12px'}}>{this.props.company.city}, {this.props.company.state}</div>
                    <div style={{fontSize:'12px'}}>{this.props.company.country}</div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Description>

              { /*<Rating icon='star' rating={this.props.data.companyRating} maxRating={5} className={'company-rating'} disabled/>*/ }


          <hr />
          <UserList goToUser={()=>this.goToUser} contact={this.props.company.contacts}/>
          <hr/>
          <ProfileStatistics company={this.props.company}/>
          <hr/>
          <Card.Description>
            <Grid columns={2} padded>
              <Grid.Row centered>
                {this.props.company.site_url ?
                  <Grid.Column width={8} verticalAlign='middle'>
                    <a href={this.props.company.site_url} target="_blank">
                      <Icon size={'huge'} name={'globe'} className={'company-icons'}/>
                    </a>
                  </Grid.Column>
                  : null
                }
                {this.props.company.facebook_url ?
                  <Grid.Column width={8} verticalAlign="middle">
                    <a href={this.props.company.facebook_url} target="_blank">
                      <Icon size={'huge'} name={'facebook'} className={'company-icons'}/>
                    </a>
                  </Grid.Column>
                  : null
                }
                {this.props.company.linkedin_url ?
                  <Grid.Column width={8} verticalAlign="middle">
                    <a href={this.props.company.linkedin_url} target="_blank">
                      <Icon size={'huge'} name={'linkedin'} className={'company-icons'}/>
                    </a>
                  </Grid.Column>
                  : null
                }
                {this.props.company.twitter_url ?
                  <Grid.Column width={8} verticalAlign="middle">
                    <a href={this.props.company.twitter_url} target="_blank">
                      <Icon size={'huge'} name={'twitter'} className={'company-icons'}/>
                    </a>
                  </Grid.Column>
                  : null
                }

              </Grid.Row>
            </Grid>
          </Card.Description>


        </Card.Content>

      </Card>
    )
  }
}

export default withRouter(CompanyInfo)
