import React,{Component} from 'react';
import {connect} from 'react-redux';
import {Container, Grid, Loader, Dropdown} from 'semantic-ui-react';
import {default as RatingComponent} from 'react-rating';
import _ from 'lodash';
import './Talent/Details/job-details.css';
import {rateJob, getJobRating, getEventReviews} from "../../actions/event";
import {getEventTimeSheetLineItemList} from '../../actions/timesheet';
import ServerErrors from "../Common/ServerErrors";
import {getJobDetailsData} from "../../actions/jobDetailsTalentAction";
import {getUserName} from "../../utils/common";

const stateToProps=(state)=>{
  return{
    loading:state.review.loading,
    job:state.jobsTalent.singleData,
    jobRating: state.review.jobRating,
    errorMessage:state.review.errorMessage,
    user: state.user.profile,
    timesheets: state.timesheet.eventTimeSheets,
    eventReviews: state.review.eventReviews,
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    rateJob:(data)=> dispatch(rateJob(data)),
    getJobDetailsData:(id)=> dispatch(getJobDetailsData(id)),
    getJobRating:(eventId, reviewerId)=> dispatch(getJobRating(eventId, reviewerId)),
    getTimesheets: (jobId) => dispatch(getEventTimeSheetLineItemList(jobId)),
    getEventReviews: (jobId) => dispatch(getEventReviews(jobId))
  }
};

class Rating extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isTalentAlreadyReviewed: false,
      review: {
        event: props.match.params.id,
        reviewee: null,
        review: '',
        communication: 1,
        punctual: 1,
        knowledgeable: 1,
        professional: 1,
        overall: 1,
      }
    }
  }

  componentDidMount() {
    const jobId = this.props.match.params.id;
    this.props.getJobDetailsData(jobId);
    const reviewerId = this.props.user.id;
    if (this.props.user.type !== 'talent'){
        this.props.getTimesheets(jobId);
        this.props.getEventReviews(jobId);
    }else{
        this.props.getJobRating(jobId, reviewerId);
    }
  }

  componentWillReceiveProps(nextProps, prevProps){
      const {job, jobRating, user} = nextProps;
      let {review} = this.state;
      if (!_.isEmpty(jobRating)){
         review = {...review, ...jobRating}
      }

      if (!_.isEmpty(job)){
          if (user.type === 'talent'){
              review.reviewee = job.company.user.id
          }

          this.setState({review});
      }
  }

  submitRating = () => {
    if (this.state.review.review){
      this.props.rateJob(this.state.review);
    }
  };

  changeRating = (ratingType, value) => {
    const {review} = this.state;
    review[ratingType] = value;
    this.setState({review});
  };

  changeReviewee = (revieweeId) => {
      const {eventReviews} = this.props;
      const talentAlreadyReviewed = eventReviews.find(review => review.reviewee.id === revieweeId);
      if (talentAlreadyReviewed){
          const review = {
              ...this.state.review,
              ...talentAlreadyReviewed,
              reviewee: talentAlreadyReviewed.reviewee.id,
              reviewer: talentAlreadyReviewed.reviewer.id,
          };
          this.setState({
              isTalentAlreadyReviewed: true,
              review
          });

      }else{
          const {review} = this.state;
          review.reviewee = revieweeId;
          review.review = '';
          review.professional = 1;
          review.overall = 1;
          review.knowledgeable = 1;
          review.communication = 1;
          review.punctual = 1;
          this.setState({review, isTalentAlreadyReviewed: false});
      }

  };

  renderReviewee = () => {
      const {user, job} = this.props;
      if (user.type === 'talent'){
          return job.company.name;
      }
      else{
          return "Talent"
      }
  };

  renderTalents = () => {
      const {user, timesheets} = this.props;
      const talents = timesheets.map(timesheet => {
          if (timesheet.status === 'approved'){
              return {
                  key: timesheet.id,
                  text: getUserName(timesheet.talent.user),
                  value: timesheet.talent.user.id
              }
          }
      });
      if (user.type !== 'talent'){
          return <Dropdown
              placeholder='select talent'
              fluid
              selection
              options={talents}
              onChange={(e, {value}) => this.changeReviewee(value)}
          />
      }
  };

  changeRatingMessage = (e) => {
    const {review} = this.state;
    review.review = e.target.value;
    this.setState({review});
  };

  renderSubmitButton = () => {
      const {jobRating} = this.props;
      const {isTalentAlreadyReviewed} = this.state;
      if (_.isEmpty(jobRating) && !isTalentAlreadyReviewed){
          return <button onClick={() => this.submitRating()} className="ui primary button">Submit</button>
      }

      return <strong>You've already rated this</strong>
  };

  render() {
    const {job, jobRating} = this.props;
    const isAlreadyRated = !_.isEmpty(jobRating);
    const {communication, punctual, knowledgeable, professional, overall, review} = this.state.review;

    return (
      <Container className={'main-container'}>
          <Grid stackable padded>

          {this.props.loading && <Loader active inline='centered' />}
          {this.props.errorMessage && <ServerErrors errorMessage={this.props.errorMessage}/>}
          <Grid.Row>
              <h4>{!_.isEmpty(job) && `Rate ${this.renderReviewee()} for ${job.name}`}</h4>
          </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={10} computer={10}>
              <Grid.Row>
                  {this.renderTalents()}
              </Grid.Row>

              <form className="ui form">
                  {isAlreadyRated ?
                      review :
                      <textarea required placeholder="Write a detailed review" rows="5" onChange={this.changeRatingMessage}
                                value={review}/>
                  }
              </form>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column mobile={16} tablet={6} computer={6}>
              <React.Fragment>
                  <div>Communication</div>
                  <RatingComponent
                   emptySymbol={<img src="/assets/images/star-empty.png" className="icon" />}
                   fullSymbol={<img src="/assets/images/star-full.png" className="icon" />}
                   fractions={2}
                   initialRating={communication}
                   readonly={isAlreadyRated}
                   onChange={(value) => this.changeRating('communication', value)}
                  />
              </React.Fragment>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={6} computer={6}>
              <React.Fragment>
                  <div>Punctual</div>
                  <RatingComponent
                   emptySymbol={<img src="/assets/images/star-empty.png" className="icon" />}
                   fullSymbol={<img src="/assets/images/star-full.png" className="icon" />}
                   fractions={2}
                   initialRating={punctual}
                   readonly={isAlreadyRated}
                   onChange={(value) => this.changeRating('punctual', value)}
                  />
              </React.Fragment>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={6} computer={6}>
              <React.Fragment>
                  <div>Knowledgeable</div>
                  <RatingComponent
                   emptySymbol={<img src="/assets/images/star-empty.png" className="icon" />}
                   fullSymbol={<img src="/assets/images/star-full.png" className="icon" />}
                   fractions={2}
                   initialRating={knowledgeable}
                   readonly={isAlreadyRated}
                   onChange={(value) => this.changeRating('knowledgeable', value)}
                  />
              </React.Fragment>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={6} computer={6}>
              <React.Fragment>
                  <div>Professional</div>
                  <RatingComponent
                   emptySymbol={<img src="/assets/images/star-empty.png" className="icon" />}
                   fullSymbol={<img src="/assets/images/star-full.png" className="icon" />}
                   fractions={2}
                   initialRating={professional}
                   readonly={isAlreadyRated}
                   onChange={(value) => this.changeRating('professional', value)}
                  />
              </React.Fragment>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={6} computer={6}>
              <React.Fragment>
                  <div>Overall</div>
                  <RatingComponent
                   emptySymbol={<img src="/assets/images/star-empty.png" className="icon" />}
                   fullSymbol={<img src="/assets/images/star-full.png" className="icon" />}
                   fractions={2}
                   initialRating={overall}
                   readonly={isAlreadyRated}
                   onChange={(value) => this.changeRating('overall', value)}
                  />
              </React.Fragment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
            <div>
                {this.renderSubmitButton()}
            </div>
        </Grid.Row>
      </Grid>
      </Container>
    )
  };

}

export default connect(stateToProps,dispatchToProps)(Rating)
