import React, { Component } from 'react';
import {connect} from "react-redux";
import {Segment, Button, Form, Header} from 'semantic-ui-react';
import ReactPhoneInput from 'react-phone-input';
import { parseNumber } from 'libphonenumber-js'
import '../Account/Signup/signup.css';
import {updateCompanyProfileUser} from '../../actions/company';
import ServerErrors from '../Common/ServerErrors';
import {resendVerificationEmail} from "../../actions/signupAction";
import VerifyCode from "../Account/SignupMobileVerify/VerifyCode";
import ServerSuccess from "../Common/ServerSuccess";


const stateToProps=(state)=>{
    return {
        waitingRequest: state.common.waitingRequest,
        user: state.user.profile,
        error: state.user.error,
        success: state.user.success,
        codeVerifyMessage: state.signup.codeVerifyMessage,
        codeVerifyFailedMessage: state.signup.codeVerifyFailedMessage
      }
};

const dispatchToProps=(dispatch)=>{
  return{
      updateCompanyProfileUser:(data)=>dispatch(updateCompanyProfileUser(data)),
      resendVerificationEmail:(data)=>dispatch(resendVerificationEmail(data)),
  }
};

class UserInformation extends Component {

  constructor(props){
    super(props);
    const {user} = props;
    let countryCode = 'us';
    if (user.phone){
        countryCode = parseNumber(user.phone).country.toLowerCase();
    }
    this.state = {
        formSubmit: false,
        newEmail: null,
        newPhone: null,
        user: {
            first_name: user['first_name'],
            last_name: user['last_name'],
            email: user.email,
            phone: user.phone,
            countryCode: countryCode
        }
    }
  }

  handleChange = (name, value) => {
    const {user} = this.state;
    if (name === 'email' && user.email !== value){
        this.setState({newEmail: value});
    }
    user[name] = value;
    this.setState({ user, formSubmit: false });
  };

  handlePhoneChange = (phone) => {
      const {user} = this.state;
      if (user.phone !== phone){
            this.setState({newPhone: phone});
      }
      user.phone = phone;
      this.setState({user, formSubmit: false});
  };

  updateUser = () => {
      this.setState({formSubmit: true});
      let {user} = this.state;
      // remove empty fields
      Object.keys(user).forEach(key => {
          if (!user[key]){
              delete user[key];
          }
      });
      user.id = this.props.user.id;
      this.props.updateCompanyProfileUser(user);
  };

  renderServerErrors(){
      const {error, codeVerifyFailedMessage} = this.props;
      const {newPhone} = this.state;
      if (error && error.message){
          return <ServerErrors errorMessage={error.message} />
      }
      if (error){
          return <ServerErrors errorMessage={error} />
      }
      if (newPhone && codeVerifyFailedMessage && codeVerifyFailedMessage.message){
          return <ServerErrors errorMessage={codeVerifyFailedMessage.message} />
      }
  }

  renderServerSuccess(){
      const {codeVerifyFailedMessage, codeVerifyMessage} = this.props;
      if (!codeVerifyFailedMessage && codeVerifyMessage.success){
          return <ServerSuccess successMessage={'Phone number updated'} />
      }
  }

  renderEmailVerification = () => {
      const {success, user, waitingRequest} = this.props;
      const {newEmail} = this.state;
      if (success && newEmail && !waitingRequest){
          return (
                  <Segment style={{'border': '0px', 'box-shadow': 'none'}}>
                      <Header size='tiny'>We sent a verification email to: <strong>{newEmail}</strong></Header>
                      <p>Click on the verification URL in the email to verify your email and update</p>
                      <Button
                          type={'button'}
                          size={'tiny'}
                          content='RESEND EMAIL'
                          loading={this.props.waitingRequest}
                          onClick={() => this.props.resendVerificationEmail({user: user.id, email: newEmail})}
                      />
                  </Segment>
          )
      }
  };

  renderPhoneVerification = () => {
      const {success, codeVerifyMessage, waitingRequest} = this.props;
      const {newPhone} = this.state;
      if (success && newPhone && !codeVerifyMessage.success && !waitingRequest){
          return (
                  <Segment style={{'border': '0px', 'box-shadow': 'none', 'width': '500px'}}>
                      <Header style={{'margin-bottom': '-20px'}} size='tiny'>We sent a verification code to: <strong>{newPhone}</strong></Header>
                      <VerifyCode phone={newPhone} submitButtonText={'Verify'}/>
                  </Segment>
          )
      }
  };

  renderForm() {
      const {user, formSubmit} = this.state;
      const {waitingRequest, error} = this.props;

      return (
          <div>
              {formSubmit && !waitingRequest && this.renderEmailVerification()}
              {formSubmit && !waitingRequest && this.renderServerErrors()}
              {formSubmit && !waitingRequest && this.renderServerSuccess()}
              <Form size='large' onSubmit={() => this.updateUser()}>
                  <Segment style={{'border': '0px', 'box-shadow': 'none'}}>
                      <Form.Input
                          fluid
                          icon='user'
                          iconPosition='left'
                          placeholder='First Name'
                          name='first_name'
                          value={user['first_name']}
                          fieldtype='text'
                          onChange={(e, {name, value}) => this.handleChange(name, value)}
                      />
                      <Form.Input
                          fluid
                          icon='user'
                          iconPosition='left'
                          placeholder='Last Name'
                          name='last_name'
                          value={user['last_name']}
                          fieldtype='text'
                          onChange={(e, {name, value}) => this.handleChange(name, value)}
                      />
                      <Form.Input
                          fluid
                          icon='mail'
                          iconPosition='left'
                          placeholder='Email'
                          name='email'
                          value={user.email}
                          fieldtype='email'
                          error={formSubmit && error && error.email}
                          onChange={(e, {name, value}) => this.handleChange(name, value)}
                      />
                      <ReactPhoneInput
                          name={'phone'}
                          fluid
                          icon='phone'
                          iconPosition='left'
                          placeholder='Phone'
                          value={user.phone}
                          defaultCountry={this.state.user.countryCode}
                          onChange={this.handlePhoneChange}
                      />

                      <Button color='blue' fluid size='tiny'
                              loading={this.props.waitingRequest}
                              disabled={this.props.waitingRequest}>
                          Update
                      </Button>
                  </Segment>
              </Form>
              { formSubmit && this.renderPhoneVerification()}
          </div>
      )
  }

  render() {
    return(
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <span className={'panel-header'}>
            User Information
          </span>
        </Segment>
        <Segment style={{width: '500px'}}>
          { this.renderForm() }
        </Segment>
      </Segment.Group>
    )
  }

}

export default connect(stateToProps,dispatchToProps)(UserInformation);
