import {
  GET_PROFILE_COMPANY_FULFILLED,
  GET_PROFILE_COMPANY_FAILED
} from "../constants/actionTypes";

const initialState= {
  data: {
    companyLogo: '',
    companyName: '',
    companyRating: '',
    users:[],
    statistics: [],
    phoneNumber: '',
    site_url: '',
    location: {
      street: '',
      city: ""
    },
    about: '',
    bookingInfo: []
  }
}

export default (state=initialState,action)=>{
  switch (action.type){
    case GET_PROFILE_COMPANY_FULFILLED:{
      const st={
        ...state,
        data:action.responseData
      }

      return st;
    }
    case GET_PROFILE_COMPANY_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }
    default:
      return state;
  }
}