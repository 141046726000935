import * as actionType from "../constants/actionTypes";

const initialState = {
  waitingRequest: false,
  waitingGetEventRequest: false,
  saveEventDaysWaiting: false,
  success: false,
  error: null,
  eventCreationStep: 1,
  list: [],
  event: {
    event_days: [],
    talent_roles: [],
    contacts: []
  },
  applicantList: [],
  invoice: {
    data: null,
    error: null
  },
  deleteShiftLoading: false,
  deletedShiftId: 0,
  deleteShiftError: null,
  documents: {
    list: [],
    uploadInPorgress: false,
    uploadProgressInPercentage: 0,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_EMPTY_EVENT_CREATION_PROCESS: {
      return {
        ...state,
        event: {
          event_days: [],
          talent_roles: [],
          contacts: []
        },
        success: false,
        error: null
      };
    }

    case actionType.WAITING_REQUEST: {
      return {
        ...state,
        waitingRequest: action.value
      };
    }

    case actionType.EVENT_LIST_SUCCESS: {
      return {
        ...state,
        success: true,
        error: null,
        list: action.responseData
      };
    }

    case actionType.CREATE_NEW_EVENT: {
      return {
        ...state,
        success: true,
        error: null,
        newEvent: {
          id: 0,
          name: "",
          start_date: null,
          end_date: null,
          event_type: "",
          event_type_name: "",
          details: "",
          shifts: [],
          staffs: [],
          talentRoles: []
        },
        nextEvent: {
          id: 0,
          name: "",
          start_date: null,
          end_date: null,
          event_type: "",
          event_type_name: "",
          details: "",
          shifts: [],
          staffs: [],
          talentRoles: []
        }
      };
    }

    case actionType.EVENT_LIST_ERROR: {
      return {
        ...state,
        success: false,
        error: action.errorMessage
      };
    }

    case actionType.WAITING_GET_EVENT_REQUEST: {
      return {
        ...state,
        waitingGetEventRequest: action.value
      };
    }

    case actionType.GET_TALENT_JOB_DETAILS_FULFILLED: {
      const { documents } = state;
      documents.list = action.responseData.documents;
      return {
        ...state,
        documents
      };
    }

    case actionType.GET_EVENT_SUCCESS: {
      return {
        ...state,
        event: action.responseData,
        success: true,
        error: null
      };
    }

    case actionType.GET_EVENT_ERROR: {
      return {
        ...state,
        success: false,
        error: action.errorMessage
      };
    }

    case actionType.ADD_EVENT_SUCCESS: {
      return {
        ...state,
        event: { ...state.event, ...action.responseData },
        success: true,
        error: null
      };
    }
    case actionType.ADD_EVENT_ERROR: {
      return {
        ...state,
        success: false,
        error: action.errorMessage
      };
    }

    case actionType.UPDATE_EVENT_SUCCESS: {
      return {
        ...state,
        event: { ...state.event, ...action.responseData },
        success: true,
        error: null
      };
    }

    case actionType.UPDATE_EVENT_ERROR: {
      return {
        ...state,
        success: false,
        error: action.errorMessage
      };
    }

    case actionType.EVENT_CREATION_STEP: {
      return {
        ...state,
        eventCreationStep: action.value
      };
    }

    case actionType.GET_APPLICANT_LIST_SUCCESS: {
      return {
        ...state,
        success: true,
        error: null,
        applicantList: action.responseData
      };
    }

    case actionType.GET_APPLICANT_LIST_ERROR: {
      return {
        ...state,
        success: false,
        error: action.errorMessage
      };
    }

    case actionType.UPDATE_APPLICANT_SUCCESS: {
      return {
        ...state,
        success: true,
        error: null
      };
    }

    case actionType.UPDATE_APPLICANT_ERROR: {
      return {
        ...state,
        success: false,
        error: action.errorMessage
      };
    }

    case actionType.GET_EVENT_INVOICE_SUCCESS: {
      const { invoice } = state;
      invoice.data = action.responseData;
      invoice.error = null;
      return {
        ...state,
        invoice: invoice
      };
    }

    case actionType.GET_EVENT_INVOICE_ERROR:
    case actionType.PAY_INVOICE_VIA_BANK_ERROR:
    case actionType.PAY_INVOICE_VIA_CARD_ERROR:
      const { invoice } = state;
      invoice.error = action.errorMessage;
      return {
        ...state,
        invoice: invoice
      };

    case actionType.PAY_INVOICE_VIA_BANK_SUCCESS:
    case actionType.PAY_INVOICE_VIA_CARD_SUCCESS: {
      const { invoice } = state;
      invoice.data = action.responseData;
      invoice.error = null;
      return {
        ...state,
        invoice: invoice
      };
    }

    case actionType.DELETE_SHIFT_WAITING: {
      return {
        ...state,
        deleteShiftLoading: action.value
      };
    }

    case actionType.DELETE_SHIFT_SUCCESS: {
      return {
        ...state,
        deleteShiftError: null
      };
    }

    case actionType.DELETE_SHIFT_ERROR: {
      return {
        ...state,
        deleteShiftError: action.errorMessage
      };
    }

    case actionType.DELETED_SHIFT_ID: {
      return {
        ...state,
        deletedShiftId: action.value
      };
    }

    case actionType.EVENT_DOCUMENTS_UPLOADING: {
      const { documents } = state;
      documents.uploadInPorgress = action.value;
      return {
        ...state,
        documents
      };
    }

    case actionType.EVENT_DOCUMENTS_UPLOAD_PROGRESS: {
      const { documents } = state;
      documents.uploadProgressInPercentage = action.value;
      return {
        ...state,
        documents
      };
    }

    case actionType.EVENT_DOCUMENTS_UPLOAD_SUCCESS: {
      const { documents } = state;
      documents.list = action.responseData;
      return {
        ...state,
        documents
      };
    }

    case actionType.EVENT_DOCUMENTS_UPLOAD_ERROR: {
      const { documents } = state;
      documents.error = action.value;
      return {
        ...state,
        documents
      };
    }

    default:
      return state;
  }
};
