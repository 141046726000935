import _ from 'lodash';
import { store } from "../../store";

export const isSignupCompleted = () => {
    const user = store.getState().user.profile;
    
    if (!user.phone){
        return false;
    }

    else if (user.type.toLowerCase() === 'talent'){

        const talent = store.getState().talent.profile;

        if (_.isEmpty(talent) || !talent.gender || !talent.date_of_birth){
            return false;
        }
        else if (talent['work_cities'].length === 0){
            return false;
        }
    }

    else if (user.type.toLowerCase() === 'company' || user.type.toLowerCase() === 'agency'){
        // check if each value field of company is filled out
        const company = store.getState().company.profile;
        const isCompanyEmpty = (company.name==="" || company.city==="");
        if (isCompanyEmpty){
            return false;
        }
    }

    return true;
}