import React, { Component } from 'react';
import {connect} from 'react-redux';
import {
  Container,
  Segment,
  Header,
  Message,
  Button} from 'semantic-ui-react';

import {verifyUser} from '../../../actions/signupAction';

const stateToProps=(state)=>{
  return{
    verifyMessage:state.signup.verifyMessage,
    verifyFailedMessage:state.signup.verifyFailedMessage,
    waitingRequest:state.login.waitingRequest,
    errorMessage:state.login.errorMessage,
    user:state.user.profile
  }
}

const dispatchToProps=(dispatch)=>{
  return{
    verify:(data)=>dispatch(verifyUser(data))
  }
}

class SignupVerify extends Component {
  constructor(props){
    super(props);

    this.signin=this.signin.bind(this);
  }

  componentDidMount(){
    const {uid, token, newEmail} = this.props.match.params;
    this.props.verify({uid, token, newEmail});
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.user && !nextProps.verifyFailedMessage && !nextProps.waitingRequest){
        // if(nextProps.user.type === 'talent') {
        this.props.history.push('/signup/next');
        //}
        // else if (nextProps.user.type === 'company') {
        //   this.props.history.push('/bookings');
        // }
    }
  }

  signin(){
    this.props.history.push('/login');
  }

  render() {
    var errMsg=null;

    if (this.props.verifyFailedMessage !==null && !this.props.waitingRequest){
      errMsg=(
        <Message
          error
          content={this.props.verifyFailedMessage}
        />
      )
    }

    return(
      <div>
        <Container textAlign='center' className={'main-container signup-container'}>
          <Segment raised>
            {
              this.props.verifyMessage===null?
                <Header size={'small'}>Please wait while verifying your accout ..........</Header>
                :
                <div>
                  <Header size={'small'}>Your account has been verified successfully.</Header>
                  <Button fluid
                    color={'blue'}
                    size={'large'}
                    content='Signin'
                    style={{marginBottom:'25px'}}
                    onClick={this.signin}
                  />
                </div>
            }
            {errMsg}
          </Segment>
        </Container>
      </div>
    )}
}

export default connect(stateToProps,dispatchToProps)(SignupVerify)
