import * as actionType from "../constants/actionTypes";
import {SET_ACTIVE_JOB} from "../constants/actionTypes";

const initialState={
  waitingRequest:false,
  success: false,
  error: null,
  profile: {},
  talentRoles: [],
  addSkillWaiting:false,
  skills: [],
  addCertificationWaiting:false,
  certifications: [],
  talentRoleUpdated:false,
  jobexperience: [],
  galleryphoto:[],
  activeJob: {}
};

export default (state=initialState,action) =>{
  switch (action.type){
      case actionType.TALENT_UPDATE_SUCESS:{
          return {
            ...state,
            success: true,
            error: null,
            profile: {...state.profile, ...action.responseData}
          };
    }
      case actionType.TALENT_UPDATE_ERROR:{
          return {
            ...state,
            success: false,
            error: action.errorMessage
          };
    }

    case actionType.GET_TALENT_PROFILE_SUCESS:{
          return {
            ...state,
            success: true,
            error: null,
            profile: action.responseData,
          };
    }
      case actionType.GET_TALENT_PROFILE_FAILED:{
          return {
            ...state,
          };
    }

      case actionType.ADD_WORK_CITY_SUCCESS:{
          const {profile} = state;
          const {work_cities}  = profile;
          work_cities.push(action.responseData);
          profile.work_cities = work_cities;
          return {
              ...state,
              success: true,
              error: null,
              profile
          };
      }
      case actionType.ADD_WORK_CITY_ERROR: {
          return {
              ...state,
              success: false,
              error: action.errorMessage
          };
      }
      case actionType.REMOVE_WORK_CITY_SUCCESS:{
          let profile = state.profile;
          const {work_cities} = profile;
          profile.work_cities = work_cities.filter((city) => city.id !== action.responseData);
          return {
              ...state,
              success: true,
              error: null,
              profile
          };
      }
      case actionType.PRIMARY_WORK_CITY_SUCCESS:{
          let profile = state.profile;
          let {work_cities} = profile;
          work_cities = work_cities.map(city => {
              city['is_primary'] = city.id === action.responseData.id;
              return city;
          });

          profile.work_cities = work_cities;
          return {
              ...state,
              success: true,
              error: null,
              profile
          };
      }
      case actionType.REMOVE_WORK_CITY_ERROR:{
          return {
              ...state,
              success: false,
              error: action.errorMessage
          };
      }

      case actionType.GET_TALENT_ROLES_SUCCESS:{
          return {
              ...state,
              success: true,
              error: false,
              talentRoles: action.responseData
          };
      }

      case actionType.ADD_TALENT_ROLE_SUCCESS:{
          const talentRoles = state.talentRoles.slice();
          talentRoles.push(action.responseData);
          return {
              ...state,
              success: true,
              error: false,
              talentRoles: talentRoles
          };
      }

      case actionType.ADD_TALENT_ROLE_ERROR:{
          return {
              ...state,
              success: false,
              error: action.errorMessage
          };
      }
      
      case actionType.UPDATE_TALENT_ROLE_SUCCESS:{
          let talentRoles = state.talentRoles.slice();
          // remove and add back updated role
          talentRoles = talentRoles.filter((role) => role.id !== action.responseData.id);
          talentRoles.push(action.responseData);
          return {
              ...state,
              success: true,
              error: false,
              talentRoles: talentRoles,
              talentRoleUpdated:true
          };
      }

      case actionType.UPDATE_TALENT_ROLE_ERROR:{
          return {
              ...state,
              success: false,
              error: action.errorMessage
          };
      }

      case actionType.PRIMARY_WORK_CITY_ERROR:{
          return {
              ...state,
              success: false,
              error: action.errorMessage
          };
      }

    case actionType.SET_TALENT_ROLE_UPDATED_FALSE:{
      return {
        ...state,
        talentRoleUpdated: false
      };
    }

      case actionType.ADD_SKILL_WAITING:{
        return {
          ...state,
          addSkillWaiting:action.value
        };
      }

      case actionType.ADD_SKILL_SUCCESS:{
          const skills = state.skills.slice();
          skills.push(action.responseData);
          return {
              ...state,
              success: true,
              error: false,
              skills: skills
          };
      }

      case actionType.ADD_SKILL_ERROR:{
          return {
              ...state,
              error: action.errorMessage,
          };
      }

      case actionType.GET_SKILLS_SUCCESS:{
          return {
              ...state,
              success: true,
              error: null,
              skills: action.responseData
          };
      }

      case actionType.GET_SKILLS_ERROR:{
          return {
              ...state,
              error: action.errorMessage,
          };
      }

      case actionType.ADD_CERTIFICATION_WAITING:{
        return {
          ...state,
          addCertificationWaiting:action.value
        };
      }

      case actionType.ADD_CERTIFICATION_SUCCESS:{
          let certifications = state.certifications.slice();
          certifications.push(action.responseData);
          return {
              ...state,
              success: true,
              error: false,
              certifications: certifications
          };
      }

      case actionType.ADD_CERTIFICATION_ERROR:{
          return {
              ...state,
              error: action.errorMessage,
          };
      }

      case actionType.GET_CERTIFICATIONS_SUCCESS:{
          return {
              ...state,
              success: true,
              error: null,
              certifications: action.responseData
          };
      }

      case actionType.GET_JOB_EXPERIENCE_SUCCESS:{
        return {
            ...state,
            success: true,
            error: null,
            jobexperience: action.responseData
        };
      }

      case actionType.DELETE_JOB_EXPERIENCE_SUCCESS:{
          let jobexperience = state.jobexperience
          jobexperience = jobexperience.filter((jobexperience) => jobexperience.id !== action.responseData);
          return {
              ...state,
              success: true,
              error: null,
              jobexperience
          };
      }

      case actionType.DELETE_JOB_EXPERIENCE_ERROR:{
        return {
            ...state,
            success: false,
            error: action.errorMessage
        };
      }

      case actionType.GET_CERTIFICATIONS_ERROR:{
          return {
              ...state,
              error: action.errorMessage,
          };
      }

        /**
       * PORTFOLIO ACTION  BEGIN
       */
      case actionType.GET_PORTFOLIO_SUCCESS: {
          return {
            ...state,
            success: true,
            error: null,
            portfolios: action.responseData
          };
      }

      case actionType.GET_PORTFOLIO_ERROR: {
          return {
              ...state,
              // success: false,
              // error: action.errorMessage
          };
      }

      case actionType.UPDATE_PORTFOLIO_WAITING:{
        return {
            ...state,
            updatePortfolioWaiting: action.value
        }
      }

      case actionType.UPDATE_PORTFOLIO_SUCCESS:{
        return {
            ...state,
            success: true,
            error: null,
            portfolios: action.responseData
        };
      }

      case actionType.UPDATE_PORTFOLIO_ERROR:{
          return {
              ...state,
              success: false,
              error: action.errorMessage
          }
      }

      case actionType.CREATE_PORTFOLIO_WAITING:{
        return {
            ...state,
            createPortfolioWaiting: action.value
        }
      }

      case actionType.CREATE_PORTFOLIO_SUCCESS:{
        return {
            ...state,
            success: true,
            error: null,
            portfolios: action.responseData
        };
      }

      case actionType.CREATE_PORTFOLIO_ERROR:{
          return {
              ...state,
              success: false,
              error: action.errorMessage
          }
      }

      case actionType.DELETE_PORTFOLIO_SUCCESS:{
          let portfolios = state.portfolios;
          portfolios = portfolios.filter((p) => p.id !== action.responseData);
          return {
              ...state,
              success: true,
              error: null,
              portfolios
          };
      }

      case actionType.DELETE_PORTFOLIO_ERROR:{
        return {
            ...state,
            // success: false,
            // error: action.errorMessage
        };
      }

      /**
     * PORTFOLIO ACTION  END
     */

      case actionType.ADD_JOBEXPERIENCE_SUCCESS:{
         return {
            ...state,
            success: true,
            error: null,
            jobexperience: action.responseData
          };
      }

      case actionType.ADD_JOBEXPERIENCE_WAITING:{
        return {
            ...state,
            addJobExperienceWaiting: action.value
        }
      }

      case actionType.ADD_JOBEXPERIENCE_ERROR:{
          return {
              ...state,
              success: false,
              error: action.errorMessage
          }
      }

      case actionType.UPDATE_JOBEXPERIENCE_WAITING:{
        return {
            ...state,
            updateJobExperienceWaiting:action.value
        }
      }

      case actionType.UPDATE_JOBEXPERIENCE_SUCCESS:{
        return {
            ...state,
            success: true,
            error: null,
            //jobexperience: action.responseData
        };
      }

      case actionType.UPDATE_JOBEXPERIENCE_ERROR:{
          return {
              ...state,
              success: false,
              error: action.errorMessage
          }
      }

      case actionType.ADD_GALLERYPHOTO_SUCCESS: {
          const galleryphoto = state.galleryphoto.slice();
          galleryphoto.push(action.responseData);
          return {
            ...state,
            success: true,
            error: null,
            galleryphoto: galleryphoto
          };
      }

      case actionType.ADD_GALLERYPHOTO_ERROR: {
          return {
              ...state,
              // success: false,
              // error: action.errorMessage
          };
      }

      case actionType.GET_GALLERYPHOTO_SUCCESS: {
          return {
            ...state,
            success: true,
            error: null,
            galleryphoto: action.responseData
          };
      }

      case actionType.GET_GALLERYPHOTO_ERROR: {
          return {
              ...state,
              // success: false,
              // error: action.errorMessage
          };
      }

      case actionType.DELETE_PHOTOGALLERY_IMAGE_SUCCESS:{
          let galleryphoto = state.galleryphoto
          galleryphoto = galleryphoto.filter((jobexperience) => jobexperience.id !== action.responseData);
          return {
              ...state,
              success: true,
              error: null,
              galleryphoto
          };
      }

      case actionType.DELETE_POTOGALLERY_IMAGE_ERROR:{
        return {
            ...state,
            // success: false,
            // error: action.errorMessage
        };
      }

      case actionType.RESET_TALENT:{
          return {
              ...state,
              ...action.payload
          }
      }

      case SET_ACTIVE_JOB:{
      console.log('here', action.payload.job)
      return {
          ...state,
          activeJob: action.payload.job
      };
    }

      default:
        return state;
  }
}