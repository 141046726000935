export const updateMessageNotifications=(sb,loggedInUser,setMessageNotification)=>{
  sb.connect(loggedInUser.email, (user, error) => {
    sb.getTotalUnreadMessageCount((count, error)=> {
      if (error) {
        console.log(`getTotalUnreadChannelCount error: ${error}`);
        return;
      }

      setMessageNotification(count);
    });
  });
}
