import {
  GET_PROFILE_COMPANY_USER_FULFILLED,
  GET_PROFILE_COMPANY_USER_FAILED,
  GET_PROFILE_COMPANY_USER_FULFILLED_DATA,
  GET_PROFILE_COMPANY_USER_FAILED_DATA
} from "../constants/actionTypes";

const initialState= {
  data: {
    companyLogo: '',
    clientPhoto: '',
    clientName: '',
    companyName: '',
    companyRating: '',
    statistics: [],
    phoneNumber: '',
    site_url: '',
    location: {
      street: '',
      city: ""
    },
    about: '',
    bookingInfo: []
  },

  profileCompanyUserData:{
    name: '',
    site_url: '',
    about: '',
    street: '',
    city: '',
    state: '',
    country: '',
    user: {
      phone: '',
      first_name: '',
      photo: ''
    }
  }
}

export default (state=initialState,action)=>{
  switch (action.type){
    case GET_PROFILE_COMPANY_USER_FULFILLED:{
      const st={
        ...state,
        data:action.responseData
      }

      return st;
    }
    case GET_PROFILE_COMPANY_USER_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }

    case GET_PROFILE_COMPANY_USER_FULFILLED_DATA:{
      const st={
        ...state,
        profileCompanyUserData:action.responseData
      }

      return st;
    }
    case GET_PROFILE_COMPANY_USER_FAILED_DATA:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }
    default:
      return state;
  }
}