import React from 'react';
import * as Sentry from '@sentry/browser';
import {Button} from 'semantic-ui-react'
import * as settings from "../../constants/constants";

if (settings.ENV !== 'local'){
    Sentry.init({
      dsn: settings.SENTRY_DSN,
      integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })]
    });
}

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any child components and re-render with error message
        this.setState({error});
        Sentry.configureScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
      });

      if (settings.ENV !== 'local'){
        Sentry.captureException(error);
      }

    }

    render() {
        if (this.state.error && settings.ENV !== 'local') {
            return (
                <div style={{'textAlign': 'center', 'margin': '10px auto'}}>
                    <p>There was an issue.</p>
                    <p>Our team has been notified, but you could fill out a report.</p>
                    <Button className={'button danger tiny'} onClick={() => Sentry.showReportDialog()}>
                        Report feedback
                    </Button>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}
