import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import moment from "moment/moment";
import _ from 'lodash';
import {Card, Segment, Button} from 'semantic-ui-react';
import {DND_ASSIGN_SHIFTS} from '../../../constants/actionTypes';
import {getUserName} from "../../../utils/common";
import './style.css';

const target = {
  drop(props) {
    const {role, shift} = props;
    return ({ role, shift, assignType: 'by_shift' });
  },

  canDrop(props){
    const {bookedShifts, shift, role} = props;
    const shiftBookings = bookedShifts.filter(bookedShift => bookedShift.shift.id === shift.id);
    if (shiftBookings.length > 0){
      const staff = role.staff[0];
      return staff.count > shiftBookings.length
    }

    return true

  }
};

const collect = (connect,  monitor) => ({
  connectDropTarget: connect.dropTarget(),
  titem:monitor.getItem(),
  isOver:monitor.isOver()
});

class ShiftCard extends Component {

  constructor(props){
    super(props);
    this.state={
      isCardEntered:false,
      color:null,
    }
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.isOver !== this.props.isOver){
      this.setState({isCardEntered: nextProps.isOver});
        this.setState({color:'#774b86'});
    }
  }

  renderShiftSlots = () => {
    const { role, shift, bookedShifts } = this.props;
    let shiftBookings = bookedShifts.filter(bookedShift => bookedShift.shift.id === shift.id);
    const staff = role.staff[0];
    const slots = [];
    for(let i=0; i<staff.count; i++){
        if (shiftBookings.length > 0){
            const bookedApplicant = shiftBookings[0].applicant;
            shiftBookings = shiftBookings.slice(1, shiftBookings.length);
            slots.push(
                <Segment className='staffs' inverted color={'blue'} key={i}>
                    <span>{getUserName(bookedApplicant.talent.user)}</span>
                    <Button
                        size={'mini'}
                        floated={'right'}
                        icon={'trash'}
                        onClick={() => this.props.removeAssignedByShiftTalent(shift.id, bookedApplicant, role.id)}
                    />
                </Segment>
            )
        }
        else {
            slots.push(
                <Segment className='staffs' key={i}></Segment>
            )
        }
    }

    return slots;
  };

  renderShiftBox = (shift, disabled=false) => {
      return (
          <div className={`shift-card-container ${disabled && 'shift-card-disabled'}`} >
           <Card raised className={'shift-card'} style={{backgroundColor: this.state.isCardEntered ? this.state.color:'transparent'}}>
             <Card.Content style={{marginBottom: 20}}>
               <Card.Meta>
                {moment.utc(`${shift.start_time}`, 'HH:mm:ss').local().format('hh:mm A')}&nbsp;-&nbsp;
                {moment.utc(`${shift.end_time}`, 'HH:mm:ss').local().format('hh:mm A')}
              </Card.Meta>
                {this.renderShiftSlots()}
             </Card.Content>
           </Card>
          </div>
      )
  }

  render(){
    const {shift, role, bookedShifts, draggedApplicant, connectDropTarget} = this.props;
    if (draggedApplicant){
        const isShiftBookedByDraggedApplicant = bookedShifts.find(bookedShift => bookedShift.shift.id === shift.id && bookedShift.applicant.talent.id === draggedApplicant.talent.id);
        const isShiftAppliedByDraggedApplicant = draggedApplicant.shifts.find(applicantShift => applicantShift.id === shift.id);
        const shiftBookingCount = _.countBy(bookedShifts, booking => booking.shift.id === shift.id)['true'] || 0;
        const requiredStaff = role.staff[0].count;

        if (isShiftAppliedByDraggedApplicant && !isShiftBookedByDraggedApplicant && requiredStaff > shiftBookingCount){
            return connectDropTarget(this.renderShiftBox(shift))
        }else{
            return this.renderShiftBox(shift, true)
        }
    }

    return connectDropTarget(this.renderShiftBox(shift))

  }

}

export default DropTarget(DND_ASSIGN_SHIFTS, target, collect)(ShiftCard);
