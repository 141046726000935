import React, { Component } from 'react';
import {
  Segment,
  Header,
  Container,
  List,
  Icon,
  Checkbox,
  Button,
  Message,
  } from 'semantic-ui-react';
  import moment from "moment";

class ShiftDay extends Component {
  constructor(props){
    super(props);
    this.state={
      showErrorMessage:false
    }
  }

  handleDismiss(event){
    this.setState({showErrorMessage:false});
  }

  goNext = (event) => {
    event.preventDefault();
    if (this.props.selectedDays.length === 0){
      this.setState({showErrorMessage: true});
      return;
    }

    this.props.selectStep('shift_selection');
  };

  isDayChecked = (day) => {
     day = this.props.selectedDays.find(selectedDay => selectedDay.id === day.id );
     return !!day;
  };

  render() {
    let availableDays = this.props.availableDays
      .sort((a,b)=>{
        if (moment(a.date,'YYYY-MM-DD')>moment(b.date,'YYYY-MM-DD')){
          return 1;
        }
        else if (moment(a.date,'YYYY-MM-DD')>moment(b.date,'YYYY-MM-DD')){
          return -1;
        }

        return 0;
      })
      .map((day, index)=> {
        return (
          <List.Item key={index}>
            <List.Content floated='right'>

              <Checkbox
                toggle
                checked={this.isDayChecked(day)}
                onChange={() => this.props.selectDay(day)}
              />

            </List.Content>
            <List.Content>
              <Icon name={'calendar'} style={{'color': '#774b86'}}/>
              {moment.utc(day.date).format("MMM D, YYYY")}
            </List.Content>
          </List.Item>
        )
      });

    return(
      <Segment attached raised>
        <Container textAlign='center'>
          <Header as={'h4'}>What days are you available to work this job?</Header>
          {this.state.showErrorMessage &&
          <Message
            negative
            onDismiss={(e)=> this.handleDismiss(e)}
            content='Select a day'
          />
          }
          <div className={'shift-time-div'}>
            <Segment.Group>
              <Segment textAlign={'left'}>
                  {this.props.companyName}
              </Segment>
              <Segment secondary textAlign={'left'}>
                <List divided verticalAlign='middle'>
                  {availableDays}
                </List>
              </Segment>
            </Segment.Group>
          </div>
          <Button fluid
            size={'large'}
            content='NEXT'
            color={'blue'}
            onClick={(e)=>this.goNext(e)}
          />
          <br/>
        </Container>
      </Segment>
    )
  }
}

export default ShiftDay
