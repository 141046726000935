import React, { Component } from "react";
import {
  Grid,
  Button,
  Container,
  Header,
  Icon,
  Image
} from "semantic-ui-react";
import { API_URL_BASE } from "../../constants/constants";

import "../Common/css/home.css";
import "../Common/css/google-api-css.css";
import "../Common/css/style.css";
import "../Common/css/icon-font.css";
import "../Common/js/script.js";
import "./home.css";
import { parse } from "qs";
import connect from "react-redux/es/connect/connect";
import axios from "axios";
import { loginSuccess } from "../../actions/loginAction";

class Home extends Component {
  componentWillMount() {
    // this is temp code to auto login a demo talent user when someone visit this
    //  url https://uat.shiftdash.com/?demo=1
    const queryString = parse(this.props.router.location.search.substr(1));
    const { demo } = queryString;
    if (demo && demo == 1) {
      const data = { email: "demo@shiftdash.com", password: "demouser1" };
      axios
        .post(`${API_URL_BASE}/user/login`, data)
        .then(response => {
          this.props.loginSuccess(response);
          this.props.history.push("/jobs");
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  render() {
    return (
      <div className={"home-container"}>
        <div className="bg-shape" />
        <div className="bg-circle" />
        <Container className={"intro-container"}>
          <Grid verticalAlign="middle" stackable padded>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={6} computer={6}>
                <Container textAlign="center">
                  <Header as={"h2"} className={"home-header"}>
                    Event Empowerment!
                  </Header>
                  <p className={"intro-text"}>
                    Shift Dash is the only app you need for staffing your event.
                  </p>
                  <br />
                  <Button href="/register" className={"btn-get-started"}>
                    Post a job free
                  </Button>
                </Container>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={10} computer={10}>
                <div
                  className="youtube cast-shadow"
                  data-video-id="O5CT_t7lOXQ"
                  data-params="modestbranding=1&amp;showinfo=0&amp;controls=1&amp;vq=hd720"
                >
                  <Image
                    src={"https://i.ytimg.com/vi/O5CT_t7lOXQ/hqdefault.jpg"}
                    alt="Sign up today"
                    fluid
                  />
                  <div className="play">
                    <span className="pe-7s-play pe-3x" />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        {/* <Container className={'brand-container'}>
          <Grid padded>
            <Grid.Row>
              <Grid.Column textAlign={'center'}>
                <Header as={'h5'} style={{color:'#868e96'}}>TRUSTED BY MOST POPULAR BRANDS</Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid centered doubling columns={7}>
            <Grid.Column>
              <img src={'assets/images/home/client_logo_1.png'} alt="not found"/>
            </Grid.Column>
            <Grid.Column>
              <img src={'assets/images/home/client_logo_2.png'} alt="not found"/>
            </Grid.Column>
            <Grid.Column>
              <img src={'assets/images/home/client_logo_3.png'} alt="not found"/>
            </Grid.Column>
            <Grid.Column>
              <img src={'assets/images/home/client_logo_4.png'} alt="not found"/>
            </Grid.Column>
            <Grid.Column>
              <img src={'assets/images/home/client_logo_5.png'} alt="not found"/>
            </Grid.Column>
            <Grid.Column>
              <img src={'assets/images/home/client_logo_6.png'} alt="not found"/>
            </Grid.Column>
            <Grid.Column>
              <img src={'assets/images/home/client_logo_7.png'} alt="not found"/>
            </Grid.Column>
            <Grid.Column>
              <img src={'assets/images/home/client_logo_8.png'} alt="not found"/>
            </Grid.Column>
            <Grid.Column>
              <img src={'assets/images/home/client_logo_9.png'} alt="not found"/>
            </Grid.Column>
            <Grid.Column>
              <img src={'assets/images/home/client_logo_10.png'} alt="not found"/>
            </Grid.Column>
          </Grid>
        </Container> */}

        <Container fluid className={"feature-container"}>
          <Container>
            <Grid centered doubling columns={3} stackable padded>
              <Grid.Column className={"feature-column"}>
                <div className="media-icon">
                  <i
                    aria-hidden="true"
                    className={"calendar check outline big icon"}
                  />
                </div>
                <div className="media-body">
                  <Header as={"h3"}>Event Scheduling</Header>
                  Create and view the entire schedule of all your events.
                </div>
              </Grid.Column>
              <Grid.Column className={"feature-column"}>
                <div className="media-icon">
                  {" "}
                  <Icon size={"big"} name="bullhorn" />{" "}
                </div>
                <div className="media-body">
                  <Header as={"h3"} className="mt-0">
                    Alerts and Notifications
                  </Header>{" "}
                  Send automated alerts to talent about event reminders and
                  notifications.
                </div>
              </Grid.Column>
              <Grid.Column className={"feature-column"}>
                <div className="media-icon">
                  <i
                    aria-hidden="true"
                    className="handshake outline big icon"
                  />
                  <Icon />
                </div>
                <div className="media-body">
                  <Header as={"h3"} className="mt-0">
                    Instant Payments
                  </Header>{" "}
                  Integrated payment system. No more checks or long payment
                  cycles.
                </div>
              </Grid.Column>
              <Grid.Column className={"feature-column"}>
                <div className="media-icon">
                  <i
                    aria-hidden="true"
                    className={"comment alternate outline big icon"}
                  />
                </div>
                <div className="media-body">
                  <Header as={"h3"} className="mt-0">
                    In-App Messaging
                  </Header>{" "}
                  Send and receive messages to a group or directly within the
                  app.
                </div>
              </Grid.Column>
              <Grid.Column className={"feature-column"}>
                <div className="media-icon">
                  <i
                    aria-hidden="true"
                    className={"dollar sign alternate big icon"}
                  />
                </div>
                <div className="media-body">
                  <Header as={"h3"} className="mt-0">
                    No Hidden Costs
                  </Header>{" "}
                  Free to post jobs. No subscription or monthly fees.
                </div>
              </Grid.Column>
              <Grid.Column className={"feature-column"}>
                <div className="media-icon">
                  {" "}
                  <Icon size={"big"} name="tasks" />{" "}
                </div>
                <div className="media-body">
                  <Header as={"h3"} className="mt-0">
                    Time Tracking and Approvals
                  </Header>{" "}
                  Easily approve time sheets and reports after an event.
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </Container>

        <Container className={"mobile-container"}>
          <Grid verticalAlign={"middle"} stackable padded>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Container textAlign="center">
                  <img
                    src={"assets/images/iPhone6plus.png"}
                    width="65%"
                    alt="Download on the app store"
                  />
                </Container>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Container textAlign="center">
                  <div className="media">
                    <div className="media-body">
                      <div className="media-icon">
                        {" "}
                        <i class="fas fa-mobile-alt fa-3x" />{" "}
                      </div>
                      <Header as={"h2"}>Mobile Ready</Header>
                      <br />
                      <p align="left">
                        {" "}
                        Reliable on-the-go time tracking and approvals. Know
                        exactly from where and when your representatives leave,
                        arrive, and clock out from the event. No hassling with
                        unreliable paper timesheets.{" "}
                      </p>
                      <br />
                      <div>
                        <img
                          src={"assets/images/appstore.png"}
                          className="mobile-app-download"
                          alt="Download on the app store"
                        />
                        <img
                          src={"assets/images/googleplay.png"}
                          className="mobile-app-download"
                          alt="Download on the play store"
                        />
                      </div>
                    </div>
                  </div>
                </Container>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Container className={"chart-container"}>
          <Grid verticalAlign={"middle"} stackable padded>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Container textAlign="center">
                  <div className="media">
                    <div className="media-body">
                      <div className="media-icon">
                        {" "}
                        <i class="fas fa-credit-card fa-3x" />{" "}
                      </div>
                      <Header as={"h2"}>Integrated Payments</Header>
                      <br />
                      <p align="left">
                        Payments are securely and quickly deposited directly
                        into bank accounts. Say goodbye to tedious snail mail
                        checks and long payment cycles.
                      </p>
                    </div>
                  </div>
                </Container>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Container textAlign="center">
                  <img
                    src={"assets/images/payments.jpg"}
                    alt="Integrated Payments"
                    width="100%"
                  />
                </Container>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        {/* <Container className={'mobile-container'}>
          <Grid verticalAlign={'middle'} stackable padded>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={6} computer={}>
                <div className="browser-window limit-height">
                  <div className="top-bar">
                    <div className="circles">
                      <div className="circle circle-red"/>
                      <div className="circle circle-yellow"/>
                      <div className="circle circle-blue"/>
                    </div>
                  </div>
                  <div className="content">
                    <img src={'assets/images/home/dashboard.png'} alt="not found"/>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={6} computer={6}>
                <div className="media">
                  <div className="media-body">
                    <div className = "media-icon"> <i class="fas fa-credit-card fa-3x"></i></div >
                    <Header as={'h2'}>Integrated Payments</Header>
                    <p> Payments are securely and quickly deposited directly into bank accounts.Say goodbye to tedious snail mail checks and long payment cycles. </p>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Container fluid className={'feature2-container'}>
          <Container>
            <Grid centered columns={3} doubling stackable padded>
              <Grid.Column className={'feature-column'}>
                <div className="media-icon">
                  <i className="pe-7s-medal pe-3x"></i>
                </div>
                <div className="media-body">
                  <Header as={'h3'}>Award Winning Design</Header>
                  Monotonectally envisioneer e-business niche markets vis-a-vis cost effective information. objectively promote worldwide.
                </div>
              </Grid.Column>
              <Grid.Column className={'feature-column'}>
                <div className="media-icon">
                  <i className="pe-7s-diamond pe-3x"></i>
                </div>
                <div className="media-body">
                  <Header as={'h3'} className="mt-0">Carefully Crafted</Header> Authoritatively streamline strategic markets without user-centric potentialities. Credibly integrate progressive technologies
                </div>
              </Grid.Column>
              <Grid.Column className={'feature-column'}>
                <div className="media-icon">
                  <i className="pe-7s-cloud-upload pe-3x"></i>
                </div>
                <div className="media-body">
                  <Header as={'h3'} className="mt-0">Cloud Sync</Header> Objectively underwhelm e-business leadership skills after cross-unit best practices. Continually innovate robust action items
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </Container>

        <Container className={'twitter-container'}>
          <Grid textAlign={'center'}padded>
            <Grid.Row>
              <Grid.Column width={'16'}>
                <Header as={'h2'}>What our users say</Header>
                <p>They love it. Read what our customers had to say!</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid columns={3} stackable style={{marginTop:'0'}} padded>
            <Grid.Column>
              <div className="embed-tweet-item">
                <blockquote className="twitter-tweet" data-cards="hidden" lang="en" data-width="550" data-link-color="#7642FF" data-align="center">
                  <a href="https://twitter.com/kmin/status/943914224329347072">{null}</a>
                </blockquote>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="embed-tweet-item">
                <blockquote className="twitter-tweet" data-cards="hidden" lang="en" data-width="550" data-link-color="#7642FF" data-align="center">
                  <a href="https://twitter.com/halarewich/status/954224121784688640">{null}</a>
                </blockquote>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="embed-tweet-item">
                <blockquote className="twitter-tweet" data-cards="hidden" lang="en" data-width="550" data-link-color="#7642FF" data-align="center">
                  <a href="https://twitter.com/scottbelsky/status/921417663859052544">{null}</a>
                </blockquote>
              </div>
            </Grid.Column>
          </Grid>
        </Container>
        */}
        <Container className={"end-button-container"}>
          <Grid textAlign={"center"}>
            <Grid.Row>
              <Grid.Column width={"16"}>
                <Button href="/register" className={"btn-get-started"}>
                  Get started
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

const stateToProps = state => {
  return {
    router: state.router
  };
};

const dispatchToProps = dispatch => {
  return {
    loginSuccess: response => loginSuccess(response, dispatch)
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(Home);
