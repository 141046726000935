import React, { Component } from 'react';
import {Label, Container, Button, Grid, Table, Header, Icon, Accordion, Segment} from 'semantic-ui-react';
// import TemplateRoleList from "./TemplateRoleList";
import RoleModal from "./RoleModal";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class Role extends Component {
  constructor(props) {
    super(props);
    this.state={
      totalStaffCount: 1,
      showTemplateRoleList:false,
      selectedRole:null,
      showNewRoleModal:false,
      isNewRole:false
    }
  }

  handleRoleDel = (role) => {
    this.props.deleteRoles(role);
  };

  handleRoleEdit = (role) => {
    this.setState({isNewRole:false, selectedRole:role,showNewRoleModal:true});
  };

  generateNewRole = () => {
    let id = (+ new Date() + Math.floor(Math.random() * 999999)).toString(36);
    return {
      newRole:true,
      id: id,
      count:'',
      name: '',
      attir: '',
      duties: '',
      gender:'any',
      height_min:'',
      height_max:'',
      skills:[],
      certifications:[],
      shifts:[]
    }
  };

  addEditRole=(isNewRole, role, eventTimezone)=>{

    let roles=this.props.roles.slice();

    if (isNewRole){
      roles.push(role);
    }
    else{
      roles=roles.map((item)=>{
        if (item.id.toString()===role.id.toString()){
          return role;
        }

        return item;
      });
    }

    this.props.addRoles(roles, eventTimezone);
    this.setState({showNewRoleModal:false});
  }

  handleRoleChange=(evt,{id,name,value})=> {
    let totalStaffCount=0;
    let item = {
      id: id,
      name: name,
      value: value
    };

    let roles = this.props.roles.slice();
    let newRoles = roles.map((role)=> {
      for (let key in role) {
        if (key === item.name && role.id.toString() === item.id.toString()) {
          role[key] = item.value;
        }

        if (key==='count'){
          let staff_count=0;
          if (role.count){
            staff_count=parseInt(role.count,10);
          }

          totalStaffCount=(totalStaffCount) + (staff_count);
        }
      }
      return role;
    });

    this.props.addRoles(newRoles);
    this.setState({totalStaffCount:totalStaffCount});
  };

  toggleTemplateRoleList=()=>{
    this.setState({showTemplateRoleList:!this.state.showTemplateRoleList})
  }

  openRoleModal=()=>{
    let newRole=this.generateNewRole();
    this.setState({isNewRole:true, showNewRoleModal:true, selectedRole:newRole});
  }

  closeRoleModal=()=> {
    this.setState({showNewRoleModal: false});
  }

  rolePayLabel = (role) => {
    const pay_type = role.pay_type == 'hourly' ? 'hourly' : 'fixed';
    const pay_amount = role.pay_type == 'hourly' ? role.hourly_rate : role.fixed_rate;
    return (
      <Label as='a' color={'blue'} style={{marginLeft: '10px'}}>{pay_type}
        <Label.Detail>{pay_amount}</Label.Detail>
      </Label>
    )
  }
  render() {
    const total = this.props.roles.reduce((prev,next) =>  parseInt(prev) + parseInt(next.count),0);

    let roles = this.props.roles.map((role)=> {
      return (
        <Segment key={role.id} padded>
          <Label attached='top'>
            <Grid>
              <Grid.Column width={8}>
                <Label circular color={'orange'} style={{marginRight: '10px'}}>{role.count}</Label>
                {role.name}
                {this.rolePayLabel(role)}
              </Grid.Column>
              <Grid.Column width={8} textAlign={'right'}>
                <Button basic size={'tiny'} onClick={()=> this.handleRoleEdit(role)}>
                  <Icon name={'edit'} />
                </Button>
                <Button basic size={'tiny'} onClick={()=> this.handleRoleDel(role)} style={{color:'red'}}>
                  <Icon name={'trash'}/>
                </Button>
              </Grid.Column>
            </Grid>
          </Label>
          <Container>

            { ReactHtmlParser(role.duties) }
            <strong>Gender:</strong> {role.gender.initCap()}
            <br/>
            {role.height_min !== null && role.height_min !== "" ? <strong>Min. Height:</strong> : null}
            {role.height_min !== null && role.height_min !== "" ? role.height_min : null}
            {role.height_min !== null && role.height_min !== "" ? <br/> : null}

            {role.height_max !== null && role.height_max !== "" ? <strong>Max. Height:</strong> : null}
            {role.height_max !== null && role.height_max !== "" ? role.height_max : null}
            {role.height_max !== null && role.height_max !== "" ? <br/> : null}

            {role.skills && role.skills.length !==0 ? <strong>Skills:</strong>  : null}
            {role.skills && role.skills.length !==0 ? role.skills.map((item)=> item.name).join(',') : null}
            {role.skills && role.skills.length !==0 ? <br/> : null}

            {role.certifications && role.certifications.length !==0 ? <strong>Certifications:</strong>  : null}
            {role.certifications && role.certifications.length !==0 ? role.certifications.map((item)=> item.name).join(',') : null}
            {role.certifications && role.certifications.length !==0 ? <br/> : null}

          </Container>
        </Segment>


      )
    });

    return (
      <React.Fragment>
        <Grid style={{marginBottom:'0px'}}>
          <Grid.Row className={'step1-primary-info-row'}>
            <Grid.Column width={16}>
              <Accordion styled fluid>
                <Accordion.Title className={'bold-title'} active={this.state.evtLocAccordionActiveIndex === 0} index={0} onClick={this.toggleEvtLocAccordion}>
                  <Icon name={'user'} />
                  ROLES
                  {/*<Button floated='right' color={'green'} size={'small'} onClick={this.toggleTemplateRoleList}>Template Roles</Button>*/}
                  <Button circular icon='user plus' floated='right' color={'green'} size={'small'} onClick={()=>this.openRoleModal()} />
                </Accordion.Title>
                <Accordion.Content active={true}>
                {roles.length===0 &&
                  <Segment placeholder>
                    <Header icon>
                      <Icon name='users' />
                      Get started adding staff for each role
                    </Header>
                    <Button floated='left' color={'green'} size={'small'} onClick={()=>this.openRoleModal()}>Add Your First Role</Button>
                  </Segment>
                }
                {roles.length>0 && roles}
                </Accordion.Content>
              </Accordion>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/*this.state.showTemplateRoleList &&
          <TemplateRoleList toggleTemplateRoleList={this.toggleTemplateRoleList}/>
        */}

        {this.state.showNewRoleModal &&
          <RoleModal
            role={this.state.selectedRole}
            addEditRole={this.addEditRole}
            isNewRole={this.state.isNewRole}
            closeRoleModal={this.closeRoleModal}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            eventId={this.props.eventId}
            eventDays={this.props.eventDays}
            event={this.props.event}
          />
        }
      </React.Fragment>
    );
  }
}

export default Role
