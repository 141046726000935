import * as actionType from "../constants/actionTypes";

const initialState={
  loading: false,
  data:[],
  errorMessage:null,
  filteredData:[],
  jobRating: {},
  eventReviews: []
}

export default (state=initialState,action)=>{
  switch (action.type){
    case actionType.GET_REVIEW_FULFILLED:{
      const st={
        ...state,
        data:action.responseData,
        filteredData:action.responseData
      }

      return st;
    }
    case actionType.GET_REVIEW_FAILED:{
      const st={
        ...state,
        errorMessage:action.errorMessage
      }

      return st;
    }
    case actionType.GET_RATING_SUCCESS:{
      return {
        ...state,
        jobRating: action.responseData
      };
    }
    case actionType.GET_RATING_ERROR:{
      return {
        ...state,
        errorMessage: action.errorMessage
      };
    }
    case actionType.RATE_JOB_SUCCESS:{
      return {
        ...state,
        jobRating: action.responseData
      };
    }
    case actionType.RATE_JOB_ERROR:{
      return {
        ...state,
        errorMessage: action.errorMessage
      };
    }
    case actionType.EVENT_REVIEW_LIST_SUCCESS:{
      return {
        ...state,
        eventReviews: action.responseData
      };
    }
    case actionType.EVENT_REVIEW_LIST_ERROR:{
      return {
        ...state,
        eventReviews: action.errorMessage
      };
    }
    case actionType.RATE_JOB_LOADING:
    case actionType.GET_RATING_LOADING:
    case actionType.EVENT_REVIEW_LIST_LOADING:
      {
      return {
        ...state,
        loading: action.value
      };
    }
    default:
      return state;
  }
}