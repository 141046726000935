import React,{Component} from 'react';
import {connect} from 'react-redux';

import {
  Segment,
  Header,
  Container,
  List,
  Icon,
  Button,
  Message,
  Menu} from 'semantic-ui-react';
import {addApplicant, deleteEventEventApplicant, updateApplicantion} from "../../../../../actions/jobDetailsTalentAction";
import {updateApplicant} from '../../../../../actions/event';
import moment from "moment";


const stateToProps=(state)=>{
  return{
    waitingRequest:state.jobsTalent.waitingAddApplicant,
    talent:state.user.profile.talent,
    errorMessage:state.jobsTalent.errorMessage,
    success: state.jobsTalent.success
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    addApplicant: (data) => dispatch(addApplicant(data)),
    deleteEventEventApplicant:(id)=>dispatch(deleteEventEventApplicant(id)),
    updateApplication: (currentId, newData) => dispatch(updateApplicantion(currentId, newData))
  }
};

class ApplySummary extends Component{
  constructor(props){
    super(props);
    this.state = {
      server_error:false,
    }
  }

  componentWillReceiveProps(nextProps){
      if(nextProps.success && !nextProps.errorMessage){
          setTimeout(() => window.location.reload(true), 1000);
      }
  }

  handleDismiss(event){
    this.setState({server_error:false});
  }

  renderMessage = () => {
      const {errorMessage, success} = this.props;
      if(success){
          return (
              <Message
              positive
              onDismiss={(e)=> this.handleDismiss(e)}
              header='Applied Successfully!'
              content={'Redirecting...'}
            />
          )
      }

      if(errorMessage){
          return (
              <Message
              negative
              onDismiss={(e)=> this.handleDismiss(e)}
              header='Error!'
              content={errorMessage}
            />
          )
      }
  };

  applyForJob = () => {
      const shifts = [];
      let eventId = '';
      if(this.props.status!==null){
        this.props.selectedShifts.map(day => {
          eventId = day.event;
             day.shifts.map(shift => {
              if(this.notApplyed(shift.id) || this.props.status === 'pending'){
                shifts.push({'event_shift': shift.id, 'portfolio': this.props.selectedPortfolio.id});
              }
            });
        });
      }else{
        this.props.selectedShifts.map(day => {
             day.shifts.map(shift => {
                shifts.push({'event_shift': shift.id, 'portfolio': this.props.selectedPortfolio.id});
            });
        });
      }
      if (this.props.status === 'pending') {
        let currentId = window.location.href.split('/job/').length ? window.location.href.split('/job/')[1] : '';
        if (currentId) {
          this.props.updateApplication(currentId, shifts);
        }
      } else {
        this.props.addApplicant(shifts);
      }
      
      // this.deletedShifts()
  };

  notApplyed = (shiftId) => {
    for(var i=0; i<this.props.applicants.length; i++){
      if(this.props.applicants[i].event_shift.id===shiftId)
        return false
    }
    return true;
  }

  deletedShifts = () => {
    let applicants = [];
    for(var i=0; i<this.props.applicants.length; i++){
      for(var j=0; j<this.props.selectedShifts.length; j++){
        for(var k=0; k<this.props.selectedShifts[j].shifts.length; k++){
          if(this.props.applicants[i].event_shift.id===this.props.selectedShifts[j].shifts[k].id && this.props.applicants[i].event_shift.event_day.id===this.props.selectedShifts[j].id){
            applicants.push(this.props.applicants[i])
          }
        }
      }
    }
    this.props.applicants.map((item)=>{
      let isDeleting=[]
      isDeleting=applicants.filter((itm)=>{
        return item.id===itm.id
      })
      if(isDeleting.length===0){
        this.props.deleteEventEventApplicant(item.id)
      }
    })
  }

  getShiftAmount = (shift) => {
    if (shift['talent_role']['pay_type'].toLowerCase() === 'hourly'){
        const shiftStartTime = moment.utc(shift['start_time'], 'HH:mm:ss');
        const shiftEndTime = moment.utc(shift['end_time'], 'HH:mm:ss');
        let duration = moment.duration(shiftEndTime.diff(shiftStartTime));
        duration = duration.asMinutes();
        const hours = (duration/60).toFixed(2);
        return (hours * parseFloat(shift['talent_role']['hourly_rate_talent'])).toFixed(2);
    }

    if (shift['talent_role']['pay_type'].toLowerCase() === 'fixed'){
        return parseFloat(shift['talent_role']['fixed_rate_talent']);
    }
  };

  renderShift = (shift, index) => {
      return (
          <div key={index}>
            <Icon name={'time'} style={{'color': '#774b86'}}/> &nbsp;
            <span><strong>{moment.utc(shift['start_time'], 'HH:mm:ss').local().format("hh:mm A")} - {moment.utc(shift['end_time'], 'HH:mm:ss').local().format("hh:mm A")}</strong></span>
          </div>
      )
  };

  renderDays = (day, index) => {
    const selectedDay = this.props.selectedDays.find(selectedDay => selectedDay.id === day.id);
    return (
      <Segment.Group key={index}>
        <Segment textAlign={'left'}>
          <Icon name={'calendar'} style={{'color': '#774b86'}}/>
          {moment.utc(day.date).format("MMM D, YYYY")}
        </Segment>
        <Segment secondary textAlign={'left'}>
          <List divided verticalAlign='middle'>
            <List.Item key={'1'}>
              <List.Content>
                  {day.shifts.map((shift, index) => this.renderShift(shift, index))}
                <div>
                  <Icon name={''}/> &nbsp;
                  <span>{day.shifts.length}/{selectedDay.shifts.length} Shifts Selected</span>
                </div>
                <hr/>
                <div>
                  <Icon name={'money'} style={{'color': '#774b86'}}/> &nbsp;
                  <span>
                  <strong>
                    ${day.shifts
                         .map(shift => this.getShiftAmount(shift))
                         .reduce((amount1, amount2) => (parseFloat(amount1) + parseFloat(amount2)).toFixed(2))
                     }
                  </strong>
                  </span>
                </div>
                <hr/>
                <div>
                  <Icon name={'marker'} style={{'color': '#774b86'}}/> &nbsp;
                  {day.location}
                </div>
                <hr/>
                <div className={'portfolio-div'}>
                  <Icon name={'user'} style={{'color': '#774b86'}}/> &nbsp;
                  <span><strong>{this.props.selectedPortfolio.title}</strong></span>
                </div>
              </List.Content>
            </List.Item>
          </List>
        </Segment>
      </Segment.Group>
    )
  };

  render(){
    return(
      <Segment attached raised>
        <Container textAlign='center'>
          <Header as={'h4'}>Confirm your work schedule and book this listing!</Header>

            {this.renderMessage()}

          <div className={'shift-time-div'}>
            {this.props.selectedShifts.map((day, index) => this.renderDays(day, index))}
          </div>


          <Button fluid
            disabled={!!this.props.success}
            size={'large'}
            content={this.props.status === 'pending' ? 'UPDATE' : 'APPLY'}
            color={'blue'}
            loading={this.props.waitingRequest}
            onClick={()=> this.applyForJob()}
          />
          <Menu secondary>
            <Menu.Item as='a'
                       style={{marginLeft:'auto',marginRight:'auto'}}
                       onClick={() => this.props.selectStep('shift_selection')}>
              <Icon name='arrow left' link/>
              Back
            </Menu.Item>
          </Menu>
        </Container>
      </Segment>
      )
  }
}

export default connect(stateToProps, dispatchToProps)(ApplySummary)
