import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, persistor, history } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Loader } from "semantic-ui-react";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import "react-datepicker/dist/react-datepicker.css";
import "semantic-ui-css/semantic.min.css";
import "./index.css";
import App from "./components/App/App";
import * as serviceWorker from "./serviceWorker";
import ErrorBoundary from "./components/Common/ErrorBoundary";
import ScrollToTop from "./ScrollToTop";
import StripeMobile from "./components/Common/StripeMobile";
import withTracker from "./withTracker";

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <ConnectedRouter
          onUpdate={() => window.scrollTo(0, 0)}
          history={history}
        >
          <Switch>
            <Route exact path="/stripe-mobile" component={StripeMobile} />
            <ScrollToTop>
              <Route
                path="/"
                component={withTracker(App, {
                  /* additional attributes */
                })}
              />
            </ScrollToTop>
          </Switch>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
