import axios from 'axios';

import {API_URL_BASE} from '../constants/constants';
import * as actionType from '../constants/actionTypes';
import {common} from '../utils/common';


export const registerCompany =(data)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.post(`${API_URL_BASE}/company/register`,data)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.COMPANY_UPDATE_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.COMPANY_UPDATE_ERROR);
            })
    }
}


export const updateCompany =(data)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});
        const {id} = data;
        delete data.id;
        axios.patch(`${API_URL_BASE}/company/profile/update/${id}`,data)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.COMPANY_UPDATE_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.COMPANY_UPDATE_ERROR);
            })
    }
};


export const getCompanyProfile =()=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.get(`${API_URL_BASE}/company/profile`)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.GET_COMPANY_PROFILE_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.GET_COMPANY_PROFILE_ERROR);
            })
    }
}


export const getCompanyName =(id)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});

        axios.get(`${API_URL_BASE}/company/companyinfo/`+id)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.GET_COMPANY_PROFILE_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.GET_COMPANY_PROFILE_ERROR);
            })
    }
}

export const updateCompanyProfileUser =(data)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});
        const {id} = data;
        delete data['id'];
        axios.put(`${API_URL_BASE}/user/update/profile/${id}`,data)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.UPDATE_USER);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.COMPANY_UPDATE_ERROR);
            })
    }
}


export const updateCompanyProfile =(data)=>{
    const commonObj=new common();

    return (dispatch)=>{

        dispatch({type: actionType.WAITING_REQUEST, value:true});
        let id = null;
        // this action could accept data in FormData format or Object format {}
        if (data instanceof FormData){
            id = data.get('id');
            data.delete('id');
        }else{
            id = data['id'];
            delete data['id'];
        }
        axios.patch(`${API_URL_BASE}/company/profile/update/${id}`,data)
            .then((response)=>{
                commonObj.handleServerSucess(response,dispatch, actionType.COMPANY_UPDATE_SUCCESS);
            })
            .catch((err) =>{
                commonObj.handleServerError(err,dispatch, actionType.COMPANY_UPDATE_ERROR);
            })
    }
}