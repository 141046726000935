import React,{Component} from 'react';
import {Segment, List, Radio} from 'semantic-ui-react';
import {userSettings} from "../../../actions/settingsAction";
import {connect} from "react-redux";


const stateToProps=(state)=>{
  return{
    errorMessage:state.settings.errorMessage,
    settings:state.settings.data,
    user:state.user.profile,
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    userSettings:(data)=> dispatch(userSettings(data)),
  }
};

class NotificationSettings extends Component{

  componentDidMount(){
    this.props.userSettings({});
  }

  updateSettings = (name) => {
    const {settings} = this.props;
    settings[name] = !settings[name];
    this.props.userSettings(settings);
  };

  render(){
    const {settings}=this.props;

    return(
      <div>
        <Segment.Group>
          <Segment secondary textAlign={'left'}>
          <span className={'panel-header'}>
            Notification Channels
          </span>
          </Segment>
          <Segment textAlign={'left'}>
            <List divided relaxed='very'>
              <List.Item key='1'>
                <List.Content>
                  <List.Header><strong>Push Notifications</strong></List.Header>
                  <List.Description >
                    Allow push notifications on your mobile device for all selected notification event types.
                  </List.Description>
                  <List.Description>
                    <Radio toggle onClick={()=>{this.updateSettings('is_push')}} checked={settings.is_push}/>
                  </List.Description>
                </List.Content>
              </List.Item>

              <List.Item key='2'>
                <List.Content>
                  <List.Header><strong>Emails</strong></List.Header>
                  <List.Description >
                    Send email summaries to <b>{this.props.user.email}</b> for all selected notification event types.
                  </List.Description>
                  <List.Description>
                    <Radio toggle onClick={()=>{this.updateSettings('is_email')}}  checked={settings.is_email}/>
                  </List.Description>
                </List.Content>
              </List.Item>

              <List.Item key='3'>
                <List.Content>
                  <List.Header><strong>SMS</strong></List.Header>
                  <List.Description >
                    Send SMS text messages to <b>{this.props.user.phone}</b> for all selected notification event types.

                  </List.Description>
                  <List.Description>
                    <Radio toggle onClick={()=>{this.updateSettings('is_sms')}} checked={settings.is_sms}/>
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Segment>
        </Segment.Group>
        <Segment.Group>
          <Segment secondary textAlign={'left'}>
          <span className={'panel-header'}>
            Notify Me About
          </span>
          </Segment>
          <Segment textAlign={'left'}>
            <List divided relaxed='very'>
              <List.Item key='5'>
            <List.Content>
              <List.Header style={{width:'100%'}}>
                <strong>Bookings</strong>
                <Radio toggle onClick={()=>{this.updateSettings('is_booking')}} checked={settings.is_booking} style={{float:'right'}}/>
              </List.Header>
            </List.Content>
          </List.Item>

          <List.Item key='1'>
            <List.Content>
              <List.Header style={{width:'100%'}}>
                <strong>Job Listings</strong>
                <Radio toggle onClick={()=>{this.updateSettings('is_job_listing')}} checked={settings.is_job_listing} style={{float:'right'}}/>
              </List.Header>
            </List.Content>
          </List.Item>

          <List.Item key='3'>
            <List.Content>
              <List.Header style={{width:'100%'}}>
                <strong>Direct Messages</strong>
                <Radio toggle onClick={()=>{this.updateSettings('is_direct')}} checked={settings.is_direct} style={{float:'right'}}/>
              </List.Header>
            </List.Content>
          </List.Item>

          <List.Item key='4'>
            <List.Content>
              <List.Header style={{width:'100%'}}>
                <strong>Group Messages</strong>
                <Radio toggle onClick={()=>{this.updateSettings('is_group')}} checked={settings.is_group} style={{float:'right'}}/>
              </List.Header>
            </List.Content>
          </List.Item>

          <List.Item key='2'>
            <List.Content>
              <List.Header style={{width:'100%'}}>
                <strong>Payments</strong>
                <Radio toggle onClick={()=>{this.updateSettings('is_payment')}} checked={settings.is_payment} style={{float:'right'}}/>
              </List.Header>
            </List.Content>
          </List.Item>
            </List>
          </Segment>
        </Segment.Group>
      </div>

    )
  }
}

export default connect(stateToProps,dispatchToProps)(NotificationSettings)