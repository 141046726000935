import * as actionType from "../constants/actionTypes";

const initialState={
  eventTimeSheets:[],
  success: false,
  error: null,
};

export default (state=initialState,action) =>{
  switch (action.type){

    case actionType.GET_TIMESHEETLINEITEM_LIST_SUCCESS:{
      return {
        ...state,
        error: null,
        eventTimeSheets: action.responseData
      };
    }

    case actionType.GET_TIMESHEETLINEITEM_LIST_ERROR:{
      return {
        ...state,
        success: false,
        error: action.errorMessage
      };
    }

    case actionType.UPDATE_EVENT_TIME_SHEET_ITEMS_SUCCESS:{
      return {
          ...state,
          error: null,
          success: true,
      };
    }

    case actionType.UPDATE_EVENT_TIME_SHEET_ITEMS_ERROR:{
      return {
          ...state,
          error: action.errorMessage,
          success: false,
      };
    }

    default:
      return state;
  }
}