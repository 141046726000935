import React, { Component } from "react";
import { Loader, Dimmer } from "semantic-ui-react";
import {
  CardElement,
  Elements,
  injectStripe,
  StripeProvider
} from "react-stripe-elements";
import ServerErrors from "../Common/ServerErrors";
import "./stripe.css";

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4"
        },
        padding: "20px"
      },
      invalid: {
        color: "#9e2146"
      }
    }
  };
};

class _CardForm extends Component {
  handleSubmit = ev => {
    ev.preventDefault();
    const { stripe, submitAction, invoice, payType } = this.props;
    if (stripe) {
      stripe.createToken().then(payload => {
        submitAction({ card_token: payload.token.id, id: invoice.id, payType });
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    return (
      <form onSubmit={e => this.handleSubmit(e)}>
        <label>
          <CardElement {...createOptions()} />
        </label>
        <button>Pay</button>
      </form>
    );
  }
}

const CardForm = injectStripe(_CardForm);

class StripeCardElement extends Component {
  renderLoader = () => {
    const { waitingRequest } = this.props;
    if (waitingRequest) {
      return (
        <Dimmer active inverted>
          <Loader active inline="centered" inverted>
            This may take a while...
          </Loader>
        </Dimmer>
      );
    }
  };

  render() {
    const { error, waitingRequest } = this.props;
    return (
      <div className={"stripe-card-container"}>
        {error && !waitingRequest && <ServerErrors errorMessage={error} />}
        {this.renderLoader()}
        <StripeProvider apiKey="pk_test_xY1WGpZXAAeY0ITEFqrKozgo">
          <Elements>
            <CardForm {...this.props} />
          </Elements>
        </StripeProvider>
      </div>
    );
  }
}

export default StripeCardElement;
