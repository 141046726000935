import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Segment, Header, Form, Button, Loader, Container} from 'semantic-ui-react';
import ReactPhoneInput from 'react-phone-input';
import { parseNumber } from 'libphonenumber-js'
import {common} from '../../../utils/common';
import {mobileVerificationStart} from '../../../actions/signupAction';
import VerifyCode from './VerifyCode';
import {BLANK_TEXT_ERROR_MESSAGE} from "../../../utils/Constants";
import ServerErrors from '../../Common/ServerErrors';
import ServerSuccess from "../../Common/ServerSuccess";
import  './../../../index.css';

const stateToProps=(state)=>{
  return{
      waitingRequest:state.common.waitingRequest,
      phoneVerifyMessage:state.signup.phoneVerifyMessage,
      phoneVerifyFailedMessage:state.signup.phoneVerifyFailedMessage,
      codeVerifyMessage: state.signup.codeVerifyMessage,
      user: state.user.profile
  }
}

const dispatchToProps=(dispatch)=>{
  return{
    mobileVerificationStart:(data)=>dispatch(mobileVerificationStart(data))
  }
}

class SignupMobile extends Component {
  commonObj=new common();

  constructor(props){
    super(props);
    this.handleChange=this.handleChange.bind(this);
    this.sendCode=this.sendCode.bind(this);
    this.renderForm = this.renderForm.bind(this);
    const {phone} = props.user;
    let countryCode = 'us';
    if (phone){
        countryCode = parseNumber(phone).country.toLowerCase();
    }

    this.state={
      form_submitted:false,
      form_error:false,
      server_error:false,
      phone: phone,
      countryCode: countryCode,
      errors:{phone:BLANK_TEXT_ERROR_MESSAGE},
    }
  }

  componentWillReceiveProps(nextProps){
        if (this.state.form_submitted &&
            (!nextProps.phoneVerifyMessage.success || nextProps.phoneVerifyFailedMessage)
        ){
            this.setState({form_error: false,server_error:true});
        }
        else{
            this.setState({server_error: false});
        }
  }

  handleChange = (phone) => {
    this.setState({phone});
  };

  sendCode(event){
    event.preventDefault();
    this.setState({form_submitted:true});

    if (!this.state.phone){
      return;
    }

    const {phone}=this.state;
    this.props.mobileVerificationStart({phone});

  }

  renderForm() {
      const {phone, countryCode}=this.state;

      return (
              <Form size='large' onSubmit={this.sendCode}>

                  <ReactPhoneInput
                      value={phone}
                      defaultCountry={countryCode}
                      onChange={this.handleChange}
                  />

                  <Button color='blue' fluid size='large' onClick={this.sendCode}>Continue</Button>
              </Form>
          )
  }

  render() {
    const {phone}=this.state;
    const {user, phoneVerifyMessage, phoneVerifyFailedMessage, codeVerifyMessage} = this.props;
    const isCodeVerified = codeVerifyMessage.success;
    const codeSent = phoneVerifyMessage.success;
    let successMsg = null;
    if (codeSent){
        successMsg = `Verification code sent to ${phone}.`
    }
    const errorMessage = (phoneVerifyMessage && phoneVerifyMessage.message) || phoneVerifyFailedMessage;

    return(
      <Segment attached raised style={{paddingTop:'40px', paddingBottom:'40px'}}>
        <Header size={'large'}>Ok {user.first_name} {user.last_name}, let's verify your mobile device.</Header>
        <Container textAlign='center' className={'signup-container'}>
          {this.state.server_error?
            <ServerErrors errorMessage={errorMessage} />
            :null
          }

            {this.renderForm()}

            {(codeSent && !isCodeVerified)?
                <ServerSuccess successMessage={successMsg} />
                :null
            }

          {(codeSent)?
            <VerifyCode phone={phone} />
            :
            null
          }

          {this.props.waitingRequest ?  <Loader active inline='centered' style={{marginTop: '10px'}} /> : null}
        </Container>
      </Segment>
    )
  }
}

export default connect(stateToProps,dispatchToProps)(SignupMobile)