import * as actionType from "../constants/actionTypes";
import {NEW_MESSAGE_ARRIVED} from "../constants/actionTypes";
import {UPDATE_CHANNEL_LIST_ON_REDUX, CHANNEL_LIST_SORTED} from "../constants/actionTypes";
import {UPDATE_ONE_TO_ONE_CHANNEL_LIST_ON_REDUX} from "../constants/actionTypes";
import {CHAT_TEXT_INPUT_FOCUSED} from "../constants/actionTypes";

const initialState={
  messageGroups: [],
  oneToOneChats: [],
  loadSuccess: false,
  updateSuccess: false,
  errorMessage: '',
  oneToOneChatParticipant: {},
  unreadMessageCount:0,
  chatTextInputFocused:false,
  isMsgGroupsSorted:false
};

export default (state=initialState,action) => {
  switch (action.type) {
    case actionType.MESSAGING_GROUPS_LOAD_SUCCESS: {
      let messageGroups = action.responseData.map((item) => {
        return {...item, messages: []}
      });

      return {
        ...state,
        loadSuccess: true,
        errorMessage: '',
        messageGroups: messageGroups,
        isMsgGroupsSorted:false
      };
    }

    case actionType.MESSAGING_GROUPS_LOAD_ERROR: {
      return {
        ...state,
        loadSuccess: false,
        errorMessage: action.errorMessage,
      };
    }

    case actionType.ONE_TO_ONE_CHATS_LOAD_SUCCESS: {
      return {
        ...state,
        loadSuccess: true,
        errorMessage: '',
        oneToOneChats: action.responseData
      };
    }

    case actionType.ONE_TO_ONE_CHATS_LOAD_ERROR: {
      return {
        ...state,
        loadSuccess: false,
        errorMessage: action.errorMessage,
      };
    }

    case actionType.UPDATE_CHANNEL_URL_SUCCESS: {
      let {messageGroups} = state;
      const updatedGroup = action.responseData;
      messageGroups = messageGroups.map(group => {
        if (group.id === updatedGroup.id) {
          group['channel_url'] = updatedGroup['channel_url'];
          return group;
        }
        return group;
      });
      return {
        ...state,
        loadSuccess: true,
        errorMessage: '',
        messageGroups: messageGroups
      };
    }

    case actionType.UPDATE_CHANNEL_URL_ERROR: {
      return {
        ...state,
        updateSuccess: false,
        errorMessage: action.errorMessage,
      };
    }

    case actionType.ONE_TO_ONE_CHAT_PARTICIPANT_GET_SUCCESS: {
      return {
        ...state,
        oneToOneChatParticipant: {...action.responseData}
      };
    }

    case actionType.ONE_TO_ONE_CHAT_PARTICIPANT_GET_ERROR: {
      return {
        ...state,
        oneToOneChatParticipant: {...action.responseData}
      };
    }

    case NEW_MESSAGE_ARRIVED: {
      return {...state, unreadMessageCount: action.value};
    }

    case UPDATE_CHANNEL_LIST_ON_REDUX: {
      let updatedChannel=action.value;
      let messageGroups=state.messageGroups.slice();

      const formattedMessageGroups=messageGroups.map(obj => {
        if (updatedChannel && obj.channel_url===updatedChannel.url && updatedChannel.lastMessage){
          return {...obj,lastMessageCreatedAt:updatedChannel.lastMessage.createdAt,unreadMessageCount:updatedChannel.unreadMessageCount}
        }

        return obj;
      });

      const shortedMessageGroups = formattedMessageGroups.sort((a,b)=>{
        if (a.lastMessageCreatedAt>b.lastMessageCreatedAt){
          return -1;
        }
        else if (a.lastMessageCreatedAt<b.lastMessageCreatedAt){
          return 1;
        }

        return 0;
      });

      return {...state, messageGroups: shortedMessageGroups};
    }

    case UPDATE_ONE_TO_ONE_CHANNEL_LIST_ON_REDUX: {
      const updatedChannel= action.value;
      const channels=state.oneToOneChats.slice();

      const updatedChannels=channels.map(obj => {
        let lastMessageCreatedAt=null;
        let unreadMessageCount=0;

        if (updatedChannel && obj.channel_url===updatedChannel.url && updatedChannel.lastMessage){
          lastMessageCreatedAt=updatedChannel.lastMessage.createdAt;
        }
        if (updatedChannel && obj.channel_url===updatedChannel.url){
          unreadMessageCount=updatedChannel.unreadMessageCount;
        }

        return {...obj,lastMessageCreatedAt:lastMessageCreatedAt,unreadMessageCount:unreadMessageCount};
      });

      return {...state, oneToOneChats: updatedChannels};
    }

    case CHANNEL_LIST_SORTED:{
      return {...state, isMsgGroupsSorted: true};
    }

    case CHAT_TEXT_INPUT_FOCUSED: {
      return {...state, chatTextInputFocused: action.value};
    }

    default:
      return state;
  }
}
