import React, { Component } from "react";
import {
  Container,
  Form,
  List,
  ListItem,
  Button,
  Grid,
  Accordion,
  Icon
} from "semantic-ui-react";
import _ from "lodash";
import { connect } from "react-redux";
import ServerErrors from "../../Common/ServerErrors";
import { convertToString, getUserName } from "../../../utils/common";
import { getEvent, createEvent, updateEvent } from "../../../actions/event";
import {
  getSkills,
  getCertifications,
  getTalentRoles
} from "../../../actions/talent";
import ReactTags from "react-tag-autocomplete";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment/moment";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import GooglePlaceComponent from "../../Common/GooglePlaceComponent";
import { SET_EMPTY_EVENT_CREATION_PROCESS } from "./../../../constants/actionTypes";
import Role from "./Role";
import "./create-event.css";

const dispatchToProps = dispatch => {
  return {
    getEvent: id => dispatch(getEvent(id)),
    getSkills: () => dispatch(getSkills()),
    getCertifications: () => dispatch(getCertifications()),
    getTalentRoles: (eventId, companyId) =>
      dispatch(getTalentRoles(eventId, companyId)),
    createEvent: event => dispatch(createEvent(event)),
    updateEvent: event => dispatch(updateEvent(event)),
    unsetEventInRedux: () =>
      dispatch({ type: SET_EMPTY_EVENT_CREATION_PROCESS })
  };
};

const stateToProps = state => {
  const defaultErrors = { talent_roles: [], contacts: [] };
  return {
    waitingRequest: state.common.waitingRequest,
    event: state.event.event,
    user: state.user.profile,
    errors: state.event.error || defaultErrors,
    success: state.event.success
  };
};

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewEvent: false,
      formSubmitted: false,
      event: {
        event_days: [],
        talent_roles: [],
        contacts: []
      },
      selectedContacts: [],
      contactOptions: [],
      evtLocAccordionActiveIndex: -1,
      errors: props.errors
    };
  }

  componentDidMount() {
    const existingEventId = this.props.match.params.id;
    const isNewEvent = existingEventId === undefined;
    this.setState({ isNewEvent });
    if (!isNewEvent) {
      this.props.getEvent(existingEventId);
    } else {
      const event = { event_days: [], talent_roles: [], contacts: [] };
      this.setState({ event }, () =>
        this.onEventDatesChange(moment(), moment())
      );
    }

    this.props.getSkills();
    this.props.getCertifications();
  }

  componentWillUnmount() {
    this.props.unsetEventInRedux();
  }

  componentWillReceiveProps(nextProps) {
    const { user, event, errors, success } = nextProps;

    // verify that all fields including nested fields has no error
    const isErrorExist = Object.values(errors).every(elem => !_.isEmpty(elem));

    if (
      success &&
      !isErrorExist &&
      this.state.formSubmitted &&
      !_.isEmpty(event)
    ) {
      this.props.history.push(`/event/${event.id}`);
    }

    const talentRoles = [];
    _.each(event["talent_roles"], role => {
      role["shifts"] = role.shifts.map(shift => {
        const { event_day } = shift;
        if (event_day) {
          const shiftDate = moment(event_day["date"] || shift.date).format(
            "YYYY-MM-DD"
          );
          const shiftStartTime = moment
            .utc(shift["start_time"], "HH:mm:ss")
            .local()
            .format("hh:mm A");
          const shiftEndTime = moment
            .utc(shift["end_time"], "HH:mm:ss")
            .local()
            .format("hh:mm A");
          return {
            ...shift,
            event_day: event_day["id"] || event_day,
            date: shiftDate,
            event_date: shiftDate,
            start: moment(
              `${shiftDate} ${shiftStartTime}`,
              "YYYY-MM-DD hh:mm A"
            ).toDate(),
            end: moment(
              `${shiftDate} ${shiftEndTime}`,
              "YYYY-MM-DD hh:mm A"
            ).toDate()
          };
        } else {
          return shift;
        }
      });
      talentRoles.push(role);
    });

    event["talent_roles"] = talentRoles;
    const { isNewEvent } = this.state;

    const selectedContacts = [];
    let contactOptions = [];
    _.each(user.company.contacts, contact => {
      // default selected contact are event owner and already selected contacts
      if (
        (user.id === contact.user.id && isNewEvent) ||
        event.contacts.includes(contact.id)
      ) {
        const selectedContact = {
          id: contact.id,
          name: getUserName(contact.user)
        };
        selectedContacts.push(selectedContact);
      } else {
        const otherContact = {
          id: contact.id,
          name: getUserName(contact.user)
        };
        contactOptions.push(otherContact);
      }
    });

    if (!isNewEvent) {
      this.setState({ event });
    }

    this.setState({ contactOptions, selectedContacts, errors });
  }

  addRoles(roles, eventTimezone) {
    const { errors, event } = this.state;
    delete errors["talent_roles"]["required"];
    event["talent_roles"] = roles;
    event["timezone"] = eventTimezone;
    this.setState({ event, errors });
  }

  deleteRoles(deletedRole) {
    const { event } = this.state;
    event["talent_roles"] = event["talent_roles"].filter(
      role => role.id !== deletedRole.id
    );
    this.setState({ event });
  }

  handleChange = (e, { name, value }) => {
    const { event, errors } = this.state;
    event[name] = value;
    errors[name] = [];
    this.setState({ event, errors });
  };

  onEventDatesChange(startDate, endDate) {
    if (!endDate) {
      endDate = startDate;
    }

    const { event, errors } = this.state;
    const selectedDaysCount = endDate.diff(startDate, "days");
    const existingDays = event["event_days"];
    event["event_days"] = [];
    for (let i = 0; i <= selectedDaysCount; i++) {
      const selectedDate = moment(startDate)
        .add(i, "days")
        .format("YYYY-MM-DD");
      const existingDay = existingDays.find(
        day =>
          moment(day.date, "YYYY-MM-DD").format("YYYY-MM-DD") === selectedDate
      );
      const day = {
        date: selectedDate,
        location: existingDay ? existingDay.location : "",
        lat: existingDay ? parseFloat(existingDay.lat) : null,
        lng: existingDay ? parseFloat(existingDay.lng) : null
      };
      event["event_days"].push(day);
    }

    event["start_date"] = moment(startDate).format("YYYY-MM-DD");
    event["end_date"] = moment(endDate).format("YYYY-MM-DD");
    delete errors["start_date"];
    delete errors["end_date"];
    this.setState({ event, evtLocAccordionActiveIndex: 0, errors });
  }

  onDeleteContact(i) {
    const { selectedContacts, contactOptions } = this.state;
    const deletedContact = selectedContacts.find(
      (contact, index) => index === i
    );
    contactOptions.push(deletedContact);
    selectedContacts.splice(i, 1);
    this.setState({ selectedContacts, contactOptions });
  }

  onSelectContact(contact) {
    const { selectedContacts } = this.state;
    let { contactOptions } = this.state;
    selectedContacts.push(contact);
    contactOptions = contactOptions.filter(cont => cont.id !== contact.id);
    this.setState({ selectedContacts, contactOptions });
  }

  handleLocationTextChange(address, index) {
    const { event } = this.state;
    event["event_days"][index].location = address;
    this.setState({ event });
  }

  handleLocationSelect(address, index) {
    const { event, errors } = this.state;
    geocodeByAddress(address).then(results => {
      getLatLng(results[0]).then(({ lat, lng }) => {
        event["event_days"][index]["location"] = address;
        event["event_days"][index]["lat"] = lat;
        event["event_days"][index]["lng"] = lng;
        this.setState({ event });
      });
    });

    delete errors["event_days"];
  }

  handleLocationClear(index) {
    const { event } = this.state;
    event["event_days"][index].location = "";
    this.setState({ event });
  }

  toggleEvtLocAccordion = () => {
    const { evtLocAccordionActiveIndex } = this.state;
    this.setState({
      evtLocAccordionActiveIndex: evtLocAccordionActiveIndex === 0 ? -1 : 0
    });
  };

  createEvent = () => {
    this.setState({ formSubmitted: true });
    const { isNewEvent } = this.state;
    let { event, selectedContacts } = this.state;
    let { talent_roles } = event;

    event["contacts"] = selectedContacts.map(contact => contact.id);

    if (talent_roles.length === 0) {
      const { errors } = this.state;
      errors["talent_roles"] = {};
      errors["talent_roles"]["required"] = "At least select one role";
      this.setState({ errors });
      return;
    }

    event["talent_roles"] = talent_roles;
    if (!isNewEvent) {
      this.props.updateEvent(event);
    } else {
      this.props.createEvent(event);
    }
  };

  renderFieldError = (fieldName, index = null) => {
    const { waitingRequest } = this.props;
    const { errors } = this.state;
    if (!waitingRequest && errors[fieldName]) {
      if (fieldName === "event_days") {
        return (
          <span className={"field-error"}>
            {convertToString(errors[fieldName][index])}
          </span>
        );
      }
      return (
        <span className={"field-error"}>
          {convertToString(errors[fieldName])}
        </span>
      );
    }
  };

  renderNestedErrors = errorType => {
    /*errorType: talent_roles, contacts*/
    const { errors } = this.state;
    const { waitingRequest } = this.props;
    const errorItems = [];
    if (waitingRequest) {
      return false;
    }

    Object.keys(errors[errorType]).map((fieldName, index) => {
      errorItems.push(
        <ListItem key={index} className={"field-error"}>
          {convertToString(errors[errorType][fieldName])}
        </ListItem>
      );
    });

    return <List>{errorItems}</List>;
  };

  render() {
    const eventTypeOptions = [
      { key: 0, value: "tradeshow exhibit", text: "Tradeshow Exhibit" },
      { key: 1, value: "workshop/seminar", text: "Workshop/Seminar" },
      { key: 2, value: "festival", text: "Festival" },
      { key: 3, value: "webinar/virtual event", text: "Webinar/Virtual Event" },
      { key: 4, value: "product demonstration", text: "Product Demonstration" },
      { key: 5, value: "corporate event", text: "Corporate Event" },
      { key: 6, value: "private event", text: "Private Event" }
    ];
    const {
      isNewEvent,
      contactOptions,
      selectedContacts,
      event,
      errors
    } = this.state;
    let { start_date, end_date, talent_roles } = event;
    start_date = start_date ? moment(start_date) : moment();
    end_date = end_date ? moment(end_date) : moment();
    return (
      <Container className={"main-container"}>
        <Grid stackable padded>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <Form size="small">
                {errors && <ServerErrors errorMessage={errors} />}
                <Accordion styled fluid>
                  <Accordion.Title
                    className={"bold-title"}
                    active={this.state.evtLocAccordionActiveIndex === 0}
                    index={0}
                    onClick={this.toggleEvtLocAccordion}
                  >
                    <Icon name={"info"} />
                    INFORMATION
                  </Accordion.Title>
                  <Accordion.Content active={true}>
                    <Grid>
                      <Grid.Row className={"step1-primary-info-row"}>
                        <Grid.Column width={8}>
                          <Form.Field>
                            <label>Event Name</label>
                            <Form.Input
                              name={"name"}
                              placeholder={"Name"}
                              value={event.name}
                              fieldtype="text"
                              error={
                                this.state.errors["name"] !== undefined &&
                                this.state.errors["name"].length !== 0
                              }
                              onChange={this.handleChange}
                            />
                            {this.renderFieldError("name")}
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column width={8}>
                          <Form.Field>
                            <label>Event Dates</label>
                            <DateRangePicker
                              startDate={moment(start_date)}
                              startDateId="123"
                              endDate={moment(end_date)}
                              endDateId="456"
                              onDatesChange={({ startDate, endDate }) =>
                                this.onEventDatesChange(startDate, endDate)
                              }
                              focusedInput={this.state.focusedInput}
                              onFocusChange={focusedInput =>
                                this.setState({ focusedInput })
                              }
                              minimumNights={0}
                            />
                            {this.renderFieldError("start_date")}
                            {this.renderFieldError("end_date")}
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className={"step1-primary-info-row"}>
                        <Grid.Column width={8}>
                          <Form.Field>
                            <label>Event Type</label>
                            <Form.Select
                              name={"event_type"}
                              value={event["event_type"]}
                              placeholder="Event Type"
                              options={eventTypeOptions}
                              error={
                                this.state.errors["event_type"] !== undefined &&
                                this.state.errors["event_type"].length !== 0
                              }
                              onChange={this.handleChange}
                              fieldtype="select"
                            />
                            {this.renderFieldError("event_type")}
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Form.Field>
                            <label>Event Type Name</label>
                            <Form.Input
                              placeholder="Event Type Name"
                              name="event_type_name"
                              value={event["event_type_name"]}
                              fieldtype="text"
                              error={
                                this.state.errors["event_type_name"] !==
                                  undefined &&
                                this.state.errors["event_type_name"].length !==
                                  0
                              }
                              onChange={this.handleChange}
                            />

                            {this.renderFieldError("event_type_name")}
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className={"step1-primary-info-row"}>
                        <Grid.Column width={16}>
                          <Form.Field>
                            <label>Company Users</label>
                            <ReactTags
                              autofocus={false}
                              placeholder={"Add Company Users"}
                              allowNew={false}
                              minQueryLength={0}
                              tags={selectedContacts}
                              suggestions={contactOptions}
                              error={
                                errors["contacts"] &&
                                this.renderNestedErrors("contacts")
                              }
                              handleDelete={this.onDeleteContact.bind(this)}
                              handleAddition={this.onSelectContact.bind(this)}
                            />

                            {errors["contacts"] &&
                              this.renderNestedErrors("contacts")}
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Form.Field>
                            <label>Details</label>
                            <Form.TextArea
                              style={{ minHeight: 150 }}
                              placeholder="Details"
                              name="details"
                              value={event["details"]}
                              fieldtype="text"
                              onChange={this.handleChange}
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Form.Field>
                            <label>Travel Tips</label>
                            <Form.TextArea
                              style={{ minHeight: 150 }}
                              placeholder="Information about getting to the location, entering a building, meeting information, badge pickup, parking details, etc..."
                              name="travel_tips"
                              value={event["travel_tips"]}
                              fieldtype="text"
                              onChange={this.handleChange}
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Accordion.Content>
                </Accordion>
              </Form>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={10}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Accordion styled fluid>
                      <Accordion.Title
                        className={"bold-title"}
                        active={this.state.evtLocAccordionActiveIndex === 0}
                        index={0}
                        onClick={this.toggleEvtLocAccordion}
                      >
                        <Icon name="dropdown" />
                        LOCATIONS
                      </Accordion.Title>
                      <Accordion.Content active={true}>
                        <Grid className={"grid-event-locations"}>
                          {event["event_days"].map((eventDay, index) => {
                            const { location } = eventDay;
                            const locationText =
                              typeof location === "string"
                                ? location
                                : location.address;
                            return (
                              <Grid.Row
                                key={index}
                                style={{
                                  paddingTop: "0",
                                  paddingBottom: "5px"
                                }}
                                verticalAlign="middle"
                              >
                                <Grid.Column
                                  width={3}
                                  style={{ paddingTop: "8px" }}
                                >
                                  {eventDay.date}
                                </Grid.Column>
                                <Grid.Column width={13}>
                                  <GooglePlaceComponent
                                    index={index}
                                    locationText={locationText}
                                    error={
                                      this.state.errors["event_days"] !==
                                        undefined &&
                                      this.state.errors["event_days"].length !==
                                        0
                                    }
                                    handleLocationTextChange={this.handleLocationTextChange.bind(
                                      this
                                    )}
                                    handleLocationSelect={this.handleLocationSelect.bind(
                                      this
                                    )}
                                    handleLocationClear={this.handleLocationClear.bind(
                                      this
                                    )}
                                  />
                                  {this.renderFieldError("event_days", index)}
                                </Grid.Column>
                              </Grid.Row>
                            );
                          })}
                        </Grid>
                      </Accordion.Content>
                    </Accordion>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Role
                      roles={talent_roles}
                      addRoles={this.addRoles.bind(this)}
                      deleteRoles={this.deleteRoles.bind(this)}
                      startDate={start_date}
                      endDate={end_date}
                      eventId={this.state.event.id}
                      eventDays={event["event_days"]}
                      event={event}
                    />
                    <Button
                      color="blue"
                      className="ui right floated button"
                      size="large"
                      style={{ marginTop: "30px" }}
                      loading={this.props.waitingRequest}
                      disabled={this.props.waitingRequest}
                      onClick={() => this.createEvent()}
                    >
                      {isNewEvent ? "Save" : "Update"}
                    </Button>
                    {errors["talent_roles"] &&
                      this.renderNestedErrors("talent_roles")}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default connect(
  stateToProps,
  dispatchToProps
)(Step1);
