import React, { Component } from 'react';
import {connect} from 'react-redux';
import {
  Container,
  Step} from 'semantic-ui-react';

import SignupMobileVerify from '../SignupMobileVerify';
import SignupGenderBirthday from "../SignupGenderBirthday";
import SignupPhoto from '../SignupPhoto';
import SignupArea from '../SignupArea';
import CompanySignup from '../CompanySignup';
import { setNextScreen } from "../../../actions/signupAction";

const stateToProps=(state)=>{
  return{
      signupNext: state.common.signupNext,
      user: state.user.profile
  }
}

const dispatchToProps=(dispatch)=>{
    return { dispatch }
}

class SignupNext extends Component {

  componentWillMount() {
    setNextScreen(this.props.dispatch);
  }


  skip = () => {
    if(this.props.user.type==="talent"){
      this.props.history.push('/talent/profile/'+ this.props.user.talent.id);
    }else{
      this.props.history.push('/profile-company-user/'+ this.props.user.id);
    }
  };

  render() {
    let stepElement=null;
    const userType = this.props.user.type;
    const signupNext =this.props.signupNext;

    if (signupNext==='mobile_verify') {
      stepElement = (<SignupMobileVerify/>);
    }
    else if (signupNext==='gender_birthday'){
      stepElement=(<SignupGenderBirthday/>)
    }
    else if (signupNext==='area'){
      stepElement=(<SignupArea/>)
    }
    else if (signupNext==='photo'){
      stepElement=(<SignupPhoto history={this.props.history} user={this.props.user} skip={this.skip}/>)
    }
    else if (signupNext==='company_signup'){
        stepElement=(<CompanySignup history={this.props.history}/>)
    }
    else{
      if (userType === 'talent') {
        this.props.history.push('/bookings');
      }
      else {
        this.props.history.push('/events');
      }
    }

    return(
      <Container textAlign='center' className={'main-container'}>
        <Step.Group widths={4} attached='top'>

            {/*common signup step for both talent and company*/}

          <Step active={signupNext==='mobile_verify'}>
            <Step.Content>
              <Step.Title>
                  Step 1 of {userType === 'company'? 2 : 4}
              </Step.Title>
              <Step.Description>Mobile device Verification</Step.Description>
            </Step.Content>
          </Step>

            {/*talent signup steps*/}

            {userType === 'talent' ?
                <Step active={signupNext==='gender_birthday'}>
                    <Step.Content>
                        <Step.Title>Step 2 of 4</Step.Title>
                        <Step.Description>Gender and Birthday</Step.Description>
                    </Step.Content>
                </Step>
            : null }

            {userType === 'talent' ?
                <Step active={signupNext==='area'}>
                    <Step.Content>
                        <Step.Title>Step 3 of 4</Step.Title>
                        <Step.Description>Locations</Step.Description>
                    </Step.Content>
                </Step>
            : null }

            {userType === 'talent' ?
                <Step active={signupNext==='photo'}>
                    <Step.Content>
                        <Step.Title>Step 4 of 4</Step.Title>
                        <Step.Description>Profile Photo</Step.Description>
                    </Step.Content>
                </Step>
            : null }


            {/*company signup steps*/}
            {userType === 'company'?
                <Step active={signupNext === 'company_signup'}>
                    <Step.Content>
                        <Step.Title>Step 2 of 2</Step.Title>
                        <Step.Description>Company Location</Step.Description>
                    </Step.Content>
                </Step>
            : null }

        </Step.Group>
        {stepElement}
      </Container>
    )
  }
}

export default connect(stateToProps, dispatchToProps)(SignupNext)
