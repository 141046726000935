import axios from 'axios';
import moment from 'moment';
import {API_URL_BASE} from '../constants/constants';


import {
  WAITING_REQUEST,
  GET_BOOKINGS_TALENT_FULFILLED,
  GET_BOOKINGS_TALENT_FAILED,
  GET_BOOKINGS_TALENT_FILTERED_DATA,
  GET_BOOKINGS_TALENT_WAITING
} from '../constants/actionTypes';
import {common} from '../utils/common';

export const getBookingsData = (token) => {
  const commonObj = new common();

  return (dispatch) => {
    dispatch({type: GET_BOOKINGS_TALENT_WAITING, value: true});

    axios.get(`${API_URL_BASE}/event/booked/talent`, {headers: {"Authorization": `Token ${token}`}})
      .then((response) => {
        commonObj.handleServerSucess(response, dispatch, GET_BOOKINGS_TALENT_FULFILLED);
        dispatch({type: GET_BOOKINGS_TALENT_WAITING, value: false});
      })
      .catch((err) => {
        commonObj.handleServerError(err, dispatch, GET_BOOKINGS_TALENT_FAILED);
        dispatch({type: GET_BOOKINGS_TALENT_WAITING, value: false});
      })
  }
}

export const getBookinsDataByDate = (selectedDate) => {
  const commonObj=new common();

  return (dispatch) => {
    dispatch({type:WAITING_REQUEST, value:true});

    axios.get(`${API_URL_BASE}/event/booked/talent`)
      .then((response)=>{
        let data=response.data;

        let filteredData=data.filter((item)=>{
          let startDate =moment(item.start_date,'MMMM-DD-YYYY').add(-1, 'days')
          let endDate   =moment(item.end_date,'MMMM-DD-YYYY').add(1, 'days');
          let isBetween=selectedDate.isBetween(startDate, endDate);

          return isBetween;
        });

        dispatch({type:GET_BOOKINGS_TALENT_FILTERED_DATA, filteredData:filteredData});
        dispatch({type:WAITING_REQUEST, value:false});
      })
      .catch((err) =>{
        commonObj.handleServerError(err,dispatch,GET_BOOKINGS_TALENT_FAILED);
      })
  }
}
