import {
  LOGIN_SUBMIT_FULFILLED, LOGIN_SUBMIT_ERROR, WAITING_REQUEST,
  } from "../constants/actionTypes";

const initialState={
  waitingRequest:false,
  errorMessage:null,
  user: {},
  }

export default (state=initialState,action) =>{
  switch (action.type){
    case WAITING_REQUEST:{
      const st={
        ...state,
        waitingRequest:action.waitingRequest
      }

      return st;
    }
    case LOGIN_SUBMIT_FULFILLED:{
      const st={
        ...state,
        errorMessage: null,
        user:action.responseData
      }

      return st;
    }
    case LOGIN_SUBMIT_ERROR: {
      const st = {
        ...state,
        errorMessage: action.errorMessage
      }
      return st;
    }
    default:
      return state;
  }
}