import React,{Component} from 'react';
import {connect} from "react-redux";
import {
  Segment,
  Radio,
  List,
  Grid,
  Icon,
  Feed,
  Button
} from 'semantic-ui-react';
import {common} from '../../../utils/common';
import {addSocialProfile, getSocialProfile, deleteSocialProfile} from '../../../actions/settingsAction';
import { Link } from 'react-router-dom';
import SocialButton from '../../Account/Signup/SocialButton';


const stateToProps=(state)=>{
  return{
    user:state.user.profile,
    social:state.settings.social,
    state: state,
  }
}

const dispatchToProps=(dispatch)=>{
  return{
    addSocialProfile:(data, user_id)=>dispatch(addSocialProfile(data, user_id)),
    getSocialProfile:(id)=>dispatch(getSocialProfile(id)),
    deleteSocialProfile:(id, user_id)=>dispatch(deleteSocialProfile(id,user_id)),
  }
}


class SocialSettings extends Component{

  commonObj=new common();

  constructor(props){
    super(props);

    this.state = {
      email: '',
      server_error:false,
      provider_id : '',
      type: '',
      provider_name: '',
      last_name: '',
      first_name: '',
      access_token: '',
      google_id:null,
      facebook_id:null,
    }
    this.nodes = {}

    this.onLoginSuccess = this.onLoginSuccess.bind(this)
    this.deleteSocialProfile = this.deleteSocialProfile.bind(this)
  }

  componentDidMount(){
    this.props.getSocialProfile(this.props.user.id)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({google_id:null,facebook_id:null})
    nextProps.social.map((item)=>{
      if(item.provider_name==="google"){
        this.setState({google_id:item.id})
      }
      if(item.provider_name==="facebook"){
        this.setState({facebook_id:item.id})
      }
    })
  }

  onLoginSuccess (user) {

    this.setState({
      logged: true,
      provider_id: user._profile.id,
      email: user._profile.email,
      type: 'talent',
      provider_name: user._provider,
      last_name: user._profile.lastName,
      first_name: user._profile.firstName,
      access_token: user._token.accessToken,
      user
    })

    const { provider_id, email, type, provider_name, last_name, first_name, access_token } = this.state;
    this.props.addSocialProfile({ provider_id, email, type, provider_name, last_name, first_name, access_token },this.props.user.id);
  }

  onLogoutFailure (err) {
    console.error(err)
  }

  onLoginFailure= (err)=> {
    console.error(err)
  }

  setNodeRef (provider, node) {
    if (node) {
      this.nodes[ provider ] = node
    }
  }

  deleteSocialProfile(id,type){
    this.props.deleteSocialProfile(id,this.props.user.id)
  }

  render(){
    let { google_id, facebook_id } = this.state
    return(
      <Segment.Group>
        <Segment secondary textAlign={'left'}>
          <span className={'panel-header'}>
            SOCIAL
          </span>
        </Segment>
        <Segment textAlign={'left'}>
          <Grid columns='two' divided>
            <Grid.Row>
              <Grid.Column>
                <Feed>
                  <Feed.Event>
                    <Feed.Content>
                      <SocialButton
                        disabled={this.state.google_id!==null}
                        provider='google'
                        appId='632902765335-nhliag2gj6qfoofcufqecmeucnlkaq4i.apps.googleusercontent.com'
                        onLoginSuccess={this.onLoginSuccess}
                        onLoginFailure={this.onLoginFailure}
                        onLogoutSuccess={this.onLogoutSuccess}
                        onLogoutFailure={this.onLogoutFailure}
                        getInstance={this.setNodeRef.bind(this, 'google')}
                        key={'google'}
                        fluid color={'google plus'}
                        size={'large'}
                        content='ADD GOOGLE'
                        icon='google' labelPosition='left'
                        style={{marginBottom:'25px'}}
                      />
                    </Feed.Content>
                    <Feed.Label><Button size="small" onClick={(e)=>this.deleteSocialProfile(this.state.google_id,"google")} disabled={this.state.google_id===null} style={{marginTop:'6px'}} icon>X</Button></Feed.Label>
                  </Feed.Event>
                </Feed>
              </Grid.Column>
              <Grid.Column>
                <Feed>
                  <Feed.Event>
                    <Feed.Content>
                      <SocialButton
                        disabled={this.state.facebook_id!==null}
                        provider='facebook'
                        appId='174051176589391'
                        onLoginSuccess={this.onLoginSuccess}
                        onLoginFailure={this.onLoginFailure}
                        onLogoutSuccess={this.onLogoutSuccess}
                        getInstance={this.setNodeRef.bind(this, 'facebook')}
                        key={'facebook'}
                        fluid color={'facebook'}
                        size={'large'}
                        content='ADD FACEBOOK'
                        icon='facebook f' labelPosition='left'
                        style={{marginBottom:'25px'}}
                      />
                    </Feed.Content>
                    <Feed.Label><Button size="small" onClick={(e)=>this.deleteSocialProfile(this.state.facebook_id,"facebook")} disabled={this.state.facebook_id===null} style={{marginTop:'6px'}} icon>X</Button></Feed.Label>
                  </Feed.Event>
                </Feed>
              </Grid.Column>
              <Grid.Column>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment.Group>
    )
  }
}

export default connect(stateToProps,dispatchToProps)(SocialSettings);